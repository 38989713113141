import axios from 'axios'

import { formatResponse, formatListResponse } from '@/utils/api'

const BASE_URL = `/api/ai`
const URLs = new Proxy(
  {
    FETCH_ALL_USER_SEARCH_REQUESTS: '/get-all-user-search-requests',

    // FETCH_ONE_USER_SEARCH_RESPONSE: `/get-user-search-responses/`,

    CREATE_ONE_QUEUE_USER_SEARCH: `/queue-user-search`
  },
  { get: (target, prop) => `${BASE_URL}${target[prop]}` }
)

export default {
  async fetchAllUserSearchRequests() {
    const res = await axios.get(URLs.FETCH_ALL_USER_SEARCH_REQUESTS)

    return formatListResponse(res)
  },
  // async fetchOneUserSearchResponse({ id }) {
  //   const res = await axios.get(`${URLs.FETCH_ONE_USER_SEARCH_RESPONSE}${id}`)

  //   return formatListResponse(res)
  // },
  async createOneQueueUserSearch(payload) {
    const res = await axios.post(URLs.CREATE_ONE_QUEUE_USER_SEARCH, payload)

    return formatResponse(res)
  }
}
