import axios from 'axios'

import { formatListResponse } from '@/utils/api'

const BASE_URL = `/api/team-member`
const URLs = new Proxy(
  {
    FETCH_ALL_BY_PRINCIPAL: '/get-by-principal/',

    DELETE_ONE: '/delete/'
  },
  { get: (target, prop) => `${BASE_URL}${target[prop]}` }
)

export default {
  // userId, userTypeCode
  async fetchAllByPrincipal({ id, typeCode = '1' }) {
    const res = await axios.get(
      `${URLs.FETCH_ALL_BY_PRINCIPAL}${id}/${typeCode}`
    )

    return formatListResponse(res)
  },

  async deleteOne(id) {
    const res = await axios.delete(`${URLs.DELETE_ONE}${id}`)
    const { isSuccess, error } = res

    return { isSuccess, error }
  }
}
