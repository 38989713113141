<template>
  <!-- MYZIURA 3 Update to services -->
  <v-autocomplete
    class="grow-0 white"
    :value="inputValue"
    :label="label"
    :placeholder="placeholder || label"
    no-data-text="No results"
    item-value="id"
    :item-text="defineItemText"
    clearable
    outlined
    hide-details
    return-object
    :dense="!isChips"
    :multiple="isMultiple"
    :disabled="isDisabled || teams.isInitialLoading"
    :rules="rules"
    :search-input="teams.searchInput"
    :items="filteredList"
    :loading="teams.isLoading || teams.isInitialLoading"
    @update:search-input="handleUpdateSearchInput"
    @change="handleSelect"
  >
    <template v-if="isChips" #selection="data">
      <v-chip
        v-if="isChipsClickable"
        v-bind="data.attrs"
        small
        :close="!isDisabled"
        @click="() => handleClickSelectedChip(data.item)"
        @click:close="() => handleRemoveSelectedChip(data.item)"
      >
        <span class="underline">
          {{ data.item.name }} ({{ data.item.memberCount }})
        </span>
        <v-icon right small>mdi-open-in-new</v-icon>
      </v-chip>

      <v-chip
        v-else
        v-bind="data.attrs"
        small
        :close="!isDisabled"
        @click:close="() => handleRemoveSelectedChip(data.item)"
      >
        <span>{{ data.item.name }}</span>
      </v-chip>
    </template>

    <!-- <template #item="{ item }">
        <span>{{ item.name }} {{ item.memberCount }}</span>
      </template> -->
  </v-autocomplete>
</template>

<script>
import debounce from 'lodash/debounce'

import $api from '@/api'

import * as notify from '@/utils/notify'
import { defineIsEqual } from '@/utils/base'

export default {
  name: 'BasePickerTeam',

  props: {
    // ids or id
    value: { type: [Array, Number], default: () => [] },
    label: { type: String, default: 'Select a team' },
    placeholder: { type: String, default: 'Type a name' },

    listFilter: { type: Function, default: null },

    rules: { type: Array, default: () => [] },

    isMultiple: { type: Boolean, default: false },
    isChips: { type: Boolean, default: false },
    isChipsClickable: { type: Boolean, default: false },

    isDisabled: { type: Boolean, default: false }
  },

  data: () => ({
    teams: {
      list: [],
      selected: [],

      searchInput: '',

      isLoading: false,
      isInitialLoading: false
    }
  }),

  computed: {
    inputValue() {
      return this.isMultiple ? this.teams.selected : this.teams.selected[0]
    },

    filteredList() {
      if (this.listFilter) return this.listFilter(this.teams.list)

      return this.teams.list
    },

    normalized() {
      return {
        value: [this.value].flatMap(item => item).filter(Boolean),
        selected: this.teams.selected.map(item => item.id)
      }
    }
  },

  watch: {
    value: {
      immediate: true,
      handler() {
        const { value, selected } = this.normalized

        const isEqual = defineIsEqual(value, selected)

        if (isEqual) return

        this.initInputValue()
      }
    }
  },

  methods: {
    defineItemText(item) {
      return `${item.name} (${item.memberCount})`
    },

    handleInput() {
      this.$emit('input', this.inputValue)
    },

    handleClearSelected() {
      this.teams.selected = []
    },

    handleSelect(value) {
      this.teams.selected = (this.isMultiple ? value : [value]).filter(Boolean)
      this.teams.searchInput = ''

      this.handleInput()
    },

    handleClickSelectedChip(team) {
      this.$router.open({
        name: 'admin.teams',
        params: { id: team.id }
      })
    },

    handleRemoveSelectedChip(team) {
      this.teams.selected = this.teams.selected.filter(
        selected => selected.id !== team.id
      )

      this.handleInput()
    },

    handleUpdateSearchInput: debounce(function (searchInput = '') {
      if (!searchInput) return

      this.teams.searchInput = searchInput.trim()

      this.fetchListByKeyword({ searchInput })
    }, 500),

    async initInputValue() {
      const ids = this.normalized.value

      if (!ids?.length) return

      await this.fetchListByIds(ids)
    },

    async fetchListByIds(ids) {
      this.teams.isInitialLoading = true

      try {
        const { list, error } = await $api.teams.fetchAllByIds(ids)

        if (error) {
          notify.error({ text: error })
        } else {
          this.teams.list = list
          this.teams.selected = list
        }
      } catch (error) {
        notify.error({ text: error })
      } finally {
        this.teams.isInitialLoading = false
      }
    },

    async fetchListByKeyword({ searchInput }) {
      this.teams.isLoading = true

      try {
        const { list, isSuccess, error } = await $api.teams.fetchAllByKeyword(
          searchInput
        )

        if (error) {
          notify.error({ text: error })
        } else {
          const selected = this.isMultiple
            ? this.teams.list.filter(item =>
                this.teams.selected.map(item => item.id).includes(item.id)
              )
            : []

          this.teams.list = isSuccess ? [...selected, ...list] : []
        }
      } catch (error) {
        notify.error({ text: error })
      } finally {
        this.teams.isLoading = false
      }
    }
  }
}
</script>
