import axios from 'axios'

import {
  formatResponse,
  formatItemResponse,
  formatListResponse
} from '@/utils/api'

const BASE_URL = `/api/contracts`
const URLs = new Proxy(
  {
    FETCH_ALL: `/get-all`,
    FETCH_ONE_BY_ID: `/get-by-id/`,

    CREATE_ONE: '/create',
    UPDATE_ONE: '/update',
    DELETE_ONE: '/delete/',

    FETCH_ALL_FOR_PRINCIPAL: `/get-for-principal`,
    DOWNLOAD_ONE_FOR_PRINCIPAL: `/download-pdf-for-principal/`,

    CREATE_ONE_FOR_PRINCIPAL: '/create-for-principal',
    CREATE_ONE_FOR_PRINCIPAL_WITH_UPLOAD: '/create-for-principal-with-upload',
    UPDATE_ONE_FOR_PRINCIPAL: '/update-for-principal',
    DELETE_ONE_FOR_PRINCIPAL: '/delete-for-principal/',

    SAVE_USER_NDA: '/save-user-nda',
    DOWNLOAD_ONE_AS_WORD_BY_HTML: '/get-contract-content-as-word-with-html'
  },
  { get: (target, prop) => `${BASE_URL}${target[prop]}` }
)

export default {
  async fetchAll() {
    const res = await axios.get(URLs.FETCH_ALL)

    return formatListResponse(res)
  },

  async createOne(payload) {
    const res = await axios.post(URLs.CREATE_ONE, payload)

    return formatItemResponse(res)
  },
  async updateOne(payload) {
    const res = await axios.put(URLs.UPDATE_ONE, payload)

    return formatItemResponse(res)
  },
  async deleteOne(id) {
    const res = await axios.delete(`${URLs.DELETE_ONE}${id}`)

    return formatItemResponse(res)
  },

  async fetchAllForPrincipal({ id, userTypeCode }) {
    const res = await axios.post(URLs.FETCH_ALL_FOR_PRINCIPAL, {
      principalId: id,
      userTypeCode
    })

    return formatListResponse(res)
  },

  async downloadOneForPrincipal(principalToContractId) {
    const res = await axios.get(
      `${URLs.DOWNLOAD_ONE_FOR_PRINCIPAL}${principalToContractId}`,
      {
        responseType: 'blob'
      }
    )

    return res
  },

  async createOneForPrincipal(payload) {
    const res = await axios.post(URLs.CREATE_ONE_FOR_PRINCIPAL, payload)

    return formatItemResponse(res)
  },
  async createOneForPrincipalWithUpload(payload) {
    const res = await axios.post(
      URLs.CREATE_ONE_FOR_PRINCIPAL_WITH_UPLOAD,
      payload
    )

    return formatItemResponse(res)
  },
  async updateOneForPrincipal(payload) {
    const res = await axios.put(URLs.UPDATE_ONE_FOR_PRINCIPAL, payload)

    return formatItemResponse(res)
  },
  async deleteOneForPrincipal(id) {
    const res = await axios.delete(`${URLs.DELETE_ONE_FOR_PRINCIPAL}${id}`)

    return formatItemResponse(res)
  },

  async saveUserNda(payload) {
    const res = await axios.put(URLs.SAVE_USER_NDA, payload)

    return formatResponse(res)
  },
  async downloadOneAsWordByHtml({ contractId, htmlContent, forCalifornia }) {
    const res = await axios.post(
      URLs.DOWNLOAD_ONE_AS_WORD_BY_HTML,
      { contractId, htmlContent, forCalifornia },
      { responseType: 'blob' }
    )

    return res
  }
}
