<template>
  <div class="h-full flex flex-1 flex-col">
    <div class="w-full p-8 flex flex-col">
      <h1 class="text-xl md:text-2xl font-black text-black">
        Choose Your Company
      </h1>
    </div>

    <div class="h-full flex flex-col">
      <router-link
        v-for="tenant of normalizedList"
        :key="tenant.id"
        :data-test="tenant.marker"
        class="group relative w-full h-full flex overflow-hidden"
        :to="{ name: 't.auth.login', params: { tenantMarker: tenant.marker } }"
        :style="`background: linear-gradient(45deg, ${tenant.colors.primary}, ${tenant.colors.secondary})`"
      >
        <div
          class="w-full my-auto flex flex-col text-center items-center text-white z-[1] transition"
        >
          <div
            class="w-[18rem] 2xl:w-auto gap-4 flex items-center sm:scale-125"
          >
            <BaseLogo :to="null" :tenant-marker="tenant.marker" />

            <div class="flex flex-col text-left">
              <strong data-test="avatar-title">{{ tenant?.name }}</strong>
              <small>{{ tenant?.description }}</small>
            </div>
          </div>
        </div>

        <div
          class="absolute inset-0 h-full w-full bg-black/25 transition duration-150 group-hover:bg-black/0"
        />
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import * as themeChanger from '@/utils/theme-changer'

import BaseLogo from '@/components/Base/BaseLogo.vue'

export default {
  name: 'PublicHomePage',

  components: { BaseLogo },

  computed: {
    ...mapState('tenants', ['tenants']),

    normalizedList() {
      return this.tenants?.list?.filter(item => !item.isInternal)
    }
  },

  mounted() {
    themeChanger.setDefaultTheme()
  }
}
</script>
