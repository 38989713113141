import * as services from '@/services'

const DEFAULT_LIMIT = 50

const defaultFilters = Object.freeze({
  hubName: '',
  orderBy: 'createdAt',
  isSortAscending: false,
  isIncludeDeleted: false,
  offset: 0,
  limit: DEFAULT_LIMIT
})

const defineSearchPayloadFromFilters = filters => ({
  keyword: filters.hubName,
  orderBy: filters.orderBy,
  sortOrder: !filters.isSortAscending,
  includeDeleted: filters.isIncludeDeleted,
  batchSize: filters.limit,
  pageNumber: Math.max(parseInt(filters.offset / filters.limit) + 1, 1)
})

export default {
  namespaced: true,

  state: {
    hubs: {
      content: null,
      isLoading: false,

      rows: {
        list: [],
        selected: []
      },

      filters: structuredClone(defaultFilters),
      defaultFilters: structuredClone(defaultFilters)
    }
  },

  actions: {
    async SEARCH_PAGED({ commit, state }) {
      await commit('SET_FILTERS_OFFSET', 0)

      const filters = state.hubs.filters

      await Promise.all([
        commit('SET_IS_LOADING', true),
        commit('SET_DEFAULT_FILTERS', filters),
        commit('SET_CONTENT', null),
        commit('SET_ROWS_LIST', []),
        commit('SET_ROWS_SELECTED', [])
      ])

      const payload = defineSearchPayloadFromFilters(filters)

      const { content } = await services.hubs.searchPaged(payload)

      await Promise.all([
        commit('SET_CONTENT', { ...content, results: undefined }),
        commit('SET_ROWS_LIST', content?.results),
        commit('SET_IS_LOADING', false)
      ])

      return content
    },

    async SEARCH_PAGED_ROWS_ONLY({ commit, state }) {
      const { offset, limit } = state.hubs.filters

      await Promise.all([
        commit('SET_FILTERS_OFFSET', offset + DEFAULT_LIMIT),
        commit('SET_FILTERS_LIMIT', limit)
      ])

      const filters = state.hubs.filters

      commit('SET_DEFAULT_FILTERS', filters)

      const payload = defineSearchPayloadFromFilters(filters)

      const { content } = await services.hubs.searchPaged(payload)

      commit('APPEND_TO_ROWS_LIST', content?.results || [])

      return content
    }
  },

  mutations: {
    SET_CONTENT(state, content) {
      state.hubs.content = content
    },
    SET_IS_LOADING(state, isLoading) {
      state.hubs.isLoading = isLoading
    },

    SET_ROWS_LIST(state, list) {
      state.hubs.rows.list = list
    },
    APPEND_TO_ROWS_LIST(state, list) {
      state.hubs.rows.list.push(...list)
    },
    SET_ROWS_SELECTED(state, selected) {
      state.hubs.rows.selected = selected
    },

    UPDATE_FILTERS(state, toUpdate) {
      state.hubs.filters = { ...state.hubs.filters, ...toUpdate }
    },
    SET_FILTERS_HUB_NAME(state, hubName) {
      state.hubs.filters.hubName = hubName
    },
    SET_FILTERS_ORDER_BY(state, orderBy) {
      state.hubs.filters.orderBy = orderBy
    },
    SET_FILTERS_IS_SORT_ASCENDING(state, isSortAscending) {
      state.hubs.filters.isSortAscending = isSortAscending
    },
    SET_FILTERS_OFFSET(state, offset) {
      state.hubs.filters.offset = offset
    },
    SET_FILTERS_LIMIT(state, limit) {
      state.hubs.filters.limit = limit
    },

    SET_FILTERS(state, filters) {
      state.hubs.filters = filters
    },
    RESET_FILTERS(state) {
      state.hubs.filters = structuredClone(defaultFilters)
      state.hubs.defaultFilters = structuredClone(defaultFilters)
    },

    SET_DEFAULT_FILTERS(state, defaultFilters) {
      state.hubs.defaultFilters = structuredClone(defaultFilters)
    }
  }
}
