import * as notify from '@/utils/notify'
import * as services from '@/services'

export default {
  namespaced: true,

  state: {
    uris: {
      list: [],
      isLoading: false
    },

    uriTypes: {
      list: [],
      isLoading: false
    }
  },

  actions: {
    async FETCH_ALL_BY_PRINCIPAL({ rootState, commit }, principal) {
      commit('SET_URIS_IS_LOADING', true)

      principal = principal || rootState.user.user

      const payload = {
        principalId: principal.id,
        userTypeCode: principal.userTypeCode
      }

      try {
        const { list } = await services.uris.fetchAllByPrincipal(payload)

        await commit('SET_URIS_LIST', list)
      } catch (error) {
        notify.error({ text: error })
      } finally {
        commit('SET_URIS_IS_LOADING', false)
      }
    },

    async SAVE_ONE({ dispatch }, payload) {
      let result = {}

      try {
        result = await services.uris.saveOne(payload)

        const { principalId, userTypeCode } = payload

        await dispatch('FETCH_ALL_BY_PRINCIPAL', {
          id: principalId,
          userTypeCode
        })
      } catch (error) {
        notify.error({ text: error })
      }

      return result
    },

    async DELETE_ONE({ commit, dispatch }, { principal, id }) {
      let result = {}

      commit('SET_URIS_IS_LOADING', true)

      try {
        result = await services.uris.deleteOne({ principal, id })

        const { principalId, userTypeCode } = principal

        await dispatch('FETCH_ALL_BY_PRINCIPAL', {
          id: principalId,
          userTypeCode
        })
      } catch (error) {
        notify.error({ text: error })
      } finally {
        commit('SET_URIS_IS_LOADING', false)
      }

      return result
    },

    async FETCH_ALL_URI_TYPES({ commit }) {
      commit('SET_URI_TYPES_IS_LOADING', true)

      try {
        const { list, error } = await services.uris.fetchAllUserUriTypes()

        if (error) {
          notify.error({ text: error })
          return
        }

        commit('SET_URI_TYPES_LIST', list)
      } catch (error) {
        notify.error({ text: error })
      } finally {
        commit('SET_URI_TYPES_IS_LOADING', false)
      }
    }
  },

  mutations: {
    SET_URIS_LIST(state, list) {
      state.uris.list = list || []
    },
    SET_URIS_IS_LOADING(state, isLoading) {
      state.uris.isLoading = isLoading
    },

    SET_URI_TYPES_LIST(state, list) {
      state.uriTypes.list = list
    },
    SET_URI_TYPES_IS_LOADING(state, isLoading) {
      state.uriTypes.isLoading = isLoading
    }
  }
}
