<template>
  <v-card class="max-w-lg flex !bg-white !border-none overflow-hidden">
    <div class="p-2 flex flex-col text-base">
      <span class="font-bold">Congratulations!</span>
      <span>
        You've been selected as a <em>featured creative!</em>
        Now you can share your creative statement and images of your work
      </span>
    </div>

    <div
      v-ripple
      class="p-4 w-16 flex items-center justify-center bg-primary !rounded-none cursor-pointer"
      @click="handleOpenFeaturedArtist"
    >
      <span class="text-white font-medium">Share</span>
    </div>
  </v-card>
</template>

<script>
export default {
  name: 'AppHeaderNotifsSidebarTabsFeaturedCreativesInvitations',

  methods: {
    handleOpenFeaturedArtist() {
      this.$emit('close')

      setTimeout(() => {
        this.$router.push({ name: 't.featured-creatives' })
      })
    }
  }
}
</script>
