export class PauseableTimeout {
  constructor(callback, delay) {
    this.callback = callback
    this.delay = delay
    this.remainingTime = this.delay

    this.startTime = null
    this.timerId = null
  }

  start() {
    this.startTime = new Date()
    this.timerId = setTimeout(this.callback, this.remainingTime)
  }

  stop() {
    this.pause()
    this.timerId = null
    this.remainingTime = this.delay
  }

  pause() {
    clearTimeout(this.timerId)
    this.remainingTime -= new Date() - this.startTime
  }

  resume() {
    this.startTime = new Date()

    clearTimeout(this.timerId)
    this.timerId = setTimeout(this.callback, this.remainingTime)
  }
}
