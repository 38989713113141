<template>
  <!-- MYZIURA 3 Update to services -->
  <div>
    <v-autocomplete
      class="grow-0 white"
      :value="inputValue"
      :label="label"
      :placeholder="placeholder || label"
      no-data-text="No results"
      item-value="id"
      :item-text="getItemText"
      clearable
      outlined
      hide-details
      return-object
      :autofocus="isAutofocus"
      :dense="!isChips"
      :multiple="isMultiple"
      :disabled="isDisabled || users.isInitialLoading || isLoading"
      :rules="rules"
      :search-input.sync="users.searchInput"
      :items="filteredList"
      :loading="users.isLoading || users.isInitialLoading || isLoading"
      @update:search-input="handleUpdateSearchInput"
      @change="handleSelect"
    >
      <template v-if="isChips" #selection="data">
        <v-chip
          v-if="isChipsClickable"
          v-bind="data.attrs"
          small
          :close="!isDisabled"
          @click="() => handleClickSelectedChip(data.item)"
          @click:close="() => handleRemoveSelectedChip(data.item)"
        >
          <span class="underline">{{
            data.item.name || data.item.fullName
          }}</span>
          <v-icon class="ml-1" small>mdi-open-in-new</v-icon>
        </v-chip>

        <v-chip
          v-else
          v-bind="data.attrs"
          small
          :close="!isDisabled"
          @click:close="() => handleRemoveSelectedChip(data.item)"
        >
          <span>{{ data.item.name || data.item.fullName }}</span>
        </v-chip>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import debounce from 'lodash/debounce'

import $api from '@/api'

import * as notify from '@/utils/notify'
import { defineIsEqual } from '@/utils/base'

const TYPES = Object.freeze({
  ALL: 'all',
  ADMINS: 'administrator'
})

export { TYPES }
export default {
  name: 'BasePickerUser',

  props: {
    // ids or id
    value: { type: [Array, Number], default: () => [] },
    label: { type: String, default: 'Select a user' },
    placeholder: { type: String, default: 'Type a name' },

    rules: { type: Array, default: () => [] },

    type: {
      type: String,
      default: TYPES.ALL,
      validator: type => Object.values(TYPES).includes(type)
    },

    listFilter: { type: Function, default: null },

    isMultiple: { type: Boolean, default: false },
    isChips: { type: Boolean, default: false },
    isChipsClickable: { type: Boolean, default: false },

    isAutofocus: { type: Boolean, default: false },

    isDisabled: { type: Boolean, default: false },
    isLoading: { type: Boolean, default: false }
  },

  data: () => ({
    users: {
      list: [],
      selected: [],

      searchInput: '',

      isLoading: false,
      isInitialLoading: false
    }
  }),

  computed: {
    inputValue() {
      return this.isMultiple ? this.users.selected : this.users.selected[0]
    },

    filteredList() {
      if (this.listFilter) return this.listFilter(this.users.list)

      return this.users.list
    },

    isType() {
      return {
        all: this.type === TYPES.ALL,
        admins: this.type === TYPES.ADMINS
      }
    },

    normalized() {
      return {
        value: [this.value].flatMap(item => item).filter(Boolean),
        selected: this.users.selected.map(item => item.id)
      }
    }
  },

  watch: {
    value: {
      immediate: true,
      handler() {
        const { value, selected } = this.normalized

        const isEqual = defineIsEqual(value, selected)

        if (isEqual) return

        this.initInputValue()
      }
    }
  },

  methods: {
    handleInput() {
      this.$emit('input', this.inputValue)
    },

    handleClearSelected() {
      this.users.selected = []
    },

    handleSelect(value) {
      this.users.selected = (this.isMultiple ? value : [value]).filter(Boolean)
      this.users.searchInput = ''

      this.handleInput()
    },

    handleClickSelectedChip(user) {
      this.$router.open({
        name: 'admin.people.id',
        params: { id: user.id }
      })
    },

    handleRemoveSelectedChip(user) {
      this.users.selected = this.users.selected.filter(
        selected => selected.id !== user.id
      )

      this.handleInput()
    },

    handleUpdateSearchInput: debounce(function (searchInput = '') {
      if (!searchInput) return

      this.users.searchInput = searchInput.trim()

      this.fetchList({ searchInput })
    }, 500),

    async initInputValue() {
      const ids = this.normalized.value

      if (!ids?.length) return

      await this.fetchListByIds(ids)
    },

    async fetchListByIds(ids) {
      this.users.isInitialLoading = true

      try {
        const { list, error } = await $api.users.fetchAllByIds(ids)

        if (error) {
          notify.error({ text: error })
        } else {
          this.users.list = list
          this.users.selected = list
        }
      } catch (error) {
        notify.error({ text: error })
      } finally {
        this.users.isInitialLoading = false
      }
    },

    fetchMethod({ searchInput }) {
      return this.isType.all
        ? $api.users.fetchAllByKeyword(searchInput)
        : $api.users.fetchAllByRoleName(TYPES.ADMINS)
    },

    async fetchList({ searchInput }) {
      this.users.isLoading = true

      try {
        const { list, isSuccess, error } = await this.fetchMethod({
          searchInput
        })

        if (error) {
          notify.error({ text: error })
        } else {
          const selected = this.isMultiple
            ? this.users.list.filter(item =>
                this.users.selected.map(item => item.id).includes(item.id)
              )
            : []

          this.users.list = isSuccess ? [...selected, ...list] : []
        }
      } catch (error) {
        notify.error({ text: error })
      } finally {
        this.users.isLoading = false
      }
    },

    getItemText(item) {
      return item.name || item.fullName
    }
  }
}
</script>
