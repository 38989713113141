import vuetify from '@/plugins/vuetify'

import { COLORS } from '@/utils/theme'
import * as titleManager from '@/utils/title-manager'

const DEFAULT_COLORS = Object.freeze({
  primary: COLORS.PRIMARY,
  accent: COLORS.ACCENT,
  secondary: COLORS.SECONDARY,
  success: COLORS.SUCCESS,
  info: COLORS.INFO,
  warning: COLORS.WARNING,
  error: COLORS.ERROR
})

const toHex = hexa => {
  if (hexa?.match(/#[a-zA-Z0-9]{8}/)) {
    return hexa.substring(0, 7)
  }

  return hexa
}

export const defineColorsByTenant = tenant => {
  const styleJson = JSON.parse(tenant.styleJson)

  const colors = {
    primary: toHex(styleJson.primaryColor),
    accent: toHex(styleJson.accentColor),
    secondary: toHex(styleJson.secondaryColor),
    success: toHex(styleJson.successColor),
    info: toHex(styleJson.infoColor),
    warning: toHex(styleJson.warningColor),
    error: toHex(styleJson.errorColor)
  }

  return colors
}

const setVuetifyTheme = ({ name, colors }) => {
  const theme = { name, dark: colors, light: colors }
  const light = theme.light

  Object.keys(light).forEach(i => {
    vuetify.framework.theme.themes.dark[i] = light[i]
    vuetify.framework.theme.themes.light[i] = light[i]
  })

  vuetify.framework.theme.themes.name = theme.name
}

const setTailwindTheme = colors => {
  const root = document.documentElement

  root.style.setProperty('--theme-color-primary', colors.primary)
  root.style.setProperty('--theme-color-accent', colors.accent)
  root.style.setProperty('--theme-color-secondary', colors.secondary)
  root.style.setProperty('--theme-color-success', colors.success)
  root.style.setProperty('--theme-color-info', colors.info)
  root.style.setProperty('--theme-color-warning', colors.warning)
  root.style.setProperty('--theme-color-error', colors.error)
}

const setThemeByTenant = tenant => {
  const colors = defineColorsByTenant(tenant)

  setVuetifyTheme({ name: tenant.name, colors })
  setTailwindTheme(colors)

  if (!tenant?.files?.favicon) return

  const favicon = document.getElementById('favicon')
  favicon.href = tenant.files.favicon.path

  const [, pageName, ...other] = titleManager.getTitle()
  titleManager.setTitle([tenant?.name, pageName, ...other])
}

export const setDefaultTheme = () => {
  const colors = DEFAULT_COLORS

  setVuetifyTheme({ name: 'default', colors })
  setTailwindTheme(colors)

  const favicon = document.getElementById('favicon')
  favicon.href = '/images/favicon.ico'

  const [, pageName] = titleManager.getTitle()
  titleManager.setTitle(['PharmYard', pageName])
}

export const setTheme = tenant => {
  tenant ? setThemeByTenant(tenant) : setDefaultTheme()
}
