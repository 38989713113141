const defineIsAdminPage = route => route.name?.includes('admin')

const defineIsHubPage = route => route.name?.includes('t.hub')
const defineIsMemberPage = route => route.name?.includes('t.member')
const defineIsPrincipalPage = route => {
  return (
    defineIsHubPage(route) ||
    defineIsMemberPage(route) ||
    route.name?.startsWith('t.')
  )
}
const defineIsAuthPage = route => route.name?.includes('t.auth')
const defineIsMfaPage = route => route.name?.includes('t.mfa')
const defineIsPublicPage = route => route.name?.includes('public.')

export {
  defineIsAdminPage,
  defineIsHubPage,
  defineIsMemberPage,
  defineIsPrincipalPage,
  defineIsAuthPage,
  defineIsMfaPage,
  defineIsPublicPage
}
