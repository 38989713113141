import $api from '@/api'
import * as notify from '@/utils/notify'

export const fetchAllUserSearchRequests = async () => {
  let result = {}

  try {
    result = await $api.ai.fetchAllUserSearchRequests()
    const { error } = result

    if (error) {
      notify.error({ text: error })
    }
  } catch (error) {
    notify.error({ text: error })
  }

  return result
}

// export const fetchOneUserSearchResponse = async ({ id }) => {
//   let result = {}

//   try {
//     result = await $api.ai.fetchOneUserSearchResponse({ id })
//     const { error } = result

//     if (error) {
//       notify.error({ text: error })
//     }
//   } catch (error) {
//     notify.error({ text: error })
//   }

//   return result
// }

export const createOneQueueUserSearch = async payload => {
  let result = {}

  try {
    result = await $api.ai.createOneQueueUserSearch(payload)
    const { error } = result

    if (error) {
      notify.error({ text: error })
    }
  } catch (error) {
    notify.error({ text: error })
  }

  return result
}
