<template>
  <v-card class="sticky top-0 flex-shrink-0">
    <slot v-if="$slots.default" />

    <v-card-title v-else class="p-0 flex flex-nowrap justify-between">
      <div
        class="p-4 font-bold"
        :style="isMobileAndDown ? `font-size: 1rem` : ''"
      >
        <span v-html="headerTitle"></span>
      </div>

      <v-btn
        style="height: 4rem; width: 2rem"
        color="red"
        text
        :disabled="isLoading"
        @click="handleClose"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>
  </v-card>
</template>

<script>
export default {
  name: 'BaseSidebarHeader',

  props: {
    headerTitle: { type: String, default: '' },

    isLoading: { type: Boolean, default: false },
    isMobileAndDown: { type: Boolean, default: false }
  },

  methods: {
    handleClose() {
      this.$emit('close')
    }
  }
}
</script>
