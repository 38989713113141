<template>
  <v-expansion-panels v-model="activeIndex" flat class="!rounded-none">
    <v-expansion-panel class="!rounded-none">
      <v-expansion-panel-header class="p-4 gap-4 flex bg-gray-200">
        <div class="gap-4 flex items-center">
          <v-icon color="black">mdi-view-grid-plus</v-icon>
          <b class="text-base">Actions</b>
        </div>
      </v-expansion-panel-header>

      <v-expansion-panel-content class="gap-4 flex bg-gray-200">
        <div class="gap-4 p-4 pt-0 flex flex-wrap items-center">
          <v-btn
            v-for="(button, index) of actions"
            :key="index"
            :color="button.color || 'primary'"
            :loading="button.isLoading"
            :disabled="button.isDisabled"
            small
            @click="button.handler"
          >
            {{ button.label }}
          </v-btn>

          <slot />
        </div>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
export default {
  name: 'AdminMemberFinderContentActions',

  props: {
    actions: { type: Array, default: () => [] }
  },

  data: () => ({
    activeIndex: 0
  })
}
</script>
