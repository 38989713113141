import axios from 'axios'

import {
  formatResponse,
  formatListResponse,
  formatItemResponse
} from '@/utils/api'

const BASE_URL = `/api/user-assessment-attempt`
const URLs = new Proxy(
  {
    // FETCH_ALL: `/get-all`,
    // FETCH_ONE_BY_ID: `/get-by-id/`,
    // FETCH_ALL_BY_USER_ID: `/get-by-user-id/`,
    FETCH_ALL_BY_USER_ID_AND_DEFINITION_ID: `/get-by-user-id-and-definition-id/`,

    CREATE_ONE: '/create',
    UPDATE_ONE: '/update',
    // DELETE_ONE: '/delete/'

    FETCH_FILES_ALL_BY_ID: '/get-all-files/',
    ADD_FILE_ONE: '/add-file'
    // DELETE_FILE_ONE: '/delete-file/',
  },
  { get: (target, prop) => `${BASE_URL}${target[prop]}` }
)

export default {
  // async fetchAllByUserId(userId) {
  //   const res = await axios.get(`${URLs.FETCH_ALL_BY_USER_ID}${userId}`)

  //   return formatListResponse(res)
  // },

  async fetchAllByUserIdAndDefinitionId({ userId, definitionId }) {
    const res = await axios.get(
      `${URLs.FETCH_ALL_BY_USER_ID_AND_DEFINITION_ID}${userId}/${definitionId}`
    )

    return formatListResponse(res)
  },

  async createOne(payload) {
    const res = await axios.post(URLs.CREATE_ONE, payload)

    return formatItemResponse(res)
  },

  async updateOne(payload) {
    const res = await axios.put(URLs.UPDATE_ONE, payload)

    return formatItemResponse(res)
  },

  // async deleteOne(id) {
  //   const res = await axios.delete(`${URLs.DELETE_ONE}${id}`)

  //   return formatResponse(res)
  // },

  // async fetchFilesAllByTaskId(taskId) {
  //   const res = await axios.get(`${URLs.FETCH_FILES_ALL_BY_ID}${taskId}`)

  //   return formatListResponse(res)
  // },

  async fetchFilesAllById(taskId) {
    const res = await axios.get(`${URLs.FETCH_FILES_ALL_BY_ID}${taskId}`)

    return formatListResponse(res)
  },

  async addFileOne(payload) {
    const res = await axios.post(URLs.ADD_FILE_ONE, payload)

    return formatResponse(res)
  }
}
