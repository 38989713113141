<template>
  <BaseDialog
    ref="dialog"
    class="BaseConfirm"
    content-class="p-4 !pt-8"
    :max-width="640"
    :header-title="normalized.title"
    @close="handleClose"
  >
    <v-form ref="form" class="gap-4 flex flex-col" @submit.prevent>
      <div class="gap-4 flex items-center">
        <v-icon class="mt-1" large :color="normalized.color">
          {{ normalized.icon }}
        </v-icon>

        <div class="flex flex-col text-black">
          <span v-html="text" />
          <slot v-if="$slots.default" />
        </div>
      </div>

      <v-text-field
        v-if="isPrompt"
        v-model="data"
        :placeholder="placeholder"
        dense
        outlined
        autofocus
        :rules="[$rules.required]"
        @keydown.enter="handleConfirm"
      />
    </v-form>

    <template #footer>
      <div class="w-full p-4">
        <BaseButtons
          class="flex-col justify-end sm:flex-row"
          :buttons="footerButtons"
        />
      </div>
    </template>
  </BaseDialog>
</template>

<script>
import * as notify from '@/utils/notify'
import { TYPES } from '@/utils/confirm'

import BaseDialog from '@/components/Base/BaseDialog/BaseDialog.vue'
import BaseButtons from '@/components/Base/BaseButtons.vue'

export default {
  name: 'BaseConfirm',

  components: { BaseDialog, BaseButtons },

  props: {
    type: {
      type: String,
      required: true,
      validator: value => Object.values(TYPES).includes(value)
    },

    title: { type: String, default: '' },
    text: { type: String, default: 'Confirm action?' },

    isPrompt: { type: Boolean, default: false },
    placeholder: { type: String, default: '' },

    isCancelButton: { type: Boolean, default: true },
    isConfirmButton: { type: Boolean, default: true },

    confirmButtonText: { type: String, default: 'Yes' },
    cancelButtonText: { type: String, default: 'Cancel' },

    isDistinguishCancelAndClose: { type: Boolean, default: false }
  },

  data: () => ({
    data: ''
  }),

  computed: {
    normalized() {
      const types = {
        [TYPES.WARNING]: {
          title: this.title || 'Warning',
          icon: 'mdi-alert-outline',
          color: 'warning'
        },
        [TYPES.INFO]: {
          title: this.title || 'Info',
          icon: 'mdi-alert-circle-outline',
          color: 'info'
        },
        [TYPES.ERROR]: {
          title: this.title || 'Error',
          icon: 'mdi-alert-octagon-outline',
          color: 'error'
        }
      }

      return types[this.type]
    },

    footerButtons() {
      return [
        this.isCancelButton && {
          text: this.cancelButtonText,
          attrs: { text: true, color: 'grey' },
          handler: this.isDistinguishCancelAndClose
            ? this.handleCancel
            : this.handleClose
        },
        this.isConfirmButton && {
          text: this.confirmButtonText,
          attrs: { color: 'primary' },
          handler: this.handleConfirm
        }
      ].filter(Boolean)
    }
  },

  methods: {
    handleConfirm() {
      const isValid = this.defineIsValid()

      if (!isValid) return

      this.$emit('confirm', this.data)
    },
    handleCancel() {
      this.$emit('cancel')
    },
    handleClose() {
      setTimeout(() => this.$emit('close'))
    },

    defineIsValid() {
      const isValid = this.$refs.form.validate()

      if (!isValid) {
        notify.error({ title: 'Fill in required field' })
      }

      return isValid
    }
  }
}
</script>
