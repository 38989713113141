import axios from 'axios'

import { formatListResponse } from '@/utils/api'

const BASE_URL = `/api/user-assessment-attempt-comment`
const URLs = new Proxy(
  {
    FETCH_ALL_BY_ATTEMPT_ID: `/get-by-user-assessment-attempt/`,

    CREATE_ONE: '/create',
    UPDATE_ONE: '/update',
    DELETE_ONE: '/delete/'
  },
  { get: (target, prop) => `${BASE_URL}${target[prop]}` }
)

export default {
  async fetchAllByAttemptId(attemptId) {
    const res = await axios.get(`${URLs.FETCH_ALL_BY_ATTEMPT_ID}${attemptId}`)

    return formatListResponse(res)
  },

  async createOne(payload) {
    const res = await axios.post(`${URLs.CREATE_ONE}`, payload)
    const { content: item, isSuccess, error } = res

    return { item, isSuccess, error }
  },
  async updateOne(payload) {
    const res = await axios.post(`${URLs.UPDATE_ONE}`, payload)
    const { content: item, isSuccess, error } = res

    return { item, isSuccess, error }
  },
  async deleteOne(id) {
    const res = await axios.delete(`${URLs.DELETE_ONE}${id}`)
    const { isSuccess, error } = res

    return { isSuccess, error }
  }
}
