export default {
  inserted: (_, binding) => {
    if (!Object.keys(binding).includes('value')) {
      binding.value = true
    }

    if (binding.value) {
      document.querySelector('html').classList.add('overflow-y-hidden')
    }
  },
  componentUpdated: binding => {
    if (!Object.keys(binding).includes('value')) {
      binding.value = true
    }

    if (binding.oldValue === binding.value) return

    if (binding.value) {
      document.querySelector('html').classList.add('overflow-y-hidden')
    } else {
      document.querySelector('html').classList.remove('overflow-y-hidden')
    }
  },
  unbind: () =>
    document.querySelector('html').classList.remove('overflow-y-hidden')
}
