// MYZIURA 3 remove
import $api from '@/api'
import * as notify from '@/utils/notify'

export default {
  namespaced: true,

  state: {
    attributes: {
      list: []
    }
  },

  actions: {
    async FETCH_ALL_SIMPLE_BY_CATEGORY_ID({ commit }, categoryId) {
      let result = {}

      try {
        result = await $api.attributes.fetchAllSimpleByCategoryId(categoryId)
        const { list, isSuccess, error } = result

        if (error) {
          notify.error({ text: error })
        } else {
          commit('SET_LIST', isSuccess ? list : [])
        }
      } catch (error) {
        notify.error({ text: error })
      }

      return result
    }
  },

  mutations: {
    SET_LIST(state, list) {
      state.attributes.list = list
    }
  }
}
