<template>
  <div class="w-full flex flex-col">
    <BasePaginatedTable :store-path="STORE_PATH" :actions="actions">
      <template #filters-header>
        <v-text-field
          :value="table.filters.keyword"
          class="max-w-sm"
          label="Individual name"
          placeholder="Individual name"
          background-color="white"
          dense
          outlined
          clearable
          hide-details
          @input="value => updateFilters({ keyword: value })"
        />
      </template>

      <!-- <template #actions>
        <div class="flex flex-1 justify-end">
          <BasePickerUser
            ref="user-picker"
            class="w-full"
            style="max-width: 20rem"
            label="Invite member"
            placeholder="Type a name"
            @input="handleBeforeInviteUser"
          />
        </div>
      </template> -->

      <template #list-item-content="{ item, index }">
        <div class="w-10 flex flex-initial items-center">
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <v-btn
                  icon
                  fab
                  small
                  @click="() => handleOpenSubmissionFiles(item)"
                >
                  <v-icon color="primary">
                    mdi-file-document-multiple-outline
                  </v-icon>
                </v-btn>
              </div>
            </template>

            <span>View submissions</span>
          </v-tooltip>
        </div>

        <div class="w-px min-w-[20rem] gap-2 flex items-center overflow-hidden">
          <router-link
            class="gap-1 inline-flex group flex flex-initial"
            target="_blank"
            :to="{ name: 'admin.people.id', params: { id: item.id } }"
          >
            <span class="text-gray-400 break-keep">{{ index + 1 }}. </span>

            <div class="inline-flex flex-initial overflow-hidden">
              <span class="group-hover:underline truncate">
                {{ item.name }}
              </span>
            </div>

            <v-icon small color="primary"> mdi-open-in-new </v-icon>
          </router-link>
        </div>

        <div class="w-[220px] ml-auto mr-0 flex flex-initial items-center">
          {{ void (interest = defineInterest(item)) }}

          <v-chip small :color="interest.color">
            <span>{{ interest.label }}</span>
          </v-chip>
        </div>

        <div class="w-24 flex flex-initial items-center">
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-chip
                v-if="!item.canUninviteFromAssignment"
                class="w-full"
                x-small
                v-bind="attrs"
                v-on="on"
              >
                <span>Can't Uninvite</span>
              </v-chip>
            </template>

            <span>You cannot cancel an invitation</span>
          </v-tooltip>
        </div>
      </template>
    </BasePaginatedTable>

    <InvitationsDialogUsers
      v-if="invitationsDialogUsers.isVisible"
      :type="INVITATIONS_DIALOG_USERS_TYPES.JOB"
      :assignment-id="assignmentId"
      @close="handleCloseInvitationsDialogUsers"
    />
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import $api from '@/api'

import * as notify from '@/utils/notify'
import * as services from '@/services'
import { INTEREST_INDICATORS } from '@/utils/consts'
import store from '@/store'
import paginatedTable from '@/store/modules/paginated-table'

import BasePaginatedTable from '@/components/Base/BasePaginatedTable/BasePaginatedTable.vue'
import {
  default as InvitationsDialogUsers,
  TYPES as INVITATIONS_DIALOG_USERS_TYPES
} from '@/components/Invitations/InvitationsDialogUsers.vue'

const STORE_PATH = 'jobManagerUsers/paginatedTable'

export default {
  name: 'AdminJobManagerItemInviteUsers',

  components: { BasePaginatedTable, InvitationsDialogUsers },

  props: {
    assignmentId: { type: Number, required: true },
    assignmentTitle: { type: String, required: true }
  },

  data: () => ({
    isLoadingUninvite: false,

    invitationsDialogUsers: {
      isVisible: false
    }
  }),

  computed: {
    ...mapState(STORE_PATH, ['table']),

    actions() {
      const selected = this.table.rows.selected
      const isSelected = selected.length > 0

      const isAllSelectedUninvitable = selected.every(
        item => item.canUninviteFromAssignment
      )

      return [
        {
          label: 'Invite',
          color: 'primary',
          handler: this.handleOpenInvitationsDialogUsers
        },
        {
          label: 'Uninvite',
          color: 'error',
          isLoading: this.isLoadingUninvite,
          isDisabled: !isSelected || !isAllSelectedUninvitable,
          handler: () => this.uninviteUsers(selected)
        }
      ].filter(Boolean)
    }
  },

  watch: {
    'table.content'(content) {
      this.$emit('loaded', content?.totalCount || 0)
    }
  },

  beforeCreate() {
    store.registerModule(STORE_PATH, {
      ...paginatedTable,
      state: () => ({
        ...paginatedTable.state(),
        orderByList: [{ id: 'id', name: 'Id' }],
        fetchContentHandler: services.invitees.fetchUserInviteesByAssignment
      })
    })
  },

  created() {
    this.INTEREST_INDICATORS = INTEREST_INDICATORS
    this.INVITATIONS_DIALOG_USERS_TYPES = INVITATIONS_DIALOG_USERS_TYPES
    this.STORE_PATH = STORE_PATH

    this.updateFilters({ assignmentId: this.assignmentId, orderBy: 'id' })
    this.setDefaultFilters(this.table.filters)
  },

  beforeDestroy() {
    store.unregisterModule(STORE_PATH)
  },

  methods: {
    ...mapActions(STORE_PATH, {
      fetchContent: 'FETCH_CONTENT'
    }),
    ...mapMutations(STORE_PATH, {
      updateFilters: 'UPDATE_FILTERS',
      setDefaultFilters: 'SET_DEFAULT_FILTERS'
    }),

    handleBeforeInviteUser(user) {
      setTimeout(this.$refs['user-picker'].handleClearSelected)
      this.handleOpenInvitationsDialogUsers({ users: [user] })
    },

    handleBeforeUninviteUsers(selected) {
      return this.uninviteUsers(selected)
    },

    handleOpenInvitationsDialogUsers() {
      this.invitationsDialogUsers.isVisible = true
    },
    handleCloseInvitationsDialogUsers() {
      this.invitationsDialogUsers.isVisible = false
    },

    handleOpenSubmissionFiles(user) {
      this.$router.open({
        name: 'admin.job-submissions-viewer',
        params: { submitterId: user.id, assignmentId: this.assignmentId },
        query: {
          submitterFullName: user.name,
          assignmentTitle: this.assignmentTitle
        }
      })
    },

    async uninviteUsers(users) {
      try {
        this.isLoadingUninvite = true

        const { isSuccess, error } = await $api.invitees.uninviteUsers({
          assignmentId: this.assignmentId,
          idList: users.map(u => u.id)
        })

        if (error) {
          notify.error({ text: error })
          return
        }

        if (isSuccess) {
          await this.fetchContent()
        }
      } catch (error) {
        notify.error({ text: error })
      } finally {
        this.isLoadingUninvite = false
      }
    },

    defineInterest(item) {
      const interestId = item.invitee?.interestIndicatorId || 0

      return {
        color: INTEREST_INDICATORS.colorByCode[interestId],
        label: INTEREST_INDICATORS.labelByCode[interestId]
      }
    }
  }
}
</script>
