import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import { Ripple, Intersect, ClickOutside } from 'vuetify/lib/directives'

import { COLORS } from '@/utils/theme'

Vue.use(Vuetify, {
  directives: { Ripple, Intersect, ClickOutside }
})

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: COLORS.PRIMARY,
        secondary: COLORS.SECONDARY,
        accent: COLORS.ACCENT,
        error: COLORS.ERROR,
        info: COLORS.INFO,
        success: COLORS.SUCCESS,
        warning: COLORS.WARNING,

        gold: COLORS.GOLD
      },
      dark: {
        primary: COLORS.PRIMARY,
        secondary: COLORS.SECONDARY,
        accent: COLORS.ACCENT,
        error: COLORS.ERROR,
        info: COLORS.INFO,
        success: COLORS.SUCCESS,
        warning: COLORS.WARNING,

        gold: COLORS.GOLD
      }
    }
  }
})
