<template>
  <div v-loader="isLoading" class="p-4 flex flex-col shadow-inner">
    <template v-if="filteredTreeList.length">
      <BaseLazyHOC
        v-for="branch of filteredTreeList"
        :key="branch.id"
        min-height="1rem"
      >
        <BaseTreeBranch
          :ref="`branch-${branch.id}`"
          :parent="null"
          :branch="branch"
          :selected="selected"
          @select="handleSelect"
        />
      </BaseLazyHOC>
    </template>

    <BaseEmpty v-else-if="isEmpty" :subtitle="['No items found']">
      <v-card-text>
        <v-btn color="primary" outlined small @click="$emit('reset-filters')">
          Clear filters
        </v-btn>
      </v-card-text>
    </BaseEmpty>

    <div v-else class="h-[216px] w-full" />
  </div>
</template>

<script>
import BaseEmpty from '@/components/Base/BaseEmpty.vue'
import BaseLazyHOC from '@/components/Base/BaseLazyHOC.vue'
import BaseTreeBranch from '@/components/Admin/Base/BaseTree/BaseTreeBranch.vue'

export default {
  name: 'AdminBaseTree',

  components: {
    BaseEmpty,
    BaseLazyHOC,
    BaseTreeBranch
  },

  provide() {
    return {
      defineChildren: this.defineChildren,
      defineBranchMenuButtons: this.defineBranchMenuButtons
    }
  },

  props: {
    filteredTreeList: { type: Array, default: () => [] },
    selected: { type: Array, default: () => [] },

    isLoading: { type: Boolean, default: false },

    defineChildren: { type: Function, required: true },
    defineBranchMenuButtons: { type: Function, required: true }
  },

  computed: {
    isEmpty() {
      return !this.filteredTreeList.length && !this.isLoading
    }
  },

  methods: {
    handleSelect(branch) {
      const isDuplicate = this.selected.some(item => item.id === branch.id)

      let selected = this.selected

      if (isDuplicate) {
        selected = selected.filter(item => item.id !== branch.id)

        this.$emit('update:selected', selected)
        return
      }

      selected.push(branch)

      this.$emit('update:selected', selected)
    }
  }
}
</script>
