import $api from '@/api'
import * as notify from '@/utils/notify'

function sortByOffset(arrayOfObjects) {
  const offsets = arrayOfObjects.map(obj => {
    const matches = obj.name.match(/GMT\s*([+-]\d{1,2}:\d{2})/)
    return matches ? matches[1] : '+00:00'
  })

  const objectOffsetPairs = arrayOfObjects.map((obj, index) => ({
    obj,
    offset: offsets[index]
  }))

  objectOffsetPairs.sort((a, b) => {
    const offsetA = a.offset.replace(':', '')
    const offsetB = b.offset.replace(':', '')
    return parseInt(offsetA, 10) - parseInt(offsetB, 10)
  })

  const sortedArray = objectOffsetPairs.map(pair => pair.obj)

  return sortedArray
}

export const fetchAllCountries = async () => {
  let result = {}

  try {
    result = await $api.geo.fetchAllCountries()
    const { error } = result

    if (error) {
      notify.error({ text: error })
    }
  } catch (error) {
    notify.error({ text: error })
  }

  return result
}

export const fetchAllTimezones = async () => {
  let result = {}

  try {
    result = await $api.geo.fetchAllTimezones()
    const { error } = result

    if (error) {
      notify.error({ text: error })
    }

    result.list = sortByOffset(result.list)
  } catch (error) {
    notify.error({ text: error })
  }

  return result
}

export const fetchProvincesByCountry = async countryId => {
  let result = {}

  try {
    result = await $api.geo.fetchProvincesByCountry(countryId)
    const { error } = result

    if (error) {
      notify.error({ text: error })
    }
  } catch (error) {
    notify.error({ text: error })
  }

  return result
}
