import axios from 'axios'

import { formatListResponse } from '@/utils/api'

const BASE_URL = `/api/assignees`
const URLs = new Proxy(
  {
    FETCH_ALL_ASSIGNMENTS_BY_USER: `/get-assignments-by-user/`
  },
  { get: (target, prop) => `${BASE_URL}${target[prop]}` }
)

export default {
  async fetchAllAssignmentsByUser({ userId, isActive }) {
    const res = await axios.get(
      `${URLs.FETCH_ALL_ASSIGNMENTS_BY_USER}${userId}/${isActive}`
    )

    return formatListResponse(res)
  }
}
