import axios from 'axios'

import { formatResponse, formatListResponse } from '@/utils/api'

const BASE_URL = `/api/invitees`
const URLs = new Proxy(
  {
    FETCH_ONE_FOR_INVITATION_RESPONSE_WITH_INVITEE: `/get-for-invitation-response-with-invitee/`,

    PARTICIPATION_DECISION: `/participation-decision`,
    DECLINE_ONE_PARTICIPATION: `/decline-participation/`,
    INTEREST_DECISION: `/interest-decision`,

    FETCH_CATEGORIZED_BY_USER: `/get-categorized-by-user/`,

    FETCH_USERS_BY_ASSIGNMENT: `/get-users-by-assignment/`,
    FETCH_USER_INVITEES_BY_ASSIGNMENT: `/get-user-invitees-by-assignment`,
    FETCH_TEAMS_BY_ASSIGNMENT: `/get-teams-by-assignment/`,
    FETCH_TEAM_INVITEES_BY_ASSIGNMENT: `/get-team-invitees-by-assignment`,

    UNINVITE_USERS: `/uninvite-users`,
    UNINVITE_TEAMS: `/uninvite-teams`,

    EXPORT_ONE: '/export'
  },
  { get: (target, prop) => `${BASE_URL}${target[prop]}` }
)

export default {
  async fetchOneForInvitationResponseWithInvitee({ inviteeId }) {
    const res = await axios.get(
      `${URLs.FETCH_ONE_FOR_INVITATION_RESPONSE_WITH_INVITEE}${inviteeId}`
    )
    const { content, isSuccess, error } = res

    return { content, isSuccess, error }
  },

  async participationDecision(payload) {
    const res = await axios.put(URLs.PARTICIPATION_DECISION, payload)
    const { content, isSuccess, error } = res

    return { content, isSuccess, error }
  },
  async interestDecision(payload) {
    const res = await axios.put(URLs.INTEREST_DECISION, payload)
    const { content, isSuccess, error } = res

    return { content, isSuccess, error }
  },
  async declineOneParticipation({ inviteeId }) {
    const res = await axios.put(`${URLs.DECLINE_ONE_PARTICIPATION}${inviteeId}`)
    const { isSuccess, error } = res

    return { isSuccess, error }
  },

  async fetchCategorizedByUser({ id }) {
    const res = await axios.get(`${URLs.FETCH_CATEGORIZED_BY_USER}${id}`)

    return formatListResponse(res)
  },

  async fetchUsersByAssignment({ id, excludeThoseInvitedViaTeams }) {
    const res = await axios.get(
      `${URLs.FETCH_USERS_BY_ASSIGNMENT}${id}/${excludeThoseInvitedViaTeams}`
    )

    return formatListResponse(res)
  },
  async fetchUserInviteesByAssignment(payload) {
    const res = await axios.post(
      URLs.FETCH_USER_INVITEES_BY_ASSIGNMENT,
      payload
    )

    return formatResponse(res)
  },
  async fetchTeamsByAssignment({ id }) {
    const res = await axios.get(`${URLs.FETCH_TEAMS_BY_ASSIGNMENT}${id}`)

    return formatListResponse(res)
  },

  async fetchTeamInviteesByAssignment(payload) {
    const res = await axios.post(
      URLs.FETCH_TEAM_INVITEES_BY_ASSIGNMENT,
      payload
    )

    return formatResponse(res)
  },

  async uninviteUsers(payload) {
    const res = await axios.put(URLs.UNINVITE_USERS, payload)

    return formatResponse(res)
  },
  async uninviteTeams(payload) {
    const res = await axios.put(URLs.UNINVITE_TEAMS, payload)

    return formatResponse(res)
  },

  async exportOne(payload) {
    const res = await axios.post(URLs.EXPORT_ONE, payload, {
      responseType: 'blob'
    })

    return res
  }
}
