<template>
  <v-expansion-panels v-model="activeIndex" flat>
    <v-expansion-panel>
      <v-expansion-panel-header class="p-4 gap-4 flex bg-gray-200">
        <div class="w-full gap-2 flex justify-between flew-wrap">
          <div class="gap-4 flex items-center">
            <v-icon color="black">mdi-filter</v-icon>
            <b class="text-base">Filters</b>
          </div>

          <div v-if="isFiltersVisible" class="gap-2 flex flex-wrap justify-end">
            <v-btn
              small
              outlined
              color="error"
              @click.stop="handleClearFilters"
            >
              <v-icon left>mdi-eraser</v-icon>
              <span>Clear filters</span>
            </v-btn>

            <v-btn
              small
              color="primary"
              :loading="table.isLoading"
              @click.stop="handleRefresh"
            >
              <v-icon left>mdi-magnify</v-icon>
              <span>Search</span>
            </v-btn>
          </div>
        </div>
      </v-expansion-panel-header>

      <v-expansion-panel-content class="gap-4 flex bg-gray-200">
        <div class="p-4 pt-0 gap-4 flex flex-col">
          <slot />

          <BasePaginatedTableFiltersOther
            :filters="table.filters"
            :store-path="storePath"
          />

          <v-alert v-if="isModified" class="m-0" type="error" dense text>
            <span>
              Filters are changed but not applied. Click "Search" button above
              to apply filters.
            </span>
          </v-alert>
        </div>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import { defineIsEqual } from '@/utils/base'

import BasePaginatedTableFiltersOther from '@/components/Base/BasePaginatedTable/BasePaginatedTableFiltersOther.vue'

export default {
  name: 'BasePaginatedTableHeaderFilters',

  components: { BasePaginatedTableFiltersOther },

  props: {
    table: { type: Object, required: true },
    storePath: { type: String, required: true }
  },

  data: () => ({
    activeIndex: -1
  }),

  computed: {
    isFiltersVisible() {
      return this.activeIndex === 0
    },

    isModified() {
      return !defineIsEqual(this.table.defaultFilters, this.table.filters)
    }
  },

  created() {
    this.handleRefresh()
  },

  methods: {
    handleRefresh() {
      this.$emit('refresh')
    },

    handleClearFilters() {
      this.$emit('reset-filters')
      this.handleRefresh()
    },

    resetFilters(payload) {
      return this.commit('RESET_FILTERS', payload)
    },

    commit(name, payload) {
      return this.$store.commit(`${this.storePath}/${name}`, payload, {
        root: true
      })
    }
  }
}
</script>

<style>
.v-expansion-panel-content__wrap {
  @apply p-0;
}
</style>
