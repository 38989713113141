import axios from 'axios'

import { formatListResponse, formatResponse } from '@/utils/api'

const BASE_URL = `/api/announcements`
const URLs = new Proxy(
  {
    FETCH_ALL: '/get-all',

    FETCH_ALL_ACTIVE_BY_USER: `/get-active-by-user-id/`,

    DISMISS_ONE: `/dismiss/`,
    CREATE_ONE: '/create',
    UPDATE_ONE: '/update',
    DELETE_MULTIPLE: `/delete-multiple`
  },
  { get: (target, prop) => `${BASE_URL}${target[prop]}` }
)

export default {
  async fetchAll() {
    const res = await axios.get(URLs.FETCH_ALL)

    return formatListResponse(res)
  },
  async fetchAllActiveByUser({ userId }) {
    const res = await axios.get(`${URLs.FETCH_ALL_ACTIVE_BY_USER}${userId}`)

    return formatListResponse(res)
  },

  async dismissOne({ userId, announcementId }) {
    const res = await axios.put(
      `${URLs.DISMISS_ONE}${announcementId}/${userId}`
    )

    return formatListResponse(res)
  },

  async createOne(payload) {
    const res = await axios.post(URLs.CREATE_ONE, payload)

    return formatResponse(res)
  },
  async updateOne(payload) {
    const res = await axios.put(URLs.UPDATE_ONE, payload)

    return formatResponse(res)
  },
  async deleteMultiple(payload) {
    const res = await axios.put(URLs.DELETE_MULTIPLE, payload)

    return formatResponse(res)
  }
}
