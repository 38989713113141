<template>
  <div class="h-full p-4 gap-16 flex bg-white">
    <TheProfessions />

    <AdminTenantsCreationDialogAddresses
      v-if="false"
      :tenant="tenants.list[0]"
    />

    <TheAttributes
      v-if="false"
      ref="attributes"
      header-title="All available expertise"
      header-subtitle="Click the plus signs to expand, then check the boxes to add"
      :principal="user"
      is-selectable
    />

    <DefinitionsCreationDialogInvitedUsers
      v-if="false"
      class="w-full max-w-[600px] mx-auto"
      :definition-id="40"
      is-editing
    />

    <JobManagerItemInviteUsers
      v-if="false"
      class="w-full max-w-[600px] pt-16 mx-auto"
      :assignment-id="67"
      assignment-title="Test title"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'

// import * as services from '@/services'
import TheProfessions from '@/components/Admin/Professions/TheProfessions.vue'
import TheAttributes from '@/components/Attributes/TheAttributes.vue'
import DefinitionsCreationDialogInvitedUsers from '@/components/Assessment/Definitions/DefinitionsCreationDialogInvitedUsers.vue'
import JobManagerItemInviteUsers from '@/components/Admin/JobManager/JobManagerItemInviteUsers.vue'
import AdminTenantsCreationDialogAddresses from '@/components/Admin/AdminTenants/AdminTenantsCreationDialogAddresses.vue'

export default {
  name: 'PublicTestPage',

  components: {
    TheProfessions,
    TheAttributes,
    DefinitionsCreationDialogInvitedUsers,
    JobManagerItemInviteUsers,
    AdminTenantsCreationDialogAddresses
  },

  computed: {
    ...mapState('user', ['user']),
    ...mapState('tenants', ['tenants'])
  },

  methods: {}
}
</script>
