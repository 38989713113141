import * as services from '@/services'

export default {
  namespaced: true,

  state: {
    project: {
      assignee: null,
      assignment: null,
      assignmentFiles: [],
      submissionFiles: [],

      isLoading: false
    }
  },

  getters: {
    isProject: ({ project }) => project.assignee && project.assignment
  },

  actions: {
    async FETCH_PROJECT({ commit }, { userId, assignmentId }) {
      commit('SET_IS_LOADING', true)

      const result = await services.assignments.fetchDetailWithAssignee({
        userId: userId,
        assignmentId: assignmentId
      })

      commit('SET_IS_LOADING', false)

      const { isSuccess, error, ...content } = result

      await commit('SET_PROJECT', {
        assignee: content.assignee,
        assignment: content.assignment,
        assignmentFiles: content.assignmentFiles,
        submissionFiles: content.submissionFiles
      })

      return { isSuccess, error, ...content }
    }

    // async SUBMISSION_UPLOAD({ commit }, { assigneeId, payload }) {
    //   commit('SET_IS_LOADING', true)

    //   const result = await services.submissions.assigneeUpload({
    //     assigneeId,
    //     payload
    //   })

    //   commit('SET_IS_LOADING', false)

    //   return result
    // },
  },

  mutations: {
    SET_PROJECT(
      state,
      { assignee, assignment, assignmentFiles, submissionFiles }
    ) {
      state.project = {
        ...state.project,
        assignee,
        assignment,
        assignmentFiles,
        submissionFiles
      }
    },
    SET_IS_LOADING(state, isLoading) {
      state.project.isLoading = isLoading
    }
  }
}
