import definitions from '@/store/modules/assessment/definitions'
import tasks from '@/store/modules/assessment/tasks'
import attempts from '@/store/modules/assessment/attempts'
import attemptAnnotations from '@/store/modules/assessment/attempt-annotations'
import attemptComments from '@/store/modules/assessment/attempt-comments'
import stepper from '@/store/modules/assessment/stepper'

export default {
  namespaced: true,

  modules: {
    definitions,
    tasks,
    attempts,
    attemptAnnotations,
    attemptComments,
    stepper
  }
}
