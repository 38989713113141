import axios from 'axios'

import { formatListResponse } from '@/utils/api'

const BASE_URL = `/api/showcase-files`
const URLs = new Proxy(
  {
    FETCH_ALL: '/get-all-transformed/',
    FETCH_ALL_RANDOMIZED: '/get-randomized/',

    PATCH_ONE: '/patch',
    CREATE_ONE: '/create',
    UPDATE_ONE: '/update',
    DELETE_ONE: '/delete/'
  },
  { get: (target, prop) => `${BASE_URL}${target[prop]}` }
)

export default {
  async fetchAll({ isIncludeDisabled = false } = {}) {
    const res = await axios.get(`${URLs.FETCH_ALL}${isIncludeDisabled}`)

    return formatListResponse(res)
  },

  async fetchAllRandomized({ limit, isIncludeDisabled }) {
    const res = await axios.get(
      `${URLs.FETCH_ALL_RANDOMIZED}${limit}/${isIncludeDisabled}`
    )

    return formatListResponse(res)
  },

  async createOne(payload) {
    const res = await axios.post(URLs.CREATE_ONE, payload)

    return formatListResponse(res)
  },

  async updateOne(payload) {
    const res = await axios.post(URLs.UPDATE_ONE, payload)

    return formatListResponse(res)
  },

  async patchOne(payload) {
    const res = await axios.patch(URLs.PATCH_ONE, payload)

    return formatListResponse(res)
  },

  async deleteOne(id) {
    const res = await axios.delete(`${URLs.DELETE_ONE}${id}`)

    return formatListResponse(res)
  }
}
