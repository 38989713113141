import axios from 'axios'

import { formatResponse, formatListResponse } from '@/utils/api'

const BASE_URL = `/api/user-comments`
const URLs = new Proxy(
  {
    FETCH_ALL_BY_USER_ID: `/get-by-user/`,

    CREATE_ONE: '/create',
    UPDATE_ONE: '/update',
    DELETE_ONE: '/delete/'
  },
  { get: (target, prop) => `${BASE_URL}${target[prop]}` }
)

export default {
  async fetchAllByUserId({ id }) {
    const res = await axios.get(`${URLs.FETCH_ALL_BY_USER_ID}${id}`)

    return formatListResponse(res)
  },
  async createOne(payload) {
    const res = await axios.post(URLs.CREATE_ONE, payload)

    return formatResponse(res)
  },
  async updateOne(payload) {
    const res = await axios.post(URLs.UPDATE_ONE, payload)

    return formatResponse(res)
  },
  async deleteOne({ id }) {
    const res = await axios.delete(`${URLs.DELETE_ONE}${id}`)

    return formatResponse(res)
  }
}
