<template>
  <BasePaginatedTable
    v-if="isEditing"
    :store-path="STORE_PATH"
    :actions="actions"
  >
    <template #filters-header>
      <v-text-field
        :value="table.filters.keyword"
        class="max-w-sm"
        label="Member name"
        placeholder="Member name"
        background-color="white"
        dense
        outlined
        clearable
        hide-details
        @input="value => updateFilters({ keyword: value })"
      />
    </template>

    <template #actions>
      <div class="flex flex-1 justify-end">
        <BasePickerUser
          ref="user-picker"
          class="w-full"
          style="max-width: 20rem"
          label="Invite member"
          placeholder="Type a name"
          :is-disabled="isLoadingInvite"
          @input="handleBeforeInviteUser"
        />
      </div>
    </template>

    <template #list-item-content="{ item, index }">
      <div class="w-px min-w-[20rem] gap-2 flex items-center overflow-hidden">
        <router-link
          class="gap-1 inline-flex group flex flex-initial"
          target="_blank"
          :to="{ name: 'admin.people.id', params: { id: item.id } }"
        >
          <span class="text-gray-400 break-keep">{{ index + 1 }}. </span>

          <div class="inline-flex flex-initial overflow-hidden">
            <span class="group-hover:underline truncate">
              {{ item.name }}
            </span>
          </div>

          <v-icon small color="primary"> mdi-open-in-new </v-icon>
        </router-link>
      </div>

      <div
        v-if="!item.canUninviteFromAssessment"
        class="w-24 ml-auto flex flex-initial items-center justify-end"
      >
        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-chip class="w-full" x-small v-bind="attrs" v-on="on">
              <span>Can't Uninvite</span>
            </v-chip>
          </template>

          <span>You cannot cancel an invitation</span>
        </v-tooltip>
      </div>

      <!-- <div class="min-w-[1.5rem] flex flex-initial items-center">
        <BaseTenantAvatars :list="item.tenantIdList" />
      </div> -->
    </template>
  </BasePaginatedTable>

  <BasePickerUser
    v-else
    :value="picker.selected.map(u => u.id)"
    label="Who should we invite to this assessment?"
    placeholder="Type a name"
    is-multiple
    is-chips
    @input="handleInputUsersToInvite"
  />
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'

import $api from '@/api'
import * as notify from '@/utils/notify'
import * as services from '@/services'

import store from '@/store'
import paginatedTable from '@/store/modules/paginated-table'

import BasePickerUser from '@/components/Base/BasePicker/BasePickerUser.vue'
// import BaseTenantAvatars from '@/components/Admin/Base/BaseTenantAvatars.vue'
import BasePaginatedTable from '@/components/Base/BasePaginatedTable/BasePaginatedTable.vue'

const STORE_PATH = 'assessmentDefinitionUsers/paginatedTable'

export default {
  name: 'DefinitionsCreationDialogInvitedUsers',

  components: { BasePickerUser, BasePaginatedTable },

  props: {
    definitionId: { type: Number, default: 0 },
    isEditing: { type: Boolean, default: false }
  },

  data: () => ({
    picker: {
      selected: []
    },

    isLoadingInvite: false,
    isLoadingUninvite: false
  }),

  computed: {
    ...mapState(STORE_PATH, ['table']),

    isCreating() {
      return !this.isEditing
    },

    actions() {
      const selected = this.table.rows.selected
      const isSelected = selected.length > 0

      const isAllSelectedUninvitable = selected.every(
        item => item.canUninviteFromAssessment
      )

      return [
        {
          label: 'Uninvite',
          color: 'error',
          isLoading: this.isLoadingUninvite,
          isDisabled: !isSelected || !isAllSelectedUninvitable,
          handler: () => this.uninviteUsers(selected)
        }
      ].filter(Boolean)
    }
  },

  beforeCreate() {
    store.registerModule(STORE_PATH, {
      ...paginatedTable,
      state: () => ({
        ...paginatedTable.state(),
        orderByList: [
          { id: 'name', name: 'Name' },
          { id: 'createdAt', name: 'Join Date' }
        ],
        fetchContentHandler: services.assessmentDefinitions.fetchUserList
      })
    })
  },

  created() {
    this.STORE_PATH = STORE_PATH

    if (!this.isEditing) return

    this.updateFilters({
      assessmentDefinitionId: this.definitionId,
      orderBy: 'createdAt'
    })
    this.setDefaultFilters(this.table.filters)
  },

  beforeDestroy() {
    store.unregisterModule(STORE_PATH)
  },

  methods: {
    ...mapActions(STORE_PATH, {
      fetchContent: 'FETCH_CONTENT'
    }),
    ...mapMutations(STORE_PATH, {
      updateFilters: 'UPDATE_FILTERS',
      setDefaultFilters: 'SET_DEFAULT_FILTERS'
    }),

    handleBeforeInviteUser(userToInvite) {
      setTimeout(this.$refs['user-picker'].handleClearSelected)
      this.inviteUsers([userToInvite])
    },

    handleInputUsersToInvite(usersToInvite) {
      this.picker.selected = usersToInvite
    },

    async handleInitialInvitation(definitionId) {
      if (!this.picker.selected.length) return true

      const { isSuccess } = await this.inviteUsers(
        this.picker.selected,
        definitionId
      )

      return isSuccess
    },

    async inviteUsers(usersToInvite, definitionId = null) {
      let result = {}

      this.isLoadingInvite = true

      try {
        result = await $api.assessment.definitions.inviteMembers({
          id: definitionId || this.definitionId,
          userIds: usersToInvite.map(u => u.id)
        })

        if (this.isCreating) return result

        await this.fetchContent()

        const { name, fullName } = usersToInvite[0]

        notify.success({
          title: `User ${name || fullName} was invited`,
          text: 'You can safely close the "Editing Assessment" dialog'
        })
      } catch (error) {
        notify.error({ text: error })
      } finally {
        this.isLoadingInvite = false
      }

      return result
    },

    async uninviteUsers(selected) {
      let result = {}

      this.isLoadingUninvite = true

      try {
        result = await $api.assessment.definitions.uninviteMembers({
          id: this.definitionId,
          userIds: selected.map(item => item.id)
        })

        await this.fetchContent()

        notify.success({ text: `User(s) removed` })
      } catch (error) {
        notify.error({ text: error })
      } finally {
        this.isLoadingUninvite = false
      }

      return result
    }
  }
}
</script>
