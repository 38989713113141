import axios from 'axios'

import {
  formatResponse,
  formatListResponse,
  formatItemResponse
} from '@/utils/api'

const BASE_URL = `/api/assessment-definition`
const URLs = new Proxy(
  {
    FETCH_ALL: `/get-all`,
    FETCH_ONE_BY_ID: `/get-by-id/`,
    FETCH_USER_LIST: `/get-user-list`,

    CREATE_ONE: `/create`,
    UPDATE_ONE: `/update`,
    DELETE_ONE: `/delete/`,
    DELETE_TRIGGER_ONE: `/delete-attribute-trigger/`,

    ADD_TRIGGERS_ALL_BY_ASSESSMENT_ID: `/bulk-change-attribute-triggers/`,

    FETCH_FILES_ALL_BY_DEFINITION_ID: `/get-all-files/`,
    ADD_FILES_ALL: `/add-files`,
    // DELETE_FILE_ONE: `/delete-file/`
    DELETE_FILES_ALL_BY_IDS: `/delete-file-list`,

    FETCH_ATTEMPT_STATS_BY_ID: `/get-attempt-stats-by-id/`,

    FETCH_INVITED_USER_LIST: `/get-invited-user-list/`,
    INVITE_MEMBERS: `/invite-members`,
    UNINVITE_MEMBERS: `/uninvite-members`,

    CHECK_AVAILABLE_BY_ATTRIBUTE: `/check-available-by-attribute`
  },
  { get: (target, prop) => `${BASE_URL}${target[prop]}` }
)

export default {
  async fetchAll() {
    const res = await axios.get(`${URLs.FETCH_ALL}`)

    return formatListResponse(res)
  },
  async fetchOneById(id) {
    const res = await axios.get(`${URLs.FETCH_ONE_BY_ID}${id}`)

    return formatItemResponse(res)
  },
  async fetchUserList(payload) {
    const res = await axios.post(URLs.FETCH_USER_LIST, payload)

    return formatResponse(res)
  },
  async createOne(payload) {
    const res = await axios.post(URLs.CREATE_ONE, payload)

    return formatItemResponse(res)
  },
  async updateOne(payload) {
    const res = await axios.put(URLs.UPDATE_ONE, payload)

    return formatItemResponse(res)
  },
  async deleteOne(id) {
    const res = await axios.delete(`${URLs.DELETE_ONE}${id}`)

    return formatResponse(res)
  },
  async deleteTriggerOne(payload) {
    const res = await axios.put(`${URLs.DELETE_TRIGGER_ONE}`, payload)

    return formatResponse(res)
  },
  async addTriggersAllByAssessmentId({ assessmentId, payload }) {
    const res = await axios.put(
      `${URLs.ADD_TRIGGERS_ALL_BY_ASSESSMENT_ID}${assessmentId}`,
      payload
    )

    return formatItemResponse(res)
  },

  async fetchFilesAllByDefinitionId(definitionId) {
    const res = await axios.get(
      `${URLs.FETCH_FILES_ALL_BY_DEFINITION_ID}${definitionId}`
    )

    return formatListResponse(res)
  },

  async addFilesAll(payload) {
    const res = await axios.post(URLs.ADD_FILES_ALL, payload)

    return formatResponse(res)
  },

  async fetchFilesAllById(definitionId) {
    const res = await axios.get(
      `${URLs.FETCH_FILES_ALL_BY_DEFINITION_ID}${definitionId}`
    )

    return formatListResponse(res)
  },
  // async deleteFileOne(id) {
  //   const res = await axios.delete(`${URLs.DELETE_FILE_ONE}${id}`)
  //

  //   return formatResponse(res)
  // }
  async deleteFilesAllByIds(payload) {
    const res = await axios.put(URLs.DELETE_FILES_ALL_BY_IDS, payload)

    return formatResponse(res)
  },

  async fetchAttemptStatsById(definitionId) {
    const res = await axios.get(
      `${URLs.FETCH_ATTEMPT_STATS_BY_ID}${definitionId}`
    )

    return formatResponse(res)
  },

  async fetchInvitedUserList({ id }) {
    const res = await axios.get(`${URLs.FETCH_INVITED_USER_LIST}${id}`)

    return formatListResponse(res)
  },
  async inviteMembers({ id, userIds }) {
    const res = await axios.put(`${URLs.INVITE_MEMBERS}`, {
      id,
      idList: userIds
    })

    return formatResponse(res)
  },
  async uninviteMembers({ id, userIds }) {
    const res = await axios.delete(URLs.UNINVITE_MEMBERS, {
      data: { id, idList: userIds },
      headers: { 'Content-Type': 'application/json' }
    })

    return formatResponse(res)
  },

  async checkAvailableByAttribute(payload) {
    const res = await axios.post(URLs.CHECK_AVAILABLE_BY_ATTRIBUTE, payload)

    return formatListResponse(res)
  }
}
