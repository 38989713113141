import $api from '@/api'
import * as notify from '@/utils/notify'

export const fetchOneFilterData = async ({ filterName }) => {
  let result = {}

  try {
    result = await $api.memberFinder.fetchOneFilterData({
      filterName
    })
    const { error } = result

    if (error) {
      notify.error({ text: error })
    }
  } catch (error) {
    notify.error({ text: error })
  }

  return result
}

export const fetchAssessmentDefinitionFilters = async payload => {
  let result = {}

  try {
    result = await $api.memberFinder.fetchAssessmentDefinitionFilters(payload)
    const { error } = result

    if (error) {
      notify.error({ text: error })
    }
  } catch (error) {
    notify.error({ text: error })
  }

  return result
}

export const searchGrouped = async ({ payload, isMembers }) => {
  let result = {}

  try {
    const handler = isMembers
      ? $api.memberFinder.searchMembersGrouped
      : $api.memberFinder.searchHubsGrouped

    result = await handler(payload)
    const { error } = result

    if (error) {
      notify.error({ text: error })
    }
  } catch (error) {
    notify.error({ text: error })
  }

  return result
}

export const searchGroupedRowsOnly = async ({ payload, isMembers }) => {
  let result = {}

  try {
    const handler = isMembers
      ? $api.memberFinder.searchMembersGroupedRowsOnly
      : $api.memberFinder.searchHubsGroupedRowsOnly

    result = await handler(payload)
    const { error } = result

    if (error) {
      notify.error({ text: error })
    }
  } catch (error) {
    notify.error({ text: error })
  }

  return result
}

export const exportGrouped = async ({ payload, isMembers }) => {
  let result = {}

  try {
    const handler = isMembers
      ? $api.memberFinder.exportMembersGrouped
      : $api.memberFinder.exportHubsGrouped

    result = await handler(payload)
    const { error } = result

    if (error) {
      notify.error({ text: error })
    }
  } catch (error) {
    notify.error({ text: error })
  }

  return result
}

export const vetMany = async payload => {
  let result = {}

  try {
    result = await $api.memberFinder.vetMany(payload)
    const { error } = result

    if (error) {
      notify.error({ text: error })
    }
  } catch (error) {
    notify.error({ text: error })
  }

  return result
}
export const syncMany = async payload => {
  let result = {}

  try {
    result = await $api.memberFinder.syncMany(payload)
    const { error } = result

    if (error) {
      notify.error({ text: error })
    }
  } catch (error) {
    notify.error({ text: error })
  }

  return result
}
export const statusChangeMany = async payload => {
  let result = {}

  try {
    result = await $api.memberFinder.statusChangeMany(payload)
    const { error } = result

    if (error) {
      notify.error({ text: error })
    }
  } catch (error) {
    notify.error({ text: error })
  }

  return result
}
export const rateMany = async payload => {
  let result = {}

  try {
    result = await $api.memberFinder.rateMany(payload)
    const { error } = result

    if (error) {
      notify.error({ text: error })
    }
  } catch (error) {
    notify.error({ text: error })
  }

  return result
}
export const teamAddMany = async payload => {
  let result = {}

  try {
    result = await $api.memberFinder.teamAddMany(payload)
    const { error } = result

    if (error) {
      notify.error({ text: error })
    }
  } catch (error) {
    notify.error({ text: error })
  }

  return result
}
export const excludeFromCountsMany = async payload => {
  let result = {}

  try {
    result = await $api.memberFinder.excludeFromCountsMany(payload)
    const { error } = result

    if (error) {
      notify.error({ text: error })
    }
  } catch (error) {
    notify.error({ text: error })
  }

  return result
}
