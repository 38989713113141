export const TASK_STATUSES = Object.freeze({
  // UNKNOWN: 0,
  NEW: 1,
  IN_PROGRESS: 2,
  PAUSED: 3,

  COMPLETED: 4,
  CANCELLED: 5,
  EXPIRED: 6,
  ABANDONED: 8,
  SUPERSEDED: 9,

  SKIPPED: 7
})

export const ATTEMPT_STATUSES = Object.freeze({
  NEW: 1,
  IN_PROGRESS: 2,
  PAUSED: 3,

  COMPLETED: 4,
  CANCELLED: 5,
  EXPIRED: 6,
  ABANDONED: 8,
  SUPERSEDED: 9,

  SKIPPED: 7
})

export const STATUSES_COLORS = Object.freeze({
  [TASK_STATUSES.NEW]: { bg: 'blue', text: 'white' },
  [TASK_STATUSES.IN_PROGRESS]: { bg: 'orange', text: 'white' },
  [TASK_STATUSES.PAUSED]: { bg: 'gray', text: 'black' },

  [TASK_STATUSES.COMPLETED]: { bg: 'green', text: 'white' },
  [TASK_STATUSES.CANCELLED]: { bg: 'red', text: 'white' },
  [TASK_STATUSES.EXPIRED]: { bg: 'red', text: 'white' },
  [TASK_STATUSES.ABANDONED]: { bg: 'red', text: 'white' },
  [TASK_STATUSES.SUPERSEDED]: { bg: 'red', text: 'white' },

  [TASK_STATUSES.SKIPPED]: { bg: 'gray', text: 'black' }
})

export const AVAILABLE_STATUSES = [
  ATTEMPT_STATUSES.NEW,
  ATTEMPT_STATUSES.PAUSED,
  ATTEMPT_STATUSES.IN_PROGRESS
]
export const UNAVAILABLE_STATUSES = [
  ATTEMPT_STATUSES.COMPLETED,
  ATTEMPT_STATUSES.CANCELLED,
  ATTEMPT_STATUSES.ABANDONED,
  ATTEMPT_STATUSES.SUPERSEDED,
  ATTEMPT_STATUSES.EXPIRED
]

export const sortAttemptsByCreatedDate = list =>
  list.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))

export const defineIsStatusAvailable = status =>
  AVAILABLE_STATUSES.includes(status)
export const defineIsStatusUnavailable = status =>
  UNAVAILABLE_STATUSES.includes(status)
