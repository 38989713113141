<template>
  <BaseDialog
    ref="base-dialog"
    :max-width="512"
    :header-title="headerTitle"
    :is-loading="isLoading"
    :footer-buttons="footerButtons"
    @close="$emit('close')"
  >
    <div class="w-full flex flex-col">
      <v-form ref="form">
        <v-text-field
          v-model="form.name"
          label="Attribute name"
          maxlength="255"
          background-color="white"
          outlined
          dense
          :rules="[$rules.required]"
        />

        <v-textarea
          v-model="form.description"
          label="Description"
          maxlength="500"
          background-color="white"
          outlined
          dense
        />

        <BaseFormSwitch
          v-model="form.isOnlyForHubs"
          class="mb-6"
          label="Only for hubs"
          is-hide-details
        />
      </v-form>
    </div>
  </BaseDialog>
</template>

<script>
import $api from '@/api'

import * as notify from '@/utils/notify'
import { defineIsEqual } from '@/utils/base'

import BaseDialog from '@/components/Base/BaseDialog/BaseDialog.vue'
import BaseFormSwitch from '@/components/Base/Form/FormSwitch.vue'

const defaultEmptyForm = Object.freeze({
  name: '',
  description: '',
  isOnlyForHubs: false
})

export default {
  name: 'AttributesCreatingDialog',

  components: { BaseDialog, BaseFormSwitch },

  props: {
    branch: { type: Object, required: true },
    isEditing: { type: Boolean, default: false }
  },

  data: () => ({
    form: { ...defaultEmptyForm },
    isLoading: false
  }),

  computed: {
    isCreating() {
      return !this.isEditing
    },

    isModifiedForm() {
      return !defineIsEqual(this.defaultForm, this.form)
    },

    headerTitle() {
      return [this.isCreating ? 'Create new' : 'Update', 'attribute'].join(' ')
    },

    isFromAttribute() {
      return Boolean(this.branch.attributeCategoryId)
    },

    footerButtons() {
      return [
        {
          text: 'Close',
          attrs: { disabled: this.isLoading, color: 'grey', text: true },
          handler: this.handleClose
        },
        {
          text: this.isCreating ? 'Create' : 'Save',
          attrs: {
            color: 'primary',
            loading: this.isLoading
          },
          handler: this.handleSubmit
        }
      ]
    }
  },

  created() {
    this.setInitialForm()
  },

  methods: {
    setInitialForm() {
      this.form = this.isEditing
        ? {
            name: this.branch.name,
            description: this.branch.description,
            isOnlyForHubs: this.branch.onlyForHubs
          }
        : structuredClone(defaultEmptyForm)
      this.defaultForm = structuredClone(this.form)
    },

    handleSubmit() {
      const isValid = this.defineIsValid()

      if (!isValid) return

      this.makeRequest()
    },

    handleSuccess() {
      this.$emit('success')
      this.handleClose()
    },

    async makeRequest() {
      const payload = this.definePayload()
      const requestsMethod = this.defineRequestMethod()

      this.setIsLoading(true)

      try {
        const { error } = await requestsMethod(payload)

        if (error) {
          notify.error({ text: error })
          return
        }

        this.handleSuccess()
      } catch (error) {
        notify.error({ text: error })
      } finally {
        this.setIsLoading(false)
      }
    },

    defineRequestMethod() {
      return this.isCreating
        ? $api.attributes.createOne
        : $api.attributes.updateOne
    },

    definePayload() {
      const payload = {
        id: this.isEditing ? this.branch.id : undefined,
        value: this.form.name,
        description: this.form.description,
        onlyForHubs: this.form.isOnlyForHubs
      }

      if (this.isCreating) {
        payload.parentId = this.isFromAttribute ? this.branch.id : null
      } else {
        payload.parentId = this.branch.parentId
      }

      payload.attributeCategoryId = this.isFromAttribute
        ? this.branch?.attributeCategoryId
        : this.branch?.id

      // delete payload.$type
      delete payload.children

      return payload
    },

    defineIsValid() {
      const isValid = this.$refs.form.validate()

      return isValid
    },

    setIsLoading(isLoading) {
      this.isLoading = isLoading
    },

    handleClose() {
      this.$refs['base-dialog'].handleClose()
    }
  }
}
</script>
