import * as services from '@/services'

import addresses from '@/store/modules/user/addresses'
import profile from '@/store/modules/user/profile'
import contracts from '@/store/modules/user/contracts'

import { USER_TYPES, USER_STATUSES } from '@/utils/consts'

const defineIsHub = user => user?.userTypeCode === USER_TYPES.codes.HUB
const defineIsMember = user => user?.userTypeCode === USER_TYPES.codes.MEMBER

const defineIsUserRole = user => {
  const roles = user?.roleNames?.split(',').filter(Boolean) || []

  const superAdmin = roles.includes('administrator')
  const multiTenantAdmin = roles.includes('multitenantadmin')

  return {
    anyAdmin: superAdmin || superAdmin,
    superAdmin,
    multiTenantAdmin
  }
}

const defineIsUserStatus = user => {
  const statusCode = user?.userStatusCode

  return {
    active: statusCode === USER_STATUSES.codes.ACTIVE,
    paused: statusCode === USER_STATUSES.codes.PAUSED,
    deleted: statusCode === USER_STATUSES.codes.DELETED,
    pending: statusCode === USER_STATUSES.codes.PENDING,
    denied: statusCode === USER_STATUSES.codes.DENIED,
    incomplete: statusCode === USER_STATUSES.codes.INCOMPLETE
  }
}

//  MYZIURA 2 contracts. Remove these:
// .consultingAgreement .consultingAgreementSignature
// .nda .ndaSignature

const defineIsRegistrationIncomplete = user => {
  // const isRegistrationCompleted =
  //   user?.businessEntityTypeId || user?.registrationCompletedAt

  const isRegistrationCompleted = user?.registrationCompletedAt

  return !isRegistrationCompleted
}

const defineIsRequiredFieldsFilled = user => {
  const isHub = defineIsHub(user)

  if (isHub) {
    return (
      user?.companyOverview &&
      user.totalNumberOfEmployees &&
      user.locations.every(item => item.numberOfEmployees)
    )
  } else {
    return true
  }
}

const defineIsAccountIncomplete = (user, rootGetters) => {
  const isRegistrationCompleted = !defineIsRegistrationIncomplete(user)
  const isRequiredFieldsFilled = defineIsRequiredFieldsFilled(user)

  const isAllRequiredContractsSigned =
    rootGetters['principal/contracts/defineIsAllRequiredContractsSigned'](user)

  const isUserStatusActive = defineIsUserStatus(user).active

  return (
    !isRegistrationCompleted ||
    !isRequiredFieldsFilled ||
    !isAllRequiredContractsSigned ||
    !isUserStatusActive
  )
}

export default {
  namespaced: true,

  modules: { addresses, profile, contracts },

  state: {
    user: null
  },

  getters: {
    isHub: state => defineIsHub(state.user),
    defineIsHub: () => user => defineIsHub(user),

    isMember: state => defineIsMember(state.user),
    defineIsMember: () => user => defineIsMember(user),

    isUserRole: state => defineIsUserRole(state.user),
    defineIsUserRole: () => user => defineIsUserRole(user),

    isUserStatus: state => defineIsUserStatus(state.user),
    defineIsUserStatus: () => user => defineIsUserStatus(user),

    isRegistrationIncomplete: state =>
      defineIsRegistrationIncomplete(state.user),
    defineIsRegistrationIncomplete: () => user =>
      defineIsRegistrationIncomplete(user),

    isAccountIncomplete: (state, getters, _, rootGetters) =>
      defineIsAccountIncomplete(state.user, rootGetters),
    defineIsAccountIncomplete: (state, getters, _, rootGetters) => user =>
      defineIsAccountIncomplete(user, rootGetters),

    isRequiredFieldsFilled: state => defineIsRequiredFieldsFilled(state.user),
    defineIsRequiredFieldsFilled: () => user =>
      defineIsRequiredFieldsFilled(user)
  },

  actions: {
    async UPDATE_ONE({ commit }, payload) {
      const result = await services.users.updateOne(payload)
      const { user } = result

      await commit('SET_USER', user)

      return result
    }
  },

  mutations: {
    SET_USER(state, user) {
      state.user = user
    },
    UPDATE_USER(state, toUpdate) {
      state.user = { ...state.user, ...toUpdate }
    }
  }
}
