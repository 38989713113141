import axios from 'axios'

const BASE_URL = `/api/reports`
const URLs = new Proxy(
  {
    FETCH_ONE: '/get-report/',
    UPLOAD_CONTRACT: '/upload-contract'
  },
  { get: (target, prop) => `${BASE_URL}${target[prop]}` }
)

export default {
  async fetchOneByType({ principalId, userTypeCode, reportType }) {
    const res = await axios.post(
      `${URLs.FETCH_ONE}${principalId}/${userTypeCode}/${reportType}`,
      null,
      { responseType: 'blob' }
    )

    return res
  },

  async uploadContract(formData) {
    const res = await axios.post(URLs.UPLOAD_CONTRACT, formData)

    return res
  }
}
