import $api from '@/api'
import { formatKeysToPascalCase } from '@/utils/base'
import * as notify from '@/utils/notify'

export default {
  namespaced: true,

  state: {
    comments: {
      list: [],
      isLoading: false
    }
  },

  actions: {
    async FETCH_ALL_BY_ATTEMPT_ID({ commit }, attemptId) {
      commit('SET_IS_LOADING', true)

      let result = {}

      try {
        result = await $api.assessment.attemptComments.fetchAllByAttemptId(
          attemptId
        )
        const { list, isSuccess, error } = result

        if (error) {
          notify.error({ text: error })
        } else {
          await commit('SET_LIST', isSuccess ? list : [])
        }
      } catch (error) {
        notify.error({ text: error })
      } finally {
        commit('SET_IS_LOADING', false)
      }

      return result
    },

    async CREATE_ONE(_, payload) {
      // commit('SET_IS_LOADING', true)

      let result = {}

      try {
        result = await $api.assessment.attemptComments.createOne(payload)
        const { error } = result

        if (error) {
          notify.error({ text: error })
        }
      } catch (error) {
        notify.error({ text: error })
      }
      // finally {
      //   commit('SET_IS_LOADING', false)
      // }

      return result
    },

    async UPDATE_ONE({ rootState }, toUpdate) {
      // commit('SET_IS_LOADING', true)

      let result = {}

      const userId = rootState.user.user.id

      toUpdate = {
        UserId: userId,
        ...formatKeysToPascalCase(toUpdate)
      }

      try {
        result = await $api.assessment.attemptComments.updateOne(toUpdate)
        const { error } = result

        if (error) {
          notify.error({ text: error })
        }
      } catch (error) {
        notify.error({ text: error })
      }
      // finally {
      //   commit('SET_IS_LOADING', false)
      // }

      return result
    },

    async DELETE_ONE(_, id) {
      // commit('SET_IS_LOADING', true)

      let result = {}

      try {
        result = await $api.assessment.attemptComments.deleteOne(id)
        const { error } = result

        if (error) {
          notify.error({ text: error })
        }
      } catch (error) {
        notify.error({ text: error })
      }
      //  finally {
      //   commit('SET_IS_LOADING', false)
      // }

      return result
    }
  },

  mutations: {
    SET_LIST(state, list) {
      state.comments.list = list
    },
    SET_IS_LOADING(state, isLoading) {
      state.comments.isLoading = isLoading
    }
  }
}
