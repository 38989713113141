<template>
  <v-app class="max-w-screen-3xl w-full mx-auto">
    <!-- <v-fade-transition leave-absolute group class="flex flex-1"> -->
    <AppRedirectAnimation
      v-if="isRedirectAnimation"
      key="app-redirect-animation"
      @close="handleCloseRedirectAnimation"
    />
    <AppLoader v-else-if="isLoading" key="app-loader" />

    <template v-else>
      <AppHeader v-if="!$isOn.publicPage" key="app-header" />

      <v-main key="app-main">
        <AppContent>
          <router-view />
        </AppContent>
      </v-main>

      <AppFooter v-if="!$isOn.publicPage" key="app-footer" />
      <AppCookieBanner key="app-cookie-banner" />
    </template>
    <!-- </v-fade-transition> -->
  </v-app>
</template>

<script>
import { mapActions } from 'vuex'

import { watchForElement } from '@/utils/base'

import AppLoader from '@/components/App/AppLoader.vue'
import AppRedirectAnimation from '@/components/App/AppRedirectAnimation.vue'

import AppHeader from '@/components/App/AppHeader/AppHeader.vue'
import AppContent from '@/components/App/AppContent.vue'
import AppFooter from '@/components/App/AppFooter/AppFooter.vue'
import AppCookieBanner from '@/components/App/AppCookieBanner.vue'

export default {
  name: 'TheApp',

  components: {
    AppLoader,
    AppRedirectAnimation,
    AppHeader,
    AppContent,
    AppFooter,
    AppCookieBanner
  },

  data: () => ({
    isLoading: false,

    isRedirectAnimation: false
  }),

  watch: {
    '$route.query.showInterstitial'() {
      if (Number(this.$route?.query?.showInterstitial) === 1) {
        this.handleOpenRedirectAnimation()

        const query = { ...this.$route.query, showInterstitial: undefined }
        this.$router.replace({ query })
      }
    }
  },

  async created() {
    this.isLoading = true

    try {
      await Promise.all([
        this.checkIsUserAuthorized(),
        this.fetchAllTenants(),
        this.fetchAllContracts()
      ])
    } catch {
    } finally {
      this.isLoading = false
    }
  },

  mounted() {
    document.addEventListener('visibilitychange', this.handleVisibilityChange)

    // Builded TailwindCSS style tags append _grid style tag incorrectly
    watchForElement('link[href*="_grid"]').then(
      this.fixTailwindCssStyleTagOrder
    )

    const isImpersonatedUser = 'auth-access-token' in sessionStorage

    if (isImpersonatedUser) {
      this.fixSessionStorageInheritance()
    }
  },

  beforeDestroy() {
    document.removeEventListener(
      'visibilitychange',
      this.handleVisibilityChange
    )
  },

  methods: {
    ...mapActions('auth', {
      checkIsUserAuthorized: 'CHECK_IS_USER_AUTHORIZED',
      checkIsRefreshTokenValid: 'CHECK_IS_REFRESH_TOKEN_VALID'
    }),
    ...mapActions({
      fetchAllTenants: 'tenants/FETCH_ALL',
      fetchAllContracts: 'contracts/FETCH_ALL'
    }),

    handleVisibilityChange() {
      const isVisible = document.visibilityState === 'visible'

      if (!isVisible) return

      this.checkIsRefreshTokenValid()
    },

    handleOpenRedirectAnimation() {
      this.isRedirectAnimation = true
    },
    handleCloseRedirectAnimation() {
      this.isRedirectAnimation = false
    },

    fixTailwindCssStyleTagOrder(el) {
      if (!el) return

      document.querySelector('head').prepend(el)
    },

    fixSessionStorageInheritance() {
      const linkClickListener = event => {
        const clickedElement = event.target

        if (clickedElement.tagName === 'A' || clickedElement.closest('a')) {
          const a = clickedElement.closest('a')
          const target = a.getAttribute('target')

          if (target !== '_blank') return

          const href = a.getAttribute('href')

          if (!href) return

          event.preventDefault()
          window.open(href, '_blank')
        }
      }

      document.body.addEventListener('click', linkClickListener)
    }
  }
}
</script>
