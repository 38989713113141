import $api from '@/api'
import * as notify from '@/utils/notify'

export const fetchAll = async () => {
  let result = {}

  try {
    result = await $api.professions.fetchAll()
    const { error } = result

    if (error) {
      notify.error({ text: error })
    }
  } catch (error) {
    notify.error({ text: error })
  }

  return result
}

export const fetchAllUsersByUserId = async payload => {
  let result = {}

  try {
    result = await $api.professions.fetchAllUsersByUserId(payload)
    const { error } = result

    if (error) {
      notify.error({ text: error })
    }
  } catch (error) {
    notify.error({ text: error })
  }

  return result
}

export const createOne = async payload => {
  let result = {}

  try {
    result = await $api.professions.createOne(payload)
    const { error } = result

    if (error) {
      notify.error({ text: error })
    }
  } catch (error) {
    notify.error({ text: error })
  }

  return result
}

export const updateOne = async payload => {
  let result = {}

  try {
    result = await $api.professions.updateOne(payload)
    const { error } = result

    if (error) {
      notify.error({ text: error })
    }
  } catch (error) {
    notify.error({ text: error })
  }

  return result
}

export const deleteOne = async payload => {
  let result = {}

  try {
    result = await $api.professions.deleteOne(payload)
    const { error } = result

    if (error) {
      notify.error({ text: error })
    }
  } catch (error) {
    notify.error({ text: error })
  }

  return result
}

export const mergeMultiple = async payload => {
  let result = {}

  try {
    result = await $api.professions.mergeMultiple(payload)
    const { error } = result

    if (error) {
      notify.error({ text: error })
    }
  } catch (error) {
    notify.error({ text: error })
  }

  return result
}
