<template>
  <v-menu
    v-model="isVisible"
    transition="slide-x-reverse-transition"
    bottom
    :close-on-content-click="false"
    offset-y
    min-width="320"
  >
    <template #activator="{ on, attrs }">
      <slot name="activator" :on="on" :attrs="attrs" />
    </template>

    <BaseMenuContent :list="list" @close="handleClose" />
  </v-menu>
</template>

<script>
const BaseMenuContent = () =>
  import('@/components/App/AppHeader/AppHeaderBaseMenuContent.vue')

export default {
  name: 'AppHeaderBaseMenu',

  components: { BaseMenuContent },

  props: {
    list: { type: Array, default: () => [] }
  },

  data: () => ({
    isVisible: false
  }),

  methods: {
    handleClose() {
      this.isVisible = false
    }
  }
}
</script>
