import axios from 'axios'

import { formatListResponse } from '@/utils/api'

const BASE_URL = `/api/rx-norm`
const URLs = new Proxy(
  {
    FETCH_ALL_BY_DRAG_NAME: '/find-by-drug-name',
    CREATE_WITH_ATTRIBUTE: '/create-with-attribute'
  },
  { get: (target, prop) => `${BASE_URL}${target[prop]}` }
)

export default {
  async fetchAllByDrugName(payload) {
    const res = await axios.post(URLs.FETCH_ALL_BY_DRAG_NAME, payload)

    return formatListResponse(res)
  },
  async createWithAttribute(payload) {
    const res = await axios.post(URLs.CREATE_WITH_ATTRIBUTE, payload)

    return formatListResponse(res)
  }
}
