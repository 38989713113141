<template>
  <div class="gap-4 flex flex-col">
    <v-card
      v-for="attempt of notifs.expiredAttempts.list"
      :key="attempt.id"
      class="max-w-lg flex !bg-white !border-none overflow-hidden"
    >
      {{ void (isClickable = defineIsClickable(attempt)) }}

      <div class="p-2 flex flex-col text-base">
        <span class="font-bold">
          Your attempt on
          <em>{{ attempt.userAssessment.assessmentDefinition.name }}</em>
          expired ({{ $formatDate(attempt.expiredAt) }})
        </span>

        <span v-if="isClickable">
          View your options for taking the assessment again
        </span>
      </div>

      <div
        v-ripple
        class="p-4 w-16 flex items-center justify-center bg-gray-400 !rounded-none cursor-pointer"
        @click="() => handleView(attempt)"
      >
        <span class="text-white font-medium">View</span>
      </div>
    </v-card>
  </div>
</template>

<script>
import { TABS as MEMBER_TABS } from '@/components/Tenant/Member/MemberProfile/MemberProfile.vue'

export default {
  name: 'AppHeaderNotifsSidebarTabsExpiredAttempts',

  props: {
    notifs: { type: Object, required: true }
  },

  created() {
    this.MEMBER_TABS = MEMBER_TABS
  },

  methods: {
    handleView(attempt) {
      this.$emit('close')

      setTimeout(() => {
        this.$router.push({
          name: 't.member.profile.tab',
          params: { tabCode: MEMBER_TABS.codes.ASSESSMENTS },
          query: { userAssessmentId: attempt.userAssessmentId }
        })
      })
    },

    defineIsClickable(attempt) {
      return attempt.userAssessment.remainingAttemptCount > 0
    }
  }
}
</script>
