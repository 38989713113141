<template>
  <v-slide-x-reverse-transition>
    <div
      v-if="isVisible"
      ref="notification"
      class="fixed right-0 w-full max-w-md px-4 cursor-pointer select-none transition duration-300"
      :style="{
        top: `${verticalOffset}px`,
        '--animation-duration': `${duration}ms`,
        '--animation-play-state': isPaused ? 'paused' : 'running',
        'z-index': 1000
      }"
    >
      <v-sheet
        class="overflow-hidden bg-red-500"
        rounded
        :elevation="3"
        @mouseover="handlePause"
        @mouseleave="handleResume"
        @click="handleClose"
      >
        <v-progress-linear
          v-if="!isInfinite"
          :value="100"
          :color="notification.color"
        />

        <v-alert
          class="mb-0 !rounded-none"
          :type="notification.color"
          :color="notification.color"
          dense
          text
          @update:model-value="value => !value && handleClose"
        >
          <div class="w-full flex justify-between">
            <h3
              data-test="notif-title"
              class="w-full text-lg font-semibold leading-6"
              v-html="notification.title"
            />

            <div class="h-6 ml-4 flex items-center">
              <v-btn icon x-small :color="notification.color">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </div>
          </div>

          <span
            v-if="normalizedText"
            class="mt-1 inline-flex break-normal"
            v-html="normalizedText"
          />
        </v-alert>
      </v-sheet>
    </div>
  </v-slide-x-reverse-transition>
</template>

<script>
import { TYPES } from '@/utils/notify'
import { BASE_DURATION } from '@/utils/base'
import { PauseableTimeout } from '@/utils/pauseable-timeout'

import { generateUUID } from '@/utils/base'

const defineIsValid = value =>
  (typeof value === 'object' && value.isAxiosError) || typeof value === 'string'

export default {
  name: 'UtilsNotification',

  props: {
    type: {
      type: String,
      required: true,
      validator: value => Object.values(TYPES).includes(value)
    },
    title: { type: String, default: '' },
    text: {
      type: [Object, String],
      default: '',
      validator: defineIsValid
    },

    isInfinite: { type: Boolean, default: false },
    // isCloseable: { type: Boolean, default: true },
    duration: {
      type: Number,
      default: BASE_DURATION,
      validator: value => value >= 1000
    }

    // buttons: { type: Array, default: () => [] }
  },

  data: () => ({
    isVisible: false,

    verticalOffset: 0,

    isPaused: false,
    pauseableTimeout: null,

    uniqId: null
  }),

  computed: {
    notification() {
      const types = {
        [TYPES.SUCCESS]: {
          title: this.title || 'Success',
          color: 'success'
        },
        [TYPES.WARNING]: {
          title: this.title || 'Warning',
          color: 'warning'
        },
        [TYPES.INFO]: {
          title: this.title || 'Info',
          color: 'info'
        },
        [TYPES.ERROR]: {
          title: this.title || 'Error',
          color: 'error'
        }
      }

      return types[this.type]
    },

    isError() {
      return typeof this.text === 'object'
    },

    error() {
      return this.isError ? this.text : {}
    },

    normalizedText() {
      return this.isError && this.error?.response?.status
        ? `Sorry, an unexpected error occurred (${this.error.response.status})`
        : this.text
    }

    // normalizedButtons() {
    //   return [{ text: 'Close', handler: () => {} }, ...this.buttons].filter(
    //     Boolean
    //   )
    // },
  },

  created() {
    this.uniqId = generateUUID()
  },

  mounted() {
    this.isVisible = true

    if (this.isInfinite) return

    this.pauseableTimeout = new PauseableTimeout(
      this.handleClose,
      this.duration
    )
    this.pauseableTimeout.start()
  },

  methods: {
    handleClose() {
      this.onClose(this.uniqId)

      setTimeout(() => (this.isVisible = false))

      if (this.isInfinite) return

      this.pauseableTimeout?.stop()
    },

    handleButtonClick(handler) {
      handler()
      this.handleClose()
    },

    handlePause() {
      if (this.isInfinite) return
      if (this.isPaused) return

      this.pauseableTimeout.pause()
      this.isPaused = true
    },

    handleResume() {
      if (this.isInfinite) return
      if (!this.isPaused) return

      this.pauseableTimeout.resume()
      this.isPaused = false
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .v-progress-linear {
  &__background {
    left: 0 !important;
    width: 100% !important;
  }

  &__determinate {
    animation-name: progress;
    animation-duration: var(--animation-duration);
    animation-play-state: var(--animation-play-state);
    animation-timing-function: linear;
    animation-fill-mode: forwards;
  }

  @keyframes progress {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-100%);
    }
  }
}
</style>
