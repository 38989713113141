export default {
  namespaced: true,

  state: {
    current: null
  },

  mutations: {
    setCurrent(state, model) {
      state.current = model
    }
  }
}
