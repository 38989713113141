import axios from 'axios'

import { formatListResponse } from '@/utils/api'

const BASE_URL = `/api/user-addresses`
const URLs = new Proxy(
  {
    FETCH_ALL_BY_USER_ID: '/get-by-user/',

    UPDATE_ONE: '/update'
  },
  { get: (target, prop) => `${BASE_URL}${target[prop]}` }
)

export default {
  async fetchAllByUserId(userId) {
    const res = await axios.get(`${URLs.FETCH_ALL_BY_USER_ID}${userId}`)

    return formatListResponse(res)
  },

  async updateOne(payload) {
    const res = await axios.patch(URLs.UPDATE_ONE, payload)

    return formatListResponse(res)
  }
}
