import axios from 'axios'

import { formatItemResponse, formatListResponse } from '@/utils/api'

const BASE_URL = `/api/category`
const URLs = new Proxy(
  {
    FETCH_ALL: `/get-all`,
    FETCH_ALL_ADMIN: `/admin-get-all`,

    CREATE_ONE: '/create',
    UPDATE_ONE: '/update',
    DELETE_MULTIPLE: '/delete-multiple'
  },
  { get: (target, prop) => `${BASE_URL}${target[prop]}` }
)

export default {
  async fetchAll(isForAdmin) {
    const res = isForAdmin
      ? await axios.get(URLs.FETCH_ALL)
      : await axios.get(URLs.FETCH_ALL_ADMIN)

    return formatListResponse(res)
  },

  async createOne(payload) {
    const res = await axios.post(URLs.CREATE_ONE, payload)

    return formatItemResponse(res)
  },
  async updateOne(payload) {
    const res = await axios.put(URLs.UPDATE_ONE, payload)

    return formatItemResponse(res)
  },
  async deleteMultiple(payload) {
    const res = await axios.put(URLs.DELETE_MULTIPLE, payload)

    return formatItemResponse(res)
  }
}
