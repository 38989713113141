import * as services from '@/services'

export default {
  namespaced: true,

  state: {
    contracts: {
      list: null,
      isLoading: false
    }
  },

  getters: {
    getContractsByTenantId: state => tenantId => {
      return (
        state.contracts.list?.filter(item => item.tenantId === tenantId) || null
      )
    },

    getContractByTypeIdAndTenantId:
      state =>
      ({ typeId, tenantId }) => {
        return (
          state.contracts.list?.find(
            item => item.contractTypeId === typeId && item.tenantId === tenantId
          ) || null
        )
      }
  },

  actions: {
    async FETCH_ALL({ commit }) {
      await commit('SET_IS_LOADING', true)

      let { list } = await services.contracts.fetchAll()

      await Promise.all([
        commit('SET_LIST', list),
        commit('SET_IS_LOADING', false)
      ])

      return list
    }
  },

  mutations: {
    SET_LIST(state, list) {
      state.contracts.list = list
    },
    SET_IS_LOADING(state, isLoading) {
      state.contracts.isLoading = isLoading
    }
  }
}
