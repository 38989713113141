<template>
  <v-dialog
    v-scroll-lock
    :value="isVisible"
    :max-width="maxWidth"
    scrollable
    :persistent="isPersistent || isLoading"
    :fullscreen="isFullscreen || isMobileAndDown"
    @close="handleClose"
    @click:outside="handleClose"
    @keydown.esc="handleClose"
  >
    <v-card flat>
      <BaseDialogHeader
        v-if="headerTitle || $slots.header"
        :header-title="headerTitle"
        :is-loading="isLoading"
        :is-persistent="isPersistent"
        :is-mobile-and-down="isMobileAndDown"
        :style="`z-index: ${OVERLAY_Z_INDEX + 1}`"
        @close="handleClose"
      >
        <slot name="header" />
      </BaseDialogHeader>

      <v-card-text
        class="flex flex-col"
        :class="{
          'overflow-hidden': isLoading,
          [normalizedContentClass]: true
        }"
      >
        <slot />
      </v-card-text>

      <v-fade-transition>
        <v-overlay
          v-if="isLoading || $slots.overlay"
          absolute
          :z-index="OVERLAY_Z_INDEX"
        >
          <v-progress-circular
            v-if="isLoading"
            indeterminate
            size="64"
            color="grey lighten-4"
          />

          <slot v-else-if="$slots.overlay" name="overlay" />
        </v-overlay>
      </v-fade-transition>

      <BaseDialogFooter
        v-if="footerButtons.length || $slots.footer"
        :buttons="footerButtons"
        :style="`z-index: ${OVERLAY_Z_INDEX + 1}`"
      >
        <slot name="footer" />
      </BaseDialogFooter>
    </v-card>
  </v-dialog>
</template>

<script>
import BaseDialogHeader from '@/components/Base/BaseDialog/BaseDialogHeader.vue'
import BaseDialogFooter from '@/components/Base/BaseDialog/BaseDialogFooter.vue'

const OVERLAY_Z_INDEX = 12

export default {
  name: 'BaseDialog',

  components: { BaseDialogHeader, BaseDialogFooter },

  props: {
    maxWidth: { type: [Number, String], default: 768 },
    contentClass: { type: String, default: '' },
    headerTitle: { type: String, default: '' },
    isLoading: { type: Boolean, default: false },
    isFullscreen: { type: Boolean, default: false },
    isPersistent: { type: Boolean, default: false },
    footerButtons: { type: Array, default: () => [] },

    beforeClose: { type: [Function, null], default: null }
  },

  data: () => ({
    isVisible: true
  }),

  computed: {
    isMobileAndDown() {
      return this.$vuetify?.breakpoint?.name === 'xs'
    },

    normalizedContentClass() {
      const baseClass = ['bg-gray-100', '!p-4 sm:!p-8'].join(' ')
      return [this.contentClass || baseClass]
    }
  },

  created() {
    this.OVERLAY_Z_INDEX = OVERLAY_Z_INDEX
  },

  methods: {
    handleClose() {
      setTimeout(() => {
        const isCloseable = !this.isPersistent && !this.isLoading

        if (this.beforeClose) this.beforeClose(isCloseable)

        if (!isCloseable) return

        this.isVisible = false
        setTimeout(() => this.$emit('close'), 250)
      })
    },

    handleForceClose() {
      this.isVisible = false
      setTimeout(() => this.$emit('close'), 250)
    }
  }
}
</script>
