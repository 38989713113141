<template>
  <div class="flex flex-initial" style="z-index: 0">
    <v-tooltip
      v-for="(tenant, index) of normalizedTenantsList"
      :key="tenant.id"
      :disabled="!isTooltip"
      bottom
    >
      <template #activator="{ on, attrs }">
        <div
          class="h-full flex flex-initial items-center justify-center bg-white border-2 border-white rounded-full hover:!z-20"
          :style="{
            ...(index > 0 && {
              'margin-left': '-0.5rem'
            }),
            'z-index': index + 1
          }"
          v-bind="attrs"
          v-on="on"
        >
          <v-avatar
            :class="{ 'cursor-pointer': isClickable }"
            :size="isSmall ? '1.5rem' : '2rem'"
            :color="definePrimaryColor(tenant)"
            @click="() => handleClick(tenant)"
          >
            <v-img
              v-if="tenant?.files?.favicon?.path"
              class="absolute inset-0 h-full w-full"
              :src="tenant.files.favicon.path"
              :alt="`${tenant.name} small logo`"
            />

            <div
              class="w-full h-full flex justify-center items-center text-center"
            >
              <span class="font-bold text-xs text-white leading-none">
                {{ defineTenantAbbr(tenant) }}
              </span>
            </div>
          </v-avatar>
        </div>
      </template>

      <span>{{ tenant.name }}</span>
    </v-tooltip>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import { defineColorsByTenant } from '@/utils/theme-changer'
import { TENANT_LIST_ITEM_ACTIONS } from '@/utils/consts'

export default {
  name: 'AdminBaseTenantAvatars',

  props: {
    list: { type: Array, default: () => [] },
    isSmall: { type: Boolean, default: false },

    // true if should be aligned by the left side
    isRtl: { type: Boolean, default: false },
    // true if should be aligned by the right side
    isLtr: { type: Boolean, default: false },
    isTooltip: { type: Boolean, default: true },

    isClickable: { type: Boolean, default: false }
  },

  computed: {
    ...mapState('tenants', ['tenants']),

    normalizedTenantsList() {
      const isIdList = typeof this.list?.[0] === 'number'
      const idList = isIdList ? this.list : this.list.map(item => item.id)

      const normalizedList = this.tenants?.list?.filter(item =>
        idList.includes(item.id)
      )

      if (this.isLtr) return normalizedList
      else if (this.isRtl) return normalizedList?.reverse()

      return normalizedList
    }
  },

  methods: {
    definePrimaryColor(tenant) {
      return defineColorsByTenant(tenant).primary
    },

    defineTenantAbbr(tenant) {
      const abbr = tenant?.name.match(/[A-Z]/g).join('')

      return abbr || ''
    },

    handleClick(tenant) {
      if (!this.isClickable) return

      this.$router.open({
        name: 'admin.tenants',
        query: {
          marker: tenant.marker,
          action: TENANT_LIST_ITEM_ACTIONS.DETAILS
        }
      })
    }
  }
}
</script>
