import axios from 'axios'

import {
  formatResponse,
  formatListResponse,
  formatItemResponse
} from '@/utils/api'

const BASE_URL = `/api/tenant-addresses`
const URLs = new Proxy(
  {
    FETCH_ALL_BY_TENANT_ID: '/get-by-tenant-id/',

    UPSERT_ONE: '/upsert',
    DELETE_ONE: '/delete/'
  },
  { get: (target, prop) => `${BASE_URL}${target[prop]}` }
)

export default {
  async fetchAllByTenantId({ id }) {
    const res = await axios.get(`${URLs.FETCH_ALL_BY_TENANT_ID}${id}`)

    return formatListResponse(res)
  },

  async upsertOne(payload) {
    const res = await axios.post(URLs.UPSERT_ONE, payload)

    return formatItemResponse(res)
  },

  async deleteOne({ id }) {
    const res = await axios.delete(`${URLs.DELETE_ONE}${id}`)

    return formatResponse(res)
  }
}
