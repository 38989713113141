import axios from 'axios'

import { formatResponse, formatListResponse } from '@/utils/api'

const BASE_URL = `/api/member-finder`
const URLs = new Proxy(
  {
    FETCH_ONE_FILTER_DATA: `/get-filter/`,
    FETCH_ASSESSMENT_DEFINITION_FILTERS: `/get-assessment-definition-filters`,

    SEARCH_MEMBERS_GROUPED: `/search-members-grouped`,
    SEARCH_HUBS_GROUPED: `/search-hubs-grouped`,

    SEARCH_MEMBERS_GROUPED_ROWS_ONLY: `/search-members-grouped-rows-only`,
    SEARCH_HUBS_GROUPED_ROWS_ONLY: `/search-hubs-grouped-rows-only`,

    VET_MANY: '/vet-many',
    SYNC_MANY: '/sync-many',
    STATUS_CHANGE_MANY: '/status-change-many',
    RATE_MANY: '/rate-many',
    TEAM_ADD_MANY: '/team-add-many',
    EXCLUDE_FROM_COUNTS_MANY: '/exclude-many-from-counts'
  },
  { get: (target, prop) => `${BASE_URL}${target[prop]}` }
)

export default {
  async fetchOneFilterData({ filterName }) {
    const res = await axios.get(`${URLs.FETCH_ONE_FILTER_DATA}${filterName}`)

    return formatListResponse(res)
  },
  async fetchAssessmentDefinitionFilters(payload) {
    const res = await axios.post(
      URLs.FETCH_ASSESSMENT_DEFINITION_FILTERS,
      payload
    )

    return formatListResponse(res)
  },
  async searchMembersGrouped(payload) {
    const res = await axios.post(URLs.SEARCH_MEMBERS_GROUPED, payload)

    return formatResponse(res)
  },
  async searchHubsGrouped(payload) {
    const res = await axios.post(URLs.SEARCH_HUBS_GROUPED, payload)

    return formatResponse(res)
  },

  async searchMembersGroupedRowsOnly(payload) {
    const res = await axios.post(URLs.SEARCH_MEMBERS_GROUPED_ROWS_ONLY, payload)

    return formatResponse(res)
  },
  async searchHubsGroupedRowsOnly(payload) {
    const res = await axios.post(URLs.SEARCH_HUBS_GROUPED_ROWS_ONLY, payload)

    return formatResponse(res)
  },

  async exportMembersGrouped(payload) {
    const res = await axios.post(URLs.SEARCH_MEMBERS_GROUPED, payload, {
      responseType: 'blob'
    })

    return res
  },
  async exportHubsGrouped(payload) {
    const res = await axios.post(URLs.SEARCH_HUBS_GROUPED, payload, {
      responseType: 'blob'
    })

    return res
  },

  async vetMany(payload) {
    const res = await axios.patch(URLs.VET_MANY, payload)

    return formatResponse(res)
  },
  async syncMany(payload) {
    const res = await axios.patch(URLs.SYNC_MANY, payload)

    return formatResponse(res)
  },
  async statusChangeMany(payload) {
    const res = await axios.patch(URLs.STATUS_CHANGE_MANY, payload)

    return formatResponse(res)
  },
  async rateMany(payload) {
    const res = await axios.patch(URLs.RATE_MANY, payload)

    return formatResponse(res)
  },
  async teamAddMany(payload) {
    const res = await axios.patch(URLs.TEAM_ADD_MANY, payload)

    return formatResponse(res)
  },
  async excludeFromCountsMany(payload) {
    const res = await axios.patch(URLs.EXCLUDE_FROM_COUNTS_MANY, payload)

    return formatResponse(res)
  }
}
