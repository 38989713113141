<template>
  <div class="gap-4 flex flex-col">
    <h1 v-if="$isOn.principalPage" class="text-2xl font-black">
      Hub Profile Page
    </h1>

    <div class="gap-4 flex flex-col md:flex-row">
      <!-- :class="{ 'md:flex-row': $isOn.principalPage }" -->
      <div>
        <v-card class="sticky top-20 flex flex-initial flex-shrink-0">
          <BaseProfilePrincipalTabs
            :value="activeTabCode"
            :tabs="tabs"
            @select="handleSelectTab"
          />
          <!-- :is-vertical="$isOn.adminPage" -->
        </v-card>
      </div>

      <div class="w-full gap-4 flex flex-col">
        <component
          :is="activeTab.component"
          :user="user"
          v-bind="activeTab.props"
        />
      </div>
    </div>
  </div>
</template>

<script>
import BaseProfilePrincipalTabs from '@/components/Base/BaseProfilePrincipalTabs.vue'

import { generateConnection } from '@/utils/base'

const TabsGeneralInfo = () =>
  import('@/components/Tenant/Hub/HubProfile/Tabs/TabsGeneralInfo.vue')
const TabsLocations = () =>
  import('@/components/Tenant/Hub/HubProfile/Tabs/TabsLocations.vue')
const TabsExpertise = () =>
  import('@/components/Tenant/Hub/HubProfile/Tabs/TabsExpertise.vue')
const TabsRates = () =>
  import('@/components/Tenant/Hub/HubProfile/Tabs/TabsRates.vue')
const TabsPortfolio = () =>
  import('@/components/Tenant/Hub/HubProfile/Tabs/TabsPortfolio.vue')
const TabsContracts = () =>
  import('@/components/Tenant/Hub/HubProfile/Tabs/TabsContracts.vue')

const TABS = (() => {
  const CODES = Object.freeze({
    GENERAL_INFO: 'general-info',
    LOCATIONS: 'locations',
    EXPERTISE: 'expertise',
    RATES: 'rates',
    PORTFOLIO: 'overview',
    CONTRACTS: 'contracts'
  })

  const LABELS = Object.freeze({
    GENERAL_INFO: 'General Info',
    LOCATIONS: 'Locations & Contacts',
    EXPERTISE: 'What You Do',
    RATES: 'How You Bill',
    PORTFOLIO: 'Company Details',
    CONTRACTS: 'Contracts'
  })

  return Object.freeze({
    codes: CODES,
    labels: LABELS,

    labelByCode: generateConnection(LABELS, CODES)
  })
})()

const BASE_TAB_CODE = TABS.codes.GENERAL_INFO

export { TABS }
export default {
  name: 'TenantHubProfile',

  components: {
    BaseProfilePrincipalTabs,

    TabsGeneralInfo,
    TabsLocations,
    TabsExpertise,
    TabsRates,
    TabsPortfolio,
    TabsContracts
  },

  props: {
    tabCode: { type: String, default: BASE_TAB_CODE },
    user: { type: Object, required: true },
    isForAdmin: { type: Boolean, default: false }
  },

  data: () => ({
    activeTabCode: BASE_TAB_CODE
  }),

  computed: {
    activeTab() {
      return (
        this.tabs.find(tab => tab.code === this.activeTabCode) || this.tabs[0]
      )
    },

    tabs() {
      return [
        {
          code: TABS.codes.GENERAL_INFO,
          text: TABS.labels.GENERAL_INFO,
          icon: 'mdi-office-building-outline',
          component: 'TabsGeneralInfo'
        },
        {
          code: TABS.codes.LOCATIONS,
          text: TABS.labels.LOCATIONS,
          icon: 'mdi-map-marker-multiple-outline',
          component: 'TabsLocations'
        },
        {
          code: TABS.codes.EXPERTISE,
          text: TABS.labels.EXPERTISE,
          icon: 'mdi-math-compass',
          component: 'TabsExpertise',
          props: { isForAdmin: this.isForAdmin }
        },
        {
          code: TABS.codes.RATES,
          text: TABS.labels.RATES,
          icon: 'mdi-currency-usd',
          component: 'TabsRates'
        },
        {
          code: TABS.codes.PORTFOLIO,
          text: TABS.labels.PORTFOLIO,
          icon: 'mdi-clipboard-outline',
          component: 'TabsPortfolio'
        },
        {
          code: TABS.codes.CONTRACTS,
          text: TABS.labels.CONTRACTS,
          icon: 'mdi-script-outline',
          component: 'TabsContracts'
        }
      ].filter(Boolean)
    }
  },

  watch: {
    '$route.params.tabCode': {
      immediate: true,
      handler(value, oldValue) {
        if (value && value === oldValue) return

        this.handleChangeRouteTabCode({ isInitial: !value })
      }
    }
  },

  methods: {
    handleChangeRouteTabCode({ isInitial = false }) {
      const code = this.$route.params.tabCode
      const isValid = this.defineIsValidCode(code)

      if (isValid) {
        this.activeTabCode = code
      } else {
        this.activeTabCode = BASE_TAB_CODE
        this.handleSelectTab({ code: this.activeTabCode, isInitial })
      }

      this.scrollToTop()
    },

    handleSelectTab({ code, isInitial }) {
      const params = { ...this.$route.params, tabCode: code }

      const options = { name: this.$route.name, params }

      isInitial ? this.$router.replace(options) : this.$router.push(options)
    },

    defineIsValidCode(code) {
      if (!code) return

      const codes = this.tabs.map(tab => tab.code)
      const isValid = codes.includes(code)

      return isValid
    },

    scrollToTop() {
      window.scrollTo(0, 0)
    }
  }
}
</script>
