import $api from '@/api'
import * as notify from '@/utils/notify'

export const fetchAllByPrincipalId = async principal => {
  const { id: principalId, userTypeCode } = principal
  const payload = { principalId, userTypeCode }

  let result = {}

  try {
    result = await $api.principalToTenant.fetchAllByPrincipalId(payload)
    const { error } = result

    if (error) {
      notify.error({ text: error })
    }
  } catch (error) {
    notify.error({ text: error })
  }

  return result
}

export const createOne = async ({ principal, id }) => {
  let result = {}

  const { id: principalId, userTypeCode } = principal
  const payload = { principalId, userTypeCode, tenantId: id }

  try {
    result = await $api.principalToTenant.createOne(payload)
    const { error } = result

    if (error) {
      notify.error({ text: error })
    }
  } catch (error) {
    notify.error({ text: error })
  }

  return result
}

export const createMultiple = async payload => {
  return Promise.all(payload.map(createOne))
}

export const deleteOne = async ({ principal, id }) => {
  const { id: principalId, userTypeCode } = principal
  const payload = { principalId, userTypeCode, tenantId: id }

  let result = {}

  try {
    result = await $api.principalToTenant.deleteOne(payload)
    const { error } = result

    if (error) {
      notify.error({ text: error })
    }
  } catch (error) {
    notify.error({ text: error })
  }

  return result
}

export const deleteMultiple = async payload => {
  return Promise.all(payload.map(deleteOne))
}
