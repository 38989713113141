<template>
  <div class="h-full flex flex-1 flex-col">
    <div class="w-full p-8">
      <h1 class="text-xl md:text-2xl font-black text-black">
        Test registration as:
      </h1>
    </div>

    <div class="p-8 gap-8 flex flex-col">
      <div
        v-for="tenant of tenants?.list"
        :key="tenant.id"
        :data-test="tenant.marker"
        class="w-full gap-8 grid grid-cols-2"
      >
        <div
          v-for="userTypeCode of [
            USER_TYPES.codes.MEMBER,
            USER_TYPES.codes.HUB
          ]"
          :key="userTypeCode"
          class="col-span-2 p-1 gap-4 flex flex-initial items-center rounded cursor-pointer md:col-span-1 hover:bg-gray-100"
          @click="
            $router.open({
              name: 't.auth.test-register.user-type-name',
              params: {
                tenantMarker: tenant.marker,
                userTypeName: USER_TYPES.nameByCode[userTypeCode]
              }
            })
          "
        >
          <BaseLogo :to="null" :tenant-marker="tenant.marker" />

          <div class="flex flex-col text-left text-black">
            <strong>
              <span :style="`color: ${tenant.colors.primary}`">{{
                tenant?.name
              }}</span>
              &mdash; {{ USER_TYPES.labelByCode[userTypeCode] }}
            </strong>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import * as themeChanger from '@/utils/theme-changer'
import { USER_TYPES } from '@/utils/consts'

import BaseLogo from '@/components/Base/BaseLogo.vue'

export default {
  name: 'PublicHomePage',

  components: { BaseLogo },

  computed: {
    ...mapState('tenants', ['tenants'])
  },

  created() {
    this.USER_TYPES = USER_TYPES
  },

  mounted() {
    themeChanger.setDefaultTheme()
  }
}
</script>
