import $api from '@/api'
import * as notify from '@/utils/notify'

export const fetchAllByHubId = async ({ id }) => {
  let result = {}

  try {
    result = await $api.hubLocations.fetchAllByHubId({ id })
    const { error } = result

    if (error) {
      notify.error({ text: error })
    }
  } catch (error) {
    notify.error({ text: error })
  }

  return result
}

export const updateOne = async payload => {
  let result = {}

  try {
    result = await $api.hubLocations.updateOne(payload)
    const { error } = result

    if (error) {
      notify.error({ text: error })
    }
  } catch (error) {
    notify.error({ text: error })
  }

  return result
}
