const SPLITTER = ' | '

export const getTitle = () => {
  const [tenantName, pageName, ...other] = document.title.split(SPLITTER)

  return [tenantName, pageName, ...other]
}

export const setTitle = ([tenantName, pageName, ...other]) => {
  document.title = [tenantName, pageName, ...other]
    .filter(Boolean)
    .join(SPLITTER)
}
