import axios from 'axios'

import * as notify from '@/utils/notify'

export default {
  namespaced: true,

  state: {
    selectedUserIdList: []
  },

  actions: {
    getSubmissionBatchByRound({ state }, { roundId, batchSize, batchNumber }) {
      const payload = {
        roundId,
        batchSize,
        batchNumber,
        userIdList: state.selectedUserIdList
      }

      return axios
        .post('/api/submissions/get-batch-by-round', payload)
        .then(response => {
          if (response.error) {
            notify.error({ text: response.error })
            return
          }

          return response.content
        })
        .catch(error => {
          notify.error({ text: error })
          return false
        })
    },

    downloadSubmissionFiles(
      { rootState },
      { downloadType, submissionFiles, selectedAssignment }
    ) {
      if (submissionFiles && submissionFiles.length > 0 && selectedAssignment) {
        const payload = {
          submissionFileIdList: submissionFiles.map(e => e.id),
          generationType: downloadType,
          requestedByUserId: rootState.user.user.id,
          assignmentId: selectedAssignment.id,
          includeAllSubmissionFiles: false,
          shouldSendEmailNotification: true,
          miroBoardId: null
        }

        return axios
          .post('/api/reports/get-submission-file-report', payload)
          .then(response => {
            if (response.error) {
              notify.error({ text: response.error })
              return
            }

            notify.info({
              text: 'Request received. Check your email for a download link after a few minutes (depending on the number & size of files)'
            })
          })
          .catch(error => {
            notify.error({ text: error })
            return false
          })
      } else {
        notify.warning({ text: 'Please select one or more files.' })
      }
    }
  },

  mutations: {
    setSelectedUserIdList(state, list) {
      state.selectedUserIdList = list
    }
  }
}
