import $api from '@/api'
import * as notify from '@/utils/notify'

export const fetchUserInviteesByAssignment = async payload => {
  let result = {}

  try {
    result = await $api.invitees.fetchUserInviteesByAssignment(payload)
    const { error } = result

    if (error) {
      notify.error({ text: error })
    }
  } catch (error) {
    notify.error({ text: error })
  }

  return result
}

export const fetchTeamInviteesByAssignment = async payload => {
  let result = {}

  try {
    result = await $api.invitees.fetchTeamInviteesByAssignment(payload)
    const { error } = result

    if (error) {
      notify.error({ text: error })
    }
  } catch (error) {
    notify.error({ text: error })
  }

  return result
}

export const fetchOneForInvitationResponseWithInvitee = async ({
  inviteeId
}) => {
  let result = {}

  try {
    result = await $api.invitees.fetchOneForInvitationResponseWithInvitee({
      inviteeId
    })
    const { error } = result

    if (error) {
      notify.error({ text: error })
    }
  } catch (error) {
    notify.error({ text: error })
  }

  return result
}

export const participationDecision = async payload => {
  let result = {}

  try {
    result = await $api.invitees.participationDecision(payload)
    const { error } = result

    if (error) {
      notify.error({ text: error })
    }
  } catch (error) {
    notify.error({ text: error })
  }

  return result
}
export const interestDecision = async payload => {
  let result = {}

  try {
    result = await $api.invitees.interestDecision(payload)
    const { error } = result

    if (error) {
      notify.error({ text: error })
    }
  } catch (error) {
    notify.error({ text: error })
  }

  return result
}
export const declineOneParticipation = async ({ inviteeId }) => {
  let result = {}

  try {
    result = await $api.invitees.declineOneParticipation({ inviteeId })
    const { error } = result

    if (error) {
      notify.error({ text: error })
    }
  } catch (error) {
    notify.error({ text: error })
  }

  return result
}
