<template>
  <v-menu
    left
    :value="isVisible"
    :close-on-content-click="false"
    @input="handleToggleMenu"
  >
    <template #activator="{ on, attrs }">
      <v-btn class="mt-auto" icon small v-bind="attrs" v-on="on">
        <v-icon>mdi-dots-vertical</v-icon>
      </v-btn>
    </template>

    <v-list dense>
      <template v-if="headerTitle">
        <div class="px-4 py-2">
          <strong>{{ headerTitle }}</strong>
        </div>

        <v-divider class="my-2" />
      </template>

      <v-list-item-group dense>
        <v-list-item
          v-for="(button, index) in buttons"
          :key="index"
          :color="button.color"
          @click="() => handleMenuItemClick(button)"
        >
          <v-list-item-icon>
            <v-icon :color="button.color">{{ button.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>{{ button.label }}</v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: 'BaseMenu',

  props: {
    isVisible: { type: Boolean, default: false },
    headerTitle: { type: String, default: '' },
    item: { type: Object, default: null },
    buttons: { type: Array, default: () => [] }
  },

  methods: {
    handleToggleMenu(value) {
      if (value) return

      this.handleClose()
    },

    handleMenuItemClick(button) {
      button.handler(this.item)
      this.handleClose()
    },

    handleClose() {
      this.$emit('close')
    }
  }
}
</script>
