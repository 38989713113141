<template>
  <div>
    <div
      v-if="!isCookieBannerClosed"
      id="cookie-banner"
      class="fixed left-0 bottom-0 w-full py-4 px-4 sm:px-8 md:px-16 bg-gray-600 z-10"
    >
      <div class="gap-4 sm:gap-8 flex items-center justify-between">
        <span class="text-white text-xs">
          We use cookies to ensure you have a great experience on our website.
          If you continue to use this site, we'll assume you're OK with it.
        </span>

        <v-btn color="primary" @click="handleClose"> OK </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppCookieBanner',

  data: () => ({
    isCookieBannerClosed: true
  }),

  created() {
    this.isCookieBannerClosed = this.$cookies.isKey('cookie-banner-closed')
  },

  methods: {
    handleClose() {
      this.$cookies.set('cookie-banner-closed', true, '1m')

      this.isCookieBannerClosed = true
    }
  }
}
</script>
