export default {
  namespaced: true,

  state: {
    principalToContracts: {
      list: null
    }
  },

  mutations: {
    SET_LIST(state, list) {
      state.principalToContracts.list = list
    }
  }
}
