import axios from 'axios'

import { formatListResponse } from '@/utils/api'

const BASE_URL = `/api/tenants`
const URLs = new Proxy(
  {
    FETCH_ALL: '/get-all',
    FETCH_ONE_BY_ID: '/get-by-id/',
    FETCH_ONE_BY_MARKER: '/get-by-marker/',
    FETCH_ALL_PRINCIPALS_FOR_TENANT: '/get-principals-for-tenant/',

    CREATE_ONE: '/create',
    UPDATE_ONE: '/update',
    UPDATE_RULES: '/update-rules',
    DELETE_ONE: '/delete/'
  },
  { get: (target, prop) => `${BASE_URL}${target[prop]}` }
)

export default {
  async fetchAll() {
    const res = await axios.get(URLs.FETCH_ALL)

    return formatListResponse(res)
  },
  async fetchOneById({ id }) {
    const res = await axios.get(`${URLs.FETCH_ONE_BY_ID}${id}`)

    return formatListResponse(res)
  },
  async fetchOneByMarker({ marker }) {
    const res = await axios.get(`${URLs.FETCH_ONE_BY_MARKER}${marker}`)

    return formatListResponse(res)
  },
  async fetchAllPrincipalsForTenant({ tenantId, userTypeCode }) {
    const res = await axios.get(
      `${URLs.FETCH_ALL_PRINCIPALS_FOR_TENANT}${tenantId}/${userTypeCode}`
    )

    return formatListResponse(res)
  },

  async createOne(payload) {
    const res = await axios.post(URLs.CREATE_ONE, payload)
    const { content: item, isSuccess, error } = res

    return { item, isSuccess, error }
  },
  async updateOne(payload) {
    const res = await axios.put(URLs.UPDATE_ONE, payload)
    const { content: item, isSuccess, error } = res

    return { item, isSuccess, error }
  },
  async updateRules(payload) {
    const res = await axios.put(URLs.UPDATE_RULES, payload)
    const { content: item, isSuccess, error } = res

    return { item, isSuccess, error }
  },
  async deleteOne({ id }) {
    const res = await axios.delete(`${URLs.DELETE_ONE}${id}`)
    const { content: item, isSuccess, error } = res

    return { item, isSuccess, error }
  }
}
