import locations from '@/store/modules/hub/locations'

import * as services from '@/services'

export default {
  namespaced: true,

  modules: { locations },

  actions: {
    async UPDATE_ONE({ commit }, payload) {
      const result = await services.hubs.updateOne({
        ...payload,
        $type: undefined
      })
      const { item: hub, isSuccess } = result

      if (!isSuccess) return result

      await commit('user/SET_USER', hub, { root: true })

      return result
    }
  }
}
