import $api from '@/api'

import * as notify from '@/utils/notify'

export default {
  namespaced: true,

  state: {
    addresses: {
      list: [],
      current: null,
      isLoading: false
    }
  },

  actions: {
    async FETCH_ALL_BY_MEMBER_ID({ rootState, commit }, userId) {
      userId = userId || rootState.user.user.id

      let result = {}

      await commit('SET_IS_LOADING', true)

      try {
        result = await $api.userAddress.fetchAllByUserId(userId)
        const { list, error } = result

        if (error) {
          notify.error({ text: error })
          return
        }

        await commit('SET_ADDRESSES_LIST', list)
        await commit('SET_ADDRESSES_CURRENT', list[0])
      } catch (error) {
        notify.error({ text: error })
      } finally {
        await commit('SET_IS_LOADING', false)
      }

      return result
    },

    async UPDATE_ONE({ commit }, payload) {
      let result = {}

      await commit('SET_IS_LOADING', true)

      try {
        result = await $api.userAddress.updateOne(payload)
        const { list, error } = result

        if (error) {
          notify.error({ text: error })
          return
        }

        await commit('SET_ADDRESSES_CURRENT', list[0])
      } catch (error) {
        notify.error({ text: error })
      } finally {
        await commit('SET_IS_LOADING', false)
      }

      return result
    }
  },

  mutations: {
    SET_ADDRESSES_LIST(state, list) {
      state.addresses.list = list
    },
    SET_ADDRESSES_CURRENT(state, current) {
      state.addresses.current = current
    },
    SET_IS_LOADING(state, isLoading) {
      state.addresses.isLoading = isLoading
    }
  }
}
