export default {
  inserted(el, binding) {
    const callback = binding.value
    const isWindow = binding.arg === 'window'

    const resizeObserver = new ResizeObserver(entries => {
      const { width, height } = entries[0].contentRect

      callback({ x: width, y: height })
    })

    if (isWindow) {
      resizeObserver.observe(document.documentElement)
    } else {
      resizeObserver.observe(el)
    }

    el.__resizeObserver__ = resizeObserver
  },

  unbind(el) {
    el.__resizeObserver__.disconnect()
  }
}

// export default {
//   inserted(el, binding) {
//     const callback = binding.value
//     const isWindow = binding.arg === 'window'

//     el.__handler = () => {
//       const x = isWindow ? window.innerWidth : el.offsetWidth
//       const y = isWindow ? window.innerHeight : el.offsetHeight

//       callback({ x, y })
//     }

//     window.addEventListener('resize', el.__handler)

//     el.__handler()
//   },

//   unbind(el) {
//     window.removeEventListener('resize', el.__handler)
//   }
// }
