// import * as notify from '@/utils/notify'

import * as services from '@/services'

export default {
  namespaced: true,

  state: {
    userProfile: {
      isLoading: false
    }
  },

  actions: {
    async UPDATE_ONE({ commit, rootState }, payload) {
      commit('SET_IS_LOADING', true)

      let result = {}

      result = await services.userProfile.updateOne(payload)
      const { user, isSuccess } = result

      if (isSuccess && rootState.user.user.id === user.id) {
        await commit('user/UPDATE_USER', user, { root: true })
      }

      commit('SET_IS_LOADING', false)

      return result
    },

    async UPDATE_HEALTHCARE_EXPERIENCE({ commit, rootState }, payload) {
      const result = await services.userProfile.updateHealthcareExperience(
        payload
      )

      const { user, isSuccess } = result

      if (isSuccess && rootState.user.user.id === user.id) {
        await commit('user/UPDATE_USER', user, { root: true })
      }

      return result
    }
  },

  mutations: {
    SET_IS_LOADING(state, isLoading) {
      state.userProfile.isLoading = isLoading
    }
  }
}
