import ws from '@/utils/web-storage'
import { LS_KEYS } from '@/utils/consts'
import { COLORS } from '@/utils/theme'
import * as themeChanger from '@/utils/theme-changer'

const defineTenantColors = tenant => {
  const styles = tenant ? JSON.parse(tenant?.styleJson) : null

  return {
    primary: styles?.primaryColor || COLORS.PRIMARY,
    secondary: styles?.secondaryColor || COLORS.SECONDARY,
    accent: styles?.accentColor || COLORS.ACCENT,
    error: styles?.errorColor || COLORS.ERROR,
    info: styles?.infoColor || COLORS.INFO,
    success: styles?.successColor || COLORS.SUCCESS,
    warning: styles?.warningColor || COLORS.WARNING
  }
}

export default {
  namespaced: true,

  state: {
    tenants: {
      selected: []
    }
  },

  getters: {
    defineTenantColors: () => tenant => defineTenantColors(tenant),
    selectedTenantColors: state => {
      const isSingleSelected = state.tenants?.selected?.length === 1

      const tenant = isSingleSelected ? state.tenants.selected[0] : null

      return defineTenantColors(tenant)
    }
  },

  mutations: {
    SET_SELECTED(state, selected) {
      state.tenants.selected = selected

      ws.set(LS_KEYS.ADMIN_TENANTS_SELECTED, selected || [])

      selected?.length === 1
        ? themeChanger.setTheme(selected[0])
        : themeChanger.setDefaultTheme()
    }
  }
}
