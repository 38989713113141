<template>
  <v-app-bar
    id="app-header"
    app
    class="relative max-w-screen-3xl w-full mx-auto !bg-white !z-10"
  >
    <div
      v-if="tenant"
      class="absolute bottom-0 left-0 h-1 w-full"
      :style="`background: linear-gradient(45deg, ${tenant?.colors?.primary}, ${tenant?.colors?.secondary})`"
    />

    <div class="w-full h-full pb-1 flex items-center justify-between">
      <div class="w-full flex items-center">
        <div class="gap-4 flex flex-initial items-center">
          <BaseLogo :tenant-marker="tenant?.marker" :size="48" is-label />
        </div>
      </div>

      <div class="flex flex-shrink-0 flex-initial items-center">
        <v-btn
          v-if="$breakpoints.isTabletAndDown"
          icon
          color="black"
          exact-path
          :to="{ name: 't.auth.login' }"
        >
          <v-icon>mdi-login</v-icon>
        </v-btn>

        <v-btn v-else text :to="{ name: 't.auth.login' }" exact-path>
          <span>Sign In</span>
        </v-btn>
      </div>
    </div>
  </v-app-bar>
</template>

<script>
import { mapGetters } from 'vuex'

import BaseLogo from '@/components/Base/BaseLogo.vue'

export default {
  name: 'AppHeaderPublic',

  components: { BaseLogo },

  computed: {
    ...mapGetters('tenants', ['getTenantByMarker']),

    tenant() {
      return this.getTenantByMarker(this.$route.params.tenantMarker)
    }
  }
}
</script>
