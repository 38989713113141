<template>
  <div v-loader="isLoading" class="p-4 flex flex-col flex-1 shadow-inner">
    <template v-if="filteredTreeList.length">
      <BaseLazyHOC
        v-for="branch of filteredTreeList"
        :key="branch.id"
        min-height="1rem"
      >
        <AttributesTreeBranch
          :ref="`branch-${branch.id}`"
          :parent="null"
          :branch="branch"
          :selected="selected"
          :selected-categories="selectedCategories"
          @select="handleSelect"
          @refresh="$emit('refresh')"
          @create-custom="({ branch }) => $emit('create-custom', { branch })"
        />
      </BaseLazyHOC>
    </template>

    <BaseEmpty v-else-if="isEmpty" :subtitle="['No attributes found']">
      <v-card-text v-if="isForAdmin">
        <v-btn color="primary" outlined small @click="$emit('reset-filters')">
          Clear filters
        </v-btn>
      </v-card-text>
    </BaseEmpty>

    <div v-else class="h-[216px] w-full" />
  </div>
</template>

<script>
import * as notify from '@/utils/notify'
import { defineCategoryMergedQuantityLimit } from '@/utils/attributes'

import BaseEmpty from '@/components/Base/BaseEmpty.vue'
import BaseLazyHOC from '@/components/Base/BaseLazyHOC.vue'
import AttributesTreeBranch from '@/components/Attributes/AttributesTreeBranch.vue'

export default {
  name: 'AttributesTree',

  components: { BaseEmpty, BaseLazyHOC, AttributesTreeBranch },

  provide() {
    return {
      isEditable: this.isEditable,
      isSelectable: this.isSelectable,
      isForAdmin: this.isForAdmin,

      defineChildren: this.defineChildren,
      defineBranchMenuButtons: this.defineBranchMenuButtons
    }
  },

  props: {
    principal: { type: Object, default: null },
    filteredTreeList: { type: Array, default: () => [] },
    selected: { type: Array, default: () => [] },
    selectedCategories: { type: Array, default: () => [] },
    categoriesList: { type: Array, default: () => [] },

    isEditable: { type: Boolean, default: false },
    isSelectable: { type: Boolean, default: false },
    isForAdmin: { type: Boolean, default: false },
    isLoading: { type: Boolean, default: false },
    isIgnoreCategoryLimits: { type: Boolean, default: false },

    defineChildren: { type: Function, required: true },
    defineBranchMenuButtons: { type: Function, required: true }
  },

  computed: {
    isEmpty() {
      return !this.filteredTreeList.length && !this.isLoading
    }
  },

  methods: {
    handleSelectAttribute(branch) {
      const isDuplicate = this.selected.some(item => item.id === branch.id)

      let selected = this.selected

      if (isDuplicate) {
        selected = selected.filter(item => item.id !== branch.id)

        this.$emit('update:selected', selected)
        return
      }

      if (!this.isIgnoreCategoryLimits) {
        const categoryId = branch.attributeCategoryId
        const category = this.categoriesList.find(c => c.id === categoryId)
        const categoryName = branch.attributeCategoryName

        const selectedInCategory = this.selected.filter(
          item => item.attributeCategoryId === categoryId
        )

        const { maxQuantity } = defineCategoryMergedQuantityLimit({
          category,
          principal: this.principal
        })

        const selectedCount = selectedInCategory.length + 1

        // if (selectedCount < minQuantity) {
        //   const title = 'Not enough items chosen'
        //   const text = `The «${categoryName}» category requires a minimum of ${minQuantity} items.`

        //   notify.info({ title, text })
        //   return
        // }

        if (selectedCount > maxQuantity) {
          const title = 'You have reached the limit'
          const text = `The «${categoryName}» category has reached the limit of ${maxQuantity}.`

          notify.info({ title, text })
          return
        }
      }

      selected.push(branch)

      const formatAttribute = a => ({
        attributeCategory: {
          id: a.attributeCategoryId,
          name: a.attributeCategoryName
        },
        ...a
      })

      this.$emit('update:selected', selected.map(formatAttribute))
    },

    handleSelectCategory(branch) {
      const isDuplicate = this.selectedCategories.some(
        item => item.id === branch.id
      )

      let selectedCategories = this.selectedCategories

      if (isDuplicate) {
        selectedCategories = selectedCategories.filter(
          item => item.id !== branch.id
        )

        this.$emit('update:selected-categories', selectedCategories)
        return
      }

      selectedCategories.push(branch)

      this.$emit('update:selected-categories', selectedCategories)
    },

    handleSelect(branch) {
      const isCategory = !branch.attributeCategoryId

      if (isCategory) {
        this.handleSelectCategory(branch)
      } else {
        this.handleSelectAttribute(branch)
      }
    }
  }
}
</script>
