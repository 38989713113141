import axios from 'axios'

import { formatListResponse } from '@/utils/api'

const BASE_URL = `/api/kind`
const URLs = new Proxy(
  {
    FETCH_ALL: `/all`
  },
  { get: (target, prop) => `${BASE_URL}${target[prop]}` }
)

export default {
  async fetchAll() {
    const res = await axios.get(URLs.FETCH_ALL)

    return formatListResponse(res)
  }
}
