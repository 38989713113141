<template>
  <v-card class="flex flex-initial !bg-white !border-none overflow-hidden">
    <div class="w-full p-2 flex flex-col text-base">
      <span class="font-bold">{{ clientName }}</span>
      <span class="mb-2">{{ $truncateText(item.assignment.title, 40) }}</span>

      <span class="text-red-500">{{ dateDue }}</span>
    </div>

    <div class="p-2 flex flex-initial items-end">
      <BaseTenantAvatars class="z-[0]" :list="[item.assignment.tenantId]" />
    </div>

    <div
      v-ripple
      class="p-4 w-16 flex flex-initial flex-shrink-0 items-center justify-center !rounded-none cursor-pointer"
      :class="[color]"
      @click="handleSubmit"
    >
      <span class="text-white font-medium">Open</span>
    </div>
  </v-card>
</template>

<script>
import BaseTenantAvatars from '@/components/Admin/Base/BaseTenantAvatars.vue'

export default {
  name: 'TenantMemberProjectsListItem',

  components: { BaseTenantAvatars },

  props: {
    item: { type: Object, required: true },
    color: { type: String, required: true }
  },

  computed: {
    clientName() {
      return this.item?.assignment?.client?.name
        ? this.$truncateText(this.item.assignment.client.name, 40)
        : '(No Client)'
    },

    dateDue() {
      if (this.item?.declinedInterest) return 'Declined'

      return this.item?.assignment?.dateDue
        ? `Due: ${this.$formatDate(this.item.assignment.dateDue)}`
        : '(No due date)'
    }
  },

  methods: {
    handleSubmit() {
      this.$emit('submit')

      setTimeout(() => {
        this.$router.push(this.item.to)
      }, 100)
    }
  }
}
</script>
