import axios from 'axios'

const BASE_URL = `/api/principal-attributes`
const URLs = new Proxy(
  {
    FETCH_ALL_PRINCIPALS_BY_ATTRIBUTES: '/get-principals-by-attribute',
    FETCH_ALL_BY_PRINCIPAL: '/get-all-by-principal/',
    FETCH_ALL_BEST: '/get-best/',

    SET_MULTIPLE_BEST: `/set-best`,

    ADD_MULTIPLE_BY_PRINCIPAL_AND_CATEGORY: `/add-multiple-by-principal-and-category`,
    ADD_TO_PRINCIPAL: `/add-for-principal/`,
    DELETE_MULTIPLE_BY_PRINCIPAL: `/delete-for-principal`
  },
  { get: (target, prop) => `${BASE_URL}${target[prop]}` }
)

export default {
  async fetchAllPrincipalsByAttributes({ attributeIdList, userTypeCode }) {
    const res = await axios.post(URLs.FETCH_ALL_PRINCIPALS_BY_ATTRIBUTES, {
      attributeIdList,
      userTypeCode
    })

    const {
      content: { $values: list },
      isSuccess,
      error
    } = res

    const normalizedList = isSuccess ? list || [] : []

    return { list: normalizedList, isSuccess, error }
  },
  async fetchAllByPrincipal({ principalId, userTypeCode }) {
    const res = await axios.get(
      `${URLs.FETCH_ALL_BY_PRINCIPAL}${principalId}/${userTypeCode}`
    )

    const { content: list, isSuccess, error } = res

    const normalizedList = isSuccess ? list || [] : []

    return { list: normalizedList, isSuccess, error }
  },
  async fetchAllBest({ principalId, userTypeCode }) {
    const res = await axios.get(
      `${URLs.FETCH_ALL_BEST}${principalId}/${userTypeCode}`
    )

    const { content: list, isSuccess, error } = res

    const normalizedList = isSuccess ? list || [] : []

    return { list: normalizedList, isSuccess, error }
  },
  async setMultipleBest(payload) {
    const res = await axios.post(URLs.SET_MULTIPLE_BEST, payload)
    const { isSuccess, error } = res

    return { isSuccess, error }
  },

  async addMultipleByPrincipalAndCategory(payload) {
    const res = await axios.put(
      URLs.ADD_MULTIPLE_BY_PRINCIPAL_AND_CATEGORY,
      payload
    )
    const { content: item, isSuccess, error } = res

    return { item, isSuccess, error }
  },
  async deleteMultipleByPrincipal({
    principalId,
    userTypeCode,
    principalAttributeIdList
  }) {
    const res = await axios.delete(URLs.DELETE_MULTIPLE_BY_PRINCIPAL, {
      data: { principalId, userTypeCode, principalAttributeIdList },
      headers: { 'Content-Type': 'application/json' }
    })

    const { isSuccess, error } = res

    return { isSuccess, error }
  }
}
