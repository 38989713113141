<template>
  <v-sheet
    class="w-full max-w-screen-md mx-auto flex flex-col rounded"
    outlined
  >
    <AttributesHeader
      ref="attributes-header"
      class="rounded-t"
      :header-title="headerTitle"
      :header-subtitle="headerSubtitle"
      :is-editable="isEditable"
      :is-for-admin="isForAdmin"
      :flat-tree-list="attributesFlatTreeList"
      :filtered-flat-tree-list.sync="attributes.filteredFlatTreeList"
      :selected="selected"
      :selected-categories="selectedCategories"
      @create="() => handleOpenCreationDialog({ isCategory: true })"
      @exported="handleExportSuccess"
    />

    <AttributesTree
      ref="attributes-tree"
      class="h-px overflow-auto"
      :principal="principal"
      :filtered-tree-list="attributesFilteredTreeList"
      :selected="selected"
      :selected-categories="selectedCategories"
      :categories-list="categories.list"
      :is-editable="isEditable"
      :is-selectable="isSelectable"
      :is-for-admin="isForAdmin"
      :is-loading="attributes.isLoading"
      :is-ignore-category-limits="isIgnoreCategoryLimits"
      :define-children="defineChildren"
      :define-branch-menu-buttons="defineBranchMenuButtons"
      @refresh="handleRefresh"
      @reset-filters="() => $refs['attributes-header'].handleResetFilters()"
      @create-custom="handleOpenCustomCreationDialog"
      @update:selected="selected => $emit('update:selected', selected)"
      @update:selected-categories="
        selected => $emit('update:selected-categories', selected)
      "
    />

    <template v-if="creationDialog.isVisible">
      <AttributesCategoryCreationDialog
        v-if="creationDialog.isCategory"
        v-bind="creationDialog"
        @success="handleSuccess"
        @close="handleCloseCreationDialog"
      />

      <AttributesCreationDialog
        v-else
        v-bind="creationDialog"
        @success="handleSuccess"
        @close="handleCloseCreationDialog"
      />
    </template>

    <AttributesCustomAttributeCreationDialog
      v-if="customCreationDialog.isVisible"
      :principal="principal"
      :category-id="customCreationDialog.branch.id"
      :category-name="customCreationDialog.branch.name"
      :selected-pa="selectedPa"
      :get-attributes-by-category-id="getAttributesByCategoryId"
      @success="handleSuccessCustomAttributeCreation"
      @close="handleCloseCustomCreationDialog"
    />

    <AttributesManageDuplicatesDialog
      v-if="manageDuplicatesDialog.isVisible"
      :principal="principal"
      :keeper="manageDuplicatesDialog.keeper"
      :losers="manageDuplicatesDialog.losers"
      :get-attributes-by-category-id="getAttributesByCategoryId"
      @success="handleSuccess"
      @close="handleCloseManageDuplicatesDialog"
    />
  </v-sheet>
</template>

<script>
import * as services from '@/services'
import * as notify from '@/utils/notify'
import * as confirm from '@/utils/confirm'
import {
  NO_RULES,
  defineRuledList,
  defineTreeList,
  defineChildren
} from '@/utils/attributes'

const formatAttributeItem = (list, item) => {
  const isCategoryPrefix = !item.parentId

  const id = item.id
  const parentId = item.parentId || item.attributeCategoryId

  const _uId = `attribute-${id}`
  const _uParentId = [
    isCategoryPrefix ? 'category' : 'attribute',
    parentId
  ].join('-')

  const children =
    list
      .filter(a => a.parentId === item.id)
      .map(item => formatAttributeItem(list, item)) || []

  return {
    ...item,
    name: item.value,
    _uId,
    _uParentId,
    children: children.length ? children : null
  }
}

const formatCategoryItem = item => ({
  ...item,
  _uId: `category-${item.id}`
})

import AttributesHeader from '@/components/Attributes/AttributesHeader.vue'
import AttributesTree from '@/components/Attributes/AttributesTree.vue'

import AttributesCreationDialog from '@/components/Attributes/AttributesCreationDialog.vue'
import AttributesCategoryCreationDialog from '@/components/Attributes/AttributesCategoryCreationDialog.vue'
import AttributesCustomAttributeCreationDialog from '@/components/Attributes/AttributesCustomAttributeCreationDialog.vue'
import AttributesManageDuplicatesDialog from '@/components/Attributes/AttributesManageDuplicatesDialog.vue'

export default {
  name: 'TheAttributes',

  components: {
    AttributesHeader,
    AttributesTree,

    AttributesCreationDialog,
    AttributesCategoryCreationDialog,
    AttributesCustomAttributeCreationDialog,
    AttributesManageDuplicatesDialog
  },

  props: {
    headerTitle: { type: String, default: 'Attributes' },
    headerSubtitle: { type: String, default: 'Manage attributes here' },

    principal: { type: Object, default: null },
    selected: { type: Array, default: () => [] },
    selectedPa: { type: Array, default: () => [] },
    selectedCategories: { type: Array, default: () => [] },
    rules: { type: Object, default: () => NO_RULES },

    isEditable: { type: Boolean, default: false },
    isSelectable: { type: Boolean, default: false },
    isForAdmin: { type: Boolean, default: false },
    isForIndividual: { type: Boolean, default: false },
    isIgnoreCategoryLimits: { type: Boolean, default: false }
  },

  data: () => ({
    attributes: {
      list: [],
      filteredFlatTreeList: [],

      isLoading: false
    },

    categories: {
      list: []
    },

    creationDialog: {
      isVisible: false,
      isEditing: false,
      branch: null,
      isCategory: false
    },

    customCreationDialog: {
      isVisible: false,
      branch: null
    },

    manageDuplicatesDialog: {
      isVisible: false,
      keeper: null,
      losers: []
    }
  }),

  computed: {
    attributesFlatTreeList() {
      const attributesTreeList = defineTreeList(this.attributes.list)
      return defineChildren({ children: attributesTreeList })
    },

    attributesFilteredTreeList() {
      const filteredTreeList = defineTreeList(
        this.attributes.filteredFlatTreeList
      )

      const filteredTreeListWithoutEmptyCategories = filteredTreeList.filter(
        category =>
          (category.allowsAdHocCreation && category.allowsUserAdditions) ||
          category.children
      )

      return this.isForAdmin
        ? filteredTreeList
        : filteredTreeListWithoutEmptyCategories
    }
  },

  created() {
    this.defineChildren = defineChildren

    this.handleRefresh()
  },

  methods: {
    async handleRefresh() {
      const defineConfig = () => {
        if (this.isForAdmin) return null

        const tenantIdList = this.principal.tenants.map(t => t.id)
        return { headers: { ScopedTenants: tenantIdList } }
      }

      const payload = [{ isForAdmin: this.isForAdmin }, defineConfig()]

      this.setIsLoading(true)
      const [attributes, categories] = await Promise.all([
        services.attributes.fetchAllWithStats(...payload),
        services.attributeCategories.fetchAll(...payload)
      ])
      this.setIsLoading(false)

      const formattedAttributesList =
        attributes.list.map(item =>
          formatAttributeItem(attributes.list, item)
        ) || []
      const formattedCategoriesList =
        categories.list.map(formatCategoryItem) || []

      const list = [...formattedAttributesList, ...formattedCategoriesList]

      const ruledList = defineRuledList({
        isForAdmin: this.isForAdmin,
        principal: this.principal,
        isForIndividual: this.isForIndividual,
        rules: this.rules,
        list
      })

      this.attributes.list = ruledList
      this.categories.list = formattedCategoriesList
    },

    handleOpenCreationDialog({ branch, isEditing, isCategory }) {
      this.creationDialog = { isVisible: true, branch, isEditing, isCategory }
    },
    handleOpenCustomCreationDialog({ branch }) {
      this.customCreationDialog = { isVisible: true, branch }
    },

    handleOpenManageDuplicatesDialog({ branch }) {
      this.manageDuplicatesDialog = { isVisible: true, keeper: branch }
    },
    handleCloseManageDuplicatesDialog() {
      this.manageDuplicatesDialog = { isVisible: false }
    },

    handleCloseCreationDialog() {
      this.creationDialog.isVisible = false
    },
    handleCloseCustomCreationDialog() {
      this.customCreationDialog.isVisible = false
    },

    async handleDelete(branch) {
      const isFromAttribute = Boolean(branch.attributeCategoryId)

      const { isConfirmed } = await confirm.warning({
        text: [
          `Are you sure you want to delete the`,
          `"${branch.name}"`,
          `${isFromAttribute ? 'attribute' : 'category'}?`
        ].join(' ')
      })

      if (!isConfirmed) return

      this.setIsLoading(true)

      await this.deleteOne(branch)

      this.setIsLoading(false)
    },

    handleSuccess() {
      notify.success()
      this.handleRefresh()
    },

    handleExportSuccess() {
      notify.success()
      // this.$emit('update:selected', [])
      // this.$emit('update:selected-categories', [])
    },

    async handleSuccessCustomAttributeCreation() {
      await this.handleRefresh()
      this.$emit('refresh')
    },

    async deleteOne(branch) {
      const isFromAttribute = Boolean(branch.attributeCategoryId)

      const requestsMethod = isFromAttribute
        ? services.attributes.deleteOne
        : services.attributeCategories.deleteOne

      const isSuccess = await requestsMethod(branch)

      if (!isSuccess) return

      this.handleSuccess()
    },

    setIsLoading(isLoading) {
      this.attributes.isLoading = isLoading
    },

    defineBranchMenuButtons(branch) {
      const isFromCategory = !branch.attributeCategoryId

      return [
        branch.depth <= 1 && {
          label: 'Add Attribute',
          color: 'primary',
          icon: 'mdi-plus',
          handler: () =>
            this.handleOpenCreationDialog({ branch, isCategory: false })
        },
        !branch.children?.length && {
          label: 'Manage Duplicates',
          color: 'primary',
          icon: 'mdi-content-duplicate',
          handler: () => this.handleOpenManageDuplicatesDialog({ branch })
        },
        {
          label: 'Edit',
          color: 'secondary',
          icon: 'mdi-pencil',
          handler: () =>
            this.handleOpenCreationDialog({
              branch,
              isEditing: true,
              isCategory: isFromCategory
            })
        },
        {
          label: 'Delete',
          color: 'red',
          icon: 'mdi-trash-can',
          handler: () => this.handleDelete(branch)
        }
      ].filter(Boolean)
    },

    getAttributesByCategoryId(categoryId) {
      return this.attributes.list.filter(
        a => a.attributeCategoryId === categoryId
      )
    }
  }
}
</script>
