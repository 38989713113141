<template>
  <BaseSidebar
    ref="base-sidebar"
    :header-title="headerTitle"
    :max-width="512"
    @close="$emit('close')"
  >
    <div class="gap-12 flex flex-col sm:gap-16">
      <div
        v-for="(block, index) of blocks"
        :key="`sidebar-notifs-block-${index}`"
        class="gap-4 flex flex-col flex-initial"
      >
        <span class="text-base text-black font-black sm:text-lg">
          {{ block.label }} ({{ block.badgeContent }})
        </span>

        <component
          :is="block.component"
          :notifs="notifs"
          @close="handleClose"
        />
      </div>
    </div>
  </BaseSidebar>
</template>

<script>
import { mapState } from 'vuex'

import BaseSidebar from '@/components/Base/BaseSidebar/BaseSidebar.vue'

import TabsExpiredAttempts from '@/components/App/AppHeader/AppHeaderNotifsSidebar/Tabs/TabsExpiredAttempts.vue'
import TabsFeaturedCreativesInvitations from '@/components/App/AppHeader/AppHeaderNotifsSidebar/Tabs/TabsFeaturedCreativesInvitations.vue'
import TabsProjectInvitation from '@/components/App/AppHeader/AppHeaderNotifsSidebar/Tabs/TabsProjectInvitation.vue'
import TabsRequiredActions from '@/components/App/AppHeader/AppHeaderNotifsSidebar/Tabs/TabsRequiredActions.vue'

export default {
  name: 'AppHeaderNotifsSidebar',

  components: {
    BaseSidebar,

    TabsProjectInvitation,
    TabsRequiredActions,
    TabsFeaturedCreativesInvitations,
    TabsExpiredAttempts
  },

  computed: {
    ...mapState('memberNotifications', {
      notifs: 'notifications'
    }),

    headerTitle() {
      return `Notifications (${this.notifs.count})`
    },

    inviteeCount() {
      const { list } = this.notifs.invitees
      return list ? list.filter(this.defineIsInvitationOpenToInvitee).length : 0
    },

    actionsCount() {
      const { count } = this.notifs.assignmentsRequiringActions
      return count || 0
    },

    anotherNotificationsCount() {
      return (
        this.notifs.count -
        (this.notifs.expiredAttempts?.count || 0) -
        this.actionsCount -
        this.inviteeCount
      )
    },

    blocks() {
      return [
        this.inviteeCount > 0 && {
          icon: 'mdi-briefcase',
          label: 'Project Invitations',
          badgeContent: this.inviteeCount,
          component: 'TabsProjectInvitation'
        },
        this.notifs.assignmentsRequiringActions?.isSome && {
          icon: '',
          label: `Required Actions`,
          badgeContent: this.actionsCount,
          component: 'TabsRequiredActions'
        },
        this.notifs.isFeaturedArtist && {
          icon: 'mdi-camera-burst',
          label: `Featured Creative Invitations`,
          badgeContent: this.anotherNotificationsCount,
          component: 'TabsFeaturedCreativesInvitations'
        },
        this.notifs.expiredAttempts?.isSome && {
          icon: '',
          label: `Expired assessments`,
          badgeContent: this.notifs.expiredAttempts.count,
          component: 'TabsExpiredAttempts'
        }
      ].filter(Boolean)
    }
  },

  methods: {
    defineIsInvitationClosed(invitation) {
      return invitation ? invitation.closedForRecruitment === true : null
    },
    defineIsInvitationOpenToInvitee(invitee) {
      return !(
        this.defineIsInvitationClosed(invitee.invitation) &&
        invitee.declinedParticipation === null
      )
    },

    handleClose() {
      this.$refs['base-sidebar'].handleClose()
    }
  }
}
</script>

<style lang="scss">
.AppHeaderNotifsSidebar {
  .v-tabs-bar {
    @apply border-b border-gray-300;
  }
  .v-tabs-items {
    background-color: transparent !important;
  }
}
</style>
