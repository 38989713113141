<template>
  <v-input
    ref="input"
    dense
    :value="input.value"
    :hide-details="isHideDetails"
    :rules="rules"
    @update:error="handleToggleError"
  >
    <BaseCard
      :class="{ 'border-error': input.isError }"
      :header-title="normalizedLabel"
    >
      <template #header-suffix>
        <span>{{ [input.value.length, 'of', maxAmount].join(' ') }}</span>
      </template>

      <AdminBaseTenantsList
        :list="normalizedTenantsList"
        :is-loading="isLoading"
      >
        <template #action="{ tenant }">
          <v-list-item-action class="flex flex-initial justify-center">
            {{ void (isSelected = defineIsSelected(tenant)) }}

            <v-checkbox
              v-if="isMultiple"
              :input-value="isSelected"
              @click.prevent="() => handleSelect(tenant)"
            />

            <template v-else>
              <v-radio-group v-if="isSelected" :value="isSelected">
                <v-radio
                  :value="true"
                  color="primary"
                  @click.prevent="() => handleSelect(tenant)"
                />
              </v-radio-group>

              <v-radio
                v-else
                color="primary"
                @click.prevent="() => handleSelect(tenant)"
              />
            </template>
          </v-list-item-action>
        </template>

        <template #action-skeleton>
          <v-list-item-action>
            <v-checkbox v-if="isMultiple" disabled />
            <v-radio v-else disabled />
          </v-list-item-action>
        </template>
      </AdminBaseTenantsList>
    </BaseCard>
  </v-input>
</template>

<script>
import { mapState } from 'vuex'

import { defineIsEqual } from '@/utils/base'

import BaseCard from '@/components/Base/BaseCard.vue'
import AdminBaseTenantsList from '@/components/Admin/Base/BaseTenantsList.vue'

export default {
  name: 'AdminBaseTenantsSelectableList',

  components: { BaseCard, AdminBaseTenantsList },

  props: {
    // Array of IDs
    value: { type: Array, default: () => [] },
    list: { type: Array, default: null },
    label: { type: String, default: '' },
    rules: { type: Array, default: () => [] },
    isMultiple: { type: Boolean, default: false },
    isHideDetails: { type: Boolean, default: false },
    isLoading: { type: Boolean, default: false }
  },

  data: () => ({
    input: { value: [], isError: false },

    observer: null
  }),

  computed: {
    ...mapState('tenants', ['tenants']),

    normalizedTenantsList() {
      return this.list || this.tenants.list
    },

    isSelectedSome() {
      return this.input.value?.length > 0
    },

    normalizedLabel() {
      if (this.label) return this.label

      return this.isMultiple ? 'Select companies' : 'Select company'
    },

    maxAmount() {
      return this.isMultiple ? this.normalizedTenantsList.length : 1
    }
  },

  watch: {
    value: {
      immediate: true,
      handler(value, oldValue) {
        if (defineIsEqual(value, oldValue)) return

        this.input.value = this.tenants?.list?.filter(item =>
          this.value.includes(item.id)
        )
      }
    }
  },

  mounted() {
    // MYZIURA 4 duplicate
    this.observer = new MutationObserver(mutations => {
      for (const m of mutations) {
        const value = m.target.getAttribute(m.attributeName)
        this.$nextTick(() => this.handleInputClassChange(value))
      }
    })

    this.observer.observe(this.$refs.input.$el, {
      attributes: true,
      attributeFilter: ['class']
    })
  },

  methods: {
    handleInput() {
      const idList = this.input.value.map(item => item.id)
      this.$emit('input', idList)
    },

    handleSelect(tenant) {
      if (!this.isMultiple && this.isSelectedSome) {
        this.input.value = []
        this.handleInput()
      }

      const addItem = () => {
        this.input.value.push(tenant)
        this.handleInput()
      }
      const removeItem = () => {
        this.input.value = this.input.value.filter(
          item => item.id !== tenant.id
        )
        this.handleInput()
      }

      const isSelected = this.defineIsSelected(tenant)
      isSelected ? removeItem() : addItem()
    },

    handleToggleError(isError) {
      this.input.isError = isError
    },

    // MYZIURA 4 duplicate
    handleInputClassChange(classAttrValue) {
      const classList = classAttrValue.split(' ')

      this.input.isError = classList.includes('error--text')
    },

    defineIsSelected(tenant) {
      return this.input.value.some(item => item.id === tenant.id)
    }
  }
}
</script>
