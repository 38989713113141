<template>
  <div class="max-w-lg gap-4 flex flex-col">
    <MemberProjectsListItem
      v-for="item of list"
      :key="item.id"
      :item="item"
      color="bg-primary"
      @submit="$emit('close')"
    />
  </div>
</template>

<script>
import MemberProjectsListItem from '@/components/Tenant/Member/MemberProjects/MemberProjectsListItem.vue'

export default {
  name: 'AppHeaderNotifsSidebarTabsRequiredActions',

  components: { MemberProjectsListItem },

  props: {
    notifs: { type: Object, required: true }
  },

  computed: {
    list() {
      return this.notifs.assignmentsRequiringActions.list.map(item => ({
        ...item,
        assignment: item.assignment,
        to: {
          name: 't.member.projects.id',
          params: { assignmentId: item.assignment.id }
        }
      }))
    }
  }
}
</script>
