import axios from 'axios'

const BASE_URL = `/api/invitation`
const URLs = new Proxy(
  {
    EXPAND_TEMPLATE: `/expand-template/`
  },
  { get: (target, prop) => `${BASE_URL}${target[prop]}` }
)

export default {
  async expandTemplate({ assignmentId, inviteeId, stepCode }) {
    const res = await axios.get(
      `${URLs.EXPAND_TEMPLATE}${assignmentId}/${inviteeId}/${stepCode}`
    )
    const { content, isSuccess, error } = res

    return { content, isSuccess, error }
  }
}
