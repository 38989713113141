import axios from 'axios'

import { formatResponse, formatItemResponse } from '@/utils/api'

const BASE_URL = `/api/hub`
const URLs = new Proxy(
  {
    SEARCH_PAGED: '/search-paged',
    FETCH_ONE_BY_ID: `/get-by-id/`,

    UPDATE_RATING: '/update-rating/',
    TOGGLE_SUPPRESSION: '/toggle-suppression/',
    JOIN_ONE: `/join`,
    CREATE_ONE: '/create',
    UPDATE_ONE: '/update',
    CONVERT_TO_MEMBER: '/convert-to-member/',
    DELETE_ONE: '/delete/'
  },
  { get: (target, prop) => `${BASE_URL}${target[prop]}` }
)

export default {
  async searchPaged(payload) {
    const res = await axios.post(URLs.SEARCH_PAGED, payload)

    return formatResponse(res)
  },
  async fetchOneById({ id }) {
    const res = await axios.get(`${URLs.FETCH_ONE_BY_ID}${id}`)

    return formatItemResponse(res)
  },
  async updateRating({ hubId, ratingTierId }) {
    const res = await axios.patch(
      `${URLs.UPDATE_RATING}${hubId}/${ratingTierId}`
    )

    return formatResponse(res)
  },
  async toggleSuppression({ id }) {
    const res = await axios.patch(`${URLs.TOGGLE_SUPPRESSION}${id}`)

    return formatResponse(res)
  },
  async joinOne(payload, options) {
    const res = await axios.post(URLs.JOIN_ONE, payload, options)

    return formatItemResponse(res)
  },
  async createOne(payload) {
    const res = await axios.post(URLs.CREATE_ONE, payload)

    return formatItemResponse(res)
  },
  async updateOne(payload) {
    const res = await axios.put(URLs.UPDATE_ONE, payload)

    return formatItemResponse(res)
  },
  async convertToMember({ id }) {
    const res = await axios.get(`${URLs.CONVERT_TO_MEMBER}${id}`)

    return formatResponse(res)
  },
  async deleteOne({ id }) {
    const res = await axios.delete(`${URLs.DELETE_ONE}${id}`)

    return formatResponse(res)
  }
}
