<template>
  <div class="w-full bg-white border border-gray-300 rounded overflow-hidden">
    <div
      class="py-2 px-4 flex justify-between items-center bg-gray-200 border-b border-gray-300"
    >
      <span class="text-black text-base font-semibold">
        Company-specific settings
      </span>
    </div>

    <v-expansion-panels
      v-model="panel"
      mandatory
      multiple
      flat
      class="p-4 gap-4 flex flex-col"
    >
      <v-expansion-panel v-for="tenant of list" :key="tenant.id">
        <v-expansion-panel-header class="p-0">
          <div class="gap-4 flex">
            <BaseLogo :to="null" :tenant-marker="tenant.marker" :size="48" />

            <div class="flex flex-col">
              <span class="text-base text-black">{{ tenant.name }}</span>
              <span class="text-sm text-gray-500">{{
                tenant.description
              }}</span>
            </div>
          </div>
        </v-expansion-panel-header>

        <v-expansion-panel-content>
          <v-form ref="form" class="w-full pt-4 flex flex-col flex-initial">
            <div class="flex flex-col sm:flex-row sm:gap-4">
              {{ void (localForm = form[tenant.marker]) }}

              <v-text-field
                :value="localForm.minQuantity"
                label="Min Quantity Limit"
                background-color="white"
                outlined
                dense
                type="number"
                :rules="[
                  $rules.numeric,
                  n => $rules.between(n, 0, localForm.maxQuantity)
                ]"
                @input="
                  value =>
                    updateForm({ minQuantity: Number(value) }, tenant.marker)
                "
              />

              <v-text-field
                :value="localForm.maxQuantity"
                label="Max Quantity Limit"
                background-color="white"
                outlined
                dense
                type="number"
                :rules="[
                  $rules.numeric,
                  n => $rules.between(n, localForm.minQuantity, 100000)
                ]"
                @input="
                  value =>
                    updateForm({ maxQuantity: Number(value) }, tenant.marker)
                "
              />
            </div>
          </v-form>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import BaseLogo from '@/components/Base/BaseLogo.vue'

export default {
  name: 'AttributesCategoryCreationDialogTenantSpecificSettings',

  components: { BaseLogo },

  props: {
    category: { type: Object, required: true },
    tenantIdList: { type: Array, required: true }
  },

  data: () => ({
    panel: [],
    form: {}
  }),

  computed: {
    ...mapState('tenants', ['tenants']),

    rules() {
      return this.category.rulesJson
        ? JSON.parse(this.category.rulesJson)
        : null
    },

    list() {
      return this.tenants.list.filter(tenant =>
        this.tenantIdList.includes(tenant.id)
      )
    }
  },

  watch: {
    tenantIdList: {
      immediate: true,
      handler: 'setInitialForm'
    }
  },

  methods: {
    updateForm(toUpdate, tenantMarker) {
      const form = { ...this.form[tenantMarker], ...toUpdate }

      this.$set(this.form, tenantMarker, form)

      const rulesJson = JSON.stringify({
        ...(this.rules || {}),
        tenantRules: this.form
      })

      this.$emit('update:value', rulesJson)
    },

    setInitialForm() {
      this.form = {}

      this.list.forEach(tenant => {
        const { minQuantity = 0, maxQuantity = 5 } =
          this.rules?.tenantRules?.[tenant.marker] || {}

        this.$set(this.form, tenant.marker, { minQuantity, maxQuantity })
      })
    }
  }
}
</script>
