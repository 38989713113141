import dayjs from 'dayjs'
import dayjsDurationPlugin from 'dayjs/plugin/duration'
import localizedFormatPlugin from 'dayjs/plugin/localizedFormat'
import utcPlugin from 'dayjs/plugin/utc'
import relativeTimePlugin from 'dayjs/plugin/relativeTime'

dayjs.extend(dayjsDurationPlugin)
dayjs.extend(localizedFormatPlugin)
dayjs.extend(utcPlugin)
dayjs.extend(relativeTimePlugin)
