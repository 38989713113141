const DEFAULT_LIMIT = 50

const defaultFilters = Object.freeze({
  keyword: '',
  orderBy: 'createdAt',
  isSortAscending: false,
  offset: 0,
  limit: DEFAULT_LIMIT
})

const defineSearchPayloadFromFilters = filters => {
  const { keyword, orderBy, isSortAscending, limit, offset, ...otherFilters } =
    filters

  return {
    keyword,
    orderBy,
    sortOrder: !isSortAscending,
    batchSize: limit,
    pageNumber: Math.max(parseInt(offset / limit) + 1, 1),
    ...otherFilters
  }
}

export default {
  namespaced: true,

  state: () => ({
    table: {
      content: null,
      isLoading: false,

      rows: {
        list: [],
        selected: []
      },

      filters: structuredClone(defaultFilters),
      defaultFilters: structuredClone(defaultFilters)
    }
  }),

  actions: {
    async FETCH_CONTENT({ commit, state }) {
      await commit('SET_FILTERS_OFFSET', 0)

      const filters = state.table.filters

      await Promise.all([
        commit('SET_IS_LOADING', true),
        commit('SET_DEFAULT_FILTERS', filters),
        commit('SET_CONTENT', null),
        commit('SET_ROWS_LIST', []),
        commit('SET_ROWS_SELECTED', [])
      ])

      const payload = defineSearchPayloadFromFilters(filters)

      const { content } = await state.fetchContentHandler(payload)

      await Promise.all([
        commit('SET_CONTENT', { ...content, results: undefined }),
        commit('SET_ROWS_LIST', content?.results),
        commit('SET_IS_LOADING', false)
      ])

      return content
    },

    async FETCH_CONTENT_NEXT({ commit, state }) {
      const { offset, limit } = state.table.filters

      await Promise.all([
        commit('SET_FILTERS_OFFSET', offset + DEFAULT_LIMIT),
        commit('SET_FILTERS_LIMIT', limit)
      ])

      const filters = state.table.filters

      commit('SET_DEFAULT_FILTERS', filters)

      const payload = defineSearchPayloadFromFilters(filters)

      const { content } = await state.fetchContentHandler(payload)

      commit('APPEND_TO_ROWS_LIST', content?.results || [])
    }
  },

  mutations: {
    SET_CONTENT(state, content) {
      state.table.content = content
    },
    SET_IS_LOADING(state, isLoading) {
      state.table.isLoading = isLoading
    },

    SET_ROWS_LIST(state, list) {
      state.table.rows.list = list
    },
    APPEND_TO_ROWS_LIST(state, list) {
      state.table.rows.list.push(...list)
    },
    SET_ROWS_SELECTED(state, selected) {
      state.table.rows.selected = selected
    },

    UPDATE_FILTERS(state, toUpdate) {
      state.table.filters = {
        ...state.table.filters,
        ...toUpdate
      }
    },
    SET_FILTERS_OFFSET(state, offset) {
      state.table.filters.offset = offset
    },
    SET_FILTERS_LIMIT(state, limit) {
      state.table.filters.limit = limit
    },

    SET_FILTERS(state, filters) {
      state.table.filters = filters
    },

    RESET_FILTERS(state) {
      state.table.filters = structuredClone(state.table.defaultFilters)
    },

    SET_DEFAULT_FILTERS(state, defaultFilters) {
      state.table.defaultFilters = structuredClone(defaultFilters)
    }
  }
}
