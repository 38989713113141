<template>
  <v-footer id="app-footer" app absolute class="p-0">
    <AppFooterPrincipal />
  </v-footer>
</template>

<script>
import AppFooterPrincipal from '@/components/App/AppFooter/AppFooterPrincipal.vue'

export default {
  name: 'AppFooter',

  components: { AppFooterPrincipal }
}
</script>
