import ws from '@/utils/web-storage'
import * as services from '@/services'
import * as themeChanger from '@/utils/theme-changer'
import { LS_KEYS, FILE_CATEGORIES } from '@/utils/consts'

const defineAdminTenantIdListSelected = () => {
  const selected = ws.get(LS_KEYS.ADMIN_TENANTS_SELECTED) || null

  return selected?.map(item => item.id) || []
}

export default {
  namespaced: true,

  state: {
    tenants: {
      list: null,
      selected: null,
      isLoading: false
    }
  },

  getters: {
    getTenantByMarker: state => marker =>
      state.tenants.list?.find(item => item.marker === marker) || null,
    getTenantById: state => id =>
      state.tenants.list?.find(item => item.id === id) || null,
    getTenantsByIdList: (_, getters) => idList =>
      idList.map(getters.getTenantById)
  },

  actions: {
    async FETCH_ALL({ commit }) {
      await commit('SET_IS_LOADING', true)

      let { list } = await services.tenants.fetchAll()

      const adminTenantIdList = defineAdminTenantIdListSelected()

      await Promise.all([
        commit('SET_LIST', list),
        commit('SET_IS_LOADING', false),

        commit(
          'admin/tenants/SET_SELECTED',
          list.filter(item => adminTenantIdList.includes(item.id)),
          { root: true }
        )
      ])

      return list
    },

    async DELETE_ONE({ commit, dispatch }, { id }) {
      await commit('SET_IS_LOADING', true)

      const result = await services.tenants.deleteOne({ id })
      const { isSuccess } = result

      if (isSuccess) {
        await dispatch('FETCH_ALL')
      }

      await commit('SET_IS_LOADING', false)

      return result
    }
  },

  mutations: {
    SET_LIST(state, list) {
      state.tenants.list = list?.map(tenant => {
        const files = tenant.tenantFiles

        return {
          ...tenant,
          files: {
            logo: files.find(
              f => f.fileCategoryId === FILE_CATEGORIES.codes.LOGO
            ),
            footerLogo: files.find(
              f => f.fileCategoryId === FILE_CATEGORIES.codes.FOOTER_LOGO
            ),
            favicon: files.find(
              f => f.fileCategoryId === FILE_CATEGORIES.codes.FAVICON
            )
          },
          tenantFiles: undefined,
          colors: themeChanger.defineColorsByTenant(tenant)
        }
      })
    },
    SET_SELECTED(state, selected) {
      state.tenants.selected = selected
      themeChanger.setTheme(selected)
    },
    SET_IS_LOADING(state, isLoading) {
      state.tenants.isLoading = isLoading
    }
  }
}
