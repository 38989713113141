import users from '@/store/modules/admin/users'
import hubs from '@/store/modules/admin/hubs'
import tenants from '@/store/modules/admin/tenants'
import principalToTenant from '@/store/modules/admin/principal-to-tenant'
import memberFinder from '@/store/modules/admin/member-finder'
import jobManager from '@/store/modules/admin/job-manager'
import submissionReviewer from '@/store/modules/admin/submission-reviewer'
import addresses from '@/store/modules/admin/addresses'
import teams from '@/store/modules/admin/teams'

export default {
  namespaced: true,

  modules: {
    users,
    hubs,
    tenants,
    principalToTenant,
    memberFinder,
    jobManager,
    submissionReviewer,
    addresses,
    teams
  }
}
