<template>
  <div
    class="w-full h-full mx-auto mt-2 gap-8 flex flex-col justify-center items-center"
  >
    <div class="gap-4 flex flex-initial items-center">
      <BaseLogo />

      <h1 class="flex flex-col text-2xl font-black">
        Welcome to
        <span class="text-primary">PharmYard</span>
      </h1>
    </div>

    <v-progress-circular
      class="mb-8"
      indeterminate
      size="64"
      color="grey lighten-2"
    />
  </div>
</template>

<script>
import BaseLogo from '@/components/Base/BaseLogo.vue'

export default {
  name: 'AppLoader',

  components: { BaseLogo }
}
</script>

<style lang="scss" scoped></style>
