import axios from 'axios'

const BASE_URL = `/api/principal-to-tenant`
const URLs = new Proxy(
  {
    FETCH_ALL_BY_PRINCIPAL_ID: `/get-all-by-principal-id/`,

    CREATE_ONE: `/create`,
    DELETE_ONE: `/delete-by-principal-and-tenant-id/`
  },
  { get: (target, prop) => `${BASE_URL}${target[prop]}` }
)

export default {
  async fetchAllByPrincipalId({ principalId, userTypeCode }) {
    const res = await axios.get(
      `${URLs.FETCH_ALL_BY_PRINCIPAL_ID}${principalId}/${userTypeCode}`
    )
    const { content: list, isSuccess, error } = res

    return { list, isSuccess, error }
  },

  async createOne(payload) {
    const res = await axios.post(URLs.CREATE_ONE, payload)
    const { content: item, isSuccess, error } = res

    return { item, isSuccess, error }
  },

  async deleteOne({ principalId, userTypeCode, tenantId }) {
    const res = await axios.delete(
      `${URLs.DELETE_ONE}${principalId}/${userTypeCode}/${tenantId}`
    )
    const { content: item, isSuccess, error } = res

    return { item, isSuccess, error }
  }
}
