<template>
  <div class="BasePaginatedTableContentList flex">
    <div v-if="isEmpty" class="px-4 py-2">
      <span>(The list is empty.)</span>
    </div>

    <v-virtual-scroll
      v-else
      class="overflow-auto"
      :bench="15"
      :items="normalizedList"
      :min-height="LIST_ITEM_HEIGHT * 3"
      :max-height="LIST_ITEM_HEIGHT * 10"
      :item-height="LIST_ITEM_HEIGHT"
    >
      <template #default="{ item, index }">
        <v-card
          v-if="item.isInfiniteScroll"
          v-intersect="handleIntersect"
          :style="{ height: `${LIST_ITEM_HEIGHT}px` }"
          class="transparent"
          flat
        >
          <v-card-text class="text-center">
            <v-progress-circular indeterminate color="primary" class="bottom" />
          </v-card-text>
        </v-card>

        <BasePaginatedTableContentListItem
          v-else
          :style="{ height: `${LIST_ITEM_HEIGHT}px` }"
          :item="item"
          :index="index"
          :table="table"
          :store-path="storePath"
        >
          <slot name="list-item-content" :item="item" :index="index" />
        </BasePaginatedTableContentListItem>
      </template>
    </v-virtual-scroll>
  </div>
</template>

<script>
import BasePaginatedTableContentListItem from '@/components/Base/BasePaginatedTable/BasePaginatedTableContentListItem.vue'

const LIST_ITEM_HEIGHT = 48

export default {
  name: 'BasePaginatedTableContentList',

  components: {
    BasePaginatedTableContentListItem
  },

  props: {
    table: { type: Object, required: true },
    storePath: { type: String, required: true }
  },

  data: () => ({
    isIntersectionLoading: false
  }),

  computed: {
    normalizedList() {
      const isInfiniteScroll = this.isVisibleLoadMore

      return [
        ...this.table.rows.list,
        isInfiniteScroll && { isInfiniteScroll: true }
      ].filter(Boolean)
    },

    isVisibleLoadMore() {
      return this.table.rows.list.length < this.table.content?.totalCount
    },

    isList() {
      return Boolean(this.table.rows.list.length)
    },

    isEmpty() {
      return !this.table.isLoading && !this.isList
    }
  },

  created() {
    this.LIST_ITEM_HEIGHT = LIST_ITEM_HEIGHT
  },

  methods: {
    async handleIntersect(...[, , isIntersecting]) {
      if (!isIntersecting) return
      if (this.isIntersectionLoading) return

      this.isIntersectionLoading = true

      await this.fetchContentNext()

      this.isIntersectionLoading = false
    },

    fetchContentNext(payload) {
      return this.dispatch('FETCH_CONTENT_NEXT', payload)
    },

    dispatch(name, payload) {
      return this.$store.dispatch(`${this.storePath}/${name}`, payload, {
        root: true
      })
    }
  }
}
</script>

<style lang="scss">
.BasePaginatedTableContentList {
  .v-virtual-scroll__item {
    @apply right-[auto] lg:right-0;
  }
}
</style>
