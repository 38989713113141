import axios from 'axios'

import { formatListResponse } from '@/utils/api'

const BASE_URL = `/api/item`
const URLs = new Proxy(
  {
    FETCH_ALL: `/get-list`,
    FETCH_ONE_BY_ID: `/get/`,

    UPDATE_ONE: `/update`,

    FETCH_ALL_BG_IMAGES_BY_ID: `/get-background-images/`,
    UPDATE_ONE_BG_IMAGE: `/change-background-image/`,
    DELETE_ONE_BG_IMAGE: `/remove-background-image`
  },
  { get: (target, prop) => `${BASE_URL}${target[prop]}` }
)

export default {
  async fetchAll() {
    const res = await axios.get(URLs.FETCH_ALL)

    return formatListResponse(res)
  },

  async fetchOneById({ id }) {
    const res = await axios.get(`${URLs.FETCH_ONE_BY_ID}${id}`)
    const { content: item, isSuccess, error } = res

    return { item, isSuccess, error }
  },

  async updateOne(payload) {
    const res = await axios.put(`${URLs.UPDATE_ONE}`, payload)
    const { content: item, isSuccess, error } = res

    return { item, isSuccess, error }
  },

  async fetchAllBgImagesById(id) {
    const res = await axios.get(`${URLs.FETCH_ALL_BG_IMAGES_BY_ID}${id}`)
    const { content, isSuccess, error } = res

    return { content, isSuccess, error }
  },
  async updateOneBgImage(payload) {
    const res = await axios.post(`${URLs.UPDATE_ONE_BG_IMAGE}`, payload)
    const { content: item, isSuccess, error } = res

    return { item, isSuccess, error }
  },
  async deleteOneBgImage(payload) {
    const res = await axios.put(`${URLs.DELETE_ONE_BG_IMAGE}`, payload)
    const { isSuccess, error } = res

    return { isSuccess, error }
  }
}
