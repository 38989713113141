import store from '@/store'
import { defineChildren, setDepth, setDepthInverted } from '@/utils/tree'

const NO_RULES = Object.freeze({
  allowedCategoryIdList: [],

  requiredCategoryList: [],
  requiredBestCategoryList: []
})

const aToPa = a => ({ attribute: a })
const paToA = pa => pa.attribute

// Accepts list of attributes and categories
const defineRuledList = ({
  isForAdmin,
  principal,
  isForIndividual,
  rules,
  list
}) => {
  const isMember = store.getters['user/defineIsMember'](principal)
  isForIndividual = principal ? isMember : isForIndividual

  if (isForIndividual) {
    list = list.filter(item => !item.onlyForHubs)
  }

  if (!isForAdmin) {
    list = list.filter(item => !item.forInternalUse)
  }

  if (rules.allowedCategoryIdList?.length) {
    list = list.filter(item => {
      const categoryId = item.attributeCategoryId || item.id

      return rules.allowedCategoryIdList.includes(categoryId)
    })
  }

  return list
}

const defineTreeList = list => {
  const defineBranch = item => {
    const children = list.filter(i => i._uParentId === item._uId) || []
    const isChildren = Boolean(children.length)

    item = isChildren ? { ...item, children: children.map(defineBranch) } : item

    const allChildren = defineChildren(item)
    const allChildrenNames = isChildren ? allChildren.map(i => i.name) : []

    item.searchValue = [item.name, ...allChildrenNames].join(' ')

    return item
  }

  let treeList = list.reduce((result, item) => {
    const isRoot = item._uId.includes('category')

    if (!isRoot) return result

    const branch = defineBranch(item)

    return [...result, branch]
  }, [])

  setDepth(treeList)
  setDepthInverted(treeList)

  treeList.sort((a, b) => a.searchValue.localeCompare(b.searchValue))

  return treeList
}

// Category rulesJson
const DEFAULT_MAX_QUANTITY = 5

const defineCategoryRules = category => {
  return category.rulesJson ? JSON.parse(category.rulesJson) : {}
}

const defineCategoryRulesByPrincipal = ({ category, principal }) => {
  const rules = defineCategoryRules(category)
  let { tenantRules = {} } = rules

  tenantRules = principal.tenants.reduce((result, { marker }) => {
    if (tenantRules[marker]) result[marker] = tenantRules[marker]
    return result
  }, {})

  return { ...rules, tenantRules }
}

const defineIsCategoryQuantityLimit = ({ category, principal }) => {
  const rules = principal
    ? defineCategoryRulesByPrincipal({ category, principal })
    : defineCategoryRules(category)

  const isQuantityLimit = Object.values(rules.tenantRules)?.some(
    rule => rule.minQuantity !== 0 || rule.maxQuantity !== DEFAULT_MAX_QUANTITY
  )

  return isQuantityLimit
}

const defineCategoryMergedQuantityLimit = ({ category, principal }) => {
  const rules = principal
    ? defineCategoryRulesByPrincipal({ category, principal })
    : defineCategoryRules(category)

  const minQuantity = Object.values(rules.tenantRules).reduce(
    (result, rule) => Math.max(result, rule.minQuantity || 0),
    0
  )
  const maxQuantity = Object.values(rules.tenantRules).reduce(
    (result, rule) => Math.max(result, rule.maxQuantity),
    DEFAULT_MAX_QUANTITY
  )

  return { minQuantity, maxQuantity }
}

export {
  NO_RULES,
  aToPa,
  paToA,
  defineRuledList,
  defineTreeList,
  defineChildren
}

export {
  DEFAULT_MAX_QUANTITY,
  defineCategoryRules,
  defineCategoryRulesByPrincipal,
  defineIsCategoryQuantityLimit,
  defineCategoryMergedQuantityLimit
}
