import axios from 'axios'

import { formatListResponse } from '@/utils/api'

const BASE_URL = `/api/assessment-definition-actor`
const URLs = new Proxy(
  {
    FETCH_ALL_BY_ASSESSMENT_DEFINITION_ID: '/get-by-assessment-definition-id/',

    CREATE: '/create',
    DELETE_ONE: '/delete/'
  },
  { get: (target, prop) => `${BASE_URL}${target[prop]}` }
)

export default {
  async fetchAllByDefinitionId({ id }) {
    const res = await axios.get(
      `${URLs.FETCH_ALL_BY_ASSESSMENT_DEFINITION_ID}${id}`
    )

    return formatListResponse(res)
  },

  async create(payload) {
    const res = await axios.post(`${URLs.CREATE}`, payload)

    const { content: item, isSuccess, error } = res

    return { item, isSuccess, error }
  },

  async deleteOne(actorId) {
    const res = await axios.delete(`${URLs.DELETE_ONE}${actorId}`)

    const { isSuccess, error } = res

    return { isSuccess, error }
  }
}
