import Vue from 'vue'

import vuetify from '@/plugins/vuetify'

import UtilsNotification from '@/components/Utils/UtilsNotification.vue'

const NotificationConstructor = Vue.extend(UtilsNotification)

const BASE_OFFSET = 16

let instances = []

class Notification {
  constructor(propsData) {
    this.userOnClose = propsData.onClose
    this.instance = new NotificationConstructor({ vuetify, propsData })

    this.mount()

    return this.instance
  }

  mount() {
    this.instance.onClose = id => this.close(id, this.userOnClose)
    this.instance.verticalOffset = this.defineVerticalOffset()

    this.instance.$mount()

    instances.push(this.instance)

    document.querySelector('#app').appendChild(this.instance.$el)
  }

  defineVerticalOffset() {
    const header = document.querySelector('#app-header')
    let verticalOffset = (header?.clientHeight || 0) + BASE_OFFSET

    instances.forEach(instance => {
      verticalOffset += instance.$el.offsetHeight + BASE_OFFSET
    })

    return verticalOffset
  }

  close(id, userOnClose) {
    const removedInstance = instances.filter(
      instance => instance.uniqId === id
    )[0]

    if (!removedInstance) return

    if (userOnClose && typeof userOnClose === 'function') {
      userOnClose()
    }

    const removedInstanceIndex = instances.findIndex(
      instance => instance.uniqId === id
    )

    const instancesToUpdate = instances.slice(removedInstanceIndex + 1)

    instances = instances.filter(instance => instance.uniqId !== id)

    const removedHeight = removedInstance.$el.offsetHeight

    instancesToUpdate.forEach(
      instance => (instance.verticalOffset -= removedHeight + BASE_OFFSET)
    )
  }
}

export default Notification
