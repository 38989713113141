<template>
  <v-list class="!bg-transparent" two-line>
    <v-list-item v-if="isLoading" :style="`height: ${itemHeight}px`">
      <slot v-if="$scopedSlots['action-skeleton']" name="action-skeleton" />

      <v-list-item-avatar class="mr-4">
        <v-skeleton-loader type="avatar" />
      </v-list-item-avatar>

      <v-list-item-content>
        <v-skeleton-loader type="sentences" />
      </v-list-item-content>
    </v-list-item>

    <template v-else>
      <v-list-item v-for="tenant of list" :key="tenant.id">
        <label class="flex items-center">
          <slot v-if="$scopedSlots.action" name="action" :tenant="tenant" />

          <BaseLogo
            class="mr-4"
            :to="null"
            :tenant-marker="tenant.marker"
            :size="48"
          />

          <v-list-item-content>
            <v-list-item-title>{{ tenant.name }}</v-list-item-title>
            <v-list-item-subtitle>{{
              tenant.description
            }}</v-list-item-subtitle>
          </v-list-item-content>
        </label>
      </v-list-item>
    </template>
  </v-list>
</template>

<script>
import BaseLogo from '@/components/Base/BaseLogo.vue'

export default {
  name: 'AdminBaseTenants',

  components: { BaseLogo },

  props: {
    list: { type: Array, default: () => [] },

    itemHeight: { type: Number, default: 72 },

    isLoading: { type: Boolean, default: false }
  }
}
</script>
