import SimpleCrypto from 'simple-crypto-js'

const simpleCrypto = new SimpleCrypto(
  import.meta.env.VITE_VUE_APP_SSW_SECRET_KEY
)

const generateStorageWrapper = storageName => {
  const storage = window[storageName]

  const remove = key => storage.removeItem(key)

  const get = key => {
    const objString = storage.getItem(key)

    if (!objString) return

    const { value, expiresAt } = simpleCrypto.decrypt(objString) || {}

    if (expiresAt && Date.now() > expiresAt) {
      remove(key)

      return
    }

    return value
  }

  const set = (key, value, options) => {
    const win = options?.window || window

    let payload = {
      value,
      expiresAt: options?.expiresAt || null
    }

    const objString = JSON.stringify(payload)
    const cipherObjString = simpleCrypto.encrypt(objString)

    win[storageName].setItem(key, cipherObjString)
  }

  const clear = options => {
    const win = options?.window || window

    win[storageName].clear()
  }

  return { get, set, remove, clear }
}

const lsw = generateStorageWrapper('localStorage')
const ssw = generateStorageWrapper('sessionStorage')

const remove = key => {
  lsw.remove(key)
}

const get = key => {
  try {
    return key in sessionStorage ? ssw.get(key) : lsw.get(key)
  } catch (e) {
    console.error(e)
    clear()
  }
}

const set = (key, value, options) => {
  lsw.set(key, value, options)
}

const clear = options => {
  lsw.clear(options)
  ssw.clear(options)
}

export default { get, set, remove, clear, lsw, ssw }
