<template>
  <div>
    <v-overlay :value="isMenuVisible" />

    <v-menu
      v-model="isMenuVisible"
      :close-on-content-click="false"
      top
      offset-y
      nudge-top="16"
      min-width="320"
    >
      <template #activator="{ on, attrs }">
        <v-btn fixed fab bottom right color="primary" v-bind="attrs" v-on="on">
          <v-icon>mdi-filter-plus</v-icon>
        </v-btn>
      </template>

      <template #default>
        <v-card>
          <div class="p-2">
            <AdminBaseTenantsSelectableList
              v-model="form.selected"
              label="Companies in view:"
              is-multiple
              is-hide-details
              :list="user.tenants"
            />
          </div>

          <div v-if="!isSelectedSome" class="p-2 pt-0">
            <v-alert class="m-0" type="error" text>
              <span>At least one company must be selected</span>
            </v-alert>
          </div>

          <div class="px-2 pb-2">
            <v-btn
              class="w-full"
              color="primary"
              :disabled="!isModified || !isSelectedSome"
              @click="handleApply"
            >
              Apply
            </v-btn>
          </div>
        </v-card>
      </template>
    </v-menu>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'

import { defineIsEqual } from '@/utils/base'

import AdminBaseTenantsSelectableList from '@/components/Admin/Base/BaseTenantsSelectableList.vue'

export default {
  name: 'AdminTenantFilter',

  components: { AdminBaseTenantsSelectableList },

  data: () => ({
    isMenuVisible: false,

    form: {
      selected: []
    }
  }),

  computed: {
    ...mapState('admin/tenants', ['tenants']),
    ...mapState('user', ['user']),
    ...mapGetters('tenants', ['getTenantsByIdList']),

    isModified() {
      return !defineIsEqual(
        [...this.form.selected].sort(),
        [...this.defaultTenantsSelected].sort()
      )
    },

    isSelectedSome() {
      return this.form.selected?.length > 0
    }
  },

  watch: {
    isMenuVisible: {
      immediate: true,
      handler() {
        if (!this.isMenuVisible) return

        this.setInitialForm()
      }
    }
  },

  methods: {
    ...mapMutations('admin/tenants', {
      setSelected: 'SET_SELECTED'
    }),

    handleApply() {
      const selected = this.getTenantsByIdList(this.form.selected)

      this.setSelected(selected)
      location.reload()
    },

    setInitialForm() {
      const idList = this.tenants.selected.map(item => item.id)

      this.form.selected = structuredClone(idList)
      this.defaultTenantsSelected = structuredClone(idList)
    }
  }
}
</script>
