import * as signalR from '@microsoft/signalr'
import { HubConnectionState } from '@microsoft/signalr'

const HUB_URL = `${import.meta.env.VITE_VUE_APP_API_URL}/hubs/wire`
const CONNECTIONS = Object.freeze({
  UPDATE_NOTIFICATIONS: {
    serverEventName: 'receiveNotifications',
    clientEventName: 'signalr-notifications-received'
  },
  UPDATE_ASSESSMENT_TASKS: {
    serverEventName: 'receiveAssessmentNotifications',
    clientEventName: 'signalr-update-assessment-tasks'
  },
  RECOMMENDATION_UPDATES: {
    serverEventName: 'receiveMlRecommendationUpdates',
    clientEventName: 'signalr-ml-recommendation-updates-received'
  },
  AI_USER_SEARCH_UPDATES: {
    serverEventName: 'receiveAiUserSearchUpdates',
    clientEventName: 'signalr-ai-user-search-updates-received'
  }
})

let signalRConnection = null

const defineIsReady = () => {
  return (
    Boolean(signalRConnection) &&
    signalRConnection.state === HubConnectionState.Connected
  )
}

const createEvent = (
  signalRConnection,
  { serverEventName, clientEventName }
) => {
  signalRConnection.on(serverEventName, (...args) => {
    const payload = { detail: { args } }
    const event = new CustomEvent(clientEventName, payload)

    dispatchEvent(event)
  })
}

function createSignalRConnection({ user }) {
  const transport =
    signalR.HttpTransportType.WebSockets | signalR.HttpTransportType.LongPolling

  const options = {
    skipNegotiation: false,
    logMessageContent: true,
    transport,
    accessTokenFactory() {
      if (user) return user.token

      console.info('SignalR access token factory failure: no user')

      return null
    }
  }

  signalRConnection = new signalR.HubConnectionBuilder()
    .configureLogging(signalR.LogLevel.Warning)
    .withUrl(HUB_URL, options)
    .configureLogging(signalR.LogLevel.Warning)
    .withAutomaticReconnect()
    .build()

  if (!signalRConnection) return

  createEvent(signalRConnection, CONNECTIONS.UPDATE_NOTIFICATIONS)
  createEvent(signalRConnection, CONNECTIONS.UPDATE_ASSESSMENT_TASKS)
  createEvent(signalRConnection, CONNECTIONS.RECOMMENDATION_UPDATES)
  createEvent(signalRConnection, CONNECTIONS.AI_USER_SEARCH_UPDATES)

  signalRConnection.onclose(() => console.info('SignalR: Closed.'))
}

async function start({ user } = {}) {
  if (signalRConnection) {
    console.info('SignalR: Connection is already started')
    return
  }

  try {
    createSignalRConnection({ user })

    if (signalRConnection.state === 'Disconnected') {
      await signalRConnection.start()
      console.info('SignalR: Started.')
    } else {
      console.info(
        'SignalR: Connection is not in Disconnected state. Will not try to start.'
      )
    }
  } catch (err) {
    console.info('SignalR: Exception on start. Restarting in 30s...', err)
    setTimeout(start, 30000)
  }
}

const callServerMethod = ({ name, payload }) => {
  const isReady = defineIsReady()

  if (!isReady) return

  try {
    if (signalRConnection === null) {
      const errorMessage =
        "SignalR: Can't call a server method without a connection."
      console.info(errorMessage)
      return Promise.reject(new Error(errorMessage))
    }

    return signalRConnection.invoke(name, payload)
  } catch (err) {
    console.info('SignalR: Exception calling server method.', err)
  }
}

async function stop() {
  if (signalRConnection === null) {
    console.info("SignalR: Nothing to stop -- connection doesn't exist.")
    return
  }

  try {
    await signalRConnection.stop()
    console.info('SignalR: Stopped.')
  } catch (err) {
    console.info('SignalR: Exception on stop.', err)
  }
}

const wireService = { start, stop, callServerMethod }

export { CONNECTIONS, wireService }
