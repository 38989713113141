import $api from '@/api'
import * as notify from '@/utils/notify'

export const fetchUserList = async payload => {
  let result = {}

  try {
    result = await $api.assessment.definitions.fetchUserList(payload)
    const { error } = result

    if (error) {
      notify.error({ text: error })
    }
  } catch (error) {
    notify.error({ text: error })
  }

  return result
}
