import axios from 'axios'

import { formatListResponse } from '@/utils/api'

const BASE_URL = `/api/principal-files`
const URLs = new Proxy(
  {
    FETCH_ALL: '/get-all-by-principal/',
    FETCH_ALL_BY_CATEGORY: '/get-by-principal-and-category/',

    ADD_ONE: '/add-for-principal',
    ADD_ONE_INVOICE: '/upload-member-invoice',
    UPDATE_ONE_WITH_FILE: '/update-one',
    // UPDATE_ONE_WITHOUT_FILE: '/update-for-principal',
    DELETE_ONE: '/delete-for-principal/',

    FETCH_ALL_CATEGORIES: '/get-all-categories',
    URI_EXISTS: '/uri-exists',

    DOWNLOAD_FILE: '/download-file'
  },
  { get: (target, prop) => `${BASE_URL}${target[prop]}` }
)

export default {
  async fetchAllByPrincipal({ principalId, userTypeCode }) {
    const res = await axios.get(
      `${URLs.FETCH_ALL}${principalId}/${userTypeCode}`
    )

    return formatListResponse(res)
  },

  async fetchAllByPrincipalAndCategory({
    principalId,
    userTypeCode,
    categoryId
  }) {
    const res = await axios.get(
      `${URLs.FETCH_ALL}${principalId}/${userTypeCode}/${categoryId}`
    )

    return formatListResponse(res)
  },

  async addOneInvoice(payload) {
    const res = await axios.post(URLs.ADD_ONE_INVOICE, payload)

    const { isSuccess, error } = res

    return { isSuccess, error }
  },

  async addOne(payload) {
    const res = await axios.post(URLs.ADD_ONE, payload)

    const { isSuccess, error } = res

    return { isSuccess, error }
  },

  async updateOneWithFile(payload) {
    const res = await axios.put(URLs.UPDATE_ONE_WITH_FILE, payload)

    const { isSuccess, error } = res

    return { isSuccess, error }
  },

  // async updateOneWithoutFile(payload) {
  //   const res = await axios.put(URLs.UPDATE_ONE_WITHOUT_FILE, payload)

  //   const { isSuccess, error } = res

  //   return { isSuccess, error }
  // },

  async deleteOne({ principalId, userTypeCode, id }) {
    const res = await axios.delete(
      `${URLs.DELETE_ONE}${principalId}/${userTypeCode}/${id}`
    )

    const { isSuccess, error } = res

    return { isSuccess, error }
  },

  async fetchAllCategories() {
    const res = await axios.get(URLs.FETCH_ALL_CATEGORIES)

    return formatListResponse(res)
  },

  async uriExists(payload) {
    const res = await axios.post(URLs.URI_EXISTS, payload)

    const { content, isSuccess, error } = res

    return { content, isSuccess, error }
  },

  async downloadFile(payload) {
    const res = await axios.post(URLs.DOWNLOAD_FILE, payload, {
      responseType: 'blob'
    })

    return res
  }
}
