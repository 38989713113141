import Vue from 'vue'

import App from '@/App.vue'
import router from '@/router'
import store from '@/store'

import '@/directives'
import '@/interceptors'

import * as rules from '@/utils/rules'

import { vuetify } from '@/plugins'

import globalMixin from '@/mixins/global'

// Make sure these styles are imported after the Vuetify styles
import '@/assets/styles/index.scss'

Vue.prototype.$rules = rules

Vue.mixin(globalMixin)

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
