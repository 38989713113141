import axios from 'axios'

import { formatListResponse } from '@/utils/api'

const BASE_URL = `/api/submission-file`
const URLs = new Proxy(
  {
    FETCH_FEEDBACK: '/get-feedback/',
    GET_BY_ASSIGNMENT_AND_ACCEPTANCE_STATUS: '/get-by-assignment-and-acceptance-status/'
  },
  { get: (target, prop) => `${BASE_URL}${target[prop]}` }
)

export default {
  async fetchFeedback({ fileId }) {
    const res = await axios.get(`${URLs.FETCH_FEEDBACK}${fileId}`)

    return formatListResponse(res)
  },
  async getByAssignmentAndAcceptanceStatus({ assignmentId, statusName }) {
    const res =
      await axios.get(`${URLs.GET_BY_ASSIGNMENT_AND_ACCEPTANCE_STATUS}${assignmentId}/${statusName}`)

    return formatListResponse(res)
  }
}
