import axios from 'axios'

import { formatListResponse } from '@/utils/api'

const URLs = Object.freeze({
  FETCH_ALL_BY_USER_ID: '/api/user-rate/get-by-user/',
  // FETCH_ONE: '/api/user-rate/get/',
  CREATE_ONE: '/api/user-rate/create',
  UPDATE_ONE: '/api/user-rate/update',
  DELETE_ONE: '/api/user-rate/delete/'
})

export default {
  async fetchAllByUserId(userId) {
    const res = await axios.get(`${URLs.FETCH_ALL_BY_USER_ID}${userId}`)

    return formatListResponse(res)
  },

  async createOne(payload) {
    const res = await axios.post(URLs.CREATE_ONE, payload)
    const { content: item, isSuccess, error } = res

    return { item, isSuccess, error }
  },

  async updateOne(payload) {
    const res = await axios.put(URLs.UPDATE_ONE, payload)
    const { content: item, isSuccess, error } = res

    return { item, isSuccess, error }
  },

  async deleteOne(id) {
    const res = await axios.delete(`${URLs.DELETE_ONE}${id}`)
    const { isSuccess, error } = res

    return { isSuccess, error }
  }
}
