<template>
  <div
    class="gap-8 flex flex-col flex-initial px-4 py-2 bg-gray-200 rounded-none border-b border-gray-300"
  >
    <div class="gap-4 flex items-center justify-between">
      <div class="flex flex-col text-black">
        <template v-if="isEditable">
          <span v-if="headerTitle" class="text-lg font-semibold">{{
            headerTitle
          }}</span>
          <span v-if="headerSubtitle" class="text-sm">{{
            headerSubtitle
          }}</span>
        </template>

        <template v-else>
          <span v-if="headerTitle" class="text-base font-semibold">{{
            headerTitle
          }}</span>
          <span v-if="headerSubtitle">{{ headerSubtitle }}</span>
        </template>
      </div>

      <div
        class="w-full flex justify-end items-center"
        style="max-width: 20rem"
      >
        <v-text-field
          v-model="filters.search"
          label="Search"
          background-color="white"
          clearable
          outlined
          dense
          hide-details
        />

        <div v-if="isEditable" class="ml-4">
          <BaseMenu
            :is-visible="menu.isVisible"
            header-title="Categories"
            :buttons="menuButtons"
            @close="handleCloseMenu"
          />
        </div>
      </div>
    </div>

    <div v-if="isForAdmin" class="w-full pb-2 gap-8 flex flex-col sm:flex-row">
      <div class="w-full flex flex-col">
        <span class="text-lg font-semibold">Category filters</span>
        <v-radio-group v-model="filters.categoryIndex" class="m-0" hide-details>
          <v-radio
            v-for="(item, index) of categoriesRadioButtons"
            :key="`category-${index}`"
            :value="index"
          >
            <template #label>
              <div class="gap-1 flex">
                <span>{{ item.label }}</span>
                <v-icon v-if="item.icon" small>{{ item.icon }}</v-icon>
              </div>
            </template>
          </v-radio>
        </v-radio-group>
      </div>

      <div class="w-full flex flex-col">
        <span class="text-lg font-semibold">Attribute filters</span>
        <v-radio-group
          v-model="filters.attributeIndex"
          class="m-0"
          hide-details
        >
          <v-radio
            v-for="(item, index) of attributesRadioButtons"
            :key="`attribute-${index}`"
            :value="index"
          >
            <template #label>
              <div class="gap-1 flex">
                <span>{{ item.label }}</span>
                <v-icon v-if="item.icon" small>{{ item.icon }}</v-icon>
              </div>
            </template>
          </v-radio>
        </v-radio-group>
      </div>
    </div>

    <div v-if="isActions" class="gap-2 flex flex-col">
      <span class="text-lg font-semibold">Actions</span>

      {{
        void (isExportDisabled = !selected.length && !selectedCategories.length)
      }}

      <div class="gap-4 flex flex-wrap">
        <v-btn
          color="primary"
          small
          :loading="isLoadingExport"
          :disabled="isExportDisabled"
          @click="handleExport"
        >
          Export
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import FileSaver from 'file-saver'
import { mapState } from 'vuex'

import * as services from '@/services'
import { defineFileNameByResponse } from '@/utils/base'
import { defineIsCategoryQuantityLimit } from '@/utils/attributes'

import BaseMenu from '@/components/Base/BaseMenu.vue'

const defaultFilters = Object.freeze({
  search: '',
  categoryIndex: 0,
  attributeIndex: 0
})

export default {
  name: 'AttributesHeader',

  components: { BaseMenu },

  props: {
    headerTitle: { type: String, default: '' },
    headerSubtitle: { type: String, default: '' },

    flatTreeList: { type: Array, default: () => [] },
    filteredFlatTreeList: { type: Array, default: () => [] },

    selected: { type: Array, default: () => [] },
    selectedCategories: { type: Array, default: () => [] },

    isEditable: { type: Boolean, default: false },
    isForAdmin: { type: Boolean, default: false }
  },

  data: () => ({
    menu: {
      isVisible: false
    },

    isLoadingExport: false,

    filters: structuredClone(defaultFilters)
  }),

  computed: {
    ...mapState('user', ['user']),

    isActions() {
      return this.$route.name === 'admin.attributes'
    },

    menuButtons() {
      return [
        {
          label: 'Add Category',
          color: 'primary',
          icon: 'mdi-plus',
          handler: this.handleCreate
        }
      ]
    },

    categoriesRadioButtons() {
      return [
        { label: 'Any', handler: () => true },
        {
          label: 'Is Internal Only',
          icon: 'mdi-lock-outline',
          handler: item => item.forInternalUse
        },
        {
          label: 'Has Quantity Limit',
          icon: 'mdi-counter',
          handler: item => {
            const isQuantityLimit = defineIsCategoryQuantityLimit({
              category: item,
              principal: this.user
            })

            return isQuantityLimit
          }
        },
        {
          label: 'Allows User Additions',
          icon: 'mdi-account-edit-outline',
          handler: item => item.allowsUserAdditions
        },
        {
          label: 'Shares User Additions',
          icon: 'mdi-account-multiple-outline',
          handler: item => item.areUserAdditionsShared
        },
        {
          label: 'Allows Ad-Hoc Creation',
          icon: 'mdi-tag-plus-outline',
          handler: item => item.allowsAdHocCreation
        }
      ]
    },

    attributesRadioButtons() {
      return [
        { label: 'Any', handler: () => true },
        {
          label: 'Is User-Specific',
          icon: 'mdi-account-edit-outline',
          handler: item =>
            item.children
              ? item.children?.some(attr => attr.isUserSpecific)
              : item.isUserSpecific
        },
        {
          label: 'Is Only for Hubs',
          icon: 'mdi-tire',
          handler: item =>
            item.children
              ? item.children?.some(attr => attr.onlyForHubs)
              : item.onlyForHubs
        }
      ]
    }
  },

  watch: {
    flatTreeList: {
      handler: 'defineFilteredTreeList'
    },
    filters: {
      deep: true,
      handler: 'defineFilteredTreeList'
    }
  },

  methods: {
    handleCloseMenu() {
      this.menu.isVisible = false
    },

    handleCreate() {
      this.$emit('create')

      this.handleClose()
    },

    async handleExport() {
      this.isLoadingExport = true

      const response = await services.attributes.exportMultiple({
        attributeCategoryIds: this.selectedCategories.map(c => c.id),
        attributeIds: this.selected.map(a => a.id)
      })

      const fileName = defineFileNameByResponse(response)
      const url = URL.createObjectURL(new Blob([response.data]))

      FileSaver.saveAs(url, fileName)

      this.$emit('exported')

      this.isLoadingExport = false
    },

    handleClose() {
      this.menu.isVisible = false
    },

    defineFilteredTreeList() {
      if (!this.flatTreeList.length) {
        this.$emit('update:filtered-flat-tree-list', [])
      }

      const filteredFlatTreeList = this.flatTreeList.filter(item => {
        const search = this.filters.search

        if (search) {
          const isIncludes = item.searchValue
            .toLowerCase()
            .includes(search.toLowerCase().trim())

          if (!isIncludes) return false
        }

        const isCategory = item._uId.includes('category')
        const { categoryIndex, attributeIndex } = this.filters

        if (isCategory) {
          const radioButton = this.categoriesRadioButtons[categoryIndex]
          if (!radioButton.handler(item)) return false
        }

        const radioButton = this.attributesRadioButtons[attributeIndex]
        if (!radioButton.handler(item)) return false

        return true
      })

      this.$emit('update:filtered-flat-tree-list', filteredFlatTreeList)
    },

    handleResetFilters() {
      this.filters = structuredClone(defaultFilters)
    },

    defineRules(category) {
      return category.rulesJson ? JSON.parse(category.rulesJson) : {}
    }
  }
}
</script>
