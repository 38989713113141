<template>
  <BaseDialog
    ref="base-dialog"
    :max-width="512"
    :header-title="headerTitle"
    :is-loading="isLoading"
    :footer-buttons="footerButtons"
    @close="$emit('close')"
  >
    <div class="w-full flex flex-col">
      <v-form ref="form">
        <v-text-field
          v-model="form.name"
          label="Profession name"
          maxlength="255"
          background-color="white"
          outlined
          dense
          :rules="[$rules.required]"
        />
      </v-form>
    </div>
  </BaseDialog>
</template>

<script>
import * as services from '@/services'
import * as notify from '@/utils/notify'
import { defineIsEqual } from '@/utils/base'

import BaseDialog from '@/components/Base/BaseDialog/BaseDialog.vue'

const defaultEmptyForm = Object.freeze({
  name: ''
})

export default {
  name: 'AdminProfessionsCreationDialog',

  components: { BaseDialog },

  props: {
    branch: { type: Object, default: null },
    isEditing: { type: Boolean, default: false }
  },

  data: () => ({
    form: { ...defaultEmptyForm },
    isLoading: false
  }),

  computed: {
    isCreating() {
      return !this.isEditing
    },

    isModifiedForm() {
      return !defineIsEqual(this.defaultForm, this.form)
    },

    headerTitle() {
      return [
        this.isCreating ? 'Create' : 'Update',
        this.isSubProfession ? 'sub-profession' : 'profession'
      ].join(' ')
    },

    isSubProfession() {
      if (this.isCreating) return Boolean(this.branch)
      else return Boolean(this.branch.parentId)
    },

    footerButtons() {
      return [
        {
          text: 'Close',
          attrs: { disabled: this.isLoading, color: 'grey', text: true },
          handler: this.handleClose
        },
        {
          text: this.isCreating ? 'Create' : 'Save',
          attrs: {
            color: 'primary',
            loading: this.isLoading
          },
          handler: this.handleSubmit
        }
      ]
    }
  },

  created() {
    this.setInitialForm()
  },

  methods: {
    setInitialForm() {
      this.form = this.isEditing
        ? {
            name: this.branch.name
          }
        : structuredClone(defaultEmptyForm)
      this.defaultForm = structuredClone(this.form)
    },

    handleSubmit() {
      const isValid = this.defineIsValid()

      if (!isValid) return

      this.makeRequest()
    },

    handleSuccess() {
      this.$emit('success')
      this.handleClose()
    },

    async makeRequest() {
      const payload = this.definePayload()

      const requestsMethod = this.defineRequestMethod()

      this.setIsLoading(true)

      try {
        const { error } = await requestsMethod(payload)

        if (error) {
          notify.error({ text: error })
          return
        }

        this.handleSuccess()
      } catch (error) {
        notify.error({ text: error })
      } finally {
        this.setIsLoading(false)
      }
    },

    defineRequestMethod() {
      return this.isCreating
        ? services.professions.createOne
        : services.professions.updateOne
    },

    definePayload() {
      const payload = {
        id: this.isEditing ? this.branch.id : undefined,
        isUserSpecific: false,
        name: this.form.name
      }

      if (this.isCreating) {
        payload.parentId = this.isSubProfession ? this.branch.id : null
      } else {
        payload.parentId = this.branch.parentId
      }

      // delete payload.$type
      delete payload.children

      return payload
    },

    defineIsValid() {
      const isValid = this.$refs.form.validate()

      return isValid
    },

    setIsLoading(isLoading) {
      this.isLoading = isLoading
    },

    handleClose() {
      this.$refs['base-dialog'].handleClose()
    }
  }
}
</script>
