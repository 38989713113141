import debounce from 'lodash/debounce'

export default {
  inserted(el, binding) {
    if (!Object.keys(binding).includes('value')) {
      binding.value = true
    }

    const isFixed = binding.value

    el.__handler = debounce(() => {
      const vMainEl = document.querySelector('.v-main')
      const footerEl = document.querySelector('#app-footer')

      if (footerEl) {
        vMainEl.style.paddingBottom = footerEl.clientHeight + 'px'
      }

      el.__layoutEl = document.querySelector('#layout')
      el.__defaultHeightStyle = el.__layoutEl?.style.minHeight

      if (isFixed) {
        const headerEl = document.querySelector('#app-header')
        const hScreen = window.innerHeight

        const updatedHeight = hScreen - headerEl?.clientHeight

        if (updatedHeight) {
          el.__layoutEl.style.minHeight = updatedHeight + 'px'
        }
      } else {
        if (!el.__defaultHeightStyle) return

        el.__layoutEl = el.__defaultHeightStyle
      }

      window.addEventListener('resize', el.__handler)
    }, 500)

    el.__handler()
  },

  unbind(el) {
    if (el?.__layoutEl?.style) {
      el.__layoutEl.style.minHeight = el.__defaultHeightStyle
    }
    window.removeEventListener('resize', el.__handler)
  }
}
