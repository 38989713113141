import { defineIsEqual, BASE_DURATION } from '@/utils/base'

import Notification from '@/utils/notify/class'

/*
  Options:

  [name]:                       [type]   | [default]

  type:                         Enum of TYPES
  title:                        String   | Defines based on type
  text:                         String   | ''

  isInfinite                    Boolean  | false
  duration                      Number   | 5000
*/

/*
  isCloseable                   Boolean  | false
  buttons                       Array    | []
  onClose                       Function | -
*/

export const TYPES = Object.freeze({
  SUCCESS: 'success',
  WARNING: 'warning',
  ERROR: 'error',
  INFO: 'info'
})

const showNotification = options => {
  if (options.type === 'error') {
    console.error(options.title || '', options.text || '')
    console.trace()
  }

  try {
    setTimeout(() => {
      const notification = new Notification(options)
      return notification
    })
  } catch {}
}

let prevOptions = {}
const notify = options => {
  const isDuplicate = defineIsEqual(options, prevOptions[options.type] || {})

  prevOptions[options.type] = options

  if (isDuplicate) return

  showNotification(options)

  setTimeout(() => {
    prevOptions[options.type] = {}
  }, options.duration || BASE_DURATION)
}

export const success = options => notify({ type: TYPES.SUCCESS, ...options })
export const warning = options => notify({ type: TYPES.WARNING, ...options })
export const info = options => notify({ type: TYPES.INFO, ...options })
export const error = options => notify({ type: TYPES.ERROR, ...options })
