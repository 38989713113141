import Vue from 'vue'

import UtilsLoader from '@/components/Utils/UtilsLoader.vue'

const UtilsLoaderConstructor = Vue.extend(UtilsLoader)

const DEFAULT_SIZE = 64

const defineOptions = binding => {
  if ('value' in binding) {
    if (typeof binding.value === 'object') {
      return {
        isLoading: binding.value.isLoading,
        size: binding.value.size
      }
    } else {
      return { isLoading: binding.value }
    }
  } else {
    return { isLoading: true }
  }
}

export default {
  inserted(el, binding) {
    el.__setLoader = ({ isLoading, size = DEFAULT_SIZE }) => {
      if (isLoading) {
        el.__isLoading = true

        el.classList.add('relative')

        el.__loaderInstance = new UtilsLoaderConstructor({
          propsData: { size }
        })
        el.__loaderInstance.$mount()

        el.appendChild(el.__loaderInstance.$el)
      } else {
        el.__isLoading = false

        if (!el.__loaderInstance) return

        el.__loaderInstance.handleClose(() => {
          if (!el.__loaderInstance?.$el) return

          if (el.__loaderInstance?.$parent !== el) return

          el.removeChild(el.__loaderInstance.$el)
        })
      }
    }

    const { isLoading, size } = defineOptions(binding)

    el.__setLoader({ isLoading, size })
  },

  update(el, binding) {
    const { isLoading, size } = defineOptions(binding)

    if (el.__isLoading === isLoading) return

    el.__setLoader({ isLoading, size })
  },

  unbind(el) {
    el.__setLoader && el.__setLoader({ isLoading: false })
  }
}
