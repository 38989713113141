import $api from '@/api'
import * as notify from '@/utils/notify'

export default {
  namespaced: true,

  state: {
    hCareExp: {
      professions: {
        list: []
        // selected: []
      },

      uMs: {
        list: [],
        selected: []
      },

      uSubMs: {
        list: [],
        selected: [],
        isLoading: false
      },

      uHa: {
        // list: [],
        selected: []
      }
    }
  },

  actions: {
    async FETCH_ALL_PROFESSIONS({ commit }) {
      try {
        const result = await $api.professions.fetchAll()
        const { list, error } = result

        if (error) {
          notify.error({ text: error })
          return
        }

        await commit('SET_PROFESSIONS_LIST', [
          ...list,
          { name: 'Other', id: -1 }
        ])
      } catch (error) {
        notify.error({ text: error })
      }
    },

    async FETCH_ALL_MEDICAL_SPECIALTIES({ commit }) {
      try {
        const result =
          await $api.healthcareExperience.fetchAllMedicalSpecialties()
        const { list, error } = result

        if (error) {
          notify.error({ text: error })
          return
        }

        await commit('SET_U_MS_LIST', list)
      } catch (error) {
        notify.error({ text: error })
      }
    },

    async FETCH_ALL_USER_MEDICAL_SPECIALTIES(
      { commit },
      { principalId, userTypeCode }
    ) {
      try {
        const result =
          await $api.healthcareExperience.fetchAllUserMedicalSpecialties({
            principalId,
            userTypeCode
          })
        const { list, error } = result

        if (error) {
          notify.error({ text: error })
          return
        }

        await commit(
          'user/UPDATE_USER',
          { userMedicalSpecialties: list },
          { root: true }
        )

        const uMsList = list.filter(item => !item.medicalSpeciality.parentId)
        const uSubMsList = list.filter(item => item.medicalSpeciality.parentId)

        await commit('SET_U_MS_SELECTED', uMsList)
        await commit('SET_U_SUB_MS_SELECTED', uSubMsList)
      } catch (error) {
        notify.error({ text: error })
      }
    },
    async FETCH_ALL_MEDICAL_SUB_SPECIALTIES({ commit }, { idList }) {
      commit('SET_U_SUB_MS_IS_LOADING', true)

      try {
        const result =
          await $api.healthcareExperience.fetchAllMedicalSubSpecialties({
            idList
          })
        const { list, error } = result

        if (error) {
          notify.error({ text: error })
          return
        }

        await commit('SET_U_SUB_MS_LIST', list)
      } catch (error) {
        notify.error({ text: error })
      } finally {
        commit('SET_U_SUB_MS_IS_LOADING', false)
      }
    },

    async FETCH_ALL_USER_HOSPITAL_AFFILIATIONS(
      { commit },
      { principalId, userTypeCode }
    ) {
      try {
        const result =
          await $api.healthcareExperience.fetchAllUserHospitalAffiliations({
            principalId,
            userTypeCode
          })
        const { list, error } = result

        if (error) {
          notify.error({ text: error })
          return
        }

        await commit('SET_U_HA_SELECTED', list)
      } catch (error) {
        notify.error({ text: error })
      }
    }
  },

  mutations: {
    SET_PROFESSIONS_LIST(state, list) {
      state.hCareExp.professions.list = list
    },
    // SET_PROFESSIONS_SELECTED(state, selected) {
    //   state.hCareExp.professions.selected = selected
    // },

    SET_U_MS_LIST(state, list) {
      state.hCareExp.uMs.list = list
    },
    SET_U_MS_SELECTED(state, selected) {
      state.hCareExp.uMs.selected = selected
    },

    SET_U_SUB_MS_LIST(state, list) {
      state.hCareExp.uSubMs.list = list
    },
    SET_U_SUB_MS_SELECTED(state, selected) {
      state.hCareExp.uSubMs.selected = selected
    },
    SET_U_SUB_MS_IS_LOADING(state, isLoading) {
      state.hCareExp.uSubMs.isLoading = isLoading
    },

    // SET_U_HA_LIST(state, list) {
    //   state.hCareExp.uHa.list = list
    // },
    SET_U_HA_SELECTED(state, selected) {
      state.hCareExp.uHa.selected = selected
    }
  }
}
