<template>
  <v-card class="flex flex-col" outlined>
    <div class="flex flex-col flex-initial">
      <BasePaginatedTableFilters
        :table="table"
        :store-path="storePath"
        @refresh="handleRefresh"
      >
        <slot name="filters-header" />
      </BasePaginatedTableFilters>

      <v-divider />
    </div>

    <BasePaginatedTableContent
      :table="table"
      :store-path="storePath"
      :actions="actions"
      @refresh="handleRefresh"
    >
      <template #actions>
        <slot name="actions" />
      </template>

      <template #list-item-content="{ item, index }">
        <slot name="list-item-content" :item="item" :index="index" />
      </template>
    </BasePaginatedTableContent>
  </v-card>
</template>

<script>
import BasePaginatedTableFilters from '@/components/Base/BasePaginatedTable/BasePaginatedTableFilters.vue'
import BasePaginatedTableContent from '@/components/Base/BasePaginatedTable/BasePaginatedTableContent.vue'

export default {
  name: 'BasePaginatedTable',

  components: {
    BasePaginatedTableFilters,
    BasePaginatedTableContent
  },

  props: {
    storePath: { type: String, required: true },
    actions: { type: Array, default: () => [] }
  },

  computed: {
    table() {
      return this.$store.state[this.storePath].table
    }
  },

  methods: {
    handleRefresh() {
      this.fetchContent()
    },

    fetchContent(payload) {
      return this.dispatch('FETCH_CONTENT', payload)
    },

    dispatch(name, payload) {
      return this.$store.dispatch(`${this.storePath}/${name}`, payload, {
        root: true
      })
    }
  }
}
</script>
