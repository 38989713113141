<template>
  <div
    class="max-w-sm w-full h-full mx-auto py-8 px-4 sm:px-0 gap-8 flex flex-col items-center justify-center"
  >
    <template v-if="isUserStatus.pending">
      <div class="w-full gap-4 flex flex-col flex-initial">
        <h1 class="text-3xl font-bold leading-none">Thank You!</h1>

        <div class="leading-none">
          <span>You have successfully registered.</span>
        </div>
      </div>

      <v-alert class="!m-0" text type="info">{{ subtitle }}</v-alert>

      <BasePrincipalContractsDownloadButtons class="w-full" :principal="user" />

      <small class="text-center text-gray-500">
        You can safely close this page.
      </small>
    </template>

    <template v-else>
      <div class="w-full text-center gap-8 flex flex-col flex-initial">
        <h1 class="text-3xl font-bold leading-none">
          Your account is "{{ USER_STATUSES.labelByCode[user.userStatusCode] }}"
        </h1>

        <div class="leading-none">
          <v-btn color="error" outlined @click="logout">Logout</v-btn>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'

import { USER_STATUSES } from '@/utils/consts'

import BasePrincipalContractsDownloadButtons from '@/components/Tenant/Base/BasePrincipalContractsDownloadButtons.vue'

export default {
  name: 'TenantPrincipalStatusInfoPage',

  components: { BasePrincipalContractsDownloadButtons },

  computed: {
    ...mapState('user', ['user']),
    ...mapState('tenants', ['tenants']),
    ...mapGetters('user', ['isUserStatus']),

    tenant() {
      return this.tenants.selected
    },

    subtitle() {
      return this.tenant.isInternal
        ? 'One of our team members will reach out shortly to give you access to our site, so you can keep your profile current.'
        : `One of our team members will reach out shortly to give you access to our
        site, where you can learn about project opportunities, see what's going
        on with the network, and connect with us directly.`
    }
  },

  created() {
    this.USER_STATUSES = USER_STATUSES
  },

  methods: {
    ...mapActions('auth', {
      logout: 'LOGOUT'
    })
  }
}
</script>
