import axios from 'axios'

import { formatListResponse } from '@/utils/api'

const BASE_URL = `/api/clients`
const URLs = new Proxy(
  {
    FETCH_ALL: `/get-all`,

    CREATE_ONE: '/create'
  },
  { get: (target, prop) => `${BASE_URL}${target[prop]}` }
)

export default {
  async fetchAll() {
    const res = await axios.get(URLs.FETCH_ALL)

    return formatListResponse(res)
  },

  async createOne(payload) {
    const res = await axios.post(URLs.CREATE_ONE, payload)

    const { content: item, isSuccess, error } = res

    return { item, isSuccess, error }
  }
}
