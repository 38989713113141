<template>
  <BaseDialog
    ref="base-dialog"
    :max-width="768"
    :header-title="`Create your own ${categoryName} expertise value`"
    :is-loading="isLoading"
    :footer-buttons="footerButtons"
    @close="$emit('close')"
  >
    <div class="gap-4 flex flex-col">
      <v-alert type="info" dense text>
        Enter a custom {{ categoryName }} value and press
        <code class="!bg-info !text-white">Enter</code> to add
      </v-alert>

      <v-combobox
        :value="principalAttributes.selected"
        :search-input.sync="principalAttributes.search"
        name="profession"
        :label="categoryName"
        background-color="white"
        placeholder="Enter a custom value"
        :items="principalAttributes.list"
        :item-text="defineItemText"
        :item-value="pa => pa.attribute.id"
        :loading="isLoading"
        chips
        deletable-chips
        multiple
        clearable
        outlined
        :rules="[$rules.required]"
        @input="handleInput"
      >
        <template #selection="{ attrs, item, selected }">
          <v-chip
            v-bind="attrs"
            class="multiline-chip"
            close
            :input-value="selected"
            @click:close="() => handleRemove(item)"
          >
            <span class="multiline-chip__text">{{ item.attribute.value }}</span>
          </v-chip>
        </template>

        <template #no-data>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title v-if="principalAttributes.search" class="py-2">
                <span>Press <kbd>enter</kbd> to create a new "</span>
                <strong>{{ principalAttributes.search }}</strong>
                <span>" value</span>
              </v-list-item-title>

              <v-list-item-title v-else class="py-2">
                <span>Write a custom value and</span>
                <span> press <kbd>enter</kbd> to create it.</span>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-combobox>
    </div>
  </BaseDialog>
</template>

<script>
import differenceBy from 'lodash/differenceBy'
import { mapActions } from 'vuex'

import * as services from '@/services'
import * as notify from '@/utils/notify'
import { defineIsEqual } from '@/utils/base'

import BaseDialog from '@/components/Base/BaseDialog/BaseDialog.vue'

const defineRandomInt = () => {
  const min = -2147483647
  const max = 0
  return Math.floor(Math.random() * (max - min) + min)
}

export default {
  name: 'AttributesCustomAttributeCreationDialog',

  components: { BaseDialog },

  props: {
    principal: { type: Object, required: true },

    categoryId: { type: Number, required: true },
    categoryName: { type: String, required: true },

    selectedPa: { type: Array, default: null },

    getAttributesByCategoryId: { type: Function, default: null }
  },

  data: () => ({
    principalAttributes: {
      list: [],
      selected: [],
      search: ''
    },

    isLoading: false
  }),

  computed: {
    isEmpty() {
      return !this.principalAttributes.selected.length
    },

    isModified() {
      return !defineIsEqual(
        this.defaultPaSelected,
        this.principalAttributes.selected
      )
    },

    footerButtons() {
      return [
        {
          text: 'Close',
          attrs: { disabled: this.isLoading, color: 'grey', text: true },
          handler: this.handleClose
        },
        {
          text: 'Save',
          attrs: {
            color: 'primary',
            loading: this.isLoading
          },
          handler: this.handleSubmit
        }
      ]
    }
  },

  created() {
    this.handleRefresh()
  },

  methods: {
    ...mapActions('attributes/principal', {
      addMultiple: 'ADD_MULTIPLE_BY_PRINCIPAL_AND_CATEGORY',
      deleteMultiple: 'DELETE_MULTIPLE_BY_PRINCIPAL'
    }),

    async fetchAttributesByCategoryId() {
      if (this.getAttributesByCategoryId) {
        return this.getAttributesByCategoryId(this.categoryId)
      }

      const { list } = await services.attributes.fetchAllByCategoryId(
        this.categoryId
      )

      return list
    },

    async fetchPrincipalAttributesByCategoryId() {
      if (this.selectedPa) return this.selectedPa

      const { list } = await services.principalAttributes.fetchAllByPrincipal({
        principalId: this.principal.id,
        userTypeCode: this.principal.userTypeCode
      })

      return list.filter(
        pa => pa.attribute.attributeCategoryId === this.categoryId
      )
    },

    async handleSubmit() {
      this.isLoading = true

      try {
        const toAdd = this.principalAttributes.selected
        const toCreate = this.principalAttributes.selected.filter(
          pa => pa.attribute.id <= 0
        )

        const toDelete = differenceBy(
          this.defaultPaSelected,
          this.principalAttributes.selected,
          item => item.attribute.id
        ).filter(pa => pa.id)

        const isToAdd = Boolean(toAdd.length)
        const isToDelete = Boolean(toDelete.length)

        if (isToAdd) {
          const existingIdList = toAdd
            .filter(pa => pa.attribute.id > 0)
            .map(pa => pa.attribute.id)
          const createdIdList = await this.createMultipleAttributes(toCreate)

          const payload = {
            attributeIdList: [...existingIdList, ...createdIdList],
            principalId: this.principal.id,
            userTypeCode: this.principal.userTypeCode,
            attributeCategoryId: this.categoryId
          }

          await this.addMultiple(payload)
        }

        if (isToDelete) {
          const payload = {
            principalId: this.principal.id,
            userTypeCode: this.principal.userTypeCode,
            principalAttributeIdList: toDelete.map(pa => pa.id)
          }

          await this.deleteMultiple(payload)
        }

        this.handleSuccess()
      } finally {
        this.isLoading = false
      }
    },

    handleSuccess() {
      this.$emit('success')
      notify.success({ title: `${this.categoryName} saved` })
      this.handleClose()
    },

    async createMultipleAttributes(toAdd) {
      let result = []
      let attributesIdList = []

      const payload = toAdd.map(pa => ({
        id: undefined,
        value: pa.attribute.value,
        isUserSpecific: true,
        description: '',
        onlyForHubs: false,
        parentId: null,
        attributeCategoryId: this.categoryId
      }))

      try {
        result = await Promise.all(payload.map(services.attributes.createOne))

        const errors = result.map(res => res.error)

        errors.forEach(error => {
          if (error) {
            notify.error({ text: error })
          }
        })

        const isSuccess = result.map(res => res.isSuccess).every(Boolean)

        if (!isSuccess) return

        attributesIdList = result.map(res => res.item.id)
      } catch (error) {
        notify.error({ text: error })
      }

      return attributesIdList
    },

    handleInput(selected) {
      const defineNormalizedSelected = item => {
        if (typeof item === 'string') {
          const existingItem = this.principalAttributes.list.find(
            pa => pa.attribute.value === item
          )

          const newItem = { attribute: { id: defineRandomInt(), value: item } }

          item = existingItem || newItem
        }

        return item
      }

      const normalizedSelected = selected.map(defineNormalizedSelected)

      this.principalAttributes.selected = normalizedSelected
    },

    handleRemove(item) {
      const removeByItem = () => {
        this.principalAttributes.selected =
          this.principalAttributes.selected.filter(i => !defineIsEqual(i, item))
      }

      const isSingle = this.principalAttributes.selected.length === 1

      if (isSingle) {
        removeByItem()
        return
      }

      removeByItem()
    },

    async handleRefresh() {
      const aToPa = a => ({ attribute: a })

      this.isLoading = true

      const [attributesByCategory, selectedPa] = await Promise.all([
        this.fetchAttributesByCategoryId(),
        this.fetchPrincipalAttributesByCategoryId()
      ])

      this.isLoading = false

      const paList = attributesByCategory
        .filter(a =>
          a.isUserSpecific ? a.createdById === this.principal.id : true
        )
        .map(aToPa)

      const paSelected = selectedPa.filter(
        pa => pa.attribute.attributeCategoryId === this.categoryId
      )

      this.defaultPaSelected = structuredClone(paSelected)

      this.principalAttributes.list = paList
      this.principalAttributes.selected = paSelected
    },

    defineItemText: pa => pa.attribute.value,

    handleClose() {
      this.$refs['base-dialog'].handleClose()
    }
  }
}
</script>
