import $api from '@/api'
import * as notify from '@/utils/notify'

export const fetchAllWithStats = async (...args) => {
  let result = {}

  try {
    result = await $api.attributes.fetchAllWithStats(...args)
    const { error } = result

    if (error) {
      notify.error({ text: error })
    }
  } catch (error) {
    notify.error({ text: error })
  }

  return result
}

export const fetchAllByCategoryId = async categoryId => {
  let result = {}

  try {
    result = await $api.attributes.fetchAllByCategoryId(categoryId)
    const { error } = result

    if (error) {
      notify.error({ text: error })
    }
  } catch (error) {
    notify.error({ text: error })
  }

  return result
}

export const merge = async payload => {
  let result = {}

  try {
    result = await $api.attributes.merge(payload)
    const { error } = result

    if (error) {
      notify.error({ text: error })
    }
  } catch (error) {
    notify.error({ text: error })
  }

  return result
}

export const exportMultiple = async payload => {
  let result = {}

  try {
    result = await $api.attributes.exportMultiple(payload)
    const { error } = result

    if (error) {
      notify.error({ text: error })
    }
  } catch (error) {
    notify.error({ text: error })
  }

  return result
}

export const createOne = async payload => {
  let result = {}

  try {
    result = await $api.attributes.createOne(payload)
    const { error } = result

    if (error) {
      notify.error({ text: error })
    }
  } catch (error) {
    notify.error({ text: error })
  }

  return result
}

export const updateOne = async payload => {
  let result = {}

  try {
    result = await $api.attributes.updateOne(payload)
    const { error } = result

    if (error) {
      notify.error({ text: error })
    }
  } catch (error) {
    notify.error({ text: error })
  }

  return result
}

export const deleteOne = async payload => {
  let result = {}

  try {
    result = await $api.attributes.deleteOne(payload)
    const { error } = result

    if (error) {
      notify.error({ text: error })
    }
  } catch (error) {
    notify.error({ text: error })
  }

  return result
}
