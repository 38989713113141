import axios from 'axios'

import {
  formatResponse,
  formatListResponse,
  formatItemResponse
} from '@/utils/api'

const BASE_URL = `/api/team`
const URLs = new Proxy(
  {
    FETCH_ALL_VIEWS: `/get-all-views`,
    FETCH_ALL_BY_IDS: `/get-all-views-by-team-id-list`,
    FETCH_ALL_BY_KEYWORD: `/search-by-keyword/`,

    SYNC_TO_HUBSPOT: '/sync-to-hubspot/',
    ADD_ALL_USERS_TO_TEAM: `/add-users-to-team`,

    CREATE_ONE: `/create`,
    UPDATE_ONE: `/update`
  },
  { get: (target, prop) => `${BASE_URL}${target[prop]}` }
)

export default {
  async fetchAllViews() {
    const res = await axios.get(`${URLs.FETCH_ALL_VIEWS}`)

    return formatListResponse(res)
  },
  async fetchAllByIds(ids) {
    const res = await axios.post(`${URLs.FETCH_ALL_BY_IDS}`, {
      teamIdList: ids
    })

    return formatListResponse(res)
  },
  async fetchAllByKeyword(keyword = '') {
    const res = await axios.get(`${URLs.FETCH_ALL_BY_KEYWORD}${keyword}`)

    return formatListResponse(res)
  },

  async addAllUsersToTeam(payload) {
    const res = await axios.post(`${URLs.ADD_ALL_USERS_TO_TEAM}`, payload)
    const { isSuccess, error } = res

    return { isSuccess, error }
  },

  async syncToHubspot({ id }) {
    const res = await axios.get(`${URLs.SYNC_TO_HUBSPOT}${id}`)

    return formatResponse(res)
  },

  async createOne(payload) {
    const res = await axios.post(URLs.CREATE_ONE, payload)

    return formatItemResponse(res)
  },
  async updateOne(payload) {
    const res = await axios.put(URLs.UPDATE_ONE, payload)

    return formatItemResponse(res)
  }
}
