import * as services from '@/services'

export default {
  namespaced: true,

  state: {
    principalTenants: {
      list: [],
      isLoading: false
    }
  },

  actions: {
    async FETCH_ALL_BY_PRINCIPAL({ commit }, principal) {
      let result = {}
      await commit('SET_IS_LOADING', true)

      result = await services.principalToTenant.fetchAllByPrincipalId(principal)
      const { list } = result

      await Promise.all([
        commit('SET_LIST', list),
        commit('SET_IS_LOADING', false)
      ])

      return list
    },

    async CREATE_ONE({ commit, dispatch }, payload) {
      await commit('SET_IS_LOADING', true)

      const result = await services.principalToTenant.createOne(payload)
      const { isSuccess } = result

      if (isSuccess) {
        await dispatch('FETCH_ALL')
      }

      await commit('SET_IS_LOADING', false)

      return result
    },

    async DELETE_ONE({ commit, dispatch }, { principal, id }) {
      await commit('SET_IS_LOADING', true)

      const result = await services.principalToTenant.deleteOne({
        principal,
        id
      })
      const { isSuccess } = result

      if (isSuccess) {
        await dispatch('FETCH_ALL')
      }

      await commit('SET_IS_LOADING', false)

      return result
    }
  },

  mutations: {
    SET_LIST(state, list) {
      state.principalTenants.list = list
    },
    SET_IS_LOADING(state, isLoading) {
      state.principalTenants.isLoading = isLoading
    }
  }
}
