import axios from 'axios'

const BASE_URL = `/api/assignment-actor`
const URLs = new Proxy(
  {
    UPDATE_MULTIPLE: `/update-multiple`
  },
  { get: (target, prop) => `${BASE_URL}${target[prop]}` }
)

export default {
  async updateMultiple(payload) {
    const res = await axios.patch(URLs.UPDATE_MULTIPLE, payload)
    const { isSuccess, error } = res

    return { isSuccess, error }
  }
}
