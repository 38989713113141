import $api from '@/api'
import * as notify from '@/utils/notify'

export const fetchAll = async () => {
  let result = {}

  try {
    result = await $api.tenants.fetchAll()
    const { error } = result

    if (error) {
      notify.error({ text: error })
    }
  } catch (error) {
    notify.error({ text: error })
  }

  return result
}
export const fetchOneById = async ({ id }) => {
  let result = {}

  try {
    result = await $api.tenants.fetchOneById({ id })
    const { error } = result

    if (error) {
      notify.error({ text: error })
    }
  } catch (error) {
    notify.error({ text: error })
  }

  return result
}
export const fetchOneByMarker = async ({ marker }) => {
  let result = {}

  try {
    result = await $api.tenants.fetchOneByMarker({ marker })
    const { error } = result

    if (error) {
      notify.error({ text: error })
    }
  } catch (error) {
    notify.error({ text: error })
  }

  return result
}
export const fetchAllPrincipalsForTenant = async ({
  tenantId,
  userTypeCode
}) => {
  let result = {}

  try {
    result = await $api.tenants.fetchAllPrincipalsForTenant({
      tenantId,
      userTypeCode
    })
    const { error } = result

    if (error) {
      notify.error({ text: error })
    }
  } catch (error) {
    notify.error({ text: error })
  }

  return result
}

export const createOne = async payload => {
  let result = {}

  try {
    result = await $api.tenants.createOne(payload)
    const { error } = result

    if (error) {
      notify.error({ text: error })
    }
  } catch (error) {
    notify.error({ text: error })
  }

  return result
}
export const updateOne = async payload => {
  let result = {}

  try {
    result = await $api.tenants.updateOne(payload)
    const { error } = result

    if (error) {
      notify.error({ text: error })
    }
  } catch (error) {
    notify.error({ text: error })
  }

  return result
}
export const updateRules = async payload => {
  let result = {}

  try {
    result = await $api.tenants.updateRules(payload)
    const { error } = result

    if (error) {
      notify.error({ text: error })
    }
  } catch (error) {
    notify.error({ text: error })
  }

  return result
}
export const deleteOne = async ({ id }) => {
  let result = {}

  try {
    result = await $api.tenants.deleteOne({ id })
    const { error } = result

    if (error) {
      notify.error({ text: error })
    }
  } catch (error) {
    notify.error({ text: error })
  }

  return result
}
