import axios from 'axios'

import {
  formatResponse,
  formatItemResponse,
  formatListResponse
} from '@/utils/api'

const BASE_URL = `/api/user`
const URLs = new Proxy(
  {
    FETCH_ALL_BY_KEYWORD: `/search-by-keyword/`,
    FETCH_ALL_BY_IDS: `/get-by-id-list`,
    FETCH_ALL_BY_ROLE_NAME: `/get-by-role-name-flyweight/`,

    FETCH_ONE: `/get-by-id/`,
    FETCH_ONE_BY_ACCESS_TOKEN: `/get-from-token`,

    FETCH_ASSIGNMENT_HISTORY: `/get-assignment-history/`,

    FETCH_ALL_ACTIVE: '/get-all-active',

    FETCH_ONE_AVATAR: '/get-avatar/',
    ADD_ONE_AVATAR: '/add-avatar',

    CREATE_ONE: '/create',
    UPDATE_ONE: '/update',
    DELETE_ONE: '/delete/',

    FETCH_ALL_HOURS: '/get-hours/',
    UPDATE_HOURS: '/update-hours',
    CONVERT_TO_HUB: '/convert-to-hub/',

    PROFILE_UPDATE_ONE: '-profile/update'
  },
  { get: (target, prop) => `${BASE_URL}${target[prop]}` }
)

export default {
  async fetchAllByKeyword(keyword = '') {
    const res = await axios.get(`${URLs.FETCH_ALL_BY_KEYWORD}${keyword}`)

    return formatListResponse(res)
  },
  async fetchAllByIds(ids) {
    const res = await axios.post(URLs.FETCH_ALL_BY_IDS, ids)

    return formatListResponse(res)
  },

  async fetchAllByRoleName(roleName) {
    const res = await axios.get(`${URLs.FETCH_ALL_BY_ROLE_NAME}${roleName}`)

    return formatListResponse(res)
  },
  async fetchOneById({ id }) {
    const res = await axios.get(`${URLs.FETCH_ONE}${id}`)

    return formatItemResponse(res)
  },
  async fetchOneByAccessToken({ accessToken }) {
    const res = await axios.post(URLs.FETCH_ONE_BY_ACCESS_TOKEN, {
      token: accessToken
    })

    const { content: user, isSuccess, error } = res

    return { user, isSuccess, error }
  },
  async fetchAssignmentHistory({ principalId, userTypeCode }) {
    const res = await axios.get(
      `${URLs.FETCH_ASSIGNMENT_HISTORY}${principalId}/${userTypeCode}`
    )

    return formatListResponse(res)
  },

  async fetchAllActive() {
    const res = await axios.get(URLs.FETCH_ALL_ACTIVE)

    return formatListResponse(res)
  },

  async fetchOneAvatar({ id }) {
    const res = await axios.get(`${URLs.FETCH_ONE_AVATAR}${id}`)

    return formatResponse(res)
  },
  async addOneAvatar(payload) {
    const res = await axios.post(URLs.ADD_ONE_AVATAR, payload)

    return formatResponse(res)
  },

  async createOne(payload) {
    const res = await axios.post(URLs.CREATE_ONE, payload)

    const { content: user, isSuccess, error } = res

    return { user, isSuccess, error }
  },
  async updateOne(payload) {
    const res = await axios.put(URLs.UPDATE_ONE, payload)

    const { content: user, isSuccess, error } = res

    return { user, isSuccess, error }
  },
  async deleteOne({ id }) {
    const res = await axios.delete(`${URLs.DELETE_ONE}${id}`)

    return formatResponse(res)
  },

  async fetchAllHours({ userId }) {
    const res = await axios.get(`${URLs.FETCH_ALL_HOURS}${userId}`)

    return formatListResponse(res)
  },

  async updateHours(payload) {
    const res = await axios.put(URLs.UPDATE_HOURS, payload)

    return formatListResponse(res)
  },
  async convertToHub({ id }) {
    const res = await axios.get(`${URLs.CONVERT_TO_HUB}${id}`)

    return formatResponse(res)
  },

  async profileUpdateOne(payload) {
    const res = await axios.put(URLs.PROFILE_UPDATE_ONE, payload)

    const { content: user, isSuccess, error } = res

    return { user, isSuccess, error }
  }
}
