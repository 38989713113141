import axios from 'axios'

import { formatItemResponse, formatListResponse } from '@/utils/api'

const BASE_URL = `/api/faqs`
const URLs = new Proxy(
  {
    FETCH_FULL_OR_RANDOM: `/get-full-or-random`,
    FETCH_ALL_CATEGORIES: `/get-all-categories`,

    CREATE_ONE: '/create',
    UPDATE_ONE: '/update',
    DELETE_ONE: '/delete/',
    DELETE_MULTIPLE: '/delete-multiple'
  },
  { get: (target, prop) => `${BASE_URL}${target[prop]}` }
)

export default {
  async fetchFullOrRandom({ isFull, includeNoTenant }, config = null) {
    const res = await axios.get(
      `${URLs.FETCH_FULL_OR_RANDOM}/${isFull}/${includeNoTenant}`,
      config
    )

    return formatListResponse(res)
  },

  async fetchAllCategories() {
    const res = await axios.get(URLs.FETCH_ALL_CATEGORIES)

    return formatListResponse(res)
  },

  async createOne(payload) {
    const res = await axios.post(URLs.CREATE_ONE, payload)

    return formatItemResponse(res)
  },
  async updateOne(payload) {
    const res = await axios.put(URLs.UPDATE_ONE, payload)

    return formatItemResponse(res)
  },
  async deleteOne({ id }) {
    const res = await axios.delete(`${URLs.DELETE_ONE}${id}`)

    return formatItemResponse(res)
  },
  async deleteMultiple(payload) {
    const res = await axios.put(URLs.DELETE_MULTIPLE, payload)

    return formatItemResponse(res)
  }
}
