import uniqBy from 'lodash/uniqBy'

import $api from '@/api'

import { formatKeysToPascalCase, BASE_LIMIT } from '@/utils/base'
import * as notify from '@/utils/notify'

export default {
  namespaced: true,

  state: {
    tasks: {
      list: [],
      selected: null,
      totalCount: 0,
      isLoading: false,

      filters: {
        offset: 0,
        limit: BASE_LIMIT,
        isAttempts: null,
        isReadyAttempts: null,
        userName: '',
        statusCode: 0
      }
    }
  },

  actions: {
    async FETCH_ALL_BY_USER_ID({ commit }, userId) {
      // commit('SET_IS_LOADING', true)

      let result = {}

      try {
        result = await $api.assessment.tasks.fetchAllByUserId(userId)
        const { list, error } = result

        if (error) {
          notify.error({ text: error })
        } else {
          commit('SET_LIST', list || [])
        }
      } catch (error) {
        notify.error({ text: error })
      } finally {
        // commit('SET_IS_LOADING', false)
      }

      return result
    },

    async FETCH_ALL_BY_DEFINITION_ID({ state, commit }, definitionId) {
      // commit('SET_IS_LOADING', true)

      let result = {}
      const filters = state.tasks.filters

      try {
        result = await $api.assessment.tasks.fetchAllByDefinitionId({
          definitionId,
          filters
        })
        const { list, totalCount, error } = result

        if (error) {
          notify.error({ text: error })
        } else {
          if (filters.offset === 0) {
            commit('SET_LIST', list)
            commit('SET_TOTAL_COUNT', totalCount)
          } else {
            commit('APPEND_TO_LIST', list)
          }
        }
      } catch (error) {
        notify.error({ text: error })
      } finally {
        // commit('SET_IS_LOADING', false)
      }

      return result
    },

    async FETCH_ONE_BY_ID(_, taskId) {
      // commit('SET_IS_LOADING', true)

      let result = {}

      try {
        result = await $api.assessment.tasks.fetchOneById(taskId)
        const { error } = result

        if (error) {
          notify.error({ text: error })
        }
      } catch (error) {
        notify.error({ text: error })
      } finally {
        // commit('SET_IS_LOADING', false)
      }

      return result
    },

    async CREATE_ONE({ rootState }, payload) {
      let result = {}

      payload = {
        ...payload,
        UserId: rootState.user.user.id
      }

      try {
        result = await $api.assessment.tasks.createOne(payload)
        const { error } = result

        if (error) {
          notify.error({ text: error })
        }
      } catch (error) {
        notify.error({ text: error })
      }

      return result
    },

    async UPDATE_ONE({ state, rootState, commit }, toUpdate) {
      // commit('SET_IS_LOADING', true)

      let result = {}

      toUpdate = {
        ...formatKeysToPascalCase(toUpdate),
        UpdatedById: rootState.user.user.id
      }

      delete toUpdate.$type
      delete toUpdate?.User?.$type

      try {
        result = await $api.assessment.tasks.updateOne(toUpdate)
        const { item, isSuccess, error } = result

        if (error) {
          notify.error({ text: error })
        } else {
          if (isSuccess && item) {
            await commit('SET_LIST_ITEM', item)

            if (state.tasks?.selected?.id === item.id) {
              await commit('SET_SELECTED', item)
            }
          }
        }
      } catch (error) {
        notify.error({ text: error })
      } finally {
        // commit('SET_IS_LOADING', false)
      }

      return result
    },

    async DELETE_ONE({ commit, dispatch }, id) {
      commit('SET_IS_LOADING', true)

      let result = {}

      try {
        result = await $api.assessment.tasks.deleteOne(id)
        const { error } = result

        if (error) {
          notify.error({ text: error })
        } else {
          await dispatch('FETCH_ALL')
        }
      } catch (error) {
        notify.error({ text: error })
      } finally {
        commit('SET_IS_LOADING', false)
      }

      return result
    }
  },

  mutations: {
    SET_LIST(state, list) {
      state.tasks.list = list
    },
    SET_TOTAL_COUNT(state, totalCount) {
      state.tasks.totalCount = totalCount
    },
    APPEND_TO_LIST(state, list) {
      state.tasks.list = uniqBy([...state.tasks.list, ...list], 'id')
    },
    SET_LIST_ITEM(state, listItem) {
      state.tasks.list = state.tasks.list.map(item =>
        item.id === listItem.id ? listItem : item
      )
    },
    SET_IS_LOADING(state, isLoading) {
      state.tasks.isLoading = isLoading
    },
    SET_SELECTED(state, selected) {
      state.tasks.selected = selected
    },
    UPDATE_FILTERS(state, toUpdate) {
      state.tasks.filters = { ...state.tasks.filters, ...toUpdate }
    }
  }
}
