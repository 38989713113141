import $api from '@/api'
import * as notify from '@/utils/notify'

export const fetchAllUserUriTypes = async () => {
  let result = {}

  try {
    result = await $api.uris.fetchAllUserUriTypes()
    const { error } = result

    if (error) {
      notify.error({ text: error })
    }
  } catch (error) {
    notify.error({ text: error })
  }

  return result
}

export const fetchAllByPrincipal = async ({ principalId, userTypeCode }) => {
  let result = {}

  try {
    result = await $api.uris.fetchAllByPrincipal({ principalId, userTypeCode })
    const { error } = result

    if (error) {
      notify.error({ text: error })
    }
  } catch (error) {
    notify.error({ text: error })
  }

  return result
}

export const saveOne = async payload => {
  let result = {}

  try {
    result = await $api.uris.saveOne(payload)
    const { error } = result

    if (error) {
      notify.error({ text: error })
    }
  } catch (error) {
    notify.error({ text: error })
  }

  return result
}

export const deleteOne = async ({ principal, id }) => {
  let result = {}

  const { id: principalId, userTypeCode } = principal
  const payload = { principalId, userTypeCode, id }

  try {
    result = await $api.uris.deleteOne(payload)
    const { error } = result

    if (error) {
      notify.error({ text: error })
    }
  } catch (error) {
    notify.error({ text: error })
  }

  return result
}
