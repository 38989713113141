<template>
  <v-fade-transition>
    <v-overlay v-if="isVisible" absolute :z-index="zIndex">
      <v-progress-circular indeterminate :size="size" color="grey lighten-5" />
    </v-overlay>
  </v-fade-transition>
</template>

<script>
export default {
  name: 'BaseLoader',

  props: {
    zIndex: { type: [Number, String], default: 5 },
    size: { type: Number, required: true }
  },

  data: () => ({
    isVisible: false
  }),

  mounted() {
    this.isVisible = true
  },

  methods: {
    handleClose(callback) {
      this.isVisible = false

      setTimeout(() => {
        callback ? callback() : this.$emit('close')
      }, 250)
    }
  }
}
</script>
