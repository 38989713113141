import $api from '@/api'
import * as notify from '@/utils/notify'

export const fetchAllByKeyword = async keyword => {
  let result = {}

  try {
    result = await $api.users.fetchAllByKeyword(keyword)
    const { error } = result

    if (error) {
      notify.error({ text: error })
    }
  } catch (error) {
    notify.error({ text: error })
  }

  return result
}

export const fetchOneById = async ({ id }) => {
  let result = {}

  try {
    result = await $api.users.fetchOneById({ id })
    const { error } = result

    if (error) {
      notify.error({ text: error })
    }
  } catch (error) {
    notify.error({ text: error })
  }

  return result
}

export const fetchOneAvatar = async ({ id }) => {
  let result = {}

  try {
    result = await $api.users.fetchOneAvatar({ id })
    const { error } = result

    if (error) {
      notify.error({ text: error })
    }
  } catch (error) {
    notify.error({ text: error })
  }

  return result
}

export const updateOne = async payload => {
  let result = {}

  try {
    result = await $api.users.updateOne(payload)
    const { error } = result

    if (error) {
      notify.error({ text: error })
    }
  } catch (error) {
    notify.error({ text: error })
  }

  return result
}

export const deleteOne = async payload => {
  let result = {}

  try {
    result = await $api.users.deleteOne(payload)
    const { error } = result

    if (error) {
      notify.error({ text: error })
    }
  } catch (error) {
    notify.error({ text: error })
  }

  return result
}

export const addOneAvatar = async payload => {
  let result = {}

  try {
    result = await $api.users.addOneAvatar(payload)
    const { error } = result

    if (error) {
      notify.error({ text: error })
    }
  } catch (error) {
    notify.error({ text: error })
  }

  return result
}

export const fetchAssignmentHistory = async user => {
  let result = {}

  const payload = { principalId: user.id, userTypeCode: user.userTypeCode }

  try {
    result = await $api.users.fetchAssignmentHistory(payload)
    const { error } = result

    if (error) {
      notify.error({ text: error })
    }
  } catch (error) {
    notify.error({ text: error })
  }

  return result
}

export const fetchAllHours = async principal => {
  let result = {}

  try {
    result = await $api.users.fetchAllHours({ userId: principal.id })
    const { error } = result

    if (error) {
      notify.error({ text: error })
    }
  } catch (error) {
    notify.error({ text: error })
  }

  return result
}

export const updateHours = async payload => {
  let result = {}

  try {
    result = await $api.users.updateHours(payload)
    const { error } = result

    if (error) {
      notify.error({ text: error })
    }
  } catch (error) {
    notify.error({ text: error })
  }

  return result
}

export const convertToHub = async payload => {
  let result = {}

  try {
    result = await $api.users.convertToHub(payload)
    const { error } = result

    if (error) {
      notify.error({ text: error })
    }
  } catch (error) {
    notify.error({ text: error })
  }

  return result
}
