export default {
  namespaced: true,

  state: {
    annotations: {
      htmlContent: '',
      comments: []
    }
  },

  getters: {
    getCommentById: state => id =>
      state.annotations.comments.find(item => item.id === id)
  },

  mutations: {
    SET_ANNOTATIONS(state, annotations) {
      state.annotations = annotations
    },
    ADD_ANNOTATIONS_COMMENT(state, { commentId }) {
      state.annotations.comments.push({ id: commentId, replies: [] })
    },
    ADD_ANNOTATIONS_COMMENT_REPLY(state, { commentId, reply }) {
      state.annotations.comments = state.annotations.comments.map(item =>
        item.id === commentId
          ? { ...item, replies: [...item.replies, reply] }
          : item
      )
    },
    UPDATE_ANNOTATIONS_COMMENT_REPLY(
      state,
      { commentId, replyIndex, toUpdate }
    ) {
      state.annotations.comments = state.annotations.comments.map(comment =>
        comment.id === commentId
          ? {
              ...comment,
              replies: comment.replies.map((reply, index) =>
                index === replyIndex ? { ...reply, ...toUpdate } : reply
              )
            }
          : comment
      )
    },

    REMOVE_ANNOTATIONS_COMMENT_REPLY(state, { commentId, replyIndex }) {
      state.annotations.comments = state.annotations.comments.map(item =>
        item.id === commentId
          ? {
              ...item,
              replies: item.replies.filter((_, i) => i !== replyIndex)
            }
          : item
      )
    },
    REMOVE_ANNOTATIONS_COMMENT(state, { commentId }) {
      state.annotations.comments = state.annotations.comments.filter(
        item => item.id !== commentId
      )
    }
  }
}
