import $api from '@/api'
import * as notify from '@/utils/notify'

export const fetchAllByTenantId = async ({ id }) => {
  let result = {}

  try {
    result = await $api.tenantAddresses.fetchAllByTenantId({ id })
    const { error } = result

    if (error) {
      notify.error({ text: error })
    }
  } catch (error) {
    notify.error({ text: error })
  }

  return result
}

export const upsertOne = async payload => {
  let result = {}

  try {
    result = await $api.tenantAddresses.upsertOne(payload)
    const { error } = result

    if (error) {
      notify.error({ text: error })
    }
  } catch (error) {
    notify.error({ text: error })
  }

  return result
}

export const deleteOne = async payload => {
  let result = {}

  try {
    result = await $api.tenantAddresses.deleteOne(payload)
    const { error } = result

    if (error) {
      notify.error({ text: error })
    }
  } catch (error) {
    notify.error({ text: error })
  }

  return result
}
