import $api from '@/api'

import * as notify from '@/utils/notify'
import { TASK_STATUSES } from '@/utils/assessments'

const ROOT = { root: true }

export default {
  namespaced: true,

  state: {
    definitions: {
      list: [],
      selected: null,
      isLoading: false,

      filters: { search: '', assessmentTypeCode: null },

      files: {
        list: [],
        isLoading: false
      }
    },

    invitationDialog: {
      list: [],
      isVisible: false
    }
  },

  getters: {
    filteredList: state => {
      const list = state.definitions.list
      const { search, assessmentTypeCode } = state.definitions.filters

      let filteredList = list

      if (assessmentTypeCode !== null) {
        filteredList = list.filter(
          item => item.assessmentTypeCode === assessmentTypeCode
        )
      }

      filteredList = filteredList.filter(item => {
        const name = item.name.toLowerCase()

        return name.includes(search.toLowerCase())
      })

      return filteredList
    }
  },

  actions: {
    async FETCH_ALL({ commit }) {
      commit('SET_IS_LOADING', true)

      let result = {}

      try {
        result = await $api.assessment.definitions.fetchAll()
        const { list, isSuccess, error } = result

        if (error) {
          notify.error({ text: error })
        } else {
          await commit('SET_LIST', [])
          commit('SET_LIST', isSuccess ? list : [])
        }
      } catch (error) {
        notify.error({ text: error })
      } finally {
        commit('SET_IS_LOADING', false)
      }

      return result
    },

    async FETCH_ONE_BY_ID({ commit }, id) {
      commit('SET_IS_LOADING', true)

      let result = {}

      try {
        result = await $api.assessment.definitions.fetchOneById(id)
        const { error } = result

        if (error) {
          notify.error({ text: error })
        }
      } catch (error) {
        notify.error({ text: error })
      } finally {
        commit('SET_IS_LOADING', false)
      }

      return result
    },

    async CREATE_ONE(_, payload) {
      let result = {}

      try {
        result = await $api.assessment.definitions.createOne(payload)
        const { error } = result

        if (error) {
          notify.error({ text: error })
        }
      } catch (error) {
        notify.error({ text: error })
      }

      return result
    },

    async UPDATE_ONE(_, payload) {
      let result = {}

      try {
        result = await $api.assessment.definitions.updateOne(payload)
        const { error } = result

        if (error) {
          notify.error({ text: error })
        }
      } catch (error) {
        notify.error({ text: error })
      }

      return result
    },

    async DELETE_ONE({ commit, dispatch }, id) {
      commit('SET_IS_LOADING', true)

      let result = {}

      try {
        result = await $api.assessment.definitions.deleteOne(id)
        const { error } = result

        if (error) {
          notify.error({ text: error })
        } else {
          await dispatch('FETCH_ALL')
        }
      } catch (error) {
        notify.error({ text: error })
      } finally {
        commit('SET_IS_LOADING', false)
      }

      return result
    },

    async DELETE_TRIGGER_ONE({ commit, dispatch }, payload) {
      commit('SET_IS_LOADING', true)

      let result = {}

      try {
        result = await $api.assessment.definitions.deleteTriggerOne(payload)
        const { error } = result

        if (error) {
          notify.error({ text: error })
        } else {
          await dispatch('FETCH_ALL')
        }
      } catch (error) {
        notify.error({ text: error })
      } finally {
        commit('SET_IS_LOADING', false)
      }

      return result
    },

    async ADD_TRIGGERS_ALL(_, { definitionId, payload }) {
      let result = {}

      try {
        result = await $api.assessment.definitions.addTriggersAllByAssessmentId(
          { assessmentId: definitionId, payload: payload }
        )
        const { error } = result

        if (error) {
          notify.error({ text: error })
        }
      } catch (error) {
        notify.error({ text: error })
      }

      return result
    },

    async FETCH_FILES_ALL_BY_DEFINITION_ID(_, definitionId) {
      let result = {}

      try {
        result = await $api.assessment.definitions.fetchFilesAllByDefinitionId(
          definitionId
        )
        const { error } = result

        if (error) {
          notify.error({ text: error })
        }
      } catch (error) {
        notify.error({ text: error })
      }

      return result
    },

    async ADD_FILES_ALL(_, payload) {
      let result = {}

      try {
        result = await $api.assessment.definitions.addFilesAll(payload)
        const { error } = result

        if (error) {
          notify.error({ text: error })
        }
      } catch (error) {
        notify.error({ text: error })
      }

      return result
    },

    async DELETE_FILES_ALL_BY_IDS(_, idList) {
      let result = {}

      try {
        result = await $api.assessment.definitions.deleteFilesAllByIds({
          idList
        })
        const { error } = result

        if (error) {
          notify.error({ text: error })
        }
      } catch (error) {
        notify.error({ text: error })
      }

      return result
    },

    async FETCH_STATS_BY_ID(_, definitionId) {
      let result = {}

      try {
        result = await $api.assessment.definitions.fetchAttemptStatsById(
          definitionId
        )
        const { error } = result

        if (error) {
          notify.error({ text: error })
        }
      } catch (error) {
        notify.error({ text: error })
      }

      return result
    },

    async DEFINE_NEW_AVAILABLE_ASSESSMENTS(
      { commit, dispatch },
      { userId, idList }
    ) {
      const definitionsPromise = dispatch(
        'CHECK_AVAILABLE_ASSESSMENTS_BY_ATTRIBUTES',
        { userId, idList }
      )

      const tasksPromise = dispatch(
        'assessment/tasks/FETCH_ALL_BY_USER_ID',
        userId,
        ROOT
      )

      const [definitions, userTasks] = await Promise.all([
        definitionsPromise,
        tasksPromise
      ])

      const definitionsIds = (definitions.list || []).map(
        item => item.assessmentDefinitionId
      )
      const userTasksIds = (userTasks.list || []).map(
        item => item.assessmentDefinitionId
      )

      const definitionsIdsToCreate = definitionsIds.filter(
        id => !userTasksIds.includes(id)
      )

      if (!definitionsIdsToCreate.length) return

      const promises = definitionsIdsToCreate.map(id =>
        dispatch(
          'assessment/tasks/CREATE_ONE',
          {
            AssessmentDefinitionId: id,
            UserAssessmentStatusId: TASK_STATUSES.NEW
          },
          ROOT
        )
      )

      const result = await Promise.all(promises)
      const createdTasks = result.map(res => res.item)

      commit('SET_INVITATION_DIALOG', {
        list: createdTasks,
        isVisible: true
      })

      await dispatch('notifications/FETCH_ALL', {}, ROOT)
    },

    async CHECK_AVAILABLE_ASSESSMENTS_BY_ATTRIBUTES(_, { userId, idList }) {
      let result = {}

      try {
        result = await $api.assessment.definitions.checkAvailableByAttribute({
          userId,
          idList
        })
        const { error } = result

        if (error) {
          notify.error({ text: error })
        }
      } catch (error) {
        notify.error({ text: error })
      }

      return result
    }
  },

  mutations: {
    SET_LIST(state, list) {
      state.definitions.list = list || []
    },
    UPDATE_LIST_ITEM(state, { id, toUpdate }) {
      state.definitions.list = state.definitions.list.map(item =>
        item.id === id ? { ...item, ...toUpdate } : item
      )
    },
    SET_SELECTED(state, selected) {
      state.definitions.selected = selected
    },
    SET_IS_LOADING(state, isLoading) {
      state.definitions.isLoading = isLoading
    },

    UPDATE_FILTERS(state, toUpdate) {
      state.definitions.filters = { ...state.definitions.filters, ...toUpdate }
    },

    SET_FILES_LIST(state, list) {
      state.definitions.files.list = list
    },
    SET_FILES_IS_LOADING(state, isLoading) {
      state.definitions.files.isLoading = isLoading
    },

    SET_INVITATION_DIALOG(state, { list = [], isVisible = false }) {
      state.invitationDialog = { list, isVisible }
    }
  }
}
