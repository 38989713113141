<template>
  <v-tabs
    class="BaseProfilePrincipalTabs"
    :class="{
      'BaseProfilePrincipalTabs__w-screen-tabs': normalized.isShowArrows
    }"
    :value="activeIndex"
    :vertical="normalized.isCollapsed"
    :show-arrows="normalized.isShowArrows"
    color="primary"
  >
    <v-tab
      v-for="tab of tabs"
      :key="tab.icon"
      class="w-full flex items-center justify-start"
      @change="() => handleSelect(tab)"
    >
      <v-badge v-if="tab.badge" inline :content="tab.badge" color="accent">
        <div class="w-full gap-2 flex items-center">
          <v-icon left class="mr-2">{{ tab.icon }}</v-icon>
          <span>{{ tab.text }}</span>
        </div>
      </v-badge>

      <div v-else class="w-full gap-2 flex items-center">
        <v-icon left>{{ tab.icon }}</v-icon>
        <span>{{ tab.text }}</span>
      </div>
    </v-tab>
  </v-tabs>
</template>

<script>
export default {
  name: 'BaseProfilePrincipalTabs',

  props: {
    value: { type: [String, Number], required: true },
    tabs: { type: Array, required: true },

    isVertical: { type: Boolean, default: false }
  },

  computed: {
    activeIndex() {
      return this.tabs.findIndex(tab => tab.code === this.value)
    },

    normalized() {
      const isVertical = this.isVertical || this.$breakpoints.isTabletAndDown

      return {
        isCollapsed: !isVertical,
        isShowArrows: isVertical
      }
    }
  },

  methods: {
    handleSelect(tab) {
      this.$emit('select', tab)
    }
  }
}
</script>

<style lang="scss">
.BaseProfilePrincipalTabs {
  &__w-screen-tabs {
    .v-slide-group__wrapper {
      width: 1px;
    }
  }
}
</style>
