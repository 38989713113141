import axios from 'axios'

const BASE_URL = `/api/uris`
const URLs = new Proxy(
  {
    FETCH_ALL_USER_URI_TYPES: `/get-all-user-uri-types`,

    FETCH_ALL_BY_PRINCIPLE: '/get-all-by-principal/',

    SAVE_ONE: `/save`,
    DELETE_ONE: `/delete/`
  },
  { get: (target, prop) => `${BASE_URL}${target[prop]}` }
)

export default {
  async fetchAllUserUriTypes() {
    const res = await axios.get(URLs.FETCH_ALL_USER_URI_TYPES)

    const { content: list, isSuccess, error } = res

    const normalizedList = isSuccess ? list || [] : []

    return { list: normalizedList, isSuccess, error }
  },

  async fetchAllByPrincipal({ principalId, userTypeCode }) {
    const res = await axios.get(
      `${URLs.FETCH_ALL_BY_PRINCIPLE}${principalId}/${userTypeCode}`
    )

    const { content: list, isSuccess, error } = res

    const normalizedList = isSuccess ? list || [] : []

    return { list: normalizedList, isSuccess, error }
  },

  async saveOne(payload) {
    const res = await axios.put(URLs.SAVE_ONE, payload)

    const { isSuccess, error } = res

    return { isSuccess, error }
  },

  async deleteOne({ principalId, userTypeCode, id }) {
    const res = await axios.delete(
      `${URLs.DELETE_ONE}${principalId}/${userTypeCode}/${id}`
    )

    const { isSuccess, error } = res

    return { isSuccess, error }
  }
}
