import axios from 'axios'

import { formatListResponse } from '@/utils/api'

const BASE_URL = `/api/healthcare-experience`
const URLs = new Proxy(
  {
    FETCH_ALL_MEDICAL_SPECIALTIES: '/get-all-medical-specialities',
    FETCH_ALL_USER_MEDICAL_SPECIALTIES: '/get-user-medical-specialities/',

    FETCH_ALL_MEDICAL_SUB_SPECIALTIES: '/get-medical-sub-specialities',

    FETCH_ALL_USER_HOSPITAL_AFFILIATIONS: '/get-user-hospital-affiliations/'
  },
  { get: (target, prop) => `${BASE_URL}${target[prop]}` }
)

export default {
  async fetchAllMedicalSpecialties() {
    const res = await axios.get(URLs.FETCH_ALL_MEDICAL_SPECIALTIES)

    return formatListResponse(res)
  },
  async fetchAllUserMedicalSpecialties({ principalId, userTypeCode }) {
    const res = await axios.get(
      `${URLs.FETCH_ALL_USER_MEDICAL_SPECIALTIES}${principalId}/${userTypeCode}`
    )

    return formatListResponse(res)
  },
  async fetchAllMedicalSubSpecialties(payload) {
    const res = await axios.post(
      URLs.FETCH_ALL_MEDICAL_SUB_SPECIALTIES,
      payload
    )

    return formatListResponse(res)
  },

  async fetchAllUserHospitalAffiliations({ principalId, userTypeCode }) {
    const res = await axios.get(
      `${URLs.FETCH_ALL_USER_HOSPITAL_AFFILIATIONS}${principalId}/${userTypeCode}`
    )

    return formatListResponse(res)
  }
}
