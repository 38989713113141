import axios from 'axios'

import {
  formatResponse,
  formatListResponse,
  formatItemResponse
} from '@/utils/api'

const BASE_URL = `/api/user-assessment`
const URLs = new Proxy(
  {
    // FETCH_ALL: `/get-all`,
    FETCH_ALL_BY_USER_ID: `/get-by-user-id/`,
    FETCH_ALL_BY_DEFINITION_ID: `/get-by-definition-id-paged/`,
    FETCH_ONE_BY_ID: `/get-by-id/`,

    // FETCH_ONE_BY_USER_ID_AND_DEFINITION_ID: `/get-by-user-id-and-definition-id/`,

    CREATE_ONE: '/create',
    UPDATE_ONE: '/update',
    DELETE_ONE: '/delete/'

    // FETCH_FILES_ALL_BY_ID: '/get-all-files/'
    // ADD_FILE_ONE: '/add-file'
    // DELETE_FILE_ONE: '/delete-file/',
  },
  { get: (target, prop) => `${BASE_URL}${target[prop]}` }
)

export default {
  async fetchAllByUserId(userId) {
    const res = await axios.get(`${URLs.FETCH_ALL_BY_USER_ID}${userId}`)

    return formatListResponse(res)
  },
  // async fetchOneByUserIdAndDefinitionId({ userId, definitionId }) {
  //   const res = await axios.get(
  //     `${URLs.FETCH_ONE_BY_USER_ID_AND_DEFINITION_ID}${userId}${definitionId}`
  //   )
  //   const { content: list, isSuccess, error } = res

  //   return { list: res.content, isSuccess, error }
  // },

  async fetchAllByDefinitionId({ definitionId, filters }) {
    const res = await axios.post(`${URLs.FETCH_ALL_BY_DEFINITION_ID}`, {
      assessmentDefinitionId: definitionId,
      hasAttempts: filters.isAttempts,
      readyForReview: filters.isReadyAttempts,
      pageNumber: filters.offset / filters.limit + 1,
      batchSize: filters.limit,
      userName: filters.userName,
      statusCode: filters.statusCode
    })

    const { content, isSuccess, error } = res

    return {
      list: content.results || [],
      totalCount: content.totalCount,
      isSuccess,
      error
    }
  },

  async fetchOneById(taskId) {
    const res = await axios.get(`${URLs.FETCH_ONE_BY_ID}${taskId}`)

    return formatItemResponse(res)
  },

  async createOne(payload) {
    const res = await axios.post(`${URLs.CREATE_ONE}`, payload)

    return formatItemResponse(res)
  },
  async updateOne(payload) {
    const res = await axios.put(`${URLs.UPDATE_ONE}`, payload)

    return formatItemResponse(res)
  },
  async deleteOne(id) {
    const res = await axios.delete(`${URLs.DELETE_ONE}${id}`)

    return formatResponse(res)
  }

  // async fetchFilesAllByTaskId(taskId) {
  //   const res = await axios.get(`${URLs.FETCH_FILES_ALL_BY_ID}${taskId}`)
  //   const { content: list, isSuccess, error } = res

  //   const normalizedList = isSuccess ? list || [] : []

  //   return { list: normalizedList, isSuccess, error }
  // }
  // async addFileOne(payload) {
  //   const res = await axios.post(`${URLs.ADD_FILE_ONE}`, payload)
  //   const { isSuccess, error } = res

  //   return { isSuccess, error }
  // }
}
