import Vue from 'vue'
import FileSaver from 'file-saver'

import * as services from '@/services'
import * as notify from '@/utils/notify'
import { defineFileNameByResponse } from '@/utils/base'

import {
  OPERATORS,
  FIELD_KINDS,
  SEARCH_TYPES,
  BASE_POSSIBLE_FIELDS
} from '@/utils/member-finder'

const SORT_DIRECTION = Object.freeze({
  ASCENDING: 'asc',
  DESCENDING: 'desc'
})

const ATTRIBUTES_KEY = 'attributes'
const DEFAULT_LIMIT = 50

const defaultEmptyGroup = Object.freeze({
  operator: OPERATORS.names.AND,
  fields: []
})

const defaultFilters = Object.freeze({
  sortColumn: 'createdAt',
  isSortAscending: false,
  offset: 0,
  limit: DEFAULT_LIMIT,
  isMembers: true,
  isExport: false,

  search: {
    operator: OPERATORS.names.AND,
    groups: [structuredClone(defaultEmptyGroup)]
  }
})

const defaultAiFilters = Object.freeze({ query: '' })

const defineSearchPayloadFromFilters = filters => {
  const defineIsAttrKind = k => k === FIELD_KINDS.names.ATTRIBUTE_PICKER
  const defineValues = v => (Array.isArray(v) ? v : [v])

  const { operator, groups } = filters.search

  return {
    sortColumn: filters.sortColumn,
    sortDirection: filters.isSortAscending
      ? SORT_DIRECTION.ASCENDING
      : SORT_DIRECTION.DESCENDING,
    offset: filters.offset,
    limit: filters.limit,
    export: filters.isExport,
    operator,
    groups: groups
      .map(group => {
        const attrFields = group.fields
          .filter(field => defineIsAttrKind(field.kind))
          .map(field => ({
            key: ATTRIBUTES_KEY,
            values: defineValues(field.value)
          }))

        const otherFields = group.fields
          .filter(field => !defineIsAttrKind(field.kind))
          .map(field => ({
            key: field.key,
            values: defineValues(field.value)
          }))

        const fieldsWithValues = [...otherFields, ...attrFields].filter(field =>
          Boolean(field.values.length)
        )

        return {
          operator: group.operator,
          fields: fieldsWithValues
        }
      })
      .filter(group => Boolean(group.fields.length))
  }
}

const defineFiltersFromSearchPayload = (payload, searchPossibleFields) => {
  const searchPossibleAttributesFields = Object.values(
    searchPossibleFields
  ).filter((_, key) => Number.isInteger(key))

  const defineFieldByAttributeId = attrId =>
    searchPossibleAttributesFields.find(field =>
      field.list.find(attr => attr.id === attrId)
    )

  const filters = {
    sortColumn: payload.sortColumn,
    isSortAscending: payload.sortDirection === SORT_DIRECTION.ASCENDING,
    offset: payload.offset,
    limit: payload.limit,
    isMembers: payload.searchTypeId === SEARCH_TYPES.codes.MEMBERS,
    isExport: payload.export,

    search: {
      operator: OPERATORS.nameByCode[payload.operator],
      groups: payload.groups.map(g => {
        return {
          operator: OPERATORS.nameByCode[g.operator],
          fields: g.fields.reduce((result, f) => {
            const isAttributes = f.key === ATTRIBUTES_KEY

            if (isAttributes) {
              const attrId = f.values[0]
              const field = defineFieldByAttributeId(attrId)

              result.push({ ...field, value: f.values })

              return result
            }

            const field = searchPossibleFields[f.key]

            result.push({
              ...field,
              value: Array.isArray(field.value) ? f.values : f.values[0]
            })

            return result
          }, [])
        }
      })
    }
  }

  if (!filters.search.groups.length) {
    filters.search.groups.push(defaultEmptyGroup)
  }

  return filters
}

export default {
  namespaced: true,

  state: {
    memberFinder: {
      content: null,
      isLoading: false,

      rows: {
        list: [],
        selected: []
      },

      ui: {
        isMapVisible: false,
        isMembers: true,
        isAiFilters: false,
        warnings: []
      },

      filters: structuredClone(defaultFilters),
      defaultFilters: structuredClone(defaultFilters),

      aiFilters: structuredClone(defaultAiFilters),
      aiDefaultFilters: structuredClone(defaultAiFilters),

      searchPossibleFields: { ...BASE_POSSIBLE_FIELDS }
    }
  },

  getters: {
    fieldsByTypes: state =>
      Object.values(state.memberFinder.searchPossibleFields).reduce(
        (result, field) => {
          result[field.kind] = [...(result[field.kind] || []), field]
          return result
        },
        {}
      ),
    defineSearchPayloadFromFilters: () => defineSearchPayloadFromFilters,
    defineFiltersFromSearchPayload: state => payload =>
      defineFiltersFromSearchPayload(
        payload,
        state.memberFinder.searchPossibleFields
      )
  },

  actions: {
    async FETCH_CONTENT({ commit, state }) {
      await commit('SET_FILTERS_OFFSET', 0)

      const filters = state.memberFinder.filters

      await Promise.all([
        commit('SET_IS_LOADING', true),
        commit('SET_DEFAULT_FILTERS', filters),
        commit('SET_CONTENT', null),
        commit('SET_ROWS_LIST', []),
        commit('SET_ROWS_SELECTED', []),
        commit('SET_UI_IS_MEMBERS', filters.isMembers)
      ])

      const payload = defineSearchPayloadFromFilters(filters)

      const { content } = await services.memberFinder.searchGrouped({
        payload,
        isMembers: filters.isMembers
      })

      await Promise.all([
        commit('SET_CONTENT', content),
        commit('SET_ROWS_LIST', content?.rows),
        commit('SET_IS_LOADING', false)
      ])

      return content
    },

    async SEARCH_GROUPED_ROWS_ONLY({ commit, state }) {
      const { offset, limit } = state.memberFinder.filters

      await Promise.all([
        commit('SET_FILTERS_OFFSET', offset + DEFAULT_LIMIT),
        commit('SET_FILTERS_LIMIT', limit)
      ])

      const filters = state.memberFinder.filters

      commit('SET_DEFAULT_FILTERS', filters)

      const payload = defineSearchPayloadFromFilters(filters)

      const { content } = await services.memberFinder.searchGroupedRowsOnly({
        payload,
        isMembers: filters.isMembers
      })

      commit('APPEND_TO_ROWS_LIST', content?.rows || [])

      return content
    },

    async EXPORT_GROUPED({ state }) {
      const filters = { ...state.memberFinder.filters, isExport: true }

      const payload = defineSearchPayloadFromFilters(filters)
      const res = await services.memberFinder.exportGrouped({
        payload,
        isMembers: filters.isMembers
      })

      if (res) {
        const fileName = defineFileNameByResponse(res)

        if (fileName === 'Unknown.dat') {
          notify.warning({ text: 'Sorry, the requested file was not found.' })
        } else {
          const url = URL.createObjectURL(new Blob([res.data]))

          FileSaver.saveAs(url, fileName)
        }
      }

      return res
    },

    async FETCH_SEARCH_POSSIBLE_FIELDS({ commit }) {
      const { list } = await services.memberFinder.fetchOneFilterData({
        filterName: 'attributeFilters'
      })

      const fields = list.reduce((result, item) => {
        result[item.attributeCategoryCode] = {
          key: item.attributeCategoryCode,
          kind: FIELD_KINDS.names.ATTRIBUTE_PICKER,
          label: item.label,
          value: FIELD_KINDS.valueByName[FIELD_KINDS.names.ATTRIBUTE_PICKER],
          list: item.items,
          position: item.ordinalPosition || 0
        }

        return result
      }, {})

      await Promise.all([commit('UPDATE_SEARCH_POSSIBLE_FIELDS', fields)])
      return list
    },

    async CREATE_ONE_QUEUE_USER_SEARCH({ commit, state }) {
      const aiFilters = state.memberFinder.aiFilters

      commit('SET_AI_DEFAULT_FILTERS', aiFilters)

      return services.ai.createOneQueueUserSearch(aiFilters)
    }
  },

  mutations: {
    SET_CONTENT(state, content) {
      state.memberFinder.content = content
    },
    SET_IS_LOADING(state, isLoading) {
      state.memberFinder.isLoading = isLoading
    },

    SET_ROWS_LIST(state, list) {
      state.memberFinder.rows.list = list
    },
    APPEND_TO_ROWS_LIST(state, list) {
      state.memberFinder.rows.list.push(...list)
    },
    SET_ROWS_SELECTED(state, selected) {
      state.memberFinder.rows.selected = selected
    },

    SET_UI_IS_MEMBERS(state, isMembers) {
      state.memberFinder.ui.isMembers = isMembers
    },
    SET_UI_IS_MAP_VISIBLE(state, isMapVisible) {
      state.memberFinder.ui.isMapVisible = isMapVisible
    },
    SET_UI_IS_AI_FILTERS(state, isAiFilters) {
      state.memberFinder.ui.isAiFilters = isAiFilters
    },
    SET_UI_WARNINGS(state, warnings) {
      state.memberFinder.ui.warnings = warnings
    },

    SET_FILTERS_SORT_COLUMN(state, sortColumn) {
      state.memberFinder.filters.sortColumn = sortColumn
    },
    SET_FILTERS_IS_SORT_ASCENDING(state, isSortAscending) {
      state.memberFinder.filters.isSortAscending = isSortAscending
    },
    SET_FILTERS_IS_MEMBERS(state, isMembers) {
      state.memberFinder.filters.isMembers = isMembers
    },
    SET_FILTERS_OFFSET(state, offset) {
      state.memberFinder.filters.offset = offset
    },
    SET_FILTERS_LIMIT(state, limit) {
      state.memberFinder.filters.limit = limit
    },

    SET_FILTERS(state, filters) {
      state.memberFinder.filters = filters
    },
    RESET_FILTERS(state) {
      state.memberFinder.filters = structuredClone(defaultFilters)
      state.memberFinder.defaultFilters = structuredClone(defaultFilters)
    },

    SET_DEFAULT_FILTERS(state, defaultFilters) {
      state.memberFinder.defaultFilters = structuredClone(defaultFilters)
    },

    ADD_FILTERS_SEARCH_GROUP(state) {
      state.memberFinder.filters.search.groups.push({
        ...structuredClone(defaultEmptyGroup)
      })
    },
    REMOVE_FILTERS_SEARCH_GROUP(state, { groupIndex }) {
      state.memberFinder.filters.search.groups.splice(groupIndex, 1)
    },
    TOGGLE_FILTERS_SEARCH_OPERATOR(state) {
      const search = state.memberFinder.filters.search
      const operator =
        search.operator === OPERATORS.names.AND
          ? OPERATORS.names.OR
          : OPERATORS.names.AND

      Vue.set(search, 'operator', operator)
    },
    TOGGLE_FILTERS_SEARCH_GROUP_OPERATOR(state, { groupIndex }) {
      const group = state.memberFinder.filters.search.groups[groupIndex]
      const operator =
        group.operator === OPERATORS.names.AND
          ? OPERATORS.names.OR
          : OPERATORS.names.AND

      Vue.set(group, 'operator', operator)
    },

    ADD_FILTERS_SEARCH_GROUP_FIELD(state, { groupIndex, field }) {
      state.memberFinder.filters.search.groups[groupIndex].fields.unshift(field)
    },
    UPDATE_FILTERS_SEARCH_GROUP_FIELD(
      state,
      { groupIndex, fieldIndex, toUpdate }
    ) {
      state.memberFinder.filters.search.groups[groupIndex].fields =
        state.memberFinder.filters.search.groups[groupIndex].fields.map(
          (field, index) =>
            index === fieldIndex ? { ...field, ...toUpdate } : field
        )
    },
    REMOVE_FILTERS_SEARCH_GROUP_FIELD(state, { groupIndex, fieldIndex }) {
      state.memberFinder.filters.search.groups[groupIndex].fields =
        state.memberFinder.filters.search.groups[groupIndex].fields.filter(
          (_, index) => index !== fieldIndex
        )
    },

    SET_AI_FILTERS(state, aiFilters) {
      state.memberFinder.aiFilters = aiFilters
    },

    SET_AI_DEFAULT_FILTERS(state, aiDefaultFilters) {
      state.memberFinder.aiDefaultFilters = structuredClone(aiDefaultFilters)
    },

    UPDATE_SEARCH_POSSIBLE_FIELDS(state, fields) {
      state.memberFinder.searchPossibleFields = {
        ...state.memberFinder.searchPossibleFields,
        ...fields
      }
    }
  }
}
