import $api from '@/api'

import * as notify from '@/utils/notify'

const groupBy = (items, key) =>
  items.reduce(
    (result, item) => ({
      ...result,
      [item[key]]: [...(result[item[key]] || []), item]
    }),
    {}
  )

export default {
  namespaced: true,

  state: {
    rates: {
      list: [],
      isLoading: false
    }
  },

  getters: {
    isCreation: state => {
      return state.rates.list.some(item => !item.id)
    },

    normalizedList: state => {
      const filteredList = state.rates.list.filter(
        item => item.serviceName || !item.id
      )
      const groupedBy = groupBy(filteredList, 'serviceName')

      return groupedBy
    }
  },

  actions: {
    async FETCH_ALL({ commit }, userId) {
      commit('SET_IS_LOADING', true)

      let result = {}

      try {
        result = await $api.rates.fetchAllByUserId(userId)
        const { list, isSuccess, error } = result

        if (error) {
          notify.error({ text: error })
        } else {
          commit('SET_LIST', isSuccess ? list : [])
        }
      } catch (error) {
        notify.error({ text: error })
      } finally {
        commit('SET_IS_LOADING', false)
      }

      return result
    },

    async CREATE_ONE({ commit, dispatch }, { payload, userId }) {
      commit('SET_IS_LOADING', true)

      let result = {}

      try {
        payload = { UserId: userId, ...payload }

        result = await $api.rates.createOne(payload)
        const { item, error } = result

        if (error) {
          notify.error({ text: error })
        } else {
          const successMessage = `The rate "${item.serviceName}" was created`
          notify.success({ text: successMessage })
          dispatch('FETCH_ALL', userId)
        }
      } catch (error) {
        notify.error({ text: error })
      } finally {
        commit('SET_IS_LOADING', false)
      }

      return result
    },

    async UPDATE_ONE({ commit, dispatch }, { payload, userId }) {
      commit('SET_IS_LOADING', true)

      let result = {}

      try {
        payload = { UserId: userId, ...payload }

        result = await $api.rates.updateOne(payload)
        const { item, error } = result

        if (error) {
          notify.error({ text: error })
        } else {
          const successMessage = `The rate "${item.serviceName}" was updated`
          notify.success({ text: successMessage })
          dispatch('FETCH_ALL', userId)
        }
      } catch (error) {
        notify.error({ text: error })
      } finally {
        commit('SET_IS_LOADING', false)
      }

      return result
    },

    async DELETE_ONE({ commit, dispatch }, { id, userId }) {
      commit('SET_IS_LOADING', true)

      let result = {}

      try {
        result = await $api.rates.deleteOne(id)
        const { error } = result

        if (error) {
          notify.error({ text: error })
        } else {
          notify.success({ text: 'The rate was deleted' })
          dispatch('FETCH_ALL', userId)
        }
      } catch (error) {
        notify.error({ text: error })
      } finally {
        commit('SET_IS_LOADING', false)
      }

      return result
    }
  },

  mutations: {
    SET_LIST(state, list) {
      state.rates.list = list
    },

    CREATE_EMPTY_LIST_ITEM(state) {
      state.rates.list.unshift({})
    },
    CLEAR_UNSAVED_LIST_ITEMS(state) {
      state.rates.list = state.rates.list.filter(i => i.id)
    },

    SET_IS_LOADING(state, isLoading) {
      state.rates.isLoading = isLoading
    }
  }
}
