import axios from 'axios'

import { formatListResponse } from '@/utils/api'

const BASE_URL = `/api/submissions`
const URLs = new Proxy(
  {
    FETCH_BY_ASSIGNEE: `/get-by-assignee/`,
    FETCH_ALL_BY_USER_ID_AND_ASSIGNMENT_ID: `/get-by-user-and-assignment/`,

    ASSIGNEE_UPLOAD: `/assignee-upload/`
  },
  { get: (target, prop) => `${BASE_URL}${target[prop]}` }
)

export default {
  async fetchByAssigneeAndAssignment({ assigneeId, assignmentId }) {
    const res = await axios.get(
      `${URLs.FETCH_BY_ASSIGNEE}${assigneeId}/${assignmentId}`
    )

    const { content, isSuccess, error } = res

    return { content, isSuccess, error }
  },

  async fetchAllByUserIdAndAssignmentId({ userId, assignmentId }) {
    const res = await axios.get(
      `${URLs.FETCH_ALL_BY_USER_ID_AND_ASSIGNMENT_ID}${userId}/${assignmentId}`
    )

    return formatListResponse(res)
  },

  async assigneeUpload({ assigneeId, payload }) {
    const res = await axios.post(
      `${URLs.ASSIGNEE_UPLOAD}${assigneeId}`,
      payload
    )

    const { content, isSuccess, error } = res

    return { content, isSuccess, error }
  }
}
