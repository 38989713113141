import Vue from 'vue'

import ScrollLock from '@/directives/v-scroll-lock'
import AutoFocus from '@/directives/v-autofocus'
// import ClickOutside from '@/directives/v-click-outside'
import Resize from '@/directives/v-resize'
import Loader from '@/directives/v-loader'
import FixedLayout from '@/directives/v-fixed-layout'
import TrackVisibility from '@/directives/v-track-visibility'

Vue.directive('scroll-lock', ScrollLock)
Vue.directive('autofocus', AutoFocus)
// Vue.directive('click-outside', ClickOutside)
Vue.directive('resize', Resize)
Vue.directive('loader', Loader)
Vue.directive('fixed-layout', FixedLayout)
Vue.directive('track-visibility', TrackVisibility)
