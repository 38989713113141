export const COLORS = Object.freeze({
  PRIMARY: '#1976D2',
  SECONDARY: '#D27519',
  ACCENT: '#82B1FF',
  ERROR: '#FF5252',
  INFO: '#2196F3',
  SUCCESS: '#4CAF50',
  WARNING: '#FFC107',

  GOLD: '#ffc000'
})
