import $api from '@/api'

import * as notify from '@/utils/notify'
import * as services from '@/services'

export default {
  namespaced: true,

  actions: {
    async SEND_VERIFICATION_CODE(_, { textByType, ...payload }) {
      let result = {}

      try {
        result = await $api.mfa.sendVerificationCode(payload)
        const { error } = result

        if (error) {
          notify.error({ text: error })
          return
        }

        notify.info({
          title: 'Verification code sent',
          text: `Please check the ${textByType.via}`
        })
      } catch (error) {
        notify.error({ text: error })
      }

      return result.isSuccess
    },

    async CHECK_VERIFICATION_CODE(_, payload) {
      const result = await services.mfa.checkVerificationCode(payload)

      if (result.error) return

      return true
    },

    async VERIFY_AND_AUTHENTICATE({ commit, dispatch }, payload) {
      const result = await services.mfa.verifyAndAuthenticate(payload)

      if (result.error) return

      const { content: { token: accessToken, refreshTokenExpiresAt } = {} } =
        result

      await Promise.all([
        commit(
          'auth/SET_ACCESS_TOKEN',
          { accessToken, refreshTokenExpiresAt },
          { root: true }
        ),
        commit('auth/SET_PASSWORD', null, { root: true })
      ])

      await dispatch('auth/FETCH_ONE_USER_BY_ACCESS_TOKEN', null, {
        root: true
      })

      return true
    }
  }
}
