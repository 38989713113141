import * as services from '@/services'

export default {
  namespaced: true,

  state: {
    locations: {
      list: [],
      current: null,
      isLoading: false
    }
  },

  actions: {
    async FETCH_ALL_BY_HUB_ID({ rootState, commit }, userId) {
      userId = userId || rootState.user.user.id

      await commit('SET_IS_LOADING', true)

      const result = await services.hubLocations.fetchAllByHubId({
        id: userId
      })
      const { list } = result

      await Promise.all([
        commit('SET_ADDRESSES_LIST', list),
        commit('SET_ADDRESSES_CURRENT', list[0]),
        commit('SET_IS_LOADING', false)
      ])

      return result
    },

    async UPDATE_ONE({ commit }, payload) {
      await commit('SET_IS_LOADING', true)

      const result = await services.hubLocations.updateOne(payload)
      const { list } = result

      await Promise.all([
        commit('SET_ADDRESSES_CURRENT', list[0]),
        commit('SET_IS_LOADING', false)
      ])

      return result
    }
  },

  mutations: {
    SET_ADDRESSES_LIST(state, list) {
      state.locations.list = list
    },
    SET_ADDRESSES_CURRENT(state, current) {
      state.locations.current = current
    },
    SET_IS_LOADING(state, isLoading) {
      state.locations.isLoading = isLoading
    }
  }
}
