<template>
  <div v-if="$slots.default" class="w-full flex flex-initial grey lighten-4">
    <slot />
  </div>

  <v-card v-else class="flex-shrink-0">
    <v-card-text class="w-full pt-4">
      <BaseButtons class="justify-end" :buttons="buttons" />
    </v-card-text>
  </v-card>
</template>

<script>
import BaseButtons from '@/components/Base/BaseButtons.vue'

export default {
  name: 'BaseDialogFooter',

  components: { BaseButtons },

  props: {
    buttons: { type: Array, default: () => [] }
  }
}
</script>
