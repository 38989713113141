<template>
  <div class="AppHeaderNotifs flex">
    <v-tooltip v-if="!isHub" bottom>
      <template #activator="{ on, attrs }">
        <v-badge overlap color="transparent" :value="assessments.isBadge">
          <v-btn
            icon
            :color="notifs.isLoading ? '' : 'black'"
            :loading="notifs.isLoading"
            v-bind="attrs"
            v-on="on"
            @click="handleOpenAssessments"
          >
            <v-icon
              :style="{ color: !notifs.tasks.isSome ? 'silver' : 'black' }"
            >
              {{ notifs.tasks.isSome ? 'mdi-school' : 'mdi-school-outline' }}
            </v-icon>
          </v-btn>

          <template #badge>
            <div class="badge pulse pointer-events-none">
              <span class="font-bold">
                {{ notifs.tasks.availableCount }}
              </span>
            </div>
          </template>
        </v-badge>
      </template>

      <div class="flex flex-col">
        <span>{{ assessments.title }}</span>
      </div>
    </v-tooltip>

    <v-tooltip bottom>
      <template #activator="{ on, attrs }">
        <v-badge overlap color="transparent" :value="notifications.isBadge">
          <v-btn
            icon
            exact-path
            :color="notifs.isLoading ? '' : 'black'"
            :loading="notifs.isLoading"
            :disabled="!notifications.isBadge"
            v-bind="attrs"
            v-on="on"
            @click="handleOpenDialog"
          >
            <v-icon>
              {{ notifs.isSome ? 'mdi-email-open' : 'mdi-email-outline' }}
            </v-icon>
          </v-btn>

          <template #badge>
            <div class="badge pulse pointer-events-none">
              <span class="font-bold">{{ notifications.count }}</span>
            </div>
          </template>
        </v-badge>
      </template>

      <span v-html="notifications.title"></span>
    </v-tooltip>

    <AppHeaderNotifsSidebar
      v-if="dialog.isVisible"
      @close="handleCloseDialog"
    />
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'

import * as notify from '@/utils/notify'
import signalr from '@/mixins/signalr'

import { CONNECTIONS } from '@/utils/signalr'
import { TABS as MEMBER_TABS } from '@/components/Tenant/Member/MemberProfile/MemberProfile.vue'

import AppHeaderNotifsSidebar from '@/components/App/AppHeader/AppHeaderNotifsSidebar/AppHeaderNotifsSidebar.vue'

export default {
  name: 'AppHeaderNotifs',

  components: { AppHeaderNotifsSidebar },

  mixins: [signalr([CONNECTIONS.UPDATE_NOTIFICATIONS, 'handleRefresh'])],

  data: () => ({
    dialog: {
      isVisible: false
    }
  }),

  computed: {
    ...mapState('user', ['user']),
    ...mapState('memberNotifications', {
      notifs: 'notifications'
    }),
    ...mapGetters('user', ['isHub']),

    assessments() {
      const {
        isLoading,
        tasks: { isSomeAvailable, availableCount }
      } = this.notifs

      return {
        isBadge: (isSomeAvailable && !isLoading) || false,
        title: isSomeAvailable
          ? `${availableCount} Available Assessment(s)`
          : '(No assessments available)'
      }
    },

    notifications() {
      const { isSome, count, isLoading } = this.notifs

      return {
        count,
        isBadge: (isSome && !isLoading && count > 0) || false,
        title: isSome ? `${count} Notification(s)` : 'All caught up!'
      }
    }
  },

  watch: {
    '$isOn.principalPage': {
      immediate: true,
      handler: 'handleRefresh'
    }
  },

  created() {
    this.$signalr_callServerMethodWithInterval({
      name: 'NewMessage',
      runImmediately: true,
      payload: {
        principalId: this.user.id,
        userTypeCode: this.user.userTypeCode,
        messageTypeCode: 1,
        assignmentId: null
        // tenantIdList: this.user.tenants.map(item => item.id)
      },
      duration: 120000
    })
  },

  methods: {
    ...mapActions('memberNotifications', {
      fetchAll: 'FETCH_ALL'
    }),

    handleRefresh() {
      this.fetchAll()
    },

    handleOpenAssessments() {
      this.$router.push({
        name: 't.member.profile.tab',
        params: { tabCode: MEMBER_TABS.codes.ASSESSMENTS }
      })
    },

    handleOpenDialog() {
      if (!this.notifs.isSome) {
        notify.info({ title: 'All caught up!' })
        return
      }

      this.dialog.isVisible = true
    },
    handleCloseDialog() {
      this.dialog.isVisible = false
    }
  }
}
</script>

<style lang="scss">
.AppHeaderNotifs {
  .v-badge__badge {
    background-color: transparent !important;
  }
}
</style>

<style lang="scss" scoped>
.badge {
  position: absolute;
  top: 100%;
  left: 50%;

  height: 1rem;
  width: 1rem;

  text-align: center;
  font-size: 0.75rem;
  line-height: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: theme('colors.accent');

  border-radius: 100%;

  transform: translate(-90%, -50%);

  z-index: 1;
}

.pulse {
  animation: pulse-featured-artist-notification-icon-red 3s 5s;
  animation-iteration-count: 3;
}

@keyframes pulse-featured-artist-notification-icon-red {
  0% {
    box-shadow: 0 0 0 0 theme('colors.accent');
  }

  70% {
    box-shadow: 0 0 0 1rem rgba(0, 0, 0, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}
</style>
