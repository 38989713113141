<template>
  <div>
    <div class="w-full h-full gap-4 px-4 py-2 flex items-center">
      <v-checkbox
        class="m-0 p-0"
        :input-value="isSelected"
        hide-details
        dense
        @change="handleSelect"
      />

      <div class="w-full gap-4 flex items-center">
        <slot />
      </div>
    </div>

    <v-divider />
  </div>
</template>

<script>
export default {
  name: 'BasePaginatedTableContentListItem',

  props: {
    table: { type: Object, required: true },
    storePath: { type: String, required: true },

    item: { type: Object, required: true },
    index: { type: Number, required: true }
  },

  computed: {
    selected() {
      return this.table.rows.selected
    },

    isSelected() {
      return this.selected?.some(selected => selected.id === this.item.id)
    }
  },

  methods: {
    async handleSelect() {
      if (!this.isSelected) {
        this.setRowsSelected([...this.selected, this.item])
        return
      }

      this.setRowsSelected(
        this.selected.filter(item => item.id !== this.item.id)
      )
    },

    setRowsSelected(payload) {
      return this.commit('SET_ROWS_SELECTED', payload)
    },

    commit(name, payload) {
      return this.$store.commit(`${this.storePath}/${name}`, payload, {
        root: true
      })
    }
  }
}
</script>
