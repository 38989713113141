import $api from '@/api'
import * as notify from '@/utils/notify'

export const fetchAll = async (...args) => {
  let result = {}

  try {
    result = await $api.attributeCategories.fetchAll(...args)
    const { error } = result

    if (error) {
      notify.error({ text: error })
    }
  } catch (error) {
    notify.error({ text: error })
  }

  return result
}

export const deleteOne = async payload => {
  let result = {}

  try {
    result = await $api.attributeCategories.deleteOne(payload)
    const { error } = result

    if (error) {
      notify.error({ text: error })
    }
  } catch (error) {
    notify.error({ text: error })
  }

  return result
}
