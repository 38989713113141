import $api from '@/api'
import * as notify from '@/utils/notify'

export const fetchAll = async ({ userId }) => {
  let result = {}

  try {
    result = await $api.memberNotifications.fetchAll({
      userId
    })
    const { error } = result

    if (error) {
      notify.error({ text: error })
    }
  } catch (error) {
    notify.error({ text: error })
  }

  return result
}

export const fetchAllByUser = async user => {
  let result = {}

  const { id: userId, userTypeCode } = user
  const payload = { userId, userTypeCode }

  try {
    result = await $api.memberNotifications.fetchAllByUser(payload)
    const { error } = result

    if (error) {
      notify.error({ text: error })
    }
  } catch (error) {
    notify.error({ text: error })
  }

  return result
}
