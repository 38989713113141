import { generateConnection } from '@/utils/base'

export const RATE_UNITS_OF_MEASURE = Object.freeze({
  DAILY: { name: 'Daily', id: 3 },
  PROJECT: { name: 'Project', id: 4 },
  HOURLY: { name: 'Hourly', id: 5 }
})

export const POSITIONS = Object.freeze({
  TOP_LEFT: { key: 'left top', text: 'top left' },
  TOP_CENTER: { key: 'center top', text: 'top center' },
  TOP_RIGHT: { key: 'right top', text: 'top right' },
  CENTER_LEFT: { key: 'left center', text: 'center left' },
  CENTER_CENTER: { key: 'center center', text: 'dead center' },
  CENTER_RIGHT: { key: 'right center', text: 'center right' },
  BOTTOM_LEFT: { key: 'left bottom', text: 'bottom left' },
  BOTTOM_CENTER: { key: 'center bottom', text: 'bottom center' },
  BOTTOM_RIGHT: { key: 'right bottom', text: 'bottom right' }
})

export const STYLE_BY_POSITIONS = Object.freeze({
  [POSITIONS.TOP_LEFT.key]: {
    flexPosition: 'items-start justify-start',
    transformOrigin: 'left top'
  },
  [POSITIONS.TOP_CENTER.key]: {
    flexPosition: 'items-center justify-start',
    transformOrigin: 'center top'
  },
  [POSITIONS.TOP_RIGHT.key]: {
    flexPosition: 'items-end justify-start',
    transformOrigin: 'right top'
  },
  [POSITIONS.CENTER_LEFT.key]: {
    flexPosition: 'items-start justify-center',
    transformOrigin: 'left center'
  },
  [POSITIONS.CENTER_CENTER.key]: {
    flexPosition: 'items-center justify-center',
    transformOrigin: 'center center'
  },
  [POSITIONS.CENTER_RIGHT.key]: {
    flexPosition: 'items-end justify-center',
    transformOrigin: 'right center'
  },
  [POSITIONS.BOTTOM_LEFT.key]: {
    flexPosition: 'items-start justify-end',
    transformOrigin: 'left bottom'
  },
  [POSITIONS.BOTTOM_CENTER.key]: {
    flexPosition: 'items-center justify-end',
    transformOrigin: 'center bottom'
  },
  [POSITIONS.BOTTOM_RIGHT.key]: {
    flexPosition: 'items-end justify-end',
    transformOrigin: 'right bottom'
  }
})

export const USER_TYPES = (() => {
  const CODES = Object.freeze({
    MEMBER: 1,
    HUB: 2,
    HUB_CONTACT: 3
  })

  const NAMES = Object.freeze({
    MEMBER: 'member',
    HUB: 'hub',
    HUB_CONTACT: 'hub-contact'
  })

  const LABELS = Object.freeze({
    MEMBER: 'Individual',
    HUB: 'Company',
    HUB_CONTACT: 'Hub Contact'
  })

  return Object.freeze({
    codes: CODES,
    names: NAMES,
    labels: LABELS,

    labelByCode: generateConnection(LABELS, CODES),
    labelByName: generateConnection(LABELS, NAMES),
    nameByCode: generateConnection(NAMES, CODES),
    codeByName: generateConnection(CODES, NAMES)
  })
})()

export const USER_STATUSES = (() => {
  const CODES = Object.freeze({
    UNKNOWN: 0,
    ACTIVE: 1,
    PAUSED: 2,
    DELETED: 3,
    PENDING: 4,
    DENIED: 5,
    INCOMPLETE: 6
  })

  const LABELS = Object.freeze({
    UNKNOWN: 'Unknown',
    ACTIVE: 'Active',
    PAUSED: 'Paused',
    DELETED: 'Deleted',
    PENDING: 'Pending',
    DENIED: 'Denied',
    INCOMPLETE: 'Incomplete'
  })

  const COLORS = Object.freeze({
    UNKNOWN: 'grey',
    ACTIVE: 'green',
    PAUSED: 'orange',
    DELETED: 'red',
    PENDING: 'orange',
    DENIED: 'red',
    INCOMPLETE: 'grey'
  })

  const ICONS = Object.freeze({
    UNKNOWN: 'mdi-account-question-outline',
    ACTIVE: 'mdi-account-check',
    PAUSED: 'mdi-account-cancel',
    DELETED: 'mdi-account-alert',
    PENDING: 'mdi-account-clock-outline',
    DENIED: 'mdi-account-remove',
    INCOMPLETE: 'mdi-account-edit-outline'
  })

  return Object.freeze({
    codes: CODES,
    labels: LABELS,
    colors: COLORS,
    icons: ICONS,

    labelByCode: generateConnection(LABELS, CODES),
    colorByCode: generateConnection(COLORS, CODES),
    iconByCode: generateConnection(ICONS, CODES)
  })
})()

export const MFA_TYPES = Object.freeze({
  BY_PHONE_SMS: 1,
  BY_PHONE_VOICE: 2,
  BY_EMAIL: 3
})

export const LS_KEYS = Object.freeze({
  ACCESS_TOKEN: 'auth-access-token',
  REFRESH_TOKEN_EXPIRES_AT: 'auth-refresh-token-expires-at',

  ADMIN_TENANTS_SELECTED: 'admin-tenants-selected'
})

export const FILE_CATEGORIES = (() => {
  const CODES = Object.freeze({
    PORTFOLIO: 1,
    WORK_SAMPLE: 2,
    OTHER: 3,
    RESPONSE: 4,
    CHALLENGE: 5,
    CREATIVE_BRIEF: 6,
    PRODUCT_PHOTO: 7,
    AVATAR: 9,
    CLIENT_LIST: 10,
    RESUME: 11,
    AGREEMENT: 12,
    INVOICE: 13,
    ASSESSMENT_DEFINITION: 14,
    USER_ASSESSMENT: 15,
    FOOTER_LOGO: 16,
    FAVICON: 17,
    LOGO: 18
  })

  const LABELS = Object.freeze({
    PORTFOLIO: 'Portfolio',
    WORK_SAMPLE: 'Work Sample',
    OTHER: 'Other',
    RESPONSE: 'Response',
    CHALLENGE: 'Challenge',
    CREATIVE_BRIEF: 'Creative Brief',
    PRODUCT_PHOTO: 'Product Photo',
    AVATAR: 'Avatar',
    CLIENT_LIST: 'Client List',
    RESUME: 'Resume',
    AGREEMENT: 'Agreement',
    INVOICE: 'Invoice',
    ASSESSMENT_DEFINITION: 'Assessment Definition',
    USER_ASSESSMENT: 'User Assessment'
  })

  return Object.freeze({
    codes: CODES,
    labels: LABELS
  })
})()

export const HUB_URI_TYPES = (() => {
  const CODES = Object.freeze({
    UNKNOWN: 0,
    COMPANY_OVERVIEW: 1,
    CASE_STUDY: 2,
    SOCIAL_NETWORK: 3,
    CLIENT_LIST: 4
  })

  return Object.freeze({
    codes: CODES
  })
})()

export const PAYABLE_STATUSES = (() => {
  const CODES = Object.freeze({
    UNKNOWN: 0,
    SUBMITTED: 1,
    APPROVED: 2,
    REJECTED: 3,
    QUEUED_FOR_PAYMENT: 4,
    PAYMENT_COMPLETE: 5
  })

  const LABELS = Object.freeze({
    UNKNOWN: 'Unknown',
    SUBMITTED: 'Submitted by payee',
    APPROVED: 'Invoice approved',
    REJECTED: 'Invoice rejected',
    QUEUED_FOR_PAYMENT: 'Queued for payment',
    PAYMENT_COMPLETE: 'Payment complete'
  })

  const COLORS = Object.freeze({
    UNKNOWN: 'gray',
    SUBMITTED: 'warning',
    APPROVED: 'success',
    REJECTED: 'error',
    QUEUED_FOR_PAYMENT: 'info',
    PAYMENT_COMPLETE: 'success'
  })

  return Object.freeze({
    codes: CODES,
    labels: LABELS,
    colors: COLORS,

    colorByCode: generateConnection(COLORS, CODES)
  })
})()

export const REVIEW_STATUSES = (() => {
  const CODES = Object.freeze({
    UNREVIEWED: 1,
    ACCEPTED: 2,
    ELIMINATED: 3,
    REWORK: 4,
    MOVE_TO_NEXT_STAGE: 5
  })

  const LABELS = Object.freeze({
    UNREVIEWED: 'Not yet reviewed',
    ACCEPTED: 'Accepted',
    ELIMINATED: 'Eliminated from competition',
    REWORK: 'Requires rework (changes)',
    MOVE_TO_NEXT_STAGE: 'Move to the next stage'
  })

  return Object.freeze({
    codes: CODES,
    labels: LABELS
  })
})()

export const DEFAULT_BUSINESS_HOURS = Object.freeze({
  sunday: [{ open: '24hrs', close: '24hrs', id: '1', isOpen: true }],
  monday: [{ open: '24hrs', close: '24hrs', id: '2', isOpen: true }],
  tuesday: [{ open: '24hrs', close: '24hrs', id: '3', isOpen: true }],
  wednesday: [{ open: '24hrs', close: '24hrs', id: '4', isOpen: true }],
  thursday: [{ open: '24hrs', close: '24hrs', id: '5', isOpen: true }],
  friday: [{ open: '24hrs', close: '24hrs', id: '6', isOpen: true }],
  saturday: [{ open: '24hrs', close: '24hrs', id: '7', isOpen: true }]
})

export const BUSINESS_HOURS_LOCALIZATION = Object.freeze({
  switchOpen: 'Available ',
  switchClosed: ' ',
  placeholderOpens: 'Start ',
  placeholderCloses: 'End ',
  addHours: '+ ',
  open: {
    invalidInput:
      "Please enter a start time in 12 hour format (e.g. 08:00 AM). You may also enter '24h'. ",
    greaterThanNext: 'Please enter a start time that is before the end time. ',
    lessThanPrevious:
      'Please enter a start time that is after the previous end time. ',
    midnightNotLast:
      "Midnight can only be selected for the day's last end time."
  },
  close: {
    invalidInput:
      "Please enter an end time in 12 hour format (e.g. 05:00 PM). You may also enter '24h' or 'Midnight'. ",
    greaterThanNext: 'Please enter an end time that is after the start time. ',
    lessThanPrevious:
      'Please enter an end time that is before the next start time. ',
    midnightNotLast:
      "Midnight can only be selected for the day's last end time."
  },
  t24hours: '24h ',
  midnight: 'Midnight ',
  days: {
    monday: 'Mon. ',
    tuesday: 'Tues. ',
    wednesday: 'Weds. ',
    thursday: 'Thurs. ',
    friday: 'Fri. ',
    saturday: 'Sat. ',
    sunday: 'Sun. ',
    newYearsEve: "New Year's Eve ",
    newYearsDay: "New Year's Day ",
    martinLutherKingJrDay: 'Martin Luther King, Jr. Day ',
    presidentsDay: "Presidents' Day ",
    easter: 'Easter ',
    memorialDay: 'Memorial Day ',
    independenceDay: 'Independence Day ',
    fourthOfJuly: '4th of July ',
    laborDay: 'Labor Day ',
    columbusDay: 'Columbus Day ',
    veteransDay: 'Veterans Day ',
    thanksgivingDay: 'Thanksgiving Day ',
    christmasEve: 'Christmas Eve ',
    christmas: 'Christmas '
  }
})

export const RELATIVE_PAGE_WIDTHS = Object.freeze([
  { text: '1/8 (12.5%)', value: 0.125 },
  { text: '1/6 (16.67%)', value: 0.167 },
  { text: '1/5 (20%)', value: 0.2 },
  { text: '1/4 (25.0%)', value: 0.25 },
  { text: '1/3 (33.34%)', value: 0.34 },
  { text: '1/2 (50.0%)', value: 0.5 },
  { text: '2/3 (66.67%)', value: 0.67 },
  { text: '3/4 (75.0%)', value: 0.75 },
  { text: '1.0 (100%)', value: 1 }
])

export const VUETIFY_TABLE_FOOTER_PROPS = Object.freeze({
  itemsPerPageOptions: [25, 50, 100, 250, 500, 1000, -1]
})

export const CONTENT_ITEM_EDITOR_IMAGE_TYPES = Object.freeze({
  PRIMARY: 'primary',
  ROLLOVER: 'rollover',
  SECONDARY: 'secondary'
})

export const DEFINITION_ACTOR_ROLES = Object.freeze({
  PROMOTER: 1,
  SUBMISSION_REVIEWER: 2,
  ASSESSMENT_REVIEWER: 3
})

export const WORK_CATEGORIES = Object.freeze({
  OTHER: { key: -1, text: 'Other' },
  UNKNOWN: { key: 0, text: 'Unknown' },
  LAUNCH: { key: 1, text: 'Launch' },
  REBRAND: { key: 2, text: 'Rebrand' },
  LOGO: { key: 3, text: 'Logo' }
})

export const SHORT_MONTHS = Object.freeze([
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sept',
  'Oct',
  'Nov',
  'Dec'
])
export const LONG_MONTHS = Object.freeze([
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
])

export const INTEREST_INDICATORS = (() => {
  const CODES = Object.freeze({
    NO_RESPONSE: 0,
    INTERESTED: 1,
    INTERESTED_BUT_UNAVAILABLE: 2,
    UNAVAILABLE: 3,
    UNINTERESTED: 4
  })

  const LABELS = Object.freeze({
    NO_RESPONSE: 'No response yet',
    INTERESTED: "I'm interested and available",
    INTERESTED_BUT_UNAVAILABLE: "I'm interested but unavailable",
    UNAVAILABLE: "I'm unavailable",
    UNINTERESTED: "I'm not interested"
  })

  const COLORS = Object.freeze({
    NO_RESPONSE: 'secondary',
    INTERESTED: 'primary',
    INTERESTED_BUT_UNAVAILABLE: 'error',
    UNAVAILABLE: 'error',
    UNINTERESTED: 'error'
  })

  return Object.freeze({
    codes: CODES,
    labels: LABELS,
    colors: COLORS,

    labelByCode: generateConnection(LABELS, CODES),
    colorByCode: generateConnection(COLORS, CODES)
  })
})()

export const ASSIGNMENT_STATUSES = (() => {
  const CODES = Object.freeze({
    NEW: 1,
    IN_RECRUITMENT: 2,
    ASSIGNED: 3,
    READY_FOR_REVIEW: 4,
    IN_REVIEW: 5,
    REWORK: 6,
    CANCELLED: 7,
    ON_HOLD: 8,
    COMPLETE: 9
  })

  const LABELS = Object.freeze({
    NEW: 'New',
    IN_RECRUITMENT: 'In Recruitment',
    ASSIGNED: 'Assigned',
    READY_FOR_REVIEW: 'Ready for Review',
    IN_REVIEW: 'In Review',
    REWORK: 'Rework',
    CANCELLED: 'Cancelled',
    ON_HOLD: 'On Hold',
    COMPLETED: 'Completed'
  })

  return Object.freeze({
    codes: CODES,
    labels: LABELS,

    labelByCode: generateConnection(LABELS, CODES)
  })
})()

export const CONTRACT_TYPES = (() => {
  const CODES = Object.freeze({
    NDA: 1,
    CA: 2
  })

  const MARKERS = Object.freeze({
    NDA: 'nda',
    CA: 'consulting'
  })

  const LABELS = Object.freeze({
    NDA: 'Non-Disclosure Agreement',
    CA: 'Consulting Agreement'
  })

  return Object.freeze({
    codes: CODES,
    markers: MARKERS,
    labels: LABELS,

    labelByMarker: generateConnection(LABELS, MARKERS),
    labelByCode: generateConnection(LABELS, CODES)
  })
})()

export const CONTRACT_CONTENT_TYPES = (() => {
  const CODES = Object.freeze({
    DEFAULT: 1,
    USA_CALIFORNIA: 2
  })

  const LABELS = Object.freeze({
    DEFAULT: 'Default HTML content',
    USA_CALIFORNIA: 'HTML content for consultants in California, USA'
  })

  return Object.freeze({
    codes: CODES,
    labels: LABELS,

    labelByCode: generateConnection(LABELS, CODES)
  })
})()

export const TENANT_MARKERS = (() => {
  const CODES = Object.freeze({
    CP: 'crowdpharm',
    HIH: 'hot-iron-health'
  })

  const LABELS = Object.freeze({
    CP: 'CrowdPharm',
    HIH: 'Hot Iron Health'
  })

  return Object.freeze({
    codes: CODES,
    labels: LABELS,

    labelByCode: generateConnection(LABELS, CODES)
  })
})()

export const CONTRACT_TAGS = (() => {
  const CODES = Object.freeze({
    DATE_DAY: '[DATE_DAY]',
    DATE_MONTH: '[DATE_MONTH]',
    DATE_YEAR: '[DATE_YEAR]',
    DATE_FULL: '[DATE_FULL]',
    ENTITY_NAME: '[ENTITY_NAME]',
    ENTITY_ADDRESS_FULL: '[ENTITY_ADDRESS_FULL]',
    BUSINESS_ENTITY: '[BUSINESS_ENTITY]',
    COMPANY_DESCRIPTION: '[COMPANY_DESCRIPTION]',
    COMPANY_DESIGNEE: '[COMPANY_DESIGNEE]',
    EMPLOYEE_OR_AGENT_NAME: '[EMPLOYEE_OR_AGENT_NAME]'
  })

  const EXAMPLES = Object.freeze({
    DATE_DAY: '18',
    DATE_MONTH: 'October',
    DATE_YEAR: '2023',
    DATE_FULL: 'October 18, 2023',
    ENTITY_NAME: 'Michael Myers',
    ENTITY_ADDRESS_FULL: '4600 Madison Avenue, Suite 1500, Kansas City, MO 641',
    BUSINESS_ENTITY: 'Independent Contractor',
    COMPANY_DESCRIPTION: 'CrowdPharm, LLC',
    COMPANY_DESIGNEE: '',
    EMPLOYEE_OR_AGENT_NAME: ''
  })

  return Object.freeze({
    codes: CODES,
    examples: EXAMPLES,

    exampleByCode: generateConnection(EXAMPLES, CODES)
  })
})()

export const RATING_TIERS = (() => {
  const CODES = Object.freeze({
    UNRATED: 0,
    DO_NOT_USE: 1,
    ACCEPTABLE: 2,
    GOOD: 3,
    VERY_GOOD: 4,
    OUTSTANDING: 5
  })

  const NAMES = Object.freeze({
    UNRATED: 'Unrated',
    DO_NOT_USE: 'DoNotUse',
    ACCEPTABLE: 'Acceptable',
    GOOD: 'Good',
    VERY_GOOD: 'VeryGood',
    OUTSTANDING: 'Outstanding'
  })

  const LABELS = Object.freeze({
    UNRATED: 'Unrated',
    DO_NOT_USE: 'Do not use',
    ACCEPTABLE: 'Acceptable',
    GOOD: 'Good',
    VERY_GOOD: 'Very good',
    OUTSTANDING: 'Outstanding'
  })

  return Object.freeze({
    codes: CODES,
    names: NAMES,
    labels: LABELS,

    labelByCode: generateConnection(LABELS, CODES)
  })
})()

export const ASSESSMENT_TYPES = (() => {
  const CODES = Object.freeze({
    UNKNOWN: 0,
    PDF: 1,
    FORM: 2,
    HTML: 3
  })

  const LABELS = Object.freeze({
    UNKNOWN: 'Unknown',
    PDF: 'PDF-based',
    FORM: 'Form-based',
    HTML: 'HTML-based'
  })

  return Object.freeze({
    codes: CODES,
    labels: LABELS,

    labelByCode: generateConnection(LABELS, CODES)
  })
})()

export const ATTEMPT_STATUSES = (() => {
  const CODES = Object.freeze({
    NEW: 1,
    IN_PROGRESS: 2,
    PAUSED: 3,

    COMPLETED: 4,
    CANCELLED: 5,
    EXPIRED: 6,
    ABANDONED: 8,
    SUPERSEDED: 9,

    SKIPPED: 7
  })

  const LABELS = Object.freeze({
    NEW: 'New',
    IN_PROGRESS: 'In Progress',
    PAUSED: 'Paused',

    COMPLETED: 'Completed',
    CANCELLED: 'Cancelled',
    EXPIRED: 'Expired',
    ABANDONED: 'Abandoned',
    SUPERSEDED: 'Superseded',

    SKIPPED: 'Skipped'
  })

  return Object.freeze({
    codes: CODES,
    labels: LABELS,

    labelByCode: generateConnection(LABELS, CODES)
  })
})()

export const ADDRESS_TYPES = (() => {
  const CODES = Object.freeze({
    UNKNOWN: 0,
    PRIMARY: 1
  })

  return Object.freeze({
    codes: CODES
  })
})()

export const TENANT_LIST_ITEM_ACTIONS = Object.freeze({
  // Admin Dashboard Attribute Rules
  ADAR: 'adar',
  DETAILS: 'details'
})
