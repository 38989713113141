export default {
  namespaced: true,

  state: {
    ui: {
      attrsDragConfig: {
        categoryId: null,
        fromBranch: null,
        toBranch: null
      }
    }
  },

  mutations: {
    SET_UI_ATTRS_DRAG_CONFIG(state, config) {
      state.ui.attrsDragConfig = config
    },
    UPDATE_UI_ATTRS_DRAG_CONFIG(state, toUpdate) {
      state.ui.attrsDragConfig = { ...state.ui.attrsDragConfig, ...toUpdate }
    }
  }
}
