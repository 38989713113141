import axios from 'axios'

const BASE_URL = `/api/user-profile`
const URLs = new Proxy(
  {
    UPDATE_ONE: '/update',
    UPDATE_HEALTHCARE_EXPERIENCE: '/update-healthcare-experience'
  },
  { get: (target, prop) => `${BASE_URL}${target[prop]}` }
)

export default {
  async updateOne(payload) {
    const res = await axios.put(URLs.UPDATE_ONE, payload)

    const { content, isSuccess, error } = res
    const { user, address } = content || {}

    return { user, address, isSuccess, error }
  },

  async updateHealthcareExperience(payload) {
    const res = await axios.put(URLs.UPDATE_HEALTHCARE_EXPERIENCE, payload)

    const { content: user, isSuccess, error } = res

    return { user, isSuccess, error }
  }
}
