export default {
  namespaced: true,

  state: {
    expandedListNames: [],
    selectedStates: [],
    selectedCountries: [],
    selectedProfessions: []
  },

  getters: {
    selectedProfessions(state) {
      return state.selectedProfessions
    },
    selectedStates(state) {
      return state.selectedStates
    },
    selectedCountries(state) {
      return state.selectedCountries
    },
    isFilteredToList: state => model => {
      if (!model || !model.key || !model.listName) {
        return false
      }

      return state[model.listName].find(s => s === model.key)
    },
    isListExpanded: state => moniker => {
      return state.expandedListNames.find(item => item === moniker)
    }
  },

  mutations: {
    setList(state, model) {
      if (!model || !model.value || !model.listName) return

      state[model.listName] = model.value
    },
    filterToList(state, model) {
      if (!model || !model.key || !model.listName) return

      if (!state[model.listName].find(s => s === model.key)) {
        state[model.listName].push(model.key)
      } else {
        state[model.listName] = state[model.listName].filter(
          s => s !== model.key
        )
      }
    },
    expandList(state, model) {
      if (!model || !model.moniker) {
        return false
      }

      if (state.expandedListNames.find(item => item === model.moniker)) {
        state.expandedListNames = state.expandedListNames.filter(
          item => item !== model.moniker
        )
        return false
      } else {
        state.expandedListNames.push(model.moniker)
        return true
      }
    }
  }
}
