import * as services from '@/services'

import { defineIsStatusAvailable } from '@/utils/assessments'

export default {
  namespaced: true,

  state: {
    notifications: {
      isSome: false,
      count: 0,

      isFeaturedArtist: false,

      invitees: {},
      assignmentsRequiringActions: {},
      tasks: {},

      isLoading: false
    }
  },

  actions: {
    async FETCH_ALL({ rootState, commit, dispatch }) {
      const user = rootState.user.user

      commit('SET_IS_LOADING', true)

      const result = await services.memberNotifications.fetchAllByUser(user)
      const { content, error } = result

      commit('SET_IS_LOADING', false)

      if (!error) {
        const payload = { detail: { content } }
        await dispatch('VALIDATE_AND_SET_NOTIFICATIONS', payload)
      }

      return result
    },

    VALIDATE_AND_SET_NOTIFICATIONS({ commit }, response) {
      if (!response || !response.detail || !response.detail.content) {
        console.debug('loadFromResponse: No content received')
        return
      }

      commit('SET_NOTIFICATIONS', response.detail.content)
    }
  },

  mutations: {
    SET_NOTIFICATIONS(state, notifs) {
      const invitees = notifs.invitees || []
      const inviteesCount = invitees.length

      const actions = notifs.assignmentsRequiringAction || []
      const actionsCount = actions.length

      const tasks = notifs.userAssessments || []
      const tasksCount = tasks.length
      const tasksAvailableCount = tasks.filter(item =>
        defineIsStatusAvailable(item.userAssessmentStatusId)
      ).length

      const expiredAttempts = notifs.expiredUserAssessmentAttempts || []
      const expiredAttemptsCount = expiredAttempts.length

      const notificationsCount =
        notifs.totalNotificationCount - tasksCount + expiredAttemptsCount

      state.notifications = {
        ...state.notifications,

        isSome: notificationsCount > 0,
        count: notificationsCount,

        isFeaturedArtist: notifs.isFeaturedArtist,

        invitees: {
          isSome: inviteesCount > 0,
          list: invitees,
          count: inviteesCount
        },

        assignmentsRequiringActions: {
          isSome: actionsCount > 0,
          list: actions,
          count: actionsCount
        },

        tasks: {
          isSome: tasksCount > 0,
          isSomeAvailable: tasksAvailableCount > 0,
          // list: tasks,
          count: tasksCount,
          availableCount: tasksAvailableCount
        },

        expiredAttempts: {
          isSome: expiredAttemptsCount > 0,
          list: expiredAttempts,
          count: expiredAttemptsCount
        }
      }
    },
    SET_IS_LOADING(state, isLoading) {
      state.notifications.isLoading = isLoading
    }
  }
}
