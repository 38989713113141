<template>
  <div class="px-4 py-2 bg-gray-200 rounded-none border-b border-gray-300">
    <div class="gap-4 flex items-center justify-between">
      <div class="flex flex-col text-black">
        <span v-if="headerTitle" class="text-lg font-semibold">{{
          headerTitle
        }}</span>
        <span v-if="headerSubtitle" class="text-sm">{{ headerSubtitle }}</span>
      </div>

      <div
        class="w-full flex justify-end items-center"
        style="max-width: 20rem"
      >
        <v-text-field
          v-model="filters.search"
          label="Search"
          background-color="white"
          clearable
          outlined
          dense
          hide-details
        />

        <div class="ml-4">
          <BaseMenu
            :is-visible="menu.isVisible"
            header-title="Professions"
            :buttons="menuButtons"
            @close="handleCloseMenu"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseMenu from '@/components/Base/BaseMenu.vue'

const defaultFilters = Object.freeze({
  search: ''
})

export default {
  name: 'AdminProfessionsHeader',

  components: { BaseMenu },

  props: {
    headerTitle: { type: String, default: '' },
    headerSubtitle: { type: String, default: '' },

    flatTreeList: { type: Array, default: () => [] },
    filteredFlatTreeList: { type: Array, default: () => [] }
  },

  data: () => ({
    menu: {
      isVisible: false
    },

    filters: structuredClone(defaultFilters)
  }),

  computed: {
    menuButtons() {
      return [
        {
          label: 'Add Profession',
          color: 'primary',
          icon: 'mdi-plus',
          handler: this.handleCreate
        }
      ]
    }
  },

  watch: {
    flatTreeList: {
      handler: 'defineFilteredTreeList'
    },
    filters: {
      deep: true,
      handler: 'defineFilteredTreeList'
    }
  },

  methods: {
    handleCreate() {
      this.$emit('create')

      this.handleClose()
    },

    handleCloseMenu() {
      this.menu.isVisible = false
    },

    defineFilteredTreeList() {
      if (!this.flatTreeList.length) {
        this.$emit('update:filtered-flat-tree-list', [])
      }

      const filteredFlatTreeList = this.flatTreeList.filter(item => {
        const search = this.filters.search

        if (search) {
          const isIncludes = item.searchValue
            .toLowerCase()
            .includes(search.toLowerCase().trim())

          if (!isIncludes) return false
        }

        return true
      })

      this.$emit('update:filtered-flat-tree-list', filteredFlatTreeList)
    },

    handleResetFilters() {
      this.filters = structuredClone(defaultFilters)
    }
  }
}
</script>
