<template>
  <v-card class="py-16 mx-auto text-center transparent" flat>
    <v-card-text class="pb-0">
      <v-icon class="display-3 grey--text text--lighten-1">
        mdi-{{ mdiIcon }}
      </v-icon>
    </v-card-text>
    <v-card-title class="pt-0 justify-center">{{ title }}</v-card-title>
    <v-card-subtitle class="flex flex-col">
      <span v-for="(text, index) of subtitle" :key="index">{{ text }}</span>
    </v-card-subtitle>

    <slot />
  </v-card>
</template>

<script>
export default {
  name: 'BaseEmpty',

  props: {
    title: { type: String, default: 'Oops!' },
    subtitle: { type: Array, default: () => [] },
    mdiIcon: { type: String, default: 'cloud-search-outline' }
  }
}
</script>
