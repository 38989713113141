<template>
  <v-form ref="form" class="flex flex-col" :disabled="isLoading || !isEditable">
    <v-text-field
      background-color="white"
      label="Name"
      placeholder="Name"
      maxlength="100"
      outlined
      dense
      :value="form.name"
      :rules="[$rules.required]"
      @input="value => updateForm({ name: value })"
    />

    <v-text-field
      data-test="street-address1"
      background-color="white"
      label="Address Line 1"
      placeholder="Address Line 1"
      name="address-line1"
      autocomplete="address-line1"
      maxlength="100"
      outlined
      dense
      :value="form.streetAddress1"
      :rules="[$rules.required]"
      @input="value => updateForm({ streetAddress1: value })"
    />

    <v-text-field
      data-test="street-address2"
      background-color="white"
      label="Address Line 2 (Optional)"
      placeholder="Address Line 2 (Optional)"
      name="address-line2"
      autocomplete="address-line2"
      maxlength="100"
      outlined
      dense
      :value="form.streetAddress2"
      @input="value => updateForm({ streetAddress2: value })"
    />

    <div class="w-full sm:gap-4 flex flex-col sm:flex-row">
      <v-text-field
        data-test="city-name"
        background-color="white"
        label="City Name"
        placeholder="City Name"
        name="city-name"
        outlined
        dense
        :value="form.cityName"
        :rules="[$rules.required]"
        @input="value => updateForm({ cityName: value })"
      />

      <v-text-field
        data-test="postal-code"
        background-color="white"
        label="Zip/Postal Code"
        placeholder="Zip/Postal Code"
        name="postal-code"
        maxlength="50"
        outlined
        dense
        :value="form.postalCode"
        :rules="[$rules.required]"
        @input="value => updateForm({ postalCode: value })"
      />
    </div>

    <v-autocomplete
      data-test="country"
      background-color="white"
      label="Country"
      placeholder="Select a Country"
      name="country"
      item-text="name"
      item-value="abbreviation"
      outlined
      dense
      :value="form.country"
      :items="countriesList"
      :rules="[$rules.required]"
      @input="handleSelectCountry"
    />

    <v-text-field
      v-if="isHasRegions"
      data-test="region"
      background-color="white"
      maxlength="50"
      label="Region"
      placeholder="Region (Optional)"
      name="region"
      outlined
      dense
      :value="form.regionName"
      @input="value => updateForm({ regionName: value })"
    />

    <v-autocomplete
      background-color="white"
      data-test="state"
      label="State/Province/Nation/Area"
      placeholder="Select a State/Province/Nation/Area"
      name="state"
      item-text="name"
      item-value="name"
      outlined
      dense
      :value="form.stateOrProvince"
      :items="provinces.list"
      :loading="provinces.isLoading"
      :disabled="!isHasProvinces"
      :rules="isHasProvinces ? [$rules.required] : []"
      @input="value => updateForm({ stateOrProvince: value })"
    />

    <div v-if="isEditable" class="w-full gap-4 flex justify-end">
      <v-btn
        v-if="!isCreation"
        small
        text
        color="grey"
        :loading="isLoading"
        @click="handleDelete"
      >
        <v-icon left>mdi-trash-can</v-icon>
        <span>Delete</span>
      </v-btn>

      <v-btn
        small
        color="primary"
        :loading="isLoading"
        :disabled="!isModified"
        @click="handleSubmit"
      >
        <v-icon left>{{
          isCreation ? 'mdi-plus' : 'mdi-content-save-outline'
        }}</v-icon>
        <span>{{ isCreation ? 'Create' : 'Save' }}</span>
      </v-btn>
    </div>
  </v-form>
</template>

<script>
import debounce from 'lodash/debounce'

import { ADDRESS_TYPES } from '@/utils/consts'

import * as notify from '@/utils/notify'
import * as services from '@/services'

import { defineIsEqual } from '@/utils/base'

const defaultEmptyForm = Object.freeze({
  name: '',
  streetAddress1: '',
  streetAddress2: '',
  cityName: '',
  stateOrProvince: '',
  regionName: '',
  postalCode: '',
  country: ''
})

export default {
  name: 'AdminTenantsCreationDialogAddressesItem',

  props: {
    tenant: { type: Object, required: true },
    address: { type: Object, default: null },
    countriesList: { type: Array, default: () => [] },
    isEditable: { type: Boolean, default: true }
  },

  data: () => ({
    form: { ...defaultEmptyForm },
    provinces: {
      list: [],
      isLoading: false
    },
    isLoading: false
  }),

  computed: {
    isCreation() {
      return !this.address.id
    },

    isModified() {
      return !defineIsEqual(this.defaultForm, this.form)
    },

    isCountrySelected() {
      return Boolean(this.form?.country)
    },

    isHasRegions() {
      return this.isCountrySelected && this.form?.country !== 'US'
    },

    isHasProvinces() {
      return this.isCountrySelected && this.provinces.list.length > 0
    }
  },

  created() {
    this.setInitialForm()
  },

  methods: {
    async handleSubmit() {
      let result = {}

      const isValid = this.defineIsValid()

      if (!isValid) return result

      const payload = this.definePayload()

      this.isLoading = true
      const { isSuccess } = await services.tenantAddresses.upsertOne(payload)
      this.isLoading = false

      if (isSuccess) {
        this.handleSuccess()
      }

      return result
    },

    handleSuccess() {
      this.$emit('refresh')
      notify.success({
        title: `Tenant address ${this.isCreation ? 'created' : 'saved'}`
      })
    },

    async handleDelete() {
      const text = `Are you sure you want to delete this tenant address?`
      const { isConfirmed } = await confirm.warning({ text })

      if (!isConfirmed) return

      const { isSuccess } = await services.tenantAddresses.deleteOne({
        id: this.address.id
      })

      if (!isSuccess) return

      notify.success({ title: 'Tenant address deleted' })
      this.$emit('refresh')
    },

    handleSelectCountry(country) {
      this.updateForm({ country, regionName: null, stateOrProvince: null })

      this.fetchProvincesByCountry(country)
    },

    fetchProvincesByCountry: debounce(async function () {
      this.provinces.isLoading = true

      const countryId = this.form.country
      const { isSuccess, list } = await services.geo.fetchProvincesByCountry(
        countryId
      )

      this.provinces.isLoading = false

      if (!isSuccess) return
      this.provinces.list = list

      const isEmpty = list.length === 0

      if (isEmpty) {
        this.provinces.list.push({ name: '(No States/Provinces)' })
        this.form.stateOrProvince = '(No States/Provinces)'
      }
    }, 500),

    updateForm(toUpdate) {
      this.form = { ...this.form, ...toUpdate }

      this.$emit('update-form', toUpdate)
    },

    defineIsValid() {
      const isValid = this.$refs.form.validate()

      if (!isValid) {
        notify.error({ title: 'Fill in all required fields' })
        return
      }

      return isValid
    },

    definePayload() {
      return {
        ...(this.address || {}),
        ...this.form,
        addressTypeId: ADDRESS_TYPES.codes.PRIMARY,
        tenantId: this.tenant.id,
        $type: undefined
      }
    },

    setInitialForm() {
      const isAddress = this.address?.cityName

      this.defaultForm = isAddress
        ? {
            name: this.address.name || '',
            streetAddress1: this.address.streetAddress1 || '',
            streetAddress2: this.address.streetAddress2 || '',
            cityName: this.address.cityName || '',
            stateOrProvince: this.address.stateOrProvince || '',
            regionName: this.address.regionName || '',
            postalCode: this.address.postalCode || '',
            country: this.address.country || ''
          }
        : structuredClone(defaultEmptyForm)

      this.form = structuredClone(this.defaultForm)

      if (this.address?.country) {
        this.fetchProvincesByCountry(this.address.country)
      }

      this.$nextTick(this.$refs.form?.resetValidation)
    }
  }
}
</script>
