<template>
  <div
    :data-test="branch.name.split(' ').join('-')"
    class="flex items-center select-none"
    :class="{
      'bg-white': isParent,
      'cursor-pointer': isClickable,
      'drag-start': ui.attrsDragConfig.fromBranch?.id === branch.id,
      'drag-over': ui.attrsDragConfig.toBranch?.id === branch.id
    }"
    :style="styles"
    :depth="branch.depth"
    :depth-inverted="branch.depthInverted"
    :draggable="isDraggable"
    @click="handleClick"
    @dragstart="handleDragstart"
    @dragover="handleDragover"
    @dragend="handleDragend"
    @drop="handleDrop"
  >
    <v-btn
      class="mb-auto mr-1"
      :class="{ invisible: !isParent }"
      icon
      small
      @click.stop="handleToggleCollapse"
    >
      <v-icon>{{ isCollapsed ? 'mdi-plus' : 'mdi-minus' }}</v-icon>
    </v-btn>

    <div class="w-full flex flex-nowrap items-end">
      <div class="w-full flex">
        <v-checkbox
          v-if="isSelectable"
          :input-value="isSelected"
          class="!p-0 !m-0 flex-shrink-0"
          :class="{ '!cursor-default': isCheckboxDisabled }"
          :indeterminate="isIndeterminate && !isSelected"
          :disabled="isCheckboxDisabled"
          dense
          hide-details
          @change="handleSelect"
          @click.native.stop
        />

        <span
          class="leaders after:bottom-[6px]"
          :class="{ 'font-bold': isParent || isCategory }"
        >
          <span>{{ branch.name }}</span>
          <span
            v-if="branch.description"
            class="ml-1 inline-flex italic font-normal"
            v-html="getBranchDescription(branch)"
          />
          <span
            v-if="isParent && isEditable"
            class="ml-1 inline-flex text-gray-500 font-medium"
          >
            ({{ allChildren.length }})
          </span>
        </span>

        <div v-if="isForAdmin" class="h-7 mt-auto inline-flex items-center">
          <v-tooltip
            v-if="isCategory && branch.forInternalUse"
            left
            color="info"
          >
            <template #activator="{ on, attrs }">
              <v-icon class="ml-1" small color="info" v-bind="attrs" v-on="on">
                mdi-lock-outline
              </v-icon>
            </template>

            <span class="text-white">Only for internal use</span>
          </v-tooltip>

          <v-tooltip
            v-if="isCategory && branch.allowsUserAdditions"
            left
            color="info"
          >
            <template #activator="{ on, attrs }">
              <v-icon class="ml-1" small color="info" v-bind="attrs" v-on="on">
                mdi-account-edit-outline
              </v-icon>
            </template>

            <span class="text-white">
              Users are allowed to create their own attributes
            </span>
          </v-tooltip>

          <v-tooltip
            v-if="isCategory && branch.allowsAdHocCreation"
            left
            color="info"
          >
            <template #activator="{ on, attrs }">
              <v-icon class="ml-1" small color="info" v-bind="attrs" v-on="on">
                mdi-tag-plus-outline
              </v-icon>
            </template>

            <span class="text-white">
              Users can add attributes via a special button in the tree
            </span>
          </v-tooltip>

          <v-tooltip
            v-if="isCategory && branch.areUserAdditionsShared"
            left
            color="info"
          >
            <template #activator="{ on, attrs }">
              <v-icon class="ml-1" small color="info" v-bind="attrs" v-on="on">
                mdi-account-multiple-outline
              </v-icon>
            </template>

            <span class="text-white">
              User-created attributes are visible to all other users
            </span>
          </v-tooltip>

          {{
            void (isCategoryQuantityLimit = defineIsCategoryQuantityLimit({
              category: branch,
              principal: user
            }))
          }}

          <v-tooltip
            v-if="isCategory && isCategoryQuantityLimit"
            left
            color="info"
          >
            <template #activator="{ on, attrs }">
              <v-icon class="ml-1" small color="info" v-bind="attrs" v-on="on">
                mdi-counter
              </v-icon>
            </template>

            {{
              void ({ minQuantity, maxQuantity } =
                defineCategoryMergedQuantityLimit({
                  category: branch,
                  principal: user
                }))
            }}

            <span class="text-white">
              Has a quantity limit — users can only add between
              <strong>{{ minQuantity }}</strong> and
              <strong>{{ maxQuantity }}</strong> values of this category to
              their profiles.
            </span>
          </v-tooltip>

          <v-tooltip
            v-if="isAttribute && branch.onlyForHubs"
            left
            color="orange"
          >
            <template #activator="{ on, attrs }">
              <v-icon
                class="ml-1"
                small
                color="orange"
                v-bind="attrs"
                v-on="on"
              >
                mdi-tire
              </v-icon>
            </template>

            <span class="text-white">Only for hubs</span>
          </v-tooltip>

          <v-tooltip
            v-if="isAttribute && branch.isUserSpecific"
            left
            color="info"
          >
            <template #activator="{ on, attrs }">
              <v-icon class="ml-1" small color="info" v-bind="attrs" v-on="on">
                mdi-account-edit
              </v-icon>
            </template>

            <span class="text-white">User specific</span>
          </v-tooltip>

          <BaseTenantAvatars
            v-if="branch.tenants?.length > 0"
            class="ml-1"
            :list="branch.tenants"
            is-small
            is-rtl
          />
        </div>
      </div>
    </div>

    <BaseMenu
      v-if="isEditable"
      :is-visible="menu.isVisible"
      :header-title="branch.name"
      :item="branch"
      :buttons="defineBranchMenuButtons(branch)"
      @close="handleCloseMenu"
    />
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

import * as confirm from '@/utils/confirm'
import {
  defineChildren,
  defineIsCategoryQuantityLimit,
  defineCategoryMergedQuantityLimit
} from '@/utils/attributes'
import * as services from '@/services'

import BaseMenu from '@/components/Base/BaseMenu.vue'
import BaseTenantAvatars from '@/components/Admin/Base/BaseTenantAvatars.vue'

export default {
  name: 'AttributesTreeBranchTwig',

  components: { BaseMenu, BaseTenantAvatars },

  inject: [
    'isEditable',
    'isSelectable',
    'isForAdmin',
    'defineChildren',
    'defineBranchMenuButtons'
  ],

  props: {
    branch: { type: Object, required: true },
    parent: { type: Object, default: null },

    selected: { type: Array, default: () => [] },
    selectedCategories: { type: Array, default: () => [] },

    isCollapsed: { type: Boolean, default: false },
    isIndeterminate: { type: Boolean, default: false }
  },

  data: () => ({
    menu: { isVisible: false }
  }),

  computed: {
    ...mapState('ui', ['ui']),
    ...mapState('user', ['user']),

    allChildren() {
      return defineChildren(this.branch)
    },
    isSelected() {
      const selected = this.isCategory ? this.selectedCategories : this.selected

      const isSelectedBranch = Boolean(
        selected.find(item => item.id === this.branch.id)
      )
      return isSelectedBranch
    },
    isParentEmpty() {
      return this.isParent && !this.branch.children?.length
    },
    isParent() {
      return (
        Boolean(this.branch.children) ||
        (this.branch.allowsUserAdditions && this.branch.allowsAdHocCreation)
      )
    },
    isCategory() {
      return !this.branch.attributeCategoryId
    },
    isAttribute() {
      return !this.isCategory
    },
    isClickable() {
      if (this.isCategory && !this.isParent) return false

      return this.isParent || this.isSelectable
    },

    isCheckboxDisabled() {
      const isCategorySelectAllowed = this.$route.name === 'admin.attributes'
      return this.isCategory && !isCategorySelectAllowed
    },

    isDraggable() {
      return this.isEditable && !this.isCategory && !this.isParent
    },

    isDroppable() {
      if (!this.isEditable) return false
      if (this.branch.depth >= 2) return false

      const categoryId = this.isCategory
        ? this.branch.id
        : this.branch.attributeCategoryId

      return categoryId === this.ui.attrsDragConfig.categoryId
    },

    styles() {
      return this.isParent
        ? {
            position: 'sticky',
            top: `${this.branch.depth * 36}px`,
            'z-index': this.branch.depthInverted,
            'min-height': '36px'
          }
        : ''
    }
  },

  created() {
    this.defineIsCategoryQuantityLimit = defineIsCategoryQuantityLimit
    this.defineCategoryMergedQuantityLimit = defineCategoryMergedQuantityLimit
  },

  methods: {
    ...mapMutations('ui', {
      setUiAttrsDragConfig: 'SET_UI_ATTRS_DRAG_CONFIG',
      updateUiAttrsDragConfig: 'UPDATE_UI_ATTRS_DRAG_CONFIG'
    }),

    getBranchDescription(branch) {
      return branch.description &&
        branch.description.length &&
        branch.description !== branch.name
        ? ` (${branch.description})`
        : ''
    },

    handleCloseMenu() {
      this.menu.isVisible = false
    },

    handleSelect() {
      this.$emit('select', this.branch)
    },

    handleClick() {
      if (!this.isClickable) return

      this.isParent ? this.handleToggleCollapse() : this.handleSelect()
    },
    handleToggleCollapse() {
      this.$emit('toggle-collapse')
    },

    handleDragstart() {
      this.updateUiAttrsDragConfig({
        categoryId: this.branch.attributeCategoryId,
        fromBranch: this.branch
      })
    },

    handleDragover(event) {
      if (this.isDroppable) {
        event.preventDefault()
      } else return

      if (this.ui.attrsDragConfig.toBranch?.id === this.branch.id) return

      this.updateUiAttrsDragConfig({ toBranch: this.branch })
    },

    async handleDrop() {
      const { fromBranch, toBranch } = this.ui.attrsDragConfig

      if (fromBranch?.id === toBranch?.id) return
      if (fromBranch?._uParentId === toBranch?._uId) return

      const isToBranchCategory = !toBranch.attributeCategoryId

      const text = `Move attribute '${fromBranch.name}' to new parent '${toBranch.name}'?`
      const { isConfirmed } = await confirm.warning({
        text
      })

      if (!isConfirmed) return

      await services.attributes.updateOne({
        ...fromBranch,
        parentId: isToBranchCategory ? null : toBranch.id
      })

      this.$emit('refresh')
    },

    handleDragend() {
      this.setUiAttrsDragConfig({ fromBranch: null, toBranch: null })
    }
  }
}
</script>

<style scoped>
.drag-start {
  background-color: gold;
  opacity: 0.5;
}
.drag-over {
  outline: 2px dashed black;
  background-color: rgba(100, 100, 100, 0.6);
}
</style>
