<template>
  <v-navigation-drawer
    v-scroll-lock
    :value="isVisible"
    :width="maxWidth"
    app
    temporary
    :right="isRight"
    @input="handleToggle"
  >
    <v-card class="min-h-full flex flex-col" flat>
      <BaseSidebarHeader
        v-if="headerTitle || $slots.header"
        :header-title="headerTitle"
        :is-loading="isLoading"
        :is-mobile-and-down="isMobileAndDown"
        :style="`z-index: ${OVERLAY_Z_INDEX + 1}`"
        @close="handleClose"
      >
        <slot name="header" />
      </BaseSidebarHeader>

      <v-card-text
        class="flex flex-col grey lighten-4"
        :class="[normalizedContentClass, { 'overflow-hidden': isLoading }]"
      >
        <slot />
      </v-card-text>

      <v-fade-transition>
        <v-overlay
          v-if="isLoading || $slots.overlay"
          absolute
          :z-index="OVERLAY_Z_INDEX"
        >
          <v-progress-circular
            v-if="isLoading"
            indeterminate
            size="64"
            color="grey lighten-4"
          />

          <slot v-else-if="$slots.overlay" name="overlay" />
        </v-overlay>
      </v-fade-transition>

      <BaseSidebarFooter
        v-if="footerButtons.length || $slots.footer"
        class="mt-auto"
        :buttons="footerButtons"
        :style="`z-index: ${OVERLAY_Z_INDEX + 1}`"
      >
        <slot name="footer" />
      </BaseSidebarFooter>
    </v-card>
  </v-navigation-drawer>
</template>

<script>
import BaseSidebarHeader from '@/components/Base/BaseSidebar/BaseSidebarHeader.vue'
import BaseSidebarFooter from '@/components/Base/BaseSidebar/BaseSidebarFooter.vue'

const OVERLAY_Z_INDEX = 12

export default {
  name: 'BaseSidebar',

  components: { BaseSidebarHeader, BaseSidebarFooter },

  props: {
    maxWidth: { type: [Number, String], default: 768 },
    contentClass: { type: String, default: '' },
    headerTitle: { type: String, default: '' },

    isRight: { type: Boolean, default: true },
    isLoading: { type: Boolean, default: false },

    footerButtons: { type: Array, default: () => [] },

    beforeClose: { type: [Function, null], default: null }
  },

  data: () => ({
    isVisible: false
  }),

  computed: {
    isMobileAndDown() {
      return this.$vuetify?.breakpoint?.name === 'xs'
    },

    normalizedContentClass() {
      const baseClass = this.isMobileAndDown ? '!p-4' : '!p-8'
      return [this.contentClass || baseClass]
    }
  },

  created() {
    this.OVERLAY_Z_INDEX = OVERLAY_Z_INDEX
  },

  mounted() {
    setTimeout(() => (this.isVisible = true))
  },

  methods: {
    handleToggle(value) {
      if (value) {
        this.isVisible = true
        return
      }

      this.handleClose()
    },

    handleClose() {
      setTimeout(() => {
        const isCloseable = !this.isLoading

        if (this.beforeClose) this.beforeClose(isCloseable)

        if (!isCloseable) return

        this.isVisible = false
        setTimeout(() => this.$emit('close'), 250)
      })
    },

    handleForceClose() {
      this.isVisible = false
      setTimeout(() => this.$emit('close'), 250)
    }
  }
}
</script>
