import axios from 'axios'

import { formatListResponse } from '@/utils/api'

const BASE_URL = `/api/hub-locations`
const URLs = new Proxy(
  {
    FETCH_ALL_BY_HUB_ID: `/get-by-hub/`,

    UPDATE_ONE: '/update'
  },
  { get: (target, prop) => `${BASE_URL}${target[prop]}` }
)

export default {
  async fetchAllByHubId({ id }) {
    const res = await axios.get(`${URLs.FETCH_ALL_BY_HUB_ID}${id}`)

    return formatListResponse(res)
  },
  async updateOne(payload) {
    const res = await axios.put(URLs.UPDATE_ONE, payload)

    return formatListResponse(res)
  }
}
