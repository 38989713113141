import axios from 'axios'

import { formatListResponse, formatResponse } from '@/utils/api'

const BASE_URL = `/api/user-search`
const URLs = new Proxy(
  {
    FETCH_ALL: '/get-all-grouped',
    FETCH_ONE_BY_ID: '/get-grouped-by-id/',

    CREATE_ONE: '/save-grouped-search',
    DELETE_ONE: `/delete/`
  },
  { get: (target, prop) => `${BASE_URL}${target[prop]}` }
)

export default {
  async fetchAll() {
    const res = await axios.get(URLs.FETCH_ALL)

    return formatListResponse(res)
  },
  async fetchOneById({ id }) {
    const res = await axios.get(`${URLs.FETCH_ONE_BY_ID}${id}`)

    return formatResponse(res)
  },

  async createOne(payload) {
    const res = await axios.post(URLs.CREATE_ONE, payload)

    return formatResponse(res)
  },

  async deleteOne({ id }) {
    const res = await axios.delete(`${URLs.DELETE_ONE}${id}`)

    return formatResponse(res)
  }
}
