<template>
  <v-sheet class="w-full flex flex-col rounded" outlined>
    <ProfessionsHeader
      ref="professions-header"
      class="rounded-t"
      header-title="Professions"
      header-subtitle="Manage professions here"
      :flat-tree-list="professionsFlatTreeList"
      :filtered-flat-tree-list.sync="professions.filteredFlatTreeList"
      @create="handleOpenCreationDialog"
    />

    <BaseTree
      class="h-px overflow-auto"
      :filtered-tree-list="professionsFilteredTreeList"
      :selected="selected"
      :is-loading="professions.isLoading"
      :define-children="defineChildren"
      :define-branch-menu-buttons="defineBranchMenuButtons"
      @reset-filters="() => $refs['professions-header'].handleResetFilters()"
      @update:selected="selected => $emit('update:selected', selected)"
    />

    <ProfessionsCreationDialog
      v-if="creationDialog.isVisible"
      v-bind="creationDialog"
      @success="handleSuccess"
      @close="handleCloseCreationDialog"
    />

    <ProfessionsManageDuplicatesDialog
      v-if="manageDuplicatesDialog.isVisible"
      :list="professions.list"
      :keeper="manageDuplicatesDialog.keeper"
      :losers="manageDuplicatesDialog.losers"
      @success="handleSuccess"
      @close="handleCloseManageDuplicatesDialog"
    />
  </v-sheet>
</template>

<script>
import * as services from '@/services'
import * as notify from '@/utils/notify'
import * as confirm from '@/utils/confirm'
import { defineTreeList, defineChildren } from '@/utils/tree'

import BaseTree from '@/components/Admin/Base/BaseTree/BaseTree.vue'
import ProfessionsHeader from '@/components/Admin/Professions/ProfessionsHeader.vue'
import ProfessionsCreationDialog from '@/components/Admin/Professions/ProfessionsCreationDialog.vue'
import ProfessionsManageDuplicatesDialog from '@/components/Admin/Professions/ProfessionsManageDuplicatesDialog.vue'

export default {
  name: 'AdminTheProfessions',

  components: {
    BaseTree,
    ProfessionsHeader,
    ProfessionsCreationDialog,
    ProfessionsManageDuplicatesDialog
  },

  props: {
    selected: { type: Array, default: () => [] }
  },

  data: () => ({
    professions: {
      list: [],
      filteredFlatTreeList: [],

      isLoading: false
    },

    creationDialog: {
      isVisible: false,
      isEditing: false,
      branch: null
    },

    manageDuplicatesDialog: {
      isVisible: false,
      keeper: null,
      losers: []
    }
  }),

  computed: {
    professionsFlatTreeList() {
      const professionsTreeList = defineTreeList(this.professions.list)
      return defineChildren({ children: professionsTreeList })
    },

    professionsFilteredTreeList() {
      return defineTreeList(this.professions.filteredFlatTreeList)
    }
  },

  created() {
    this.defineChildren = defineChildren

    this.handleRefresh()
  },

  methods: {
    async handleRefresh() {
      this.setIsLoading(true)
      const { list } = await services.professions.fetchAll()
      this.setIsLoading(false)

      this.professions.list = list
    },

    async handleDelete(branch) {
      const { isConfirmed } = await confirm.warning({
        text: [
          `Are you sure you want to delete the`,
          `"${branch.name}"`,
          branch.parentId ? `sub-profession?` : `profession?`
        ].join(' ')
      })

      if (!isConfirmed) return

      this.setIsLoading(true)
      const isSuccess = await services.professions.deleteOne(branch)
      this.setIsLoading(false)

      if (!isSuccess) return

      this.handleSuccess()
    },

    handleSuccess() {
      notify.success()
      this.handleRefresh()
    },

    handleOpenCreationDialog({ branch, isEditing } = {}) {
      this.creationDialog = { isVisible: true, branch, isEditing }
    },
    handleCloseCreationDialog() {
      this.creationDialog = { isVisible: false }
    },

    handleOpenManageDuplicatesDialog({ branch }) {
      this.manageDuplicatesDialog = { isVisible: true, keeper: branch }
    },
    handleCloseManageDuplicatesDialog() {
      this.manageDuplicatesDialog = { isVisible: false }
    },

    setIsLoading(isLoading) {
      this.professions.isLoading = isLoading
    },

    defineBranchMenuButtons(branch) {
      return [
        branch.depth === 0 && {
          label: 'Add sub-profession',
          color: 'primary',
          icon: 'mdi-plus',
          handler: () => this.handleOpenCreationDialog({ branch })
        },
        !branch.children?.length && {
          label: 'Manage Duplicates',
          color: 'primary',
          icon: 'mdi-content-duplicate',
          handler: () => this.handleOpenManageDuplicatesDialog({ branch })
        },
        {
          label: 'Edit',
          color: 'secondary',
          icon: 'mdi-pencil',
          handler: () =>
            this.handleOpenCreationDialog({ branch, isEditing: true })
        },
        {
          label: 'Delete',
          color: 'red',
          icon: 'mdi-trash-can',
          handler: () => this.handleDelete(branch)
        }
      ].filter(Boolean)
    }
  }
}
</script>
