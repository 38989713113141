import $api from '@/api'
import * as notify from '@/utils/notify'

export const checkVerificationCode = async payload => {
  let result = {}

  try {
    result = await $api.mfa.checkVerificationCode(payload)
    const { error } = result

    if (error) {
      notify.error({ text: error })
    }
  } catch {
    notify.error({ text: 'Could not check your verification code' })
  }

  return result
}

export const verifyAndAuthenticate = async payload => {
  let result = {}

  try {
    result = await $api.mfa.verifyAndAuthenticate(payload)
    const { error } = result

    if (error) {
      notify.error({ text: error })
    }
  } catch {
    notify.error({ text: 'Unable to check your verification code' })
  }

  return result
}
