<template>
  <div>
    <BaseTreeBranchTwig
      class="py-1"
      :parent="parent"
      :branch="branch"
      :selected="selected"
      is-selectable
      is-editable
      :is-collapsed="isCollapsed"
      :is-indeterminate="isIndeterminate"
      @toggle-collapse="handleToggleCollapse"
      @select="handleSelect"
    />

    <template v-if="!isCollapsed && branch.children">
      <BaseLazyHOC
        v-for="childBranch of branch.children"
        :key="childBranch.id"
        min-height="1rem"
      >
        <BaseTreeBranch
          class="ml-7"
          :parent="branch"
          :branch="childBranch"
          :selected="selected"
          is-selectable
          is-editable
          @select="handleSelect"
        />
      </BaseLazyHOC>
    </template>
  </div>
</template>

<script>
import BaseLazyHOC from '@/components/Base/BaseLazyHOC.vue'
import BaseTreeBranchTwig from '@/components/Admin/Base/BaseTree/BaseTreeBranchTwig.vue'

const BaseTreeBranch = () =>
  import('@/components/Admin/Base/BaseTree/BaseTreeBranch.vue')

export default {
  name: 'AdminBaseTreeBranch',

  components: { BaseLazyHOC, BaseTreeBranch, BaseTreeBranchTwig },

  inject: ['defineChildren'],

  props: {
    parent: { type: Object, default: null },
    branch: { type: Object, required: true },

    selected: { type: Array, default: () => [] }
  },

  data: () => ({
    isCollapsed: true
  }),

  computed: {
    allChildren() {
      return this.defineChildren(this.branch)
    },
    isIndeterminate() {
      const selectedIds = this.selected.map(item => item.id)
      return this.allChildren.some(item => selectedIds.includes(item.id))
    }
  },

  methods: {
    handleSelect(branch) {
      this.$emit('select', branch)
    },

    handleToggleCollapse() {
      this.isCollapsed = !this.isCollapsed
    },

    handleClose() {
      this.isCollapsed = true
    },
    handleOpen() {
      this.isCollapsed = false
    }
  }
}
</script>
