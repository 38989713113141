// MYZIURA 3 remove
import $api from '@/api'
import * as notify from '@/utils/notify'

export default {
  namespaced: true,

  state: {
    attributeCategories: {
      list: []
    }
  },

  actions: {
    async FETCH_ALL({ commit }, isForAdmin) {
      let result = {}

      try {
        result = await $api.attributeCategories.fetchAll({ isForAdmin })
        const { list, isSuccess, error } = result

        if (error) {
          notify.error({ text: error })
        } else {
          commit('SET_LIST', isSuccess ? list : [])
        }
      } catch (error) {
        notify.error({ text: error })
      }

      return result
    }
  },

  mutations: {
    SET_LIST(state, list) {
      state.attributeCategories.list = list
    }
  }
}
