<template>
  <div class="gap-4 flex flex-col flex-initial">
    <template v-if="normalizedList.length">
      <v-btn
        v-for="item of normalizedList"
        :key="item.contract.id"
        class="multiline-btn"
        color="primary"
        :loading="principalToContracts.isLoading"
        @click="() => handleDownload(item)"
      >
        <v-icon left>mdi-download</v-icon>
        <span>Download {{ item.label }}</span>

        <BaseTenantAvatars
          v-if="item?.contract?.tenantId"
          class="ml-2"
          is-small
          :list="[item.contract.tenantId]"
        />
      </v-btn>
    </template>

    <!-- <BaseEmpty
      v-else
      title="No contracts."
      :subtitle="[`You don't have signed contracts yet.`]"
    /> -->
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'

// import BaseEmpty from '@/components/Base/BaseEmpty.vue'
import BaseTenantAvatars from '@/components/Admin/Base/BaseTenantAvatars.vue'

export default {
  name: 'TenantBasePrincipalContractsDownloadButtons',

  components: { BaseTenantAvatars },

  props: {
    principal: { type: Object, required: true }
  },

  computed: {
    ...mapState('principal/contracts', ['principalToContracts']),
    ...mapGetters('principal/contracts', ['getNormalizedList']),

    normalizedList() {
      let list = this.getNormalizedList(this.principal)

      return list.filter(item => item.isSigned && item.isLatestVersion)
    }
  },

  created() {
    this.fetchAll()
  },

  methods: {
    ...mapActions('principal/contracts', {
      fetchAll: 'FETCH_ALL',
      downloadOne: 'DOWNLOAD_ONE'
    }),

    handleDownload({ principalToContract }) {
      this.downloadOne(principalToContract.id)
    }
  }
}
</script>
