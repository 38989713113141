<template>
  <div class="gap-4 flex flex-initial items-center">
    <v-avatar
      class="relative"
      :size="size"
      rounded
      :color="isLoaded ? 'white' : primaryColor"
      @click="handleClick"
    >
      <v-img
        :src="imagePath"
        :alt="tenant?.description"
        class="absolute inset-0 h-full w-full"
        :class="{ 'cursor-pointer': isClickable }"
        @load="handleLoaded"
      />

      <div
        v-if="!isLoaded"
        class="w-full h-full flex justify-center items-center text-center"
      >
        <span class="font-bold text-xs text-white leading-none">
          {{ defineTenantAbbr(tenant) }}
        </span>
      </div>
    </v-avatar>

    <div v-if="isLabel" class="flex flex-col">
      <strong data-test="avatar-title">{{ tenant?.name }}</strong>
      <small>{{ tenant?.description }}</small>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import { defineColorsByTenant } from '@/utils/theme-changer'

export default {
  name: 'BaseLogo',

  props: {
    to: { type: Object, default: () => ({ name: 't.home' }) },
    tenantMarker: { type: String, default: '' },
    size: { type: Number, default: 56 },
    isLabel: { type: Boolean, default: false }
  },

  data: () => ({
    isLoaded: false
  }),

  computed: {
    ...mapGetters('tenants', ['getTenantByMarker']),

    tenant() {
      return this.getTenantByMarker(this.tenantMarker) || null
    },

    primaryColor() {
      return this.tenant ? defineColorsByTenant(this.tenant).primary : 'white'
    },

    isClickable() {
      return Boolean(this.to)
    },

    imagePath() {
      if (!this.tenantMarker) return '/images/pharmyard-circle-logo.png'

      return this.tenant?.files?.logo?.path
    }
  },

  methods: {
    handleClick() {
      if (!this.isClickable) return

      this.$router.push(this.to)
    },

    handleLoaded() {
      this.isLoaded = true
    },

    // defineTenantPrimaryColor(tenant) {
    //   if (!tenant) return 'white'

    //   return JSON.parse(tenant?.styleJson)?.primaryColor || 'grey'
    // },

    defineTenantAbbr(tenant) {
      const abbr = tenant?.name.match(/\b\w/g).join('').toUpperCase()
      return abbr || ''
    }
  }
}
</script>
