import axios from 'axios'

import { formatItemResponse } from '@/utils/api'

const BASE_URL = `/api/machine-learning`
const URLs = new Proxy(
  {
    QUEUE_USER_RECOMMENDATION_REQUEST_BY_ASSIGNMENT: `/queue-recommendation-request`
  },
  { get: (target, prop) => `${BASE_URL}${target[prop]}` }
)

export default {
  async queueUserRecommendationRequestByAssignment(payload) {
    const res = await axios.post(
      URLs.QUEUE_USER_RECOMMENDATION_REQUEST_BY_ASSIGNMENT,
      payload
    )

    return formatItemResponse(res)
  }
}
