<template>
  <div>
    <AttributesTreeBranchTwig
      class="py-1"
      :parent="parent"
      :branch="branch"
      :selected="selected"
      :selected-categories="selectedCategories"
      :is-collapsed="isCollapsed"
      :is-indeterminate="isIndeterminate"
      @toggle-collapse="handleToggleCollapse"
      @select="handleSelect"
      @refresh="$emit('refresh')"
    />

    <div v-if="isCategoryCustomAttributeFeature" class="pl-16">
      <v-alert
        class="!m-0 border !border-info border-dashed"
        color="info"
        dense
        text
      >
        <div
          class="gap-2 flex flex-col items-center justify-between sm:flex-row"
        >
          <span v-if="branch.children?.length > 0">
            Didn't find the right {{ branch.name }}? Create your own.
          </span>
          <span v-else> Create your own {{ branch.name }} </span>
          <div>
            <v-btn color="info" small @click="handleCreateCustom">
              <v-icon left>mdi-plus</v-icon>
              <span>Create</span>
            </v-btn>
          </div>
        </div>
      </v-alert>
    </div>

    <template v-if="!isCollapsed && branch.children">
      <BaseLazyHOC
        v-for="childBranch of branch.children"
        :key="childBranch.id"
        min-height="1rem"
      >
        <AttributesTreeBranch
          class="ml-7"
          :parent="branch"
          :branch="childBranch"
          :selected="selected"
          :selected-categories="selectedCategories"
          @select="handleSelect"
          @refresh="$emit('refresh')"
        />
      </BaseLazyHOC>
    </template>
  </div>
</template>

<script>
import { defineChildren } from '@/utils/attributes'

import BaseLazyHOC from '@/components/Base/BaseLazyHOC.vue'
import AttributesTreeBranchTwig from '@/components/Attributes/AttributesTreeBranchTwig.vue'

const AttributesTreeBranch = () =>
  import('@/components/Attributes/AttributesTreeBranch.vue')

export default {
  name: 'AttributesTreeBranch',

  components: { BaseLazyHOC, AttributesTreeBranch, AttributesTreeBranchTwig },

  inject: [
    'isEditable',
    'isSelectable',
    'isForAdmin',
    'defineChildren',
    'defineBranchMenuButtons'
  ],

  props: {
    parent: { type: Object, default: null },
    branch: { type: Object, required: true },

    selected: { type: Array, default: () => [] },
    selectedCategories: { type: Array, default: () => [] }
  },

  data: () => ({
    isCollapsed: true
  }),

  computed: {
    allChildren() {
      return defineChildren(this.branch)
    },
    isIndeterminate() {
      const selectedIds = this.selected.map(item => item.id)
      return this.allChildren.some(item => selectedIds.includes(item.id))
    },
    isCategory() {
      return !this.branch.attributeCategoryId
    },
    isCategoryCustomAttributeFeature() {
      return (
        !this.isForAdmin &&
        this.isCategory &&
        this.branch.allowsUserAdditions &&
        this.branch.allowsAdHocCreation &&
        !this.isCollapsed
      )
    }
  },

  methods: {
    handleSelect(branch) {
      this.$emit('select', branch)
    },

    handleCreateCustom() {
      this.$emit('create-custom', { branch: this.branch })
    },

    handleToggleCollapse() {
      this.isCollapsed = !this.isCollapsed
    },

    handleClose() {
      this.isCollapsed = true
    },
    handleOpen() {
      this.isCollapsed = false
    }
  }
}
</script>
