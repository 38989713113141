<template>
  <div class="flex flex-col flex-1">
    <BasePaginatedTableContentActions
      v-if="isList"
      :actions="actions"
      @refresh="$emit('refresh')"
    >
      <slot name="actions" />
    </BasePaginatedTableContentActions>

    <v-divider />

    <div v-loader="table.isLoading" class="h-full flex flex-col">
      <BasePaginatedTableContentHeader
        class="w-full flex"
        :table="table"
        :store-path="storePath"
      />

      <v-divider />

      <BasePaginatedTableContentList :table="table" :store-path="storePath">
        <template #list-item-content="{ item, index }">
          <slot name="list-item-content" :item="item" :index="index" />
        </template>
      </BasePaginatedTableContentList>
    </div>
  </div>
</template>

<script>
import BasePaginatedTableContentActions from '@/components/Base/BasePaginatedTable/BasePaginatedTableContentActions.vue'
import BasePaginatedTableContentHeader from '@/components/Base/BasePaginatedTable/BasePaginatedTableContentHeader.vue'
import BasePaginatedTableContentList from '@/components/Base/BasePaginatedTable/BasePaginatedTableContentList.vue'

export default {
  name: 'BasePaginatedTableContent',

  components: {
    BasePaginatedTableContentActions,
    BasePaginatedTableContentHeader,
    BasePaginatedTableContentList
  },

  props: {
    table: { type: Object, required: true },
    storePath: { type: String, required: true },
    actions: { type: Array, default: () => [] }
  },

  computed: {
    isList() {
      const { rows, isLoading } = this.table
      return rows.list.length && !isLoading
    }
  }
}
</script>
