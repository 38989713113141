<template>
  <v-form ref="form" class="gap-4 flex flex-col">
    <v-autocomplete
      class="white"
      :value="assignments.selected"
      :items="assignments.list"
      :loading="assignments.isLoading"
      :disabled="isDisabledAssignmentsPicker"
      :rules="[$rules.required]"
      label="Select an assignment"
      placeholder="Select an assignment"
      item-text="title"
      item-value="id"
      return-object
      hide-details
      outlined
      dense
      @input="handleSelectAssignment"
    />

    <v-select
      class="white"
      :value="rounds.selected"
      :items="roundsList"
      :disabled="!assignments.selected || isLoading"
      :rules="[$rules.required]"
      item-text="roundNumber"
      item-value="id"
      label="Select a round"
      placeholder="Select a round"
      return-object
      hide-details
      outlined
      dense
      @input="handleSelectRound"
    />
  </v-form>
</template>

<script>
import $api from '@/api'

import * as notify from '@/utils/notify'

export default {
  name: 'InvitationsDialogUsersToJob',

  props: {
    assignmentId: { type: Number, default: null }
  },

  data: () => ({
    assignments: { list: [], selected: null, isLoading: false },
    rounds: { selected: null },

    isLoading: false
  }),

  computed: {
    roundsList() {
      const list = this.assignments?.selected?.rounds || []

      return list.sort((a, b) => a.roundNumber - b.roundNumber).reverse()
    },

    isDisabledAssignmentsPicker() {
      return (
        Boolean(this.assignmentId) ||
        this.assignments.isLoading ||
        this.isLoading
      )
    }
  },

  async created() {
    if (this.assignmentId) {
      await this.fetchOneAssignment()
      this.handleSelectAssignment(this.assignments.list[0])
      return
    }

    this.fetchAssignmentsList()
  },

  methods: {
    handleSelectAssignment(selected) {
      this.assignments.selected = selected

      if (!this.roundsList[0]) {
        notify.error({ text: 'Sorry, this assignment has no rounds yet.' })
        return
      }

      this.handleSelectRound(this.roundsList[0])
    },
    handleSelectRound(selected) {
      this.rounds.selected = selected
    },

    async handleInviteUsers(userIdList) {
      const payload = {
        userIdList,
        assignmentId: this.assignments.selected.id,
        roundId: this.rounds.selected.id,

        teamIdList: null
      }

      this.isLoading = true

      let result = {}

      try {
        result = await $api.assignments.inviteUsersOrTeams(payload)
        const { error } = result

        if (error) {
          notify.error({ text: error })
        }
      } catch (error) {
        notify.error({ text: error })
      } finally {
        this.isLoading = false
      }

      return result
    },

    validate() {
      const isValid = this.$refs.form.validate()

      return isValid
    },

    async fetchOneAssignment() {
      this.assignments.isLoading = true

      try {
        const { item, isSuccess, error } =
          await $api.assignments.fetchOneFlyweight({ id: this.assignmentId })

        if (error) {
          notify.error({ text: error })
          return
        }

        this.assignments.list = isSuccess ? [item] || [] : []
      } catch (error) {
        notify.error({ text: error })
      } finally {
        this.assignments.isLoading = false
      }
    },

    async fetchAssignmentsList() {
      this.assignments.isLoading = true

      try {
        const { list, isSuccess, error } = await $api.assignments.fetchAllOpen()

        if (error) {
          notify.error({ text: error })
          return
        }

        this.assignments.list = isSuccess ? list || [] : []
      } catch (error) {
        notify.error({ text: error })
      } finally {
        this.assignments.isLoading = false
      }
    }
  }
}
</script>
