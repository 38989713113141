import $api from '@/api'
import * as notify from '@/utils/notify'

export const authenticateLight = async payload => {
  let result = {}

  try {
    result = await $api.auth.authenticateLight(payload)
    const { error } = result

    if (error) {
      notify.error({ text: error })
    }
  } catch (error) {
    if (error?.response?.data?.detail) {
      notify.error({ title: error?.response?.data?.detail })
    } else {
      notify.error({ text: error })
    }
  }

  return result
}
