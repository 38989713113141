import Vue from 'vue'

import vuetify from '@/plugins/vuetify'

import UtilsConfirm from '@/components/Utils/UtilsConfirm.vue'

const ConfirmConstructor = Vue.extend(UtilsConfirm)

class Confirm {
  constructor(propsData) {
    this.instance = new ConfirmConstructor({ vuetify, propsData })

    this._destroyConfirm = () => {
      this.instance.$destroy()
      this.instance.$el.parentNode.removeChild(this.instance.$el)
    }
  }

  _addInstanceListeners() {
    this._closeEventListener = new Promise(resolve => {
      this._onCloseEvent = () => {
        this._destroyConfirm()
        resolve({ action: 'close' })
      }

      this.instance.$on('close', this._onCloseEvent)
    })

    this._cancelEventListener = new Promise(resolve => {
      this._onCancelEvent = () => {
        this.instance.handleClose()
        resolve({ action: 'cancel' })
      }

      this.instance.$on('cancel', this._onCancelEvent)
    })

    this._confirmEventListener = new Promise(resolve => {
      this._onConfirmEvent = data => {
        this.instance.handleClose()
        resolve({ action: 'confirm', data })
      }

      this.instance.$on('confirm', this._onConfirmEvent)
    })
  }

  _removeInstanceListeners() {
    this.instance.$off('close', this._onCloseEvent)
    this.instance.$off('cancel', this._onCancelEvent)
    this.instance.$off('confirm', this._onConfirmEvent)
  }

  _init() {
    this.instance.$mount()

    document.body.appendChild(this.instance.$el)

    this._addInstanceListeners()
  }

  open() {
    this._init()

    const defineAction = ({ action, data = null }) =>
      new Promise(resolve =>
        resolve({
          action,
          data,
          isClosed: action === 'close',
          isCancelled: action === 'cancel',
          isConfirmed: action === 'confirm'
        })
      )

    return Promise.race([
      this._closeEventListener,
      this._cancelEventListener,
      this._confirmEventListener
    ]).then(defineAction)
  }

  close() {
    this._removeInstanceListeners()
    this._destroyConfirm()
  }
}

export default Confirm
