import axios from 'axios'

import { formatResponse, formatItemResponse } from '@/utils/api'

const BASE_URL = `/api/team-group`
const URLs = new Proxy(
  {
    CREATE_ONE: `/create`,
    UPDATE_ONE: `/update`,
    DELETE_ONE: `/delete/`
  },
  { get: (target, prop) => `${BASE_URL}${target[prop]}` }
)

export default {
  async createOne(payload) {
    const res = await axios.post(URLs.CREATE_ONE, payload)

    return formatItemResponse(res)
  },
  async updateOne(payload) {
    const res = await axios.put(URLs.UPDATE_ONE, payload)

    return formatItemResponse(res)
  },
  async deleteOne({ id }) {
    const res = await axios.delete(`${URLs.DELETE_ONE}${id}`)

    return formatResponse(res)
  }
}
