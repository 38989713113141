import $api from '@/api'
import * as notify from '@/utils/notify'

export const fetchAllByPrincipal = async ({ principalId, userTypeCode }) => {
  let result = {}

  try {
    result = await $api.principalFiles.fetchAllByPrincipal({
      principalId,
      userTypeCode
    })
    const { error } = result

    if (error) {
      notify.error({ text: error })
    }
  } catch (error) {
    notify.error({ text: error })
  }

  return result
}

export const fetchAllByPrincipalAndCategory = async ({
  principalId,
  userTypeCode,
  categoryId
}) => {
  let result = {}

  try {
    result = await $api.principalFiles.fetchAllByPrincipalAndCategory({
      principalId,
      userTypeCode,
      categoryId
    })
    const { error } = result

    if (error) {
      notify.error({ text: error })
    }
  } catch (error) {
    notify.error({ text: error })
  }

  return result
}

export const addOneInvoice = async payload => {
  let result = {}

  try {
    result = await $api.principalFiles.addOneInvoice(payload)
    const { error } = result

    if (error) {
      notify.error({ text: error })
    }
  } catch (error) {
    notify.error({ text: error })
  }

  return result
}

export const addOne = async payload => {
  let result = {}

  try {
    result = await $api.principalFiles.addOne(payload)
    const { error } = result

    if (error) {
      notify.error({ text: error })
    }
  } catch (error) {
    notify.error({ text: error })
  }

  return result
}

export const updateOneWithFile = async payload => {
  let result = {}

  try {
    result = await $api.principalFiles.updateOneWithFile(payload)
    const { error } = result

    if (error) {
      notify.error({ text: error })
    }
  } catch (error) {
    notify.error({ text: error })
  }

  return result
}

// export const updateOneWithoutFile = async payload => {
//   let result = {}

//   try {
//     result = await $api.principalFiles.updateOneWithoutFile(payload)
//     const { error } = result

//     if (error) {
//       notify.error({ text: error })
//     }
//   } catch (error) {
//     notify.error({ text: error })
//   }

//   return result
// }

export const deleteOne = async ({ principal, id }) => {
  let result = {}

  const { id: principalId, userTypeCode } = principal

  try {
    result = await $api.principalFiles.deleteOne({
      principalId,
      userTypeCode,
      id
    })
    const { error } = result

    if (error) {
      notify.error({ text: error })
    }
  } catch (error) {
    notify.error({ text: error })
  }

  return result
}

export const deleteMultiple = async ({ principalId, userTypeCode, idList }) => {
  const promises = idList.map(id =>
    deleteOne({ principalId, userTypeCode, id })
  )

  return Promise.all(promises)
}

export const fetchAllCategories = async () => {
  let result = {}

  try {
    result = await $api.principalFiles.fetchAllCategories()
    const { error } = result

    if (error) {
      notify.error({ text: error })
    }
  } catch (error) {
    notify.error({ text: error })
  }

  return result
}

export const uriExists = async payload => {
  let result = {}

  try {
    result = await $api.principalFiles.uriExists(payload)
    const { error } = result

    if (error) {
      notify.error({ text: error })
    }
  } catch (error) {
    notify.error({ text: error })
  }

  return result
}

export const downloadFile = async payload => {
  let result = {}

  try {
    result = await $api.principalFiles.downloadFile(payload)
  } catch (error) {
    notify.error({ text: error })
  }

  return result
}
