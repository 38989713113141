<template>
  <v-form ref="form">
    <BasePickerTeam
      :value="teams.selected.id"
      label="Select team to join"
      placeholder="Type a name"
      :is-disabled="isLoading"
      is-chips
      is-chips-clickable
      :rules="!teams.newTeamName ? [$rules.required] : []"
      @input="handleSelectTeam"
    />

    <template v-if="!isTeamsSelected">
      <div class="my-2 flex items-center">
        <v-divider />

        <span class="mx-4">or</span>

        <v-divider />
      </div>

      <v-text-field
        v-model="teams.newTeamName"
        class="white"
        type="text"
        hide-details
        dense
        outlined
        :disabled="isLoading"
        :rules="[$rules.required]"
        placeholder="Enter a new team name to create"
        label="New team name"
      />
    </template>
  </v-form>
</template>

<script>
import $api from '@/api'

import * as notify from '@/utils/notify'

import BasePickerTeam from '@/components/Base/BasePicker/BasePickerTeam.vue'

export default {
  name: 'InvitationsDialogUsersToTeam',

  components: { BasePickerTeam },

  props: {
    users: { type: Array, default: () => [] }
  },

  data: () => ({
    teams: { selected: {}, newTeamName: '' },

    isLoading: false
  }),

  computed: {
    isTeamsSelected() {
      return this.teams.selected?.id
    }
  },

  methods: {
    handleSelectTeam(selected) {
      this.teams.selected = selected || {}
    },

    validate() {
      const isValid = this.$refs.form.validate()

      return isValid
    },

    async handleInviteUsers(userIdList) {
      const payload = {
        userIdList,
        existingTeamId: this.teams.selected.id,
        newTeamName: this.teams.newTeamName || '',

        teamGroupId: null,
        useTeamGroupId: false,
        removeFromTeamId: null
      }

      this.isLoading = true

      let result = {}

      try {
        result = await $api.teams.addAllUsersToTeam(payload)
        const { error } = result

        if (error) {
          notify.error({ text: error })
        }
      } catch (error) {
        notify.error({ text: error })
      } finally {
        this.isLoading = false
      }

      return result
    }
  }
}
</script>
