<template>
  <BaseDialog
    ref="base-dialog"
    :max-width="512"
    :header-title="`${introWord} user(s) to ${type}`"
    :is-loading="isLoading"
    :footer-buttons="footerButtons"
    @close="$emit('close')"
  >
    <BasePickerUser
      class="!mb-4"
      label="Invitees"
      :value="input.value"
      is-chips
      is-chips-clickable
      is-multiple
      @input="handleSelectUser"
    />

    <component :is="formComponent" ref="form" v-bind="formComponentProps" />
  </BaseDialog>
</template>

<script>
import * as notify from '@/utils/notify'

import BaseDialog from '@/components/Base/BaseDialog/BaseDialog.vue'
import BasePickerUser from '@/components/Base/BasePicker/BasePickerUser.vue'

import InvitationsDialogUsersToJob from '@/components/Invitations/InvitationsDialogUsersToJob.vue'
import InvitationsDialogUsersToTeam from '@/components/Invitations/InvitationsDialogUsersToTeam.vue'

const TYPES = Object.freeze({
  JOB: 'job',
  TEAM: 'team'
})

export { TYPES }
export default {
  name: 'InvitationsDialogUsers',

  components: {
    BaseDialog,
    BasePickerUser,

    InvitationsDialogUsersToJob,
    InvitationsDialogUsersToTeam
  },

  props: {
    userIdList: { type: Array, default: () => [] },
    type: {
      type: String,
      default: TYPES.JOB,
      validator: type => Object.values(TYPES).includes(type)
    },

    assignmentId: { type: Number, default: null },

    introWord: { type: String, default: 'Invite' }
  },

  data: () => ({
    input: {
      value: []
    },

    isLoading: false
  }),

  computed: {
    isType() {
      return {
        job: this.type === TYPES.JOB,
        team: this.type === TYPES.TEAM
      }
    },

    formComponent() {
      return this.isType.job
        ? 'InvitationsDialogUsersToJob'
        : 'InvitationsDialogUsersToTeam'
    },

    formComponentProps() {
      return this.isType.job ? { assignmentId: this.assignmentId } : {}
    },

    footerButtons() {
      const baseAttrs = { disabled: this.isLoading }

      return [
        {
          text: 'Cancel',
          attrs: { ...baseAttrs, color: 'grey', text: true },
          handler: this.handleClose
        },
        {
          text: this.isType.job ? 'Invite' : 'Add',
          attrs: { ...baseAttrs, color: 'primary' },
          handler: this.handleBeforeSubmit
        }
      ].filter(Boolean)
    }
  },

  created() {
    this.TYPES = TYPES

    this.input.value = this.userIdList
  },

  methods: {
    handleSelectUser(values) {
      this.input.value = values.map(item => item.id)
    },

    handleBeforeSubmit() {
      const isValid = this.$refs['form'].validate()

      if (!isValid) {
        notify.error({ title: 'Fill in all required fields' })
        return
      }

      this.handleSubmit()
    },

    async handleSubmit() {
      this.isLoading = true

      const { error } = await this.$refs['form'].handleInviteUsers(
        this.input.value
      )

      this.isLoading = false

      if (error) return

      this.handleSuccess()
    },

    handleSuccess() {
      notify.success({
        title: 'User(s) invited',
        text: 'The process will complete in the background'
      })

      this.$emit('success')

      this.handleClose()
    },

    handleClose() {
      this.$refs['base-dialog'].handleClose()
    }
  }
}
</script>
