import axios from 'axios'

import {
  formatResponse,
  formatItemResponse,
  formatListResponse
} from '@/utils/api'

const BASE_URL = `/api/mfa`
const URLs = new Proxy(
  {
    FETCH_ALL_OPTIONS: `/get-all-options`,

    SEND_VERIFICATION_CODE: '/send-verification-code',
    CHECK_VERIFICATION_CODE: '/check-verification-code',
    VERIFY_AND_AUTHENTICATE: '/verify-and-authenticate',

    UPDATE_ONE: '/update',
    CLEAR_ONE: '/clear'
  },
  { get: (target, prop) => `${BASE_URL}${target[prop]}` }
)

export default {
  async fetchAllOptions() {
    const res = await axios.get(URLs.FETCH_ALL_OPTIONS)

    return formatListResponse(res)
  },

  async sendVerificationCode(payload) {
    const res = await axios.post(URLs.SEND_VERIFICATION_CODE, payload)
    const { isSuccess, error } = res

    return { isSuccess, error }
  },

  async checkVerificationCode(payload) {
    const res = await axios.post(URLs.CHECK_VERIFICATION_CODE, payload)
    const { isSuccess, error } = res

    return { isSuccess, error }
  },

  async verifyAndAuthenticate(payload) {
    const res = await axios.post(URLs.VERIFY_AND_AUTHENTICATE, payload)

    return formatResponse(res)
  },

  async updateOne(payload) {
    const res = await axios.put(URLs.UPDATE_ONE, payload)

    return formatItemResponse(res)
  },

  async clearOne(payload) {
    const res = await axios.put(URLs.CLEAR_ONE, payload)
    const { isSuccess, error } = res

    return { isSuccess, error }
  }
}
