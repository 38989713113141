<template>
  <div
    class="pb-0 gap-4 flex flex-col-reverse justify-between sm:flex-row sm:items-center"
  >
    <div class="gap-4 flex items-center">
      <v-autocomplete
        :value="filters.orderBy"
        class="w-full min-w-[8rem] flex-initial sm:w-auto"
        background-color="white"
        dense
        outlined
        :items="orderByList"
        item-text="name"
        item-value="id"
        placeholder="Order By"
        hide-details
        @input="value => updateFilters({ orderBy: value })"
      />

      <v-tooltip bottom>
        <template #activator="{ on, attrs }">
          <v-btn
            icon
            v-bind="attrs"
            v-on="on"
            @click="handleTriggerSortDirection"
          >
            <v-icon>{{ sortDirection.icon }}</v-icon>
          </v-btn>
        </template>

        <span>{{ sortDirection.tooltip }}</span>
      </v-tooltip>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BasePaginatedTableFiltersOther',

  props: {
    filters: { type: Object, required: true },
    storePath: { type: String, required: true }
  },

  computed: {
    sortDirection() {
      return {
        icon: this.filters.isSortAscending
          ? 'mdi-sort-descending'
          : 'mdi-sort-ascending',
        tooltip: this.filters.isSortAscending
          ? 'Sorting in ascending order'
          : 'Sorting in descending order'
      }
    }
  },

  created() {
    this.orderByList = this.$store.state[this.storePath].orderByList
  },

  methods: {
    handleTriggerSortDirection() {
      this.updateFilters({ isSortAscending: !this.filters.isSortAscending })
    },

    updateFilters(payload) {
      return this.commit('UPDATE_FILTERS', payload)
    },

    commit(name, payload) {
      return this.$store.commit(`${this.storePath}/${name}`, payload, {
        root: true
      })
    }
  }
}
</script>
