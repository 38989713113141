<template>
  <div class="gap-4 flex flex-col">
    <div class="gap-4 flex flex-col md:flex-row">
      <!-- :class="{ 'md:flex-row': $isOn.memberPage }" -->
      <div>
        <v-card class="sticky top-20 flex flex-initial flex-shrink-0">
          <BaseProfilePrincipalTabs
            :value="activeTabCode"
            :tabs="tabs"
            @select="handleSelectTab"
          />
          <!-- is-vertical -->
        </v-card>
      </div>

      <div class="w-full gap-4 flex flex-col">
        <component
          :is="activeTab.component"
          :user="user"
          v-bind="activeTab.props"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { TABS as MEMBER_TABS } from '@/components/Tenant/Member/MemberProfile/MemberProfile.vue'
import { generateConnection } from '@/utils/base'

import BaseProfilePrincipalTabs from '@/components/Base/BaseProfilePrincipalTabs.vue'

const TabsAssessments = () =>
  import('@/components/Admin/AdminPeople/Person/Tabs/TabsAssessments.vue')
const TabsJobParticipation = () =>
  import('@/components/Admin/AdminPeople/Person/Tabs/TabsJobParticipation.vue')
const TabsTeamMembership = () =>
  import('@/components/Admin/AdminPeople/Person/Tabs/TabsTeamMembership.vue')

const TabsPersonalInfo = () =>
  import('@/components/Tenant/Member/MemberProfile/Tabs/TabsPersonalInfo.vue')
const TabsHealthcare = () =>
  import('@/components/Tenant/Member/MemberProfile/Tabs/TabsHealthcare.vue')
const TabsExpertise = () =>
  import('@/components/Tenant/Member/MemberProfile/Tabs/TabsExpertise.vue')
const TabsAvailability = () =>
  import('@/components/Tenant/Member/MemberProfile/Tabs/TabsAvailability.vue')
const TabsLinks = () =>
  import('@/components/Tenant/Member/MemberProfile/Tabs/TabsLinks.vue')
const TabsFiles = () =>
  import('@/components/Tenant/Member/MemberProfile/Tabs/TabsFiles.vue')
const TabsRates = () =>
  import('@/components/Tenant/Member/MemberProfile/Tabs/TabsRates.vue')
const TabsContracts = () =>
  import('@/components/Tenant/Member/MemberProfile/Tabs/TabsContracts.vue')
// const TabsCredentials = () =>
//   import('@/components/Tenant/Member/MemberProfile/Tabs/TabsCredentials.vue')
const TabsInvoices = () =>
  import('@/components/Tenant/Member/MemberProfile/Tabs/TabsInvoices.vue')

const TABS = (() => {
  const CODES = Object.freeze({
    ...MEMBER_TABS.codes,
    ADMIN_ASSESSMENTS: 'assessments',
    JOB_PARTICIPATION: 'job-participation',
    TEAM_MEMBERSHIP: 'team-membership'
  })

  const LABELS = Object.freeze({
    ...MEMBER_TABS.labels,
    ADMIN_ASSESSMENTS: 'Assessments',
    JOB_PARTICIPATION: 'Job Participation',
    TEAM_MEMBERSHIP: 'Team Membership'
  })

  return Object.freeze({
    codes: CODES,
    labels: LABELS,

    labelByCode: generateConnection(LABELS, CODES)
  })
})()

const BASE_TAB_CODE = TABS.codes.PERSON_INFO

export { TABS }
export default {
  name: 'AdminPeoplePersonTabs',

  components: {
    BaseProfilePrincipalTabs,

    TabsAssessments,
    TabsJobParticipation,
    TabsTeamMembership,

    TabsPersonalInfo,
    TabsHealthcare,
    TabsExpertise,
    TabsAvailability,
    TabsLinks,
    TabsFiles,
    TabsRates,
    TabsContracts,
    // TabsCredentials,
    TabsInvoices
  },

  props: {
    tabCode: { type: String, default: TABS.codes.PERSON_INFO },
    user: { type: Object, required: true }
  },

  data: () => ({
    activeTabCode: TABS.codes.PERSON_INFO
  }),

  computed: {
    activeTab() {
      return (
        this.tabs.find(tab => tab.code === this.activeTabCode) || this.tabs[0]
      )
    },

    tabs() {
      return [
        {
          code: TABS.codes.PERSON_INFO,
          text: TABS.labels.PERSON_INFO,
          icon: 'mdi-account-circle-outline',
          component: 'TabsPersonalInfo',
          props: { isMfa: false }
        },
        {
          code: TABS.codes.PORTFOLIO_FILES,
          text: 'Files',
          icon: 'mdi-file-account',
          component: 'TabsFiles',
          props: { headerTitle: 'Files' }
        },
        {
          code: TABS.codes.PORTFOLIO_LINKS,
          text: 'Links',
          icon: 'mdi-file-link-outline',
          component: 'TabsLinks',
          props: { headerTitle: 'Links' }
        },
        {
          code: TABS.codes.HEALTHCARE,
          text: TABS.labels.HEALTHCARE,
          icon: 'mdi-heart-plus-outline',
          component: 'TabsHealthcare'
        },
        {
          code: TABS.codes.EXPERTISE,
          text: TABS.labels.EXPERTISE,
          icon: 'mdi-math-compass',
          component: 'TabsExpertise',
          props: { isForAdmin: true }
        },
        {
          code: TABS.codes.ADMIN_ASSESSMENTS,
          text: TABS.labels.ADMIN_ASSESSMENTS,
          icon: 'mdi-school-outline',
          component: 'TabsAssessments'
        },
        {
          code: TABS.codes.AVAILABILITY,
          text: TABS.labels.AVAILABILITY,
          icon: 'mdi-calendar-month-outline',
          component: 'TabsAvailability'
        },

        {
          code: TABS.codes.RATES,
          text: TABS.labels.RATES,
          icon: 'mdi-cash',
          component: 'TabsRates'
        },

        {
          code: TABS.codes.CONTRACTS,
          text: TABS.labels.CONTRACTS,
          icon: 'mdi-script-outline',
          component: 'TabsContracts'
        },

        // {
        //   code: TABS.codes.CREDENTIALS,
        //   text: TABS.labels.CREDENTIALS,
        //   icon: 'mdi-certificate-outline',
        //   component: 'TabsCredentials'
        // },
        this.user?.canUploadInvoices && {
          code: TABS.codes.INVOICES,
          text: TABS.labels.INVOICES,
          icon: 'mdi-receipt',
          component: 'TabsInvoices'
        },

        {
          code: TABS.codes.JOB_PARTICIPATION,
          text: TABS.labels.JOB_PARTICIPATION,
          icon: 'mdi-briefcase',
          component: 'TabsJobParticipation'
        },
        {
          code: TABS.codes.TEAM_MEMBERSHIP,
          text: TABS.labels.TEAM_MEMBERSHIP,
          icon: 'mdi-account-group',
          component: 'TabsTeamMembership'
        }
      ].filter(Boolean)
    }
  },

  watch: {
    '$route.params.tabCode': {
      immediate: true,
      handler(value, oldValue) {
        if (value && value === oldValue) return

        this.handleChangeRouteTabCode({ isInitial: !value })
      }
    }
  },

  methods: {
    handleChangeRouteTabCode({ isInitial = false }) {
      const code = this.$route.params.tabCode
      const isValid = this.defineIsValidCode(code)

      if (isValid) {
        this.activeTabCode = code
      } else {
        this.activeTabCode = BASE_TAB_CODE
        this.handleSelectTab({ code: this.activeTabCode, isInitial })
      }

      this.scrollToTop()
    },

    handleSelectTab({ code, isInitial }) {
      const params = { ...this.$route.params, tabCode: code }

      const options = { name: this.$route.name, params }

      isInitial ? this.$router.replace(options) : this.$router.push(options)
    },

    defineIsValidCode(code) {
      if (!code) return

      const codes = this.tabs.map(tab => tab.code)
      const isValid = codes.includes(code)

      return isValid
    },

    scrollToTop() {
      window.scrollTo(0, 0)
    }
  }
}
</script>
