<template>
  <div class="gap-4 flex flex-col">
    <div class="flex flex-col rounded rounded">
      <div class="w-full -mb-px flex">
        <div
          v-for="address of addresses.list"
          :key="address.name + address.id"
          v-ripple
          class="p-2 px-4 -mr-px text-gray-500 text-center bg-gray-200 border border-gray-300 rounded-t-lg cursor-pointer"
          :class="{
            '!bg-white !border-b-0 !text-black z-[1]':
              addresses?.selected?.name === address.name
          }"
          @click="() => handleSelectAddress(address)"
        >
          <span>{{ address.name }}</span>
        </div>

        <div
          v-if="isEditable"
          v-ripple
          class="p-2 px-4 ml-auto text-gray-500 text-center bg-gray-200 border border-gray-300 rounded-t-lg cursor-pointer"
          :class="{
            '!bg-white !border-b-0 !text-black z-[1]': !addresses?.selected
          }"
          @click="() => handleSelectAddress(null)"
        >
          <v-icon small left>mdi-plus</v-icon>
          <span>Add</span>
        </div>
      </div>

      <div
        v-loader="addresses.isLoading"
        class="p-4 bg-white border border-gray-300 rounded-b"
      >
        <AdminTenantsCreationDialogAddressesItem
          :key="addresses.selected?.name"
          :tenant="tenant"
          :address="addresses.selected || {}"
          :countries-list="countries.list"
          :is-editable="isEditable"
          @refresh="handleRefresh"
        />
      </div>
    </div>
  </div>
</template>

<script>
import * as services from '@/services'

import AdminTenantsCreationDialogAddressesItem from '@/components/Admin/AdminTenants/AdminTenantsCreationDialogAddressesItem.vue'

export default {
  name: 'AdminTenantsCreationDialogAddresses',

  components: { AdminTenantsCreationDialogAddressesItem },

  props: {
    tenant: { type: Object, required: true },
    isEditable: { type: Boolean, default: true }
  },

  data: () => ({
    addresses: {
      list: [],
      selected: null,
      isLoading: false
    },

    countries: {
      list: [],
      isLoading: false
    }
  }),

  created() {
    this.handleRefresh()
  },

  methods: {
    handleSelectAddress(selected) {
      this.addresses.selected = selected
    },

    handleRefresh() {
      return Promise.all([this.fetchAllAddresses(), this.fetchAllCountries()])
    },

    async fetchAllAddresses() {
      this.addresses.isLoading = true
      const { list } = await services.tenantAddresses.fetchAllByTenantId({
        id: this.tenant.id
      })
      this.addresses.isLoading = false

      this.addresses.list = list
      this.addresses.selected = this.addresses.list[0]
    },

    async fetchAllCountries() {
      this.countries.isLoading = true

      const { list } = await services.geo.fetchAllCountries()

      this.countries.list = list
      this.countries.isLoading = false
    }
  }
}
</script>
