<template>
  <div class="flex flex-col" :class="{ 'details-padding': !isHideDetails }">
    <span v-if="label" class="mb-1 text-xs">{{ label }}</span>

    <v-btn-toggle
      :value="indexByValue"
      dense
      class="w-full"
      color="primary"
      mandatory
      @change="handleToggle"
    >
      <v-btn
        v-for="(button, index) of normalizedButtons"
        :key="`form-switch-button-${index}`"
        :data-test="`btn-switch-${button.label}`"
        class="btn-height flex grow !bg-white"
        :disabled="isDisabled"
      >
        {{ button.label }}
      </v-btn>
    </v-btn-toggle>
  </div>
</template>

<script>
export default {
  name: 'BaseFormSwitch',

  props: {
    value: { type: [String, Number, Boolean], default: false },
    label: { type: String, default: '' },

    buttons: { type: Array, default: null },

    isDisabled: { type: Boolean, default: false },
    isAnyButton: { type: Boolean, default: false },
    isHideDetails: { type: Boolean, default: false }
  },

  computed: {
    normalizedButtons() {
      return (
        this.buttons ||
        [
          this.isAnyButton && { label: 'Any', value: null },
          { label: 'No', value: false },
          { label: 'Yes', value: true }
        ].filter(Boolean)
      )
    },

    indexByValue() {
      return this.normalizedButtons.findIndex(item => item.value === this.value)
    }
  },

  methods: {
    handleToggle(index) {
      const value = this.normalizedButtons[index].value
      this.$emit('input', value)
    }
  }
}
</script>

<style lang="scss" scoped>
.btn-height {
  height: 40px !important;
}
.details-padding {
  padding-bottom: 26px;
}
</style>
