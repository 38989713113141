import axios from 'axios'

import { formatResponse, formatListResponse } from '@/utils/api'

const BASE_URL = `/api/principals`
const URLs = new Proxy(
  {
    FETCH_ALL_BUSINESS_ENTITY_TYPES: '/get-all-business-entity-types',
    FETCH_IMPERSONATION_TOKEN: '/get-impersonation-token/',
    FETCH_ALL_STATUSES: '/get-all-statuses',

    EXIST_ONE_BY_EMAIL: '/exists-by-email',

    CHANGE_PASSWORD: '/change-password'
  },
  { get: (target, prop) => `${BASE_URL}${target[prop]}` }
)

export default {
  async fetchAllBusinessEntityTypes() {
    const res = await axios.get(URLs.FETCH_ALL_BUSINESS_ENTITY_TYPES)

    return formatListResponse(res)
  },
  async fetchImpersonationToken(principalId) {
    const res = await axios.get(
      `${URLs.FETCH_IMPERSONATION_TOKEN}${principalId}`
    )

    return formatResponse(res)
  },
  async fetchAllStatuses() {
    const res = await axios.get(URLs.FETCH_ALL_STATUSES)

    return formatListResponse(res)
  },
  async existOneByEmail(payload) {
    const res = await axios.post(URLs.EXIST_ONE_BY_EMAIL, payload)

    return formatResponse(res)
  },

  async changePassword(payload) {
    const res = await axios.put(URLs.CHANGE_PASSWORD, payload)

    return formatResponse(res)
  }
}
