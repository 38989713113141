<template>
  <div class="w-full py-2 px-4 gap-2 flex flex-initial items-center">
    <v-checkbox
      class="!pt-0 !mt-0"
      :input-value="isSelectedAll"
      :indeterminate="isIndeterminate"
      :disabled="table.isLoading || !isList"
      hide-details
      dense
      @change="handleSelectAll"
    />

    <b>Select all</b>
  </div>
</template>

<script>
export default {
  name: 'BasePaginatedTableContentHeader',

  props: {
    table: { type: Object, required: true },
    storePath: { type: String, required: true }
  },

  computed: {
    isList() {
      return Boolean(this.table.rows.list.length)
    },

    isSelectedAll() {
      const { selected, list } = this.table.rows

      return selected?.length > 0 && selected?.length === list.length
    },

    isIndeterminate() {
      const { selected, list } = this.table.rows

      return selected?.length > 0 && selected?.length < list.length
    }
  },

  methods: {
    handleSelectAll() {
      this.setRowsSelected(this.isSelectedAll ? [] : this.table.rows.list)
    },

    setRowsSelected(payload) {
      return this.commit('SET_ROWS_SELECTED', payload)
    },

    commit(name, payload) {
      return this.$store.commit(`${this.storePath}/${name}`, payload, {
        root: true
      })
    }
  }
}
</script>
