export const formatResponse = res => {
  const { content, isSuccess, error } = res

  return { content, isSuccess, error }
}

export const formatListResponse = res => {
  const { content: list, isSuccess, error } = res

  const normalizedList = isSuccess ? list || [] : []

  return { list: normalizedList, isSuccess, error }
}

export const formatItemResponse = res => {
  const { content: item, isSuccess, error } = res

  return { item, isSuccess, error }
}
