import dayjs from 'dayjs'

import store from '@/store'
import * as notify from '@/utils/notify'
import * as routeUtils from '@/utils/route'
import { defineAbbr } from '@/utils/base'
import { USER_TYPES, TENANT_MARKERS } from '@/utils/consts'
import md5 from '@/utils/md5'

const defineIsTenantByMarker = marker => ({
  crowdPharm: marker === TENANT_MARKERS.codes.CP,
  hotIronHealth: marker === TENANT_MARKERS.codes.HIH
})

const mergeObjects = (obj1, obj2) => {
  const result = {}

  for (const key in obj1) {
    if (key in obj1) {
      if (Array.isArray(obj1[key]) && Array.isArray(obj2[key])) {
        result[key] = [...new Set([...obj1[key], ...obj2[key]])]
      } else if (
        typeof obj1[key] === 'object' &&
        typeof obj2[key] === 'object'
      ) {
        result[key] = mergeObjects(obj1[key], obj2[key])
      } else {
        result[key] = obj1[key]
      }
    }
  }

  for (const key in obj2) {
    if (key in obj2 && !(key in result)) {
      result[key] = obj2[key]
    }
  }

  return result
}

export default {
  computed: {
    $isTenant() {
      const marker = this.$route?.params?.tenantMarker

      return defineIsTenantByMarker(marker)
    },

    $isAdminTenant() {
      const adminTenants = store.state.admin.tenants.tenants.selected

      const multipleMarker = adminTenants.map(t => t.marker).join()

      return {
        crowdPharm: multipleMarker.includes('crowdpharm'),
        hotIronHealth: multipleMarker.includes('hot-iron-health'),

        onlyHotIronHealth: multipleMarker === 'hot-iron-health'
      }
    },

    $isOn() {
      return {
        adminPage: routeUtils.defineIsAdminPage(this.$route),

        hubPage: routeUtils.defineIsHubPage(this.$route),
        memberPage: routeUtils.defineIsMemberPage(this.$route),
        principalPage: routeUtils.defineIsPrincipalPage(this.$route),

        authPage: routeUtils.defineIsAuthPage(this.$route),
        mfaPage: routeUtils.defineIsMfaPage(this.$route),

        publicPage: routeUtils.defineIsPublicPage(this.$route)
      }
    },

    $breakpoints() {
      const { width: x } = this.$vuetify.breakpoint

      return {
        isMobileAndDown: x < 640,

        isTabletAndUp: x >= 640,
        isTabletAndDown: x < 768,

        isLaptopAndUp: x >= 768,
        isLaptopAndDown: x < 1024,

        isDesktopAndUp: x >= 1024,

        isBiggerDesktopAndUp: x >= 1280,

        isCustomAndUp: value => x >= value,
        isCustomAndDown: value => x < value

        // isTabletAndUp: breakpoint?.smAndUp,
        // isTabletAndDown: breakpoint?.smAndDown,
        // isMobileAndDown: breakpoint?.name === 'xs',
        // isDesktopAndUp: breakpoint?.mdAndUp
      }
    },

    $tenantRules() {
      const marker = this.$route?.params?.tenantMarker
      const tenant = store.getters['tenants/getTenantByMarker'](marker)

      const { attributeRules } = JSON.parse(tenant.rulesJson || '{}') || {}

      return { attributeRules }
    }
  },

  methods: {
    // MM/DD/YYYY
    $formatDate(value) {
      return value ? dayjs(value).format('L') : ''
    },
    // MM/DD/YYYY h:mm A
    $formatDateTime(value) {
      return value ? dayjs(value).format(`L LT`) : ''
    },
    // MM/DD/YYYY h:mm:ss A
    $formatDateTimeSeconds(value) {
      return value ? dayjs(value).format(`L LTS`) : ''
    },
    // MMMM D, YYYY
    $formatDateLong(value) {
      return value ? dayjs(value).format('LL') : ''
    },
    // MMMM D, YYYY h:mm A
    $formatDateLongTime(value) {
      return value ? dayjs(value).format('LLL') : ''
    },

    // UTC
    // MM/DD/YYYY
    $formatDateUTC(value) {
      return value ? dayjs.utc(value).format('L') : ''
    },

    $formatMoney: decimalValue =>
      decimalValue ? '$' + Number(decimalValue).toFixed(2) : '$0.00',

    $formatBytes(bytes, decimals = 2) {
      if (bytes === 0) return '0 Bytes'

      const dm = decimals < 0 ? 0 : decimals
      const k = 1000

      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
      const i = Math.floor(Math.log(bytes) / Math.log(k))

      return [parseFloat((bytes / Math.pow(k, i)).toFixed(dm)), sizes[i]].join(
        ' '
      )
    },

    $truncateText(input, maxLength, withElipses) {
      let result = input

      if (input && input.length > 0 && input.length > maxLength) {
        result = input.substring(0, maxLength - 1)
        result += withElipses ? '...' : ''
      }

      return result
    },

    $openWindow(url, name, features) {
      if (url && url.length > 0) {
        window.open(url, name, features)
      } else {
        console.error(`$openWindow: invalid URL '${url}'`)
      }
    },

    $md5(s) {
      return s ? md5(s) : ''
    },

    // $defineIsTenant(marker) {
    //   return defineIsTenantByMarker(marker)
    // },

    $defineIsTenantByUser(user) {
      const marker = user.tenants[0].marker

      return defineIsTenantByMarker(marker)
    },

    $defineAttributeRulesByUser(user) {
      const defineRuleByTenant = tenant => {
        const rules = JSON.parse(tenant.rulesJson || '{}') || {}
        const userType = USER_TYPES.nameByCode[user.userTypeCode]

        return rules.attributeRules[userType]
      }

      return user.tenants.reduce(
        (merged, tenant) => mergeObjects(merged, defineRuleByTenant(tenant)),
        {}
      )
    },

    $copyToClipboard(value) {
      navigator.clipboard.writeText(value).catch(() => {
        notify.info({ title: 'Failed to write text to the clipboard' })
      })

      notify.info({ title: 'Copied' })
    },

    $scrollTo(offset, callback) {
      const fixedOffset = offset.toFixed()
      const onScroll = function () {
        if (window.pageYOffset.toFixed() === fixedOffset) {
          window.removeEventListener('scroll', onScroll)
          callback?.()
        }
      }

      window.addEventListener('scroll', onScroll)
      onScroll()
      window.scrollTo({
        top: offset,
        behavior: 'smooth'
      })
    },

    $defineAbbr: defineAbbr
  }
}
