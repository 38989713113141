<template>
  <div
    class="w-full max-w-screen-lg p-8 gap-16 flex flex-col justify-center items-center mx-auto"
  >
    <div class="gap-4 flex flex-initial flex-col lg:gap-0">
      <h1 class="flex flex-col text-4xl font-black lg:text-5xl">
        Welcome to PharmYard, home to CrowdPharm and Hot Iron Health.
      </h1>

      <h2 class="text-lg font-black lg:text-3xl">
        We're taking you to CrowdPharm's new login page.
      </h2>
    </div>

    <div
      class="w-full gap-8 flex flex-col flex-initial items-center justify-between lg:flex-row"
    >
      <div class="flex flex-col flex-initial">
        <span class="font-black uppercase">old:</span>
        <h3
          class="font-black text-lg text-error underline break-all lg:text-2xl"
        >
          {{ fromDomain }}
        </h3>
      </div>

      <div class="flex flex-col justify-center lg:flex-row">
        <v-icon
          v-for="index of 3"
          :key="`arrow-${index}`"
          size="60"
          :color="arrows.activeIndex === index ? 'grey' : 'grey lighten-2'"
          dense
          :style="{
            ...(!$breakpoints.isLaptopAndDown && {
              'letter-spacing': '-1rem'
            }),
            ...(index > 0 && {
              'margin-bottom': '-1rem'
            })
          }"
        >
          {{
            $breakpoints.isLaptopAndDown
              ? 'mdi-chevron-down'
              : 'mdi-chevron-right'
          }}
        </v-icon>
      </div>

      <div class="flex flex-col flex-initial">
        <span class="font-black uppercase">new:</span>
        <h3
          class="font-black text-lg text-primary underline break-all lg:text-2xl"
        >
          pharmyard.com/crowdpharm
        </h3>
      </div>
    </div>

    <div class="mt-16 flex flex-initial flex-col text-center">
      <span
        class="text-gray-500"
        :class="{
          'opacity-0': timer.value <= 0
        }"
      >
        Taking you to our new home in {{ timer.value }} seconds...
      </span>
      <v-btn text color="primary" @click="handleClose"> Go now </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppRedirectAnimation',

  data: () => ({
    arrows: {
      activeIndex: 0,
      interval: null
    },

    timer: {
      value: 10,
      interval: null
    }
  }),

  computed: {
    fromDomain() {
      const BASE_DOMAIN = 'members.crowdpharm.com'
      const url = window?.frames?.top?.document?.referrer

      if (!url) {
        return BASE_DOMAIN
      }

      if (url?.includes('members')) {
        return BASE_DOMAIN
      }

      if (url?.includes('portal')) {
        return 'portal.crowdpharm.com'
      }

      return new URL(url)?.hostname || BASE_DOMAIN
    }
  },

  mounted() {
    this.arrows.interval = setInterval(() => {
      this.arrows.activeIndex = (this.arrows.activeIndex + 1) % 4
    }, 500)

    this.timer.interval = setInterval(() => {
      this.timer.value -= 1

      if (this.timer.value === 0) {
        clearInterval(this.timer.interval)
        this.handleClose()
      }
    }, 1000)
  },

  beforeDestroy() {
    clearInterval(this.arrows.interval)
    clearInterval(this.timer.interval)
  },

  methods: {
    handleClose() {
      this.$emit('close')
    }
  }
}
</script>
