import $api from '@/api'
import * as notify from '@/utils/notify'

export const fetchAllByPrincipal = async ({ principalId, userTypeCode }) => {
  let result = {}

  try {
    result = await $api.principalAttributes.fetchAllByPrincipal({
      principalId,
      userTypeCode
    })
    const { error } = result

    if (error) {
      notify.error({ text: error })
    }
  } catch (error) {
    notify.error({ text: error })
  }

  return result
}

export const fetchAllPrincipalsByAttributes = async payload => {
  let result = {}

  try {
    result = await $api.principalAttributes.fetchAllPrincipalsByAttributes(
      payload
    )
    const { error } = result

    if (error) {
      notify.error({ text: error })
    }
  } catch (error) {
    notify.error({ text: error })
  }

  return result
}

export const addMultipleByPrincipalAndCategory = async categoryId => {
  let result = {}

  try {
    result = await $api.principalAttributes.addMultipleByPrincipalAndCategory(
      categoryId
    )
    const { error } = result

    if (error) {
      notify.error({ text: error })
    }
  } catch (error) {
    notify.error({ text: error })
  }

  return result
}

export const setMultipleBest = async categoryId => {
  let result = {}

  try {
    result = await $api.principalAttributes.setMultipleBest(categoryId)
    const { error } = result

    if (error) {
      notify.error({ text: error })
    }
  } catch (error) {
    notify.error({ text: error })
  }

  return result
}
