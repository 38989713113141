import * as services from '@/services'

export default {
  namespaced: true,

  state: {
    users: {
      list: [],
      selected: null,
      isLoading: false
    }
  },

  actions: {
    async FETCH_ALL_BY_KEYWORD({ commit }, keyword) {
      let result = {}

      await commit('SET_IS_LOADING', true)

      result = await services.users.fetchAllByKeyword(keyword)
      const { list } = result

      await Promise.all([
        commit('SET_LIST', list),
        commit('SET_IS_LOADING', false)
      ])

      return result
    },

    async FETCH_ONE_BY_ID({ commit }, { id }) {
      let result = {}
      await commit('SET_IS_LOADING', true)

      result = await services.users.fetchOneById({ id })
      const { item } = result

      await Promise.all([
        commit('SET_SELECTED', item),
        commit('SET_IS_LOADING', false)
      ])

      return result
    }
  },

  mutations: {
    SET_LIST(state, list) {
      state.users.list = list
    },
    SET_SELECTED(state, selected) {
      state.users.selected = selected
    },
    UPDATE_SELECTED(state, toUpdate) {
      state.users.selected = { ...state.users.selected, ...toUpdate }
    },
    SET_IS_LOADING(state, isLoading) {
      state.users.isLoading = isLoading
    }
  }
}
