import axios from 'axios'

// import { formatListResponse } from '@/utils/api'

const BASE_URL = `/api/page`
const URLs = new Proxy(
  {
    FETCH_MEMBER_HOME: `/get-member-home`,
    FETCH_MEMBER_FOOTER: `/get-member-footer`,
    FETCH_ONE_BY_TITLE: '/get-by-title'
  },
  { get: (target, prop) => `${BASE_URL}${target[prop]}` }
)

export default {
  async fetchMemberHome() {
    const res = await axios.get(URLs.FETCH_MEMBER_HOME)
    const { content, isSuccess, error } = res

    return { content, isSuccess, error }
  },
  async fetchMemberFooter() {
    const res = await axios.get(URLs.FETCH_MEMBER_FOOTER)
    const { content, isSuccess, error } = res

    return { content, isSuccess, error }
  },
  async fetchOneByTitle(payload) {
    const res = await axios.post(URLs.FETCH_ONE_BY_TITLE, payload)
    const { content, isSuccess, error } = res

    return { content, isSuccess, error }
  }
}
