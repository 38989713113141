import axios from 'axios'

import { formatListResponse } from '@/utils/api'

const BASE_URL = `/api/geo`
const URLs = new Proxy(
  {
    FETCH_ALL_COUNTRIES: `/get-all-countries`,
    FETCH_ALL_TIMEZONES: `/get-all-timezones`,
    FETCH_PROVINCES_BY_COUNTRY: `/get-provinces-by-country/`
  },
  { get: (target, prop) => `${BASE_URL}${target[prop]}` }
)

export default {
  async fetchAllCountries(payload) {
    const res = await axios.get(URLs.FETCH_ALL_COUNTRIES, payload)

    return formatListResponse(res)
  },

  async fetchAllTimezones() {
    const res = await axios.get(URLs.FETCH_ALL_TIMEZONES)

    return formatListResponse(res)
  },

  async fetchProvincesByCountry(countryId) {
    if (!countryId) {
      countryId = 'unknown'
    }

    const res = await axios.get(
      `${URLs.FETCH_PROVINCES_BY_COUNTRY}${countryId}`
    )

    return formatListResponse(res)
  }
}
