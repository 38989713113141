import $api from '@/api'
import * as notify from '@/utils/notify'

export default {
  namespaced: true,

  state: {
    clients: {
      list: [],
      isLoading: false
    }
  },

  actions: {
    async FETCH_ALL({ commit }) {
      commit('SET_IS_LOADING', true)

      let res = {}

      try {
        res = await $api.clients.fetchAll()
        const { list, isSuccess, error } = res

        if (error) {
          notify.error({ text: error })
        } else {
          commit('SET_LIST', isSuccess ? list : [])
        }
      } catch (error) {
        notify.error({ text: error })
      } finally {
        commit('SET_IS_LOADING', false)
      }

      return res
    }
  },

  mutations: {
    SET_LIST(state, list) {
      state.clients.list = list
    },
    SET_IS_LOADING(state, isLoading) {
      state.clients.isLoading = isLoading
    }
  }
}
