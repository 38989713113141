import definitions from '@/api/assessment/definitions.api'
import definitionActors from '@/api/assessment/definition-actors.api'
import tasks from '@/api/assessment/tasks.api'
import attempts from '@/api/assessment/attempts.api'
import attemptComments from '@/api/assessment/attempt-comments.api'

export default {
  definitions,
  definitionActors,

  tasks,

  attempts,
  attemptComments
}
