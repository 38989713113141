import Confirm from '@/utils/confirm/class'

/*
  Props:
  [name]:                       [type]  | [default]

  type:                         Enum of TYPES
  title:                        String  | 'Confirm?',
  text:                         String  | 'Confirm action?',

  isCancelButton:               Boolean | true
  isConfirmButton:              Boolean | true

  confirmButtonText:            String  | 'Yes'
  cancelButtonText:             String  | 'Cancel'

  isDistinguishCancelAndClose:  Boolean | false
*/

const confirm = options => new Confirm(options).open()

export const TYPES = Object.freeze({
  WARNING: 'warning',
  INFO: 'info',
  ERROR: 'error'
})

export const warning = args => confirm({ type: TYPES.WARNING, ...args })
export const info = args => confirm({ type: TYPES.INFO, ...args })
export const error = args => confirm({ type: TYPES.ERROR, ...args })
