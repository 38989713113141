<template>
  <BaseDialog
    ref="base-dialog"
    :max-width="512"
    header-title="Manage Duplicate Professions"
    :is-loading="isLoading"
    :footer-buttons="footerButtons"
    @close="$emit('close')"
  >
    <div class="w-full flex flex-col">
      <v-form ref="form" class="gap-4 flex flex-col">
        {{ void (keeperLabel = `Select the profession to keep`) }}

        <v-combobox
          :value="form.keeper"
          name="keeper"
          :label="keeperLabel"
          :placeholder="keeperLabel"
          background-color="white"
          :items="list"
          :item-text="defineItemText"
          :item-value="item => item.id"
          clearable
          outlined
          :rules="[$rules.required]"
          @input="handleSelectKeeper"
        >
          <template #selection="{ attrs, item, selected }">
            <v-chip
              v-bind="attrs"
              class="multiline-chip"
              close
              :input-value="selected"
              @click:close="() => (form.keeper = null)"
            >
              <span class="multiline-chip__text">{{ item.name }}</span>
            </v-chip>
          </template>
        </v-combobox>

        {{ void (losersLabel = `Select professions to remove`) }}

        <v-combobox
          v-model="form.losers"
          name="losers"
          :label="losersLabel"
          :placeholder="losersLabel"
          background-color="white"
          :items="losersList"
          :item-text="defineItemText"
          :item-value="item => item.id"
          clearable
          outlined
          multiple
          :rules="[$rules.required]"
        >
          <template #selection="{ attrs, item }">
            <v-chip
              v-bind="attrs"
              class="multiline-chip"
              close
              :color="item.children ? 'error' : ''"
              @click:close="() => handleRemoveLoser(item)"
            >
              <span class="multiline-chip__text">
                {{ item.name }}
                {{ item.children ? '(Parent)' : '' }}
              </span>
            </v-chip>
          </template>

          <template #item="{ item }">
            {{ void (isSelected = form.losers.find(p => p.id === item.id)) }}

            <div class="w-full gap-4 flex justify-between">
              <div class="gap-4 flex">
                <v-checkbox
                  class="!m-0 !p-0"
                  :input-value="isSelected"
                  hide-details
                />
                <span :class="{ 'text-error': item.children }">
                  {{ item.name }}
                </span>
              </div>

              <v-chip v-if="item.children" small color="error"> Parent </v-chip>
            </div>
          </template>
        </v-combobox>

        <v-alert v-if="isSelectedLosersHasParent" class="m-0" type="error" text>
          <span>
            Parent professions can't be removed. Please remove the child
            professions of this parent first.
          </span>
        </v-alert>
      </v-form>
    </div>
  </BaseDialog>
</template>

<script>
import * as services from '@/services'

import { defineIsEqual } from '@/utils/base'

import BaseDialog from '@/components/Base/BaseDialog/BaseDialog.vue'

const defaultEmptyForm = Object.freeze({
  keeper: null,
  losers: []
})

export default {
  name: 'AdminProfessionsManageDuplicatesDialog',

  components: { BaseDialog },

  props: {
    list: { type: Array, default: () => [] },
    keeper: { type: Object, default: null },
    losers: { type: Array, default: () => [] }
  },

  data: () => ({
    form: { ...defaultEmptyForm },

    isLoading: false
  }),

  computed: {
    isModifiedForm() {
      return !defineIsEqual(this.defaultForm, this.form)
    },

    losersList() {
      return this.form.keeper?.id
        ? this.list.filter(item => item.id !== this.form.keeper.id)
        : this.list
    },

    isSelectedLosersHasParent() {
      return this.form.losers.some(item => item.children)
    },

    footerButtons() {
      return [
        {
          text: 'Close',
          attrs: { disabled: this.isLoading, color: 'grey', text: true },
          handler: this.handleClose
        },
        {
          text: 'Submit',
          attrs: {
            color: 'primary',
            disabled: !this.isModifiedForm || this.isSelectedLosersHasParent,
            loading: this.isLoading
          },
          handler: this.handleSubmit
        }
      ]
    }
  },

  created() {
    this.setInitialForm()
  },

  methods: {
    handleSelectKeeper(selected) {
      this.form.keeper = selected

      this.form.losers = this.form.losers.filter(
        item => item.id !== selected.id
      )
    },

    setInitialForm() {
      this.form = {
        keeper: this.keeper || null,
        losers: this.losers || []
      }
      this.defaultForm = structuredClone(this.form)
    },

    async handleSubmit() {
      const isValid = this.defineIsValid()

      if (!isValid) return

      const payload = this.definePayload()

      this.setIsLoading(true)
      const { isSuccess } = await services.professions.mergeMultiple(payload)
      this.setIsLoading(false)

      if (isSuccess) this.handleSuccess()
    },

    handleSuccess() {
      this.$emit('success')
      this.handleClose()
    },

    definePayload() {
      const payload = {
        keeperId: this.form.keeper.id,
        loserIds: this.form.losers.map(item => item.id)
      }

      return payload
    },

    defineIsValid() {
      if (this.isSelectedLosersHasParent) return false

      const isValid = this.$refs.form.validate()

      return isValid
    },

    setIsLoading(isLoading) {
      this.isLoading = isLoading
    },

    defineItemText: item => item.name,

    handleRemoveLoser(loser) {
      this.form.losers = this.form.losers.filter(item => item.id !== loser.id)
    },

    handleClose() {
      this.$refs['base-dialog'].handleClose()
    }
  }
}
</script>
