import $api from '@/api'
import * as notify from '@/utils/notify'

export const fetchAll = async () => {
  let result = {}

  try {
    result = await $api.contracts.fetchAll()
    const { error } = result

    if (error) {
      notify.error({ text: error })
    }
  } catch (error) {
    notify.error({ text: error })
  }

  return result
}

export const createOne = async payload => {
  let result = {}

  try {
    result = await $api.contracts.createOne(payload)
    const { error } = result

    if (error) {
      notify.error({ text: error })
    }
  } catch (error) {
    notify.error({ text: error })
  }

  return result
}

export const updateOne = async payload => {
  let result = {}

  try {
    result = await $api.contracts.updateOne(payload)
    const { error } = result

    if (error) {
      notify.error({ text: error })
    }
  } catch (error) {
    notify.error({ text: error })
  }

  return result
}

export const deleteOne = async payload => {
  let result = {}

  try {
    result = await $api.contracts.deleteOne(payload)
    const { error } = result

    if (error) {
      notify.error({ text: error })
    }
  } catch (error) {
    notify.error({ text: error })
  }

  return result
}

export const fetchAllForPrincipal = async principal => {
  let result = {}

  try {
    result = await $api.contracts.fetchAllForPrincipal(principal)
    const { error } = result

    if (error) {
      notify.error({ text: error })
    }
  } catch (error) {
    notify.error({ text: error })
  }

  return result
}

export const downloadOneForPrincipal = async principalToContractId => {
  let result = {}

  try {
    result = await $api.contracts.downloadOneForPrincipal(principalToContractId)
    const { error } = result

    if (error) {
      notify.error({ text: error })
    }
  } catch (error) {
    notify.error({ text: error })
  }

  return result
}

export const createOneForPrincipal = async payload => {
  let result = {}

  try {
    result = await $api.contracts.createOneForPrincipal(payload)
    const { error } = result

    if (error) {
      notify.error({ text: error })
    }
  } catch (error) {
    notify.error({ text: error })
  }

  return result
}

export const createOneForPrincipalWithUpload = async payload => {
  let result = {}

  try {
    result = await $api.contracts.createOneForPrincipalWithUpload(payload)
    const { error } = result

    if (error) {
      notify.error({ text: error })
    }
  } catch (error) {
    notify.error({ text: error })
  }

  return result
}

export const updateOneForPrincipal = async payload => {
  let result = {}

  try {
    result = await $api.contracts.updateOneForPrincipal(payload)
    const { error } = result

    if (error) {
      notify.error({ text: error })
    }
  } catch (error) {
    notify.error({ text: error })
  }

  return result
}

export const deleteOneForPrincipal = async id => {
  let result = {}

  try {
    result = await $api.contracts.deleteOneForPrincipal(id)
    const { error } = result

    if (error) {
      notify.error({ text: error })
    }
  } catch (error) {
    notify.error({ text: error })
  }

  return result
}

export const deleteOneAndCreateOneForPrincipal = async ({ id, payload }) => {
  const { isSuccess: isSuccessDelete } = await deleteOneForPrincipal(id)
  const { isSuccess: isSuccessCreate } = await createOneForPrincipal(payload)

  return { isSuccess: isSuccessDelete || isSuccessCreate }
}

export const saveUserNda = async payload => {
  let result = {}

  try {
    result = await $api.contracts.saveUserNda(payload)
    const { error } = result

    if (error) {
      notify.error({ text: error })
    }
  } catch (error) {
    notify.error({ text: error })
  }

  return result
}
export const downloadOneAsWordByHtml = async payload => {
  let result = {}

  try {
    result = await $api.contracts.downloadOneAsWordByHtml(payload)
    const { error } = result

    if (error) {
      notify.error({ text: error })
    }
  } catch (error) {
    notify.error({ text: error })
  }

  return result
}
