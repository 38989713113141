export const ICONS = Object.freeze({
  DEFAULT: 'mdi-link',
  YOUTUBE: 'mdi-youtube',
  VIMEO: 'mdi-vimeo'
})

export const LINK_PARTS = Object.freeze({
  YOUTUBE_SHORT_KEY: '/youtu.be/',
  YOUTUBE_LONG_KEY: '/www.youtube.com/embed/',
  VIMEO_SHORT_KEY: '/vimeo.com/',
  VIMEO_LONG_KEY: '/player.vimeo.com/video/'
})

const VIDEO_SERVICES = Object.freeze({
  YOUTUBE: {
    icon: ICONS.YOUTUBE,
    shortKey: LINK_PARTS.YOUTUBE_SHORT_KEY,
    longKey: LINK_PARTS.YOUTUBE_LONG_KEY,
    previewImageLink: id => `https://img.youtube.com/vi/${id}/0.jpg`
    // previewImageLink: id => `https://img.youtube.com/vi/${id}/maxresdefault.jpg`
  },
  VIMEO: {
    icon: ICONS.VIMEO,
    shortKey: LINK_PARTS.VIMEO_SHORT_KEY,
    longKey: LINK_PARTS.VIMEO_LONG_KEY,
    previewImageLink: id => `https://vumbnail.com/${id}_large.jpg`
  }
})

export const defineIsEmbedVideoLink = link => {
  return Object.values(LINK_PARTS).some(key => link?.includes(key))
}

export const parseVideoLink = link => {
  const isYouTube = link.includes(VIDEO_SERVICES.YOUTUBE.shortKey)
  const isVimeo = link.includes(VIDEO_SERVICES.VIMEO.shortKey)
  const isValid = isYouTube || isVimeo

  const id = link && isValid ? new URL(link).pathname.split('/')[1] : null

  let icon = ICONS.DEFAULT
  let previewLink = null
  let normalizedVideoLink = link

  const { YOUTUBE, VIMEO } = VIDEO_SERVICES

  if (isYouTube) {
    icon = YOUTUBE.icon
    previewLink = YOUTUBE.previewImageLink(id)
    normalizedVideoLink = link.replace(YOUTUBE.shortKey, YOUTUBE.longKey)
  } else if (isVimeo) {
    icon = VIMEO.icon
    previewLink = VIMEO.previewImageLink(id)
    normalizedVideoLink = link.replace(VIMEO.shortKey, VIMEO.longKey)
  }

  return { normalizedVideoLink, previewLink, icon, isValid, isYouTube, isVimeo }
}

// https://youtu.be/dQw4w9WgXcQ
// https://youtube.com/embed/dQw4w9WgXcQ
// https://img.youtube.com/vi/dQw4w9WgXcQ/maxresdefault.jpg

// https://vimeo.com/719182720
// https://player.vimeo.com/video/719182720
// http://vimeo.com/api/v2/video/719182720.json?callback=showThumb
// https://vumbnail.com/719182720_small.jpg
// https://vumbnail.com/719182720_medium.jpg
// https://vumbnail.com/719182720_large.jpg
// thumbnail_large
