<template>
  <div
    class="flex items-center select-none cursor-pointer"
    :class="{ 'bg-white': isParent }"
    :style="styles"
    :depth="branch.depth"
    :depth-inverted="branch.depthInverted"
    @click="handleClick"
  >
    <v-btn
      class="mb-auto mr-1"
      :class="{ invisible: !isParent }"
      icon
      small
      @click.stop="handleToggleCollapse"
    >
      <v-icon>{{ isCollapsed ? 'mdi-plus' : 'mdi-minus' }}</v-icon>
    </v-btn>

    <div class="w-full flex flex-nowrap items-end">
      <div class="w-full flex">
        <v-checkbox
          :input-value="isSelected"
          class="!p-0 !m-0 flex-shrink-0"
          :indeterminate="isIndeterminate && !isSelected"
          dense
          hide-details
          @change="handleSelect"
          @click.native.stop
        />

        <span
          class="leaders after:bottom-[6px]"
          :class="{ 'font-bold': isParent }"
        >
          <span>{{ branch.name }}</span>
          <span
            v-if="isParent"
            class="ml-1 inline-flex text-gray-500 font-medium"
          >
            ({{ allChildren.length }})
          </span>
        </span>

        <div class="h-7 mt-auto inline-flex items-center">
          <v-tooltip v-if="branch.isUserSpecific" left color="info">
            <template #activator="{ on, attrs }">
              <v-icon class="ml-1" small color="info" v-bind="attrs" v-on="on">
                mdi-account-edit
              </v-icon>
            </template>

            <span class="text-white">User specific</span>
          </v-tooltip>
        </div>

        <BaseTenantAvatars
          v-if="branch.tenants?.length > 0"
          class="ml-1"
          :list="branch.tenants"
          is-small
          is-rtl
        />
      </div>
    </div>

    <BaseMenu
      :is-visible="menu.isVisible"
      :header-title="branch.name"
      :item="branch"
      :buttons="defineBranchMenuButtons(branch)"
      @close="handleCloseMenu"
    />
  </div>
</template>

<script>
import BaseMenu from '@/components/Base/BaseMenu.vue'
import BaseTenantAvatars from '@/components/Admin/Base/BaseTenantAvatars.vue'

export default {
  name: 'AdminBaseTreeBranchTwig',

  components: { BaseMenu, BaseTenantAvatars },

  inject: ['defineChildren', 'defineBranchMenuButtons'],

  props: {
    branch: { type: Object, required: true },
    parent: { type: Object, default: null },
    selected: { type: Array, default: () => [] },

    isCollapsed: { type: Boolean, default: true },
    isIndeterminate: { type: Boolean, default: false }
  },

  data: () => ({
    menu: { isVisible: false }
  }),

  computed: {
    allChildren() {
      return this.defineChildren(this.branch)
    },
    isSelected() {
      const isSelectedBranch = Boolean(
        this.selected.find(item => item.id === this.branch.id)
      )
      return isSelectedBranch
    },
    isParent() {
      return Boolean(this.branch.children)
    },

    styles() {
      return this.isParent
        ? {
            position: 'sticky',
            top: `${this.branch.depth * 36}px`,
            'z-index': this.branch.depthInverted,
            'min-height': '36px'
          }
        : ''
    }
  },

  methods: {
    handleCloseMenu() {
      this.menu.isVisible = false
    },

    handleSelect() {
      this.$emit('select', this.branch)
    },

    handleClick() {
      this.isParent ? this.handleToggleCollapse() : this.handleSelect()
    },
    handleToggleCollapse() {
      this.$emit('toggle-collapse')
    }
  }
}
</script>
