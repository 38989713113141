export default {
  namespaced: true,

  state: {
    stepper: {
      isLoading: false
    }
  },

  mutations: {
    SET_IS_LOADING(state, isLoading) {
      state.stepper.isLoading = isLoading
    }
  }
}
