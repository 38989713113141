import axios from 'axios'

const BASE_URL = `/api/attachment`
const URLs = new Proxy(
  {
    UPDATE_ONE_DESCRIPTION: `/update-description`
  },
  { get: (target, prop) => `${BASE_URL}${target[prop]}` }
)

export default {
  async updateOneDescription(payload) {
    const res = await axios.put(URLs.UPDATE_ONE_DESCRIPTION, payload)

    const { content: item, isSuccess, error } = res

    return { item, isSuccess, error }
  }
}
