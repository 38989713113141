export const defineChildren = branch => {
  return (branch.children || []).reduce((result, b) => {
    if (!b.children) return [...result, b]

    return [...result, b, ...defineChildren(b)]
  }, [])
}

export const setDepth = (treeList, depth = 0) =>
  (treeList || []).forEach(branch => {
    branch.depth = depth
    setDepth(branch.children, depth + 1)
  })

export const setDepthInverted = treeList => {
  const treeListAsBranch = { children: treeList }
  const allChildren = defineChildren(treeListAsBranch)

  allChildren.forEach(branch => {
    const allChildren = defineChildren(branch)
    const allDepths = allChildren.map(b => b.depth)
    const maxDepth = allDepths.length ? Math.max(...allDepths) : 0
    const depthInverted = maxDepth - branch.depth

    branch.depthInverted = depthInverted
  })
}

export const defineTreeList = list => {
  const defineBranch = item => {
    const children = list.filter(i => i.parentId === item.id) || []
    const isChildren = Boolean(children.length)

    item = isChildren ? { ...item, children: children.map(defineBranch) } : item

    const allChildren = defineChildren(item)
    const allChildrenNames = isChildren ? allChildren.map(i => i.name) : []

    item.searchValue = [item.name, ...allChildrenNames].join(' ')

    return item
  }

  let treeList = list.reduce((result, item) => {
    const isRoot = !item.parentId

    if (!isRoot) return result

    const branch = defineBranch(item)

    return [...result, branch]
  }, [])

  setDepth(treeList)
  setDepthInverted(treeList)

  treeList.sort((a, b) => a.searchValue.localeCompare(b.searchValue))

  return treeList
}
