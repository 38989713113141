<template>
  <BaseDialog
    ref="base-dialog"
    :max-width="512"
    :header-title="headerTitle"
    :is-loading="isLoading"
    :footer-buttons="footerButtons"
    @close="$emit('close')"
  >
    <v-form ref="form" class="w-full flex flex-col flex-initial">
      <v-text-field
        v-model="form.name"
        label="Category name"
        maxlength="255"
        background-color="white"
        outlined
        dense
        :rules="[$rules.required]"
      />

      <v-textarea
        v-model="form.description"
        label="Description"
        maxlength="500"
        background-color="white"
        outlined
        dense
      />

      <div class="flex flex-col sm:flex-row sm:gap-4">
        <v-text-field
          v-model.number="form.ordinalPosition"
          label="Display Position (Optional)"
          background-color="white"
          outlined
          dense
          type="number"
          :rules="[$rules.numeric, $rules.positive]"
        />
      </div>

      <BaseFormSwitch
        v-model="form.isOnlyForInternalUse"
        label="Only for internal use"
      />

      <BaseFormSwitch
        v-model="form.isAllowsUserAdditions"
        label="Allow users to create their own attributes"
      />

      <BaseFormSwitch
        v-model="form.isUserAdditionsShared"
        label="User additions shared"
      />

      <BaseFormSwitch
        v-model="form.allowsAdHocCreation"
        label="Allows ad-hoc user attribute creation"
      />

      <AdminBaseTenantsSelectableList v-model="form.tenantIdList" is-multiple />

      <AttributesCategoryCreationDialogTenantSpecificSettings
        v-if="form.tenantIdList"
        :value="form"
        :category="branch"
        :tenant-id-list="form.tenantIdList"
        @update:value="rulesJson => updateForm({ rulesJson })"
      />
    </v-form>
  </BaseDialog>
</template>

<script>
import $api from '@/api'

import * as notify from '@/utils/notify'
import { defineIsEqual } from '@/utils/base'

import BaseDialog from '@/components/Base/BaseDialog/BaseDialog.vue'
import BaseFormSwitch from '@/components/Base/Form/FormSwitch.vue'

import AdminBaseTenantsSelectableList from '@/components/Admin/Base/BaseTenantsSelectableList.vue'
import AttributesCategoryCreationDialogTenantSpecificSettings from '@/components/Attributes/AttributesCategoryCreationDialogTenantSpecificSettings.vue'

const defaultEmptyForm = Object.freeze({
  name: '',
  description: '',
  isOnlyForInternalUse: false,
  isAllowsUserAdditions: false,
  isUserAdditionsShared: false,
  ordinalPosition: 0,
  tenantIdList: [],
  rulesJson: null
})

export default {
  name: 'AttributesCategoryCreatingDialog',

  components: {
    BaseDialog,
    BaseFormSwitch,
    AdminBaseTenantsSelectableList,
    AttributesCategoryCreationDialogTenantSpecificSettings
  },

  props: {
    branch: { type: Object, default: null },
    isEditing: { type: Boolean, default: false }
  },

  data: () => ({
    form: { ...defaultEmptyForm },
    isLoading: false
  }),

  computed: {
    isCreating() {
      return !this.isEditing
    },

    isModifiedForm() {
      return !defineIsEqual(this.defaultForm, this.form)
    },

    headerTitle() {
      return [this.isCreating ? 'Create new' : 'Update', 'category'].join(' ')
    },

    footerButtons() {
      return [
        {
          text: 'Close',
          attrs: { disabled: this.isLoading, color: 'grey', text: true },
          handler: this.handleClose
        },
        {
          text: this.isCreating ? 'Create' : 'Save',
          attrs: {
            color: 'primary',
            loading: this.isLoading
          },
          handler: this.handleSubmit
        }
      ]
    }
  },

  created() {
    this.setInitialForm()
  },

  methods: {
    setInitialForm() {
      this.form = this.isEditing
        ? {
            name: this.branch.name,
            description: this.branch.description,
            isOnlyForInternalUse: this.branch.forInternalUse,
            isAllowsUserAdditions: this.branch.allowsUserAdditions,
            isAllowsAdHocCreation: this.branch.allowsAdHocCreation,
            isUserAdditionsShared: this.branch.areUserAdditionsShared,
            ordinalPosition: this.branch.ordinalPosition,
            tenantIdList: this.branch.tenants.map(item => item.id)
          }
        : structuredClone(defaultEmptyForm)
      this.defaultForm = structuredClone(this.form)
    },

    handleSubmit() {
      const isValid = this.defineIsValid()

      if (!isValid) return

      this.makeRequest()
    },

    handleSuccess() {
      this.$emit('success')
      this.handleClose()
    },

    async makeRequest() {
      const payload = this.definePayload()
      const requestsMethod = this.defineRequestMethod()

      this.setIsLoading(true)

      try {
        const { error } = await requestsMethod(payload)

        if (error) {
          notify.error({ text: error })
          return
        }

        this.handleSuccess()
      } catch (error) {
        notify.error({ text: error })
      } finally {
        this.setIsLoading(false)
      }
    },

    defineRequestMethod() {
      return this.isCreating
        ? $api.attributeCategories.createOne
        : $api.attributeCategories.updateOne
    },

    definePayload() {
      const payload = {
        id: this.isEditing ? this.branch.id : undefined,
        name: this.form.name,
        description: this.form.description,
        forInternalUse: this.form.isOnlyForInternalUse,
        allowsUserAdditions: this.form.isAllowsUserAdditions,
        allowsAdHocCreation: this.form.isAllowsAdHocCreation,
        areUserAdditionsShared: this.form.isUserAdditionsShared,
        ordinalPosition: this.form.ordinalPosition,
        tenantIdList: this.form.tenantIdList,
        rulesJson: this.form.rulesJson
      }

      // delete payload.$type
      delete payload.children

      return payload
    },

    updateForm(toUpdate) {
      this.form = { ...this.form, ...toUpdate }
    },

    defineIsValid() {
      return this.$refs.form.validate()
    },

    setIsLoading(isLoading) {
      this.isLoading = isLoading
    },

    handleClose() {
      this.$refs['base-dialog'].handleClose()
    }
  }
}
</script>
