<template>
  <div class="h-full p-4 grid place-content-center text-center">
    <h1 class="text-8xl text-red-700">404</h1>
    <h2 class="text-2xl">Page not found</h2>

    <router-link class="mt-8" :to="{ name: 't.home' }">
      <span class="underline">Go home</span>
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'PublicNotFoundPage'
}
</script>
