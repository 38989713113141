import $api from '@/api'

import * as notify from '@/utils/notify'

export default {
  namespaced: true,

  state: {
    attempts: {
      list: [],
      isLoading: false
    }
  },

  actions: {
    async FETCH_ALL({ rootState, commit }, { definitionId }) {
      commit('SET_IS_LOADING', true)

      let result = {}

      const userId = rootState.user.user.id
      definitionId = definitionId || rootState.assessment.tasks.selected?.id

      try {
        result = await $api.assessment.attempts.fetchAllByUserIdAndDefinitionId(
          { userId, definitionId }
        )
        const { list, isSuccess, error } = result

        if (error) {
          notify.error({ text: error })
        } else {
          await commit('SET_LIST', isSuccess ? list : [])
        }
      } catch (error) {
        notify.error({ text: error })
      } finally {
        commit('SET_IS_LOADING', false)
      }

      return result
    },

    async CREATE_ONE({ rootState, commit }, payload) {
      commit('SET_IS_LOADING', true)

      let result = {}

      payload = {
        ...payload,
        UserId: rootState.user.user.id
      }

      try {
        result = await $api.assessment.attempts.createOne(payload)
        const { error } = result

        if (error) {
          notify.error({ text: error })
        }
      } catch (error) {
        notify.error({ text: error })
      } finally {
        commit('SET_IS_LOADING', false)
      }

      return result
    },

    async UPDATE_ONE({ rootState, commit }, toUpdate) {
      commit('SET_IS_LOADING', true)

      let result = {}

      toUpdate = {
        // AssessmentDefinitionId: definitionId,
        UserId: rootState.user.user.id,
        ...toUpdate
      }

      try {
        result = await $api.assessment.attempts.updateOne(toUpdate)
        const { item, error } = result

        if (error) {
          notify.error({ text: error })
        } else {
          await commit('SET_LIST_ITEM', item)
        }
      } catch (error) {
        notify.error({ text: error })
      } finally {
        commit('SET_IS_LOADING', false)
      }

      return result
    },

    async DELETE_ONE({ commit, dispatch }, id) {
      commit('SET_IS_LOADING', true)

      let result = {}

      try {
        result = await $api.assessment.attempts.deleteOne(id)
        const { error } = result

        if (error) {
          notify.error({ text: error })
        } else {
          await dispatch('FETCH_ALL')
        }
      } catch (error) {
        notify.error({ text: error })
      } finally {
        commit('SET_IS_LOADING', false)
      }

      return result
    },

    async FETCH_FILES_ALL_BY_ID(_, attemptId) {
      let result = {}

      try {
        result = await $api.assessment.attempts.fetchFilesAllById(attemptId)
        const { error } = result

        if (error) {
          notify.error({ text: error })
        }
      } catch (error) {
        notify.error({ text: error })
      }

      return result
    },

    async ADD_FILE_ONE(_, payload) {
      let result = {}

      try {
        result = await $api.assessment.attempts.addFileOne(payload)
        const { error } = result

        if (error) {
          notify.error({ text: error })
        }
      } catch (error) {
        notify.error({ text: error })
      }

      return result
    }
  },

  mutations: {
    SET_LIST(state, list) {
      state.attempts.list = list
    },
    SET_LIST_ITEM(state, listItem) {
      state.attempts.list = state.attempts.list.map(item =>
        item.id === listItem.id ? listItem : item
      )
    },
    SET_SELECTED(state, selected) {
      state.attempts.selected = selected
    },
    SET_IS_LOADING(state, isLoading) {
      state.attempts.isLoading = isLoading
    }
  }
}
