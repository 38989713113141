<template>
  <div ref="target" :style="isShouldRender ? {} : { 'min-height': minHeight }">
    <v-fade-transition>
      <slot v-if="isShouldRender" />
    </v-fade-transition>
  </div>
</template>

<script>
export default {
  name: 'BaseLazyHOC',

  props: {
    rootEl: { type: Object, default: null },
    minHeight: { type: String, required: true }
  },

  data: () => ({
    observer: null,
    targetEl: null,

    isShouldRender: false
  }),

  mounted() {
    let options = {
      root: null,
      rootMargin: '0px',
      threshold: 1.0
    }

    this.targetEl = this.$refs.target

    const handler = entries => {
      entries.forEach(entry => {
        if (!entry.isIntersecting) return

        this.isShouldRender = true
        this.observer.unobserve(this.targetEl)
      })
    }

    this.observer = new IntersectionObserver(handler, options)

    this.observer.observe(this.targetEl)
  },

  beforeDestroy() {
    this.observer.observe(this.targetEl)
  }
}
</script>
