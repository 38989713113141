export default {
  inserted(el, binding) {
    const options = binding.value || {}

    const callback = entries => {
      entries.forEach(entry => {
        if (entry.intersectionRatio > (options.threshold || 0)) {
          options.onVisible?.(entry.target)
        } else {
          options.onHidden?.(entry.target)
        }
      })
    }

    const observer = new IntersectionObserver(callback, { threshold: 0 })

    observer.observe(el)
    el._vue_visibility_observer = observer
  },

  unbind(el) {
    if (el._vue_visibility_observer) {
      el._vue_visibility_observer.disconnect()
      delete el._vue_visibility_observer
    }
  }
}
